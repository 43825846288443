import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Offcanvas } from 'react-bootstrap'
import Sidenav from '../../Componet/Sidenav/Sidenav'
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg'
import Input from '../../Componet/InputFild/Input'
import Table from '../../Componet/DataTable/Table'
import {
  FormControl,
  InputLabel,
  //  Select,
  MenuItem
} from '@mui/material'
import './Animal.css'
import { MdOutlineVpnKey } from 'react-icons/md'
import { GoSignOut } from 'react-icons/go'

import { STORAGE_KEY } from '../../constant/common'
import { API_URLS, serviceUrl } from '../../API/Api'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import BackBtn from '../../Componet/Button/BackBtn'
const { Animal_Zone } = API_URLS
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN)

async function getZone (credentials) {
  return fetch(Animal_Zone.GetZone, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(res => res.json())
}

async function getVisitRoute (credentials) {
  return fetch(Animal_Zone.GetVisitRoute, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(res => res.json())
}

async function getData (credentials) {
  return fetch(Animal_Zone.GetData, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(res => res.json())
}

async function transferData (credentials) {
  return fetch(Animal_Zone.TransferData, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(res => res.json())
}

const Animal = () => {
  const API_KEY = serviceUrl

  const [search, setSearch] = useState('')
  const [center, setCenter] = useState('')
  const [route, setRoute] = useState('')
  const [dcs, setDcs] = useState('')
  const [farmer, setFarmer] = useState('')
  const [lot, setLot] = useState('')
  const [routeVisit, setRouteVisit] = useState('')
  const [farmerName, setFarmerName] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [progressData, setProgressData] = useState('0%')
  const navigate = useNavigate()
  const logOut = () => {
    navigate('/')
  }

  const [heightOffSet, setHeight] = useState(0)

  const [animalList, setAnimalList] = useState([])
  const [centerList, setCenterList] = useState([])
  const [routeList, setRouteList] = useState([])
  const [dcsList, setDcsList] = useState([])
  const [farmerList, setFarmerList] = useState([])
  const [routeVisitList, setRouteVisitList] = useState([])
  const [lotList, setLotList] = useState([])
  const [farmerNameList, setFarmerNameList] = useState([])
  const [rowsList, setRowsList] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [headerShow, setHeaderShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setHeaderShow(!headerShow)
  }

  const fatchAnimalData = async () => {
    const res = await getZone()
    const res1 = await getVisitRoute()
    if (res) {
      setCenterList(res)
    }
    if (res1) {
      setRouteVisitList(res1)
      console.log(res1)
    }
  }

  useEffect(() => {
    fatchAnimalData()
    let divHeight = document.getElementById('heightOffsetStock')?.offsetHeight
    setHeight(divHeight)
    fatchRouteData()
    fatchDcsData()
    fatchFarmerData()
    fatchLotDate()
    fatchFarmer1Data()
  }, [])

  let ids = rowsList.map(i => i.ID)

  const Transfer = async () => {
    console.log(dcs)
    console.log('routeVisit  ' + routeVisit)
    setIsLoading(true)
    // const res = await transferData({ dataType: "animal", farmer, herd: routeVisit, id: ids, lot: dcs, project: null, uid: 1 })
    // const res2 = await getData({ ahc: center, dataType: "animal", farmer, lot: dcs, project: null, route });
    // if (res2) {
    //     setAnimalList(res2);
    //     setIsLoading(false);
    // }

    await fetch(Animal_Zone.TransferData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({
        dataType: 'animal',
        farmer,
        herd: routeVisit,
        id: ids,
        lot: dcs,
        project: null,
        uid: 1
      })
    })
      .then(res => res.json())
      .then(res => {
        toast.success(res.status)
        setIsLoading(false)
      })
    await View()
  }

  const selectCenter = e => {
    setCenter(e)
    fatchRouteData(e)
    console.log(center)
  }
  const fatchRouteData = async e => {
    if (e) {
      fetch(`${API_KEY}/cattle-detail/getVisitRoutes/` + e, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      })
        .then(res => res.json())
        .then(result => {
          setRouteList(result)
        })
    }
  }

  const selectRoute = e => {
    setRoute(e)
    fatchDcsData(e)
  }
  const fatchDcsData = async e => {
    if (e) {
      fetch(`${API_KEY}/cattle-detail/getVisitLot/` + e, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      })
        .then(res => res.json())
        .then(result => {
          setDcsList(result)
          console.log(result)
        })
    }
  }

  const selectDsc = e => {
    console.log(e)
    setDcs(e)
    fatchFarmerData(e)
    fatchDcsData(e)
  }

  const fatchFarmerData = async e => {
    if (e) {
      fetch(`${API_KEY}/cattle-detail/getVisitFarmer/` + e, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      })
        .then(res => res.json())
        .then(result => {
          setFarmerList(result)
        })
    }
  }

  const selectRoute1 = e => {
    setRouteVisit(e)
    fatchLotDate(e)
    // console.log("e.. " + JSON.stringify(e))
  }
  const fatchLotDate = async e => {
    if (e) {
      fetch(`${API_KEY}/cattle-detail/getVisitLot/` + e, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      })
        .then(res => res.json())
        .then(result => {
          setLotList(result)
          console.log(result)
        })
    }
  }

  const selectLot = e => {
    setLot(e)
    fatchFarmer1Data(e)
  }
  const fatchFarmer1Data = async e => {
    if (e) {
      fetch(`${API_KEY}/cattle-detail/getVisitFarmer/` + e, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      })
        .then(res => res.json())
        .then(result => {
          setFarmerNameList(result)
        })
    }
  }
  const View = async () => {
    setIsLoading(true)
    console.log('abc')
    const res2 = await getData({
      ahc: center,
      dataType: 'animal',
      farmer,
      lot: dcs,
      project: null,
      route
    })
    if (res2) {
      setAnimalList(res2)
      setIsLoading(false)
      setProgressData('0%')
    } else {
      setIsLoading(false)
      setProgressData('0%')
    }
  }
  const SelectedRowFn = rows => {
    setRowsList(rows.selectedRows)
    console.log(rows.selectedRows)
  }

  const SearchFn = async () => {
    setIsLoading(true)
    await fetch(`${API_KEY}/cattle-detail/get-transfer-search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ dataType: 'Animal', search: search })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start (controller) {
              const reader = response.body.getReader()
              read()
              function read () {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(res => res.json())
      .then(result => {
        setAnimalList(result)
        setIsLoading(false)
        setProgressData('0%')
      })
  }

  const colum = [
    {
      name: 'ID',
      selector: 'ID',
      cell: e => (
        <p
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {e['ID']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Idno',
      selector: 'Idno',
      cell: e => (
        <p
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {e['Idno']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Status',
      selector: 'Status',
      cell: e => (
        <p
          style={{
            paddingLeft: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          {e['Status']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Age',
      selector: 'Age',
      cell: e => (
        <p
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {e['Age']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Lot Code',
      selector: 'Lot Code',
      cell: e => (
        <p
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {e['Lot Code']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'DCS Name',
      selector: 'DCS NAme',
      cell: e => (
        <p
          style={{
            paddingLeft: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          {e['DCS NAme']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Farmer Code',
      selector: 'Farmer Code',
      cell: e => (
        <p
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {e['Farmer Code']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Farmer Name',
      selector: 'Farmer Name',
      cell: e => (
        <p
          style={{
            paddingLeft: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          {e['Farmer Name']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Farmer MobileNumber',
      selector: 'Farmer MobileNumber',
      cell: e => (
        <p
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {e['Farmer MobileNumber']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Suspended',
      selector: 'Suspended'
    }
  ]

  console.log(centerList)
  console.log(routeVisitList)
  console.log(dcsList)

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (
            <Col lg='2' className='hideNavbar'>
              {' '}
              <Sidenav />
            </Col>
          )}
          <Col className='header' lg={headerShow ? '10' : '12'}>
            <Container fluid>
              <Row className='pt-1 sub-header align-items-center justify-content-between'>
                <Col
                  md='10'
                  sm='10'
                  xs='10'
                  className='py-2 d-flex align-items-center'
                >
                  <button
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    onClick={handleShow}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <button
                    style={{ border: 'none' }}
                    className='hide_Navbar'
                    onClick={() => setShow(true)}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <h5 className='ms-4 mt-2'>Transfer / Animal Transfer</h5>
                </Col>
                <Col
                  md='1'
                  sm='1'
                  xs='10'
                  className='py-2 d-flex align-items-center text-end ms-auto'
                >
                  <BackBtn
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    lable='Back'
                    onClick={() => {
                      navigate('/')
                    }}
                  />
                </Col>
                <Col md='1' sm='1' xs='2' className='text-end'>
                  <img src={profile} width={40} />
                </Col>
              </Row>
              <Row
                className='align-items-center justify-content-end p-2'
                id='heightOffsetStock'
              >
                <Col
                  xs='12'
                  sm='12'
                  lg='12'
                  md='12'
                  className='justify-content-start'
                >
                  <Row className='justify-content-start align-items-end'>
                    <Col
                      lg='2'
                      sm='4'
                      xs='4'
                      md='2'
                      className='justify-content-start'
                    >
                      <Select
                        placeholder='Center'
                        onChange={selectedOption =>
                          selectCenter(selectedOption.value)
                        }
                        options={centerList.map(e => ({
                          value: e.Id,
                          label: e.name
                        }))}
                      />
                    </Col>
                    <Col
                      lg='2'
                      sm='4'
                      xs='4'
                      md='2'
                      className='justify-content-start'
                    >
                      <Select
                        placeholder='Route'
                        onChange={selectedOption =>
                          selectRoute(selectedOption.value)
                        }
                        options={routeVisitList.map(e => ({
                          value: e.Id,
                          label: e.name
                        }))}
                      />
                    </Col>
                    <Col
                      lg='2'
                      sm='4'
                      xs='4'
                      md='2'
                      className='justify-content-start'
                    >
                      <Select
                        placeholder='Dcs'
                        onChange={selectedOption =>
                          selectDsc(selectedOption.value)
                        }
                        options={dcsList.map(e => ({
                          value: e.Id,
                          label: e.name
                        }))}
                      />
                    </Col>
                    <Col
                      lg='2'
                      sm='4'
                      xs='4'
                      md='2'
                      className='justify-content-start'
                    >
                      <Select
                        placeholder='Farmer'
                        onChange={selectedOption =>
                          setFarmer(selectedOption.value)
                        }
                        options={farmerList.map(e => ({
                          value: e.Id,
                          label: e.name
                        }))}
                      />
                    </Col>
                    <Col
                      lg='1'
                      xl='1'
                      md='3'
                      sm='2'
                      xs='4'
                      className='justify-content-start'
                    >
                      <button
                        className='btn-blue-Animal mt-1'
                        onClick={e => View(e)}
                      >
                        View
                      </button>
                    </Col>
                    <Col
                      lg='2'
                      sm='4'
                      xs='4'
                      md='3'
                      className='ms-auto align-items-center justify-content-end'
                    >
                      <Input
                        label='Search'
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                      />
                    </Col>
                    <Col
                      lg='1'
                      xl='1'
                      md='3'
                      sm='2'
                      xs='4'
                      className='justify-content-start'
                    >
                      <button
                        className='btn-blue-Animal mt-1'
                        onClick={SearchFn}
                      >
                        Search
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className='mt-2'>
                {animalList.length > 0 ? (
                  <Col xl='9' xxl='9' lg='9' md='8' sm='12'>
                    <Table
                      columns={colum}
                      data={searchFilter ? searchFilter : animalList}
                      height={`calc(100vh - (${heightOffSet}px + 120px))`}
                      pagination
                      selectableRows
                      onSelectedRowsChange={SelectedRowFn}
                    />
                  </Col>
                ) : (
                  <Col
                    xl='9'
                    xxl='9'
                    lg='9'
                    md='8'
                    sm='12'
                    className='data-container'
                  >
                    <div className='no-data-message'>No Data</div>
                  </Col>
                )}
                <Col xxl='3' xl='3' lg='3' md='3' sm='12'>
                  <div className='mainCard'>
                    <Row className='align-items-center justify-content-center'>
                      <Col
                        xl='12'
                        xxl='10'
                        lg='12'
                        md='12'
                        sm='4'
                        className='align-items-center justify-content-center cardHeading mb-3'
                      >
                        <Col className='mt-2' xl='12'>
                          <div className='boxBg text-center p-2 align-items-center'>
                            <h6 className='fw-bold m-0'>
                              Select the Destination Herd
                            </h6>
                          </div>
                        </Col>
                        <Col className='mt-1'>
                          <Select
                            placeholder='Route'
                            onChange={selectedOption => {
                              selectRoute1(selectedOption.value)
                            //   console.log('aaa : ' + selectedOption.value)
                            }}
                            options={routeVisitList.map(e => ({
                              value: e.Id,
                              label: e.name
                            }))}
                          />
                        </Col>
                      </Col>
                      <Col
                        xl='12'
                        xxl='10'
                        lg='12'
                        md='12'
                        sm='4'
                        className='align-items-center justify-content-center cardHeading mb-3'
                      >
                        <Col className='mt-2'>
                          <div className='boxBg text-center p-2 align-items-center'>
                            <h6 className='fw-bold m-0'>
                              Select the Destination Lot
                            </h6>
                          </div>
                        </Col>
                        <Col className='mt-1'>
                          <Select
                            placeholder='Lot'
                            onChange={selectedOption => {
                              selectDsc(selectedOption.value)
                            }}
                            options={lotList.map(e => ({
                              value: e.Id,
                              label: e.name
                            }))}
                          />
                        </Col>
                      </Col>
                      <Col
                        xl='12'
                        xxl='10'
                        lg='12'
                        md='12'
                        sm='4'
                        className='align-items-center justify-content-center cardHeading mb-3'
                      >
                        <Col className='mt-2'>
                          <div className='boxBg text-center p-2 align-items-center'>
                            <h6 className='fw-bold m-0'>
                              Select the Destination Farmer
                            </h6>
                          </div>
                        </Col>
                        <Col className='mt-1'>
                          <Select
                            placeholder='Farmer'
                            onChange={selectedOption =>
                              setFarmerName(selectedOption.value)
                            }
                            options={farmerNameList.map(e => ({
                              value: e.Id,
                              label: e.name
                            }))}
                          />
                        </Col>
                      </Col>
                    </Row>
                    <Row className='align-items-center mt-4 text-center'>
                      <Col>Click on the below to make changes</Col>
                    </Row>
                    <Row className='align-items-center justify-content-center'>
                      <Col
                        lg='4'
                        sm='2'
                        xs='4'
                        xl='4'
                        className='mt-2 d-flex align-items-center'
                      >
                        <button
                          className='btn-blue-Animal mt-1 mb-3'
                          onClick={Transfer}
                        >
                          Transfer
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      {show && (
        <Offcanvas className='hide_Navbar' show={show} onHide={handleClose}>
          <Container>
            <Row>
              <Col>
                <Sidenav />
              </Col>
            </Row>
          </Container>
        </Offcanvas>
      )}

      {isLoading ? (
        <div className='circleText'>
          <div className='progressText'>{progressData}</div>
          <div className=''>
            <span className='loader-circle-13'></span>
          </div>
        </div>
      ) : (
        false
      )}

      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        style={{ fontSize: '14px' }}
      />
    </>
  )
}
export default Animal
