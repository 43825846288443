import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas, Modal, Accordion } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import { downloadExcel } from "react-export-table-to-excel";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import Select from 'react-select';
import {
  TextField,
  FormControl,
  InputLabel,
  // Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import './supplier.css';

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { borderRadius, margin } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
// import Select from 'react-select/dist/declarations/src/Select';
const { AitStockApi, SupplieStockApi, SemenSupplierApi } = API_URLS;

const AITStock = () => {
  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
  const USERID = localStorage.getItem("USERID");

  async function getZone(credentials) {
    return fetch(AitStockApi.GetZone, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json());
  }

  async function getSupplierData(credentials) {
    return fetch(SupplieStockApi.GetStaff, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json());
  }

  async function getStaff(credentials) {
    return fetch(AitStockApi.GetStaff, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json());
  }

  async function getSire(credentials) {
    return fetch(AitStockApi.GetSire, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json());
  }
  async function getSireData(credentials) {
    return fetch(SupplieStockApi.GetSire, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json());
  }

  async function getItemData(credentials) {
    return fetch(SemenSupplierApi.GetItemData, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json());
  }

  const API_KEY = serviceUrl;
  const selectedToDate = new Date().toISOString().slice(0, 10);

  const [center, setCenter] = useState("")
  const [date, setDate] = useState("")
  const [sire, setSire] = useState("")
  const [role, setRole] = useState("1")
  const [staff, setStaff] = useState("")
  const [staff1, setStaff1] = useState("")
  const [selectRole, setSelectRole] = useState('0')

  const navigate = useNavigate()
  const logOut = () => { navigate("/") }
  const [date1, setDate1] = useState(selectedToDate)
  const [date2, setDate2] = useState(selectedToDate)
  const [progressData, setProgressData] = useState("0%");
  const [sortedSemen, setSortedSemen] = useState("")
  // const [sirename, setSireName] = useState("")
  const [sireId, setSireId] = useState("")
  const [staffId, setStaffId] = useState("")
  const [search, setSearch] = useState("")
  const [searchFilter, setSearchFilter] = useState("")
  const [staffFilter, setStaffFilter] = useState("")
  const [searchStaff, setSearchStaff] = useState("")
  const [sireFilter, setSireFilter] = useState("")
  const [searchSire, setSearchSire] = useState("")
  // const [staff1, setStaff1] = useState("")
  const [bench, setBench] = useState("")
  const [qty, setQty] = useState("")
  const [semen, setSemen] = useState("")
  const [itemCode, setItemCode] = useState("")
  const [supplied, setSupplied] = useState("")
  const [item, setItem] = useState("")
  const [otheritem, setOtherItem] = useState("")

  const [show, setShow] = useState(false);
  const [headerShow, setHeaderShow] = useState(true);
  const handleClose = () => { setShow(false); };
  const handleShow = () => { setHeaderShow(!headerShow); }
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false)

  const [aitList, setAitList] = useState([])
  const [aitList1, setAitList1] = useState([])
  const [centerList, setCenterList] = useState([])
  const [sireList, setSireList] = useState([])

  const [staffList, setStaffList] = useState([])
  const [rowsList, setRowsList] = useState([]);
  const [rowsListStaff, setRowsListStaff] = useState([])

  const [supplierList, setSupplierList] = useState([])
  const [Supplier, setSupplier] = useState([])
  const [itemList, setItemList] = useState([])
  const [strawList, setStrawList] = useState([])
  const [otherItemList, setOtherItemList] = useState([])
  const [otherItem1, setOtherItem1] = useState([])

  const [supplier2, setSupplier2] = useState("");
  const [staff2, setStaff2] = useState("");
  const [staffDCS, setStaffDCS] = useState("");


  // const headers = {
  //   'Authorization': `Bearer ${added_by}`
  // };

  // fetch('https://api.example.com/data', { headers })
  //   .then(response => {
  //     // Handle the response
  //     console.log(response)
  //   })
  //   .catch(error => {
  //     // Handle any errors
  //     console.log(error)
  //   });


  const getStockData = async () => {
    const res = await getZone();
    const res1 = await getStaff();
    const res3 = await getSupplierData();
    const res2 = await getSire();
    const res4 = await getItemData();

    if (res) { setCenterList(res) }
    if (res1) { setSireList(res1); }
    if (res2) { setStaffList(res2); }
    if (res3) {
      setSupplierList(res3);
      // console.log(res3);
      // console.log(supplierList + "  supplierList")
    }
    if (res4) { setItemList(res4); }
  }


  const fetchData = async () => {

    setIsLoading(true)
    let ids = (rowsList.map(i => i.id)).join(',');
    let ids1 = (rowsListStaff.map(i => i.id)).join(',');
    fetch(`${API_KEY}/straw-management/getPeriodicSireStockReport?staff=${staff ? staff : sire}&sementype=${selectRole}&sire=${ids ? ids : ids1}&fromdate=${date1}&todate=${date2}&ait=1&reporttype=${role}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      }
    })
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) { controller.close(); return; }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      })
      .then(res => res.json())
      .then((result) => { setAitList(result); setAitList1(result); setIsLoading(false); setProgressData("0%") })
  }

  const selectStaff = (e) => {
    setStaff(e)
  }
  const selectStaff1 = (e) => {
    setStaff1(e)
  }
  const selectSupplier = (e) => {
    setSupplier(e)
  }
  const selectSire = (e1) => {
    setSire(e1)
  }
  const selectOtherItem = (e1) => {
    setOtherItem(e1)
  }

  useEffect(() => {
    // try {
    //   if (added_by) {
    //     console.log("Token was Found")
    //   }
    // } catch (error) {
    //   console.log("Token was not Found")
    // }
    getStockData()
  }, [staff])

  const colum21 = [
    {
      name: "Action",
      cell: (e) => (
        <>
          <img
            src={remove}
            onClick={() => delateHandle(e)}
            style={{ width: "15px" }}
          />
        </>
      ),
    },
    {
      name: "Sire",
      selector: "sire",
      cell: (row) => {
        const matchedSire = sireList.find((sire) => sire.id === row.sire);
        return matchedSire ? matchedSire.Name : "";
      },
    },
    {
      name: "Semen Type",
      selector: "semen",
    },
    {
      name: "Batch No",
      selector: "bench",
    },
    {
      name: "Qty",
      selector: "qty",
    },
  ]

  const nameColum = [
    {
      name: "Name",
      selector: "Name",
      cell: (e) => (<div className='ms-2' style={{ paddingRight: "8px" }}>{e["Name"]}</div>),
      sortable: true

    }
  ]
  const selectItem = (selectedItem) => {
    if (selectedItem) {
      setItem(selectedItem);
      setItemCode(selectedItem);
    } else {
      setItem("");
      setItemCode("");
    }
  }

  const updateFn = (e) => {
    if (selectRole == 2 || selectRole == "2") {
      toast.error("Not Click On Action Becuase Of Selected Both")
    }
    else {
      setSireId(e.sireid)
      setStaffId(e.staffid)
      setModalShow1(true)
    }
  }

  const deleteData = () => {
    setModalShow(false)
  }

  const handleChecked = (e) => {
    if (e === true) {
      fetchData()
    }
    else {
      return
    }
  }

  const colum = [
    {
      name: "Staff Name",
      selector: "Staffname",
      cell: (e) => (<p style={{ paddingRight: '5px', margin: 0 }} className='ms-auto'>{e["Staffname"]}</p>),
      width: "230px",
      sortable: true
    },
    {
      name: "Opening Balane",
      selector: "OpeningBalane",
      cell: (e) => (<p style={{ paddingRight: '5px', margin: 0 }} className='ms-auto'>{e["Opening Balance"]}</p>),
      width: "100px"
      ,
      sortable: true
    },
    {
      name: "Distribute",
      selector: "Distributed",
      cell: (e) => (<p className='ms-auto'>{e["Distributed"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Transfer From",
      selector: "Transfer From",
      cell: (e) => (<p className='ms-auto'>{e["Transfer From"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Semen Used",
      selector: "Semen Used",
      cell: (e) => (<p style={{ paddingRight: '10px' }} className='ms-auto'>{e["Semen Used"]}</p>),
      width: "110px",
      sortable: true
    },
    {
      name: "A.I Done",
      selector: "A.I Done",
      cell: (e) => (<p style={{ paddingRight: '10px' }} className='ms-auto'>{e["A.I Done"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Wastage",
      selector: "Wastage",
      cell: (e) => (<p className='ms-auto'>{e["Wastage"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Missing",
      selector: "Missing",
      cell: (e) => (<p className='ms-auto'>{e["Missing"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Camp",
      selector: "Camp",
      cell: (e) => (<p className='ms-auto'>{e["Camp"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Manual",
      selector: "Manual",
      cell: (e) => (<p className='ms-auto'>{e["Manual"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Transfer To",
      selector: "Transfer To",
      cell: (e) => (<p className='ms-auto'>{e["Transfer To"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Recovery",
      selector: "Recovery",
      cell: (e) => (<p className='ms-auto'>{e["Recovery"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Closing Stock",
      selector: "Closing Stock",
      cell: (e) => (<p style={{ paddingRight: '10px' }} className='ms-auto'>{e["Closing Stock"]}</p>),
      width: "70px",
      sortable: true
    },
    {
      name: "Sire Id",
      selector: "sireid",
      cell: (e) => (<p className='ms-auto'>{e["sireid"]}</p>),
      width: "80px",
      sortable: true
    },
  ]

  const columSire = [
    {
      name: "Actions",
      cell: (e) => {
        const matchResult = e.Sirename && e.Sirename.match("Total");
        if (matchResult) {
          return <></>
        }
        else {
          return <img
            src={edit}
            onClick={() => updateFn(e)}
            style={{ width: "15px", margin: "10px" }}
          />
        }
      },
      width: "80px"
    },
    {
      name: "Sire Name",
      selector: "Sirename",
      cell: (e) => (<p style={{ paddingRight: '5px' }} className='ms-auto'>{e["Sirename"]}</p>),
      width: "170px",
      sortable: true
    },
    {
      name: "Opening Balance",
      selector: "OpeningBalane",
      cell: (e) => (<p style={{ paddingRight: '5px' }} className='ms-auto'>{e["Opening Balance"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Distributed",
      selector: "Distributed",
      cell: (e) => (<p className='ms-auto'>{e["Distributed"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Transfer From",
      selector: "Transfer From",
      cell: (e) => (<p className='ms-auto'>{e["Transfer From"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Semen Used",
      selector: "Semen Used",
      cell: (e) => (<p className='ms-auto'>{e["Semen Used"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "A.I Done",
      selector: "A.I Done",
      cell: (e) => (<p style={{ paddingRight: '5px' }} className='ms-auto'>{e["A.I Done"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Wastage",
      selector: "Wastage",
      cell: (e) => (<p className='ms-auto'>{e["Wastage"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Missing",
      selector: "Missing",
      cell: (e) => (<p className='ms-auto'>{e["Missing"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Camp",
      selector: "Camp",
      cell: (e) => (<p className='ms-auto'>{e["Camp"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Manual",
      selector: "Manual",
      cell: (e) => (<p className='ms-auto'>{e["Manual"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Transfer To",
      selector: "Transfer To",
      cell: (e) => (<p className='ms-auto'>{e["Transfer To"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Recovery",
      selector: "Recovery",
      cell: (e) => (<p className='ms-auto'>{e["Recovery"]}</p>),
      width: "80px",
      sortable: true
    },
    {
      name: "Closing Stock",
      selector: "Closing Stock",
      cell: (e) => (<p className='ms-auto' style={{ paddingRight: '5px' }}>{e["Closing Stock"]}</p>),
      sortable: true
      // width: "80px"
    },
  ]

  const blankData = [{}]
  const blankColmns = [
    {
      name: "Actions",
      width: "80px"
    },
    {
      name: "Sire Name",
      width: "170px"
    },
    {
      name: "Opening Balance",
      width: "80px"
    },
    {
      name: "Distributed",
      width: "80px"
    },
    {
      name: "Transfer From",
      width: "80px"
    },
    {
      name: "Semen Used",
      width: "80px"
    },
    {
      name: "A.I Done",
      width: "80px"
    },
    {
      name: "Wastage",
      width: "80px"
    },
    {
      name: "Missing",
      width: "80px"
    },
    {
      name: "Camp",
      width: "80px"
    },
    {
      name: "Manual",
      width: "80px"
    },
    {
      name: "Transfer To",
      width: "80px"
    },
    {
      name: "Recovery",
      width: "80px"
    },
    {
      name: "Closing Stock",
      width: "80px"
    },
  ]

  const SelectedRowFn = (rows) => {
    setRowsList(rows.selectedRows);
  }

  const SelectedRowFn1 = (rows) => {
    setRowsListStaff(rows.selectedRows);
  }

  const ApplyFn = () => {
    fetchData()
  }

  const SaveFn = () => { }

  const clearFields = () => {

    setBench('');
    setQty('');
  };

  const handleDownloadExcel = () => {
    const opt = `Sement And Other Report ${date1} ${date2}`;
    const header = Object.keys(aitList).map((key, e) => (key));

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: aitList
      }
    });
  }
  const AddData = async () => {

    setIsLoading(true)

    await fetch(AitStockApi.SireUpdateNew, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      body: JSON.stringify({ staffId: staffId, currentStock: sortedSemen, sire: sireId, sorted: selectRole })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) {
                      controller.close();
                      return;
                    }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete);
                    controller.enqueue(progressEvent.value);
                    read();
                  });
              }
            }
          })
        );
      })
      .then((res) => res.json())
      .then((result) => {
        setModalShow1(false);
        setIsLoading(false);
        setProgressData('0%');
        toast.success("Stock Successfully Update...");
        setSortedSemen("")
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle error here
      });
  }

  const AddData1 = async () => {

    setIsLoading(true)

    for (let i = 0; i < strawList.length; i++) {

      try {
        console.log("strawlist : ", strawList[i].sire);
        const response = await fetch("https://dudhsagar.herdman.in/straw-management/strawMgmtSave1", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
          },
          body: JSON.stringify({

            RequestDate: null,
            RequestQuantity: null,
            SireVoucherType: 2,
            Sires: [
              {
                SireCode: strawList[i].sire,
                Normal: strawList[i].qty,
                batchnonormal: strawList[i].bench,
                Sorted: strawList[i].semen,
                batchnosorted: selectRole

                // Sorted: selectRole,
                // batchnosorted: strawList[i].semen
              }
            ],
            Staff: parseFloat(staff2) ? parseFloat(staff2) : 0,
            VoucherDate: date1,
            createdAt: moment().format("DD-MM-YYYY"),
            createdByUser: USERID,
            dcs: parseFloat(staffDCS) ? parseFloat(staffDCS) : 0,
            isEdit: 0,
            lastUpdatedByUser: null,
            supplierId: supplier2,
            updatedAt: null

          })
        })
      }
      catch (error) {
        console.error(error);
      }

    }

    for (let i = 0; i < otherItemList.length; i++) {
      try {
        fetch(SemenSupplierApi.SaveItem, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
          },


          body: JSON.stringify({
            Items: [
              { Item: otherItemList[i].item, ItemCode: otherItemList[i].itemCode, quantity: strawList[0].qty }
            ],
            RequestDate: date1,
            RequestQuantity: 0,
            Staff: parseFloat(staff) ? parseFloat(staff) : 0,
            VoucherDate: date,
            VoucherType: 2,
            batchno: null,
            createdAt: null,
            createdByUser: null,
            dcs: null,
            isEdit: 0,
            lastUpdatedByUser: null,
            supplierId: 0,
            updatedAt: null,

          })
        })
          .then((result) => { setIsLoading(false); })
      } catch (error) {
        console.log(error);
      }
    }

    setModalShow(false)
    toast.success("Data Successfully Saved..")
    clearFields("")
    setItemCode("");
    setSupplied("");
    setStrawList("");
    setOtherItemList("");
  }


  const SearchFn = (e) => {
    const data1 = e.target.value;
    if (data1 !== '') {
      const results = aitList.filter((user) => {
        return user && (user.Sirename ? user.Sirename : user.Staffname) && (user.Sirename ? user.Sirename : user.Staffname).toLowerCase().startsWith(data1.toLowerCase())
      });
      setSearchFilter(results);
    } else {
      setSearchFilter(aitList);
    }
    setSearch(data1);
  }

  const SearchStaffFn = (e) => {
    const data = e.target.value;
    if (data !== '') {
      const results = sireList.filter((user) => {
        return user && (user.Name ? user.Name : user.Name) && (user.Name ? user.Name : user.Name).toLowerCase().startsWith(data.toLowerCase())
      });
      setStaffFilter(results);
    } else {
      setStaffFilter(sireList);
    }
    setSearchStaff(data);
  }

  const SearchSireFn = (e) => {
    const data = e.target.value;
    if (data !== '') {
      const results = staffList.filter((user) => {
        return user && (user.Name ? user.Name : user.Name) && (user.Name ? user.Name : user.Name).toLowerCase().startsWith(data.toLowerCase())
      });
      setSireFilter(results);
    } else {
      setSireFilter(sireList);
    }
    setSearchSire(data);
  }

  const selectCenter = (e) => {
    setCenter(e.value)
  }

  const colum1 = [
    {
      name: "Action",
      cell: (e) => (
        <>
          <img
            src={remove}
            onClick={() => delateHandle1(e)}
            style={{ width: "15px" }}
          />
        </>
      ),
    },
    {
      name: "Item Name",
      selector: "item",
      cell: (row) => {
        const matchedItem = itemList.find((item) => item.id === row.item);
        return matchedItem ? matchedItem.Name : "";
      },
    },
    {
      name: "Item Code",
      selector: "itemCode",
    },
    {
      name: "Supplied",
      selector: "supplied",
    },
  ]

  const Add = () => {
    // setStaffList([])
    setModalShow(true)
    openData()
    // getStockData();
  }

  const openData = async () => {
    const res = await getSireData()
    if (res) { setSireList(res) }

  }

  const AddStraw = () => {
    setStrawList((prev) => {
      const newIndex = prev.length + 1;
      return [...prev, { index: newIndex, staff, sire, semen, bench, qty }]
    })
    setSemen("");
    setSire("");
    setSireId("");
    clearFields();
    setItem("");
    setItemCode("");
  }

  const itemSave = async () => {
    setOtherItemList((prev) => {
      const newIndex = prev.length + 1;
      return [...prev, { index: newIndex, item, itemCode, supplied }]
    })
    setItem("");
    setItemCode("")
    setSupplied("")
  }

  const delateHandle = (e) => {
    setStrawList(strawList.filter(item => item.index !== e.index))
  }

  const delateHandle1 = (e) => {
    setOtherItemList(otherItemList.filter(item => item.index !== e.index))
  }


  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                  <button type="button" id="navToggle" class="navbar-toggler d-block" for="opensiderMenu" onClick={handleShow} style={{ border: "none", cursor: "pointer" }}><img src={menu} width={20} /></button>
                  <h5 className="ms-4 mt-2">Straw / AIT Stock</h5>
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                  <img src={profile} width={40} />
                  <div className="dropdpwn_Open">
                    <Row className="text-center align-items-center">
                      <Col xs="12" className="menuHover mb-3">
                        <Row >
                          <Col className="" xs="3">
                            <span className=""><MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="menuHover" onClick={logOut}>
                        <Row>
                          <Col className="" xs="3">
                            <span className=""><GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-end justify-content-start " id="aitStock">
                <Col md="2" xl="2" xxl="2" sm="2" className='d-flex align-items-start justify-content-start'>
                  <Select
                    placeholder="Center"
                    onChange={(selectedOption) => {
                      selectCenter(selectedOption);
                    }}
                    options={centerList.map((item) => ({
                      value: item.id,
                      label: item.Name,
                    }))}
                  />
                </Col>
                <Col md="2" xl="2" xxl="2" sm="3" className="d-flex align-items-start justify-content-start text-start w-all">
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={role} name="row-radio-buttons-group">
                    <FormControlLabel value="2" control={<Radio className="p-1" />} onChange={e => setRole(e.target.value)} label="Sire" className="mt-1" />
                    <FormControlLabel value="1" control={<Radio className="p-1" />} onChange={e => setRole(e.target.value)} label="Staff" className="mt-1" />
                    <div className='mt-2 align-items-center'>
                      <input type="checkbox" id="All" value="All" className="me-2" onClick={(e) => handleChecked(e.target.checked)} />All
                    </div>
                  </RadioGroup>
                </Col>
                <Col md="2" xl="2" xxl="2" sm="2" className='d-flex align-items-start justify-content-start text-start'>
                  {
                    role === "2" &&
                    <Select
                      placeholder="Search Sire"
                      onChange={(selectedOption) => {
                        selectSire(selectedOption.value);
                      }}
                      options={sireList.map((item) => ({
                        value: item.id,
                        label: item.Name,
                      }))}
                    />
                  }
                  {
                    role === "1" &&
                    <Select
                      placeholder="Search Staff"
                      onChange={(selectedOption) => {
                        selectStaff(selectedOption.value);
                      }}
                      options={staffList.map((item) => ({
                        value: item.id,
                        label: `${item.code} - ${item.Name}`,
                      }))}
                    />
                  }
                </Col>
                <Col md="2" sm="2" xxl="1" xl="2" className='m-0'>
                  <sub className="mb-0 pb-0">From Date</sub>
                  <input type="date" className="dateSelect_bg" id="date-1" value={date1} onChange={(e) => { setDate1(e.target.value) }} />
                </Col>
                <Col md="3" sm="2" xxl="1" xl="2" className='m-0'>
                  <sub className="mb-0 pb-0">To Date</sub>
                  <input type="date" className="dateSelect_bg" id="date-2" value={date2} onChange={(e) => { setDate2(e.target.value) }} />
                </Col>
                <Col md="5" sm="5" xl="2" className="d-flex align-items-center me-0 w1-all">
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={selectRole} name="row-radio-buttons-group">

                    <FormControlLabel
                      value="0"
                      control={<Radio className="p-1" />}
                      onChange={() => {
                        setSelectRole("0");

                      }}
                      label="Normal"
                      className="mt-1"
                    />

                    <FormControlLabel
                      value="1"
                      control={<Radio className="p-1" />}
                      onChange={() => {
                        setSelectRole("1");
                      }}
                      label="Sorted"
                      className="mt-1"
                    />


                    <FormControlLabel
                      value="2"
                      control={<Radio className="p-1" />}
                      onChange={() => {
                        setSelectRole("2");
                      }}

                      label="Both"
                      className="mt-1"
                    />
                  </RadioGroup>
                </Col>
                <Col md="1" sm="4" xs="4" className="d-flex ms-auto align-items-center justify-content-start">
                  <button className="btn-blue-Animal mt-1 mb-1" style={{ width: '100%', height: '3vh' }} onClick={Add}>Stock Supply</button>
                </Col>
                <Col md="1" sm="4" xs="4" className="d-flex ms-auto align-items-center justify-content-start">
                  <button className="btn-blue-Animal mt-1 mb-1" style={{ width: '100%', height: '3vh' }} onClick={handleDownloadExcel}>Export</button>
                </Col>
                <Col md="1" sm="4" xs="4" className="d-flex align-items-center justify-content-start">
                  <button className="btn-blue-Animal mt-1 mb-1" style={{ width: '100%', height: '3vh' }} onClick={ApplyFn}>Apply</button>
                </Col>
              </Row>

              <Row className="align-items-start">
                <Col md="3" lg="3" xl="3" xxl="2" xs="12" className='mt-0'>
                  {
                    role === "2" && <Table columns={nameColum} data={sireFilter ? sireFilter : staffList} height="84vh" selectableRows onSelectedRowsChange={SelectedRowFn1} />
                  }
                  {
                    role === "1" && <Table columns={nameColum} data={staffFilter ? staffFilter : sireList} height="84vh" selectableRows onSelectedRowsChange={SelectedRowFn} />
                  }
                </Col>
                <Col md="8" lg="9" xl="9" xs="12" xxl="10">
                  {
                    role === "2" && <Table columns={colum} data={searchFilter ? searchFilter : aitList} height="75vh" pagination />
                  }
                  {
                    role === "1" &&
                    <>
                      {/* <Table columns={columSire} data={searchFilter ? searchFilter : aitList} height="75vh" pagination /> */}
                      <Row className='text-start me-2'>
                        <Col>
                          {/* <h6>Total: {aitList.length}</h6> */}
                          {
                            aitList.length > 0 ?
                              <Table columns={columSire} data={searchFilter ? searchFilter : aitList} height="75vh" pagination />
                              :
                              <Table columns={blankColmns} data={blankData} height="10vh" pagination />
                          }
                        </Col>
                      </Row>
                    </>
                  }
                </Col>
              </Row>

            </Container>
          </Col>
        </Row>
      </Container >



      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            Delete ?
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-end">
              <Col xl="3">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => SaveFn(e)}>Save</button>
              </Col>
              <Col xl="3">
                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow1} onHide={() => setModalShow1(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter">Sire</Modal.Title>
          <img src={closeButton} onClick={() => setModalShow1(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xl="6">
                <TextField label="Sorted Semen" id="standard-basic" value={sortedSemen} onChange={(e) => { setSortedSemen(e.target.value); }} fullWidth variant="standard" />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-between">
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => AddData()}>Save</button>
              </Col>
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow1(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter" className=''>AIT Stock</Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='align-tems-center justify-content-start'>
              <Col md="6" sm="3">
                {/* <FormControl variant="standard" fullWidth>
                                   <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Supplier</InputLabel>
                                   <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={supplier2} onChange={(e) => { setSupplier2(e.target.value) }}>
                                       {supplierList.map((e) => (
                                           <MenuItem className="drop_value" key={e.id} value={e.id}>
                                               {e.code} - {e.Name}
                                           </MenuItem>
                                       ))}
                                   </Select>
                               </FormControl> */}
                <Select
                  placeholder="Supplier"
                  onChange={(selectedOption) => setSupplier2(selectedOption.value)}
                  options={supplierList.map((e) => ({ value: e.id, label: `${e.code} - ${e.Name}` }))}
                />
              </Col>
              <Col md="6">
                {/* <FormControl variant="standard" fullWidth>
                                   <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Staff</InputLabel>
                                   <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff2} onChange={(e) => { setStaff2(e.target.value) }}>
                                       {staffList.map((e) => (
                                           <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                               {e.Code} - {e.Paravet_Name}
                                           </MenuItem>
                                       ))}
                                   </Select>
                               </FormControl> */}
                <Select
                  placeholder="Staff"
                  onChange={(selectedOption) => { setStaff2(selectedOption.value); setStaffDCS(selectedOption.DCS); console.log("new " + JSON.stringify(selectedOption)); }}
                  options={staffList.map((e) => ({ value: e.id, label: `${e.code} - ${e.Name}`, DCS: e.DCS }))}
                />
                {/* <TextField label="staff Code" id="standard-basic" value={staff2} onChange={(e) => { setStaff2(e.target.value); }} fullWidth variant="standard" /> */}
              </Col>

            </Row>
            <Row className='mt-3'>
              <Col>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Add Staw</Accordion.Header>
                    <Accordion.Body>
                      <Row className='align-tems-center justify-content-between'>
                        <Col md="3" sm="3" className='mt-2'>
                          {/* <FormControl variant="standard" fullWidth>
                                                       <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sire</InputLabel>
                                                       <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={(e) => { setSire(e.target.value) }}>
                                                           {sireList.map((e) => (
                                                               <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                   {e.Name}
                                                               </MenuItem>
                                                           ))}
                                                       </Select>
                                                   </FormControl> */}
                          <div>
                            {sireList && (
                              <Select
                                placeholder="Sire"
                                value={sire !== "" ? { value: sire, label: sireList.find(item => item.id === sire)?.Name } : ""}
                                onChange={(selectedOption) => setSire(selectedOption ? selectedOption.value : "")}
                                options={sireList.map((e) => ({ value: e.id, label: e.Name }))}
                              />

                            )}

                          </div>
                          {/* <Select
                                                  placeholder="Sire"
                                                        onChange={(selectedOption) => setSire(selectedOption.value)}
                                                        options={sireList.map((e) => ({ value: e.id, label: e.Name }))}
                                                    /> */}
                        </Col>
                        <Col md="3" className='mt-2'>
                          {/* <FormControl variant="standard" fullWidth>
                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Semen</InputLabel>
                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={semen} onChange={(e) => { setSemen(e.target.value) }}>
                                                            <MenuItem className="drop_value" value="0">Normal Semen</MenuItem>
                                                            <MenuItem className="drop_value" value="1">Sex Semen</MenuItem>
                                                        </Select>
                                                    </FormControl> */}
                          <Select
                            placeholder="Semen"
                            value={semen !== "" ? { value: semen, label: semen === "0" ? "Normal Semen" : "Sex Semen" } : ""}
                            onChange={(selectedOption) => setSemen(selectedOption ? selectedOption.value : "")}
                            options={[
                              { value: "0", label: "Normal Semen" }, // Option for Normal Semen
                              { value: "1", label: "Sex Semen" },     // Option for Sex Semen
                            ]}
                          />



                        </Col>
                        <Col md="2">
                          <TextField label="Batch No" id="standard-basic" value={bench} onChange={(e) => { setBench(e.target.value); }} fullWidth variant="standard" />
                        </Col>
                        <Col md="2">
                          <TextField label="Qty" id="standard-basic" value={qty} onChange={(e) => { setQty(e.target.value); }} fullWidth variant="standard" />
                        </Col>
                        <Col xs="4" sm="4" md="2" className="mt-2 d-flex align-items-center text-end justify-content-end">
                          <button className="btn-blue-Animal mt-1 mb-1" onClick={() => { setSemen(); AddStraw() }}>Add Straw</button>
                        </Col>
                      </Row>
                      <Row className='align-tems-end justify-content-end'>

                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Table columns={colum21} data={strawList} />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header >Add Other Item</Accordion.Header>
                    <Accordion.Body>
                      <Row className='align-tems-center justify-content-end'>
                        <Col md="3" className='mt-2'>
                          <Select
                            placeholder="Item Name"
                            value={item !== "" ? { value: item, label: itemList.find(e => e.id === item)?.Name } : ""}
                            onChange={(selectedOption) => selectItem(selectedOption ? selectedOption.value : "")}
                            options={itemList.map((e) => ({ value: e.id, label: e.Name }))}
                          />
                        </Col>

                        <Col md="3">
                          <TextField label="Item Code" id="standard-basic" value={itemCode} disabled onChange={(e) => { setItemCode(e.target.value); }} fullWidth variant="standard" />
                        </Col>
                        <Col md="3">
                          <TextField label="Supplied" id="standard-basic" value={supplied} onChange={(e) => { setSupplied(e.target.value); }} fullWidth variant="standard" />
                        </Col>
                        <Col xs="4" sm="2" md="3" className="mt-2 d-flex align-items-center text-end justify-content-end">
                          <button className="btn-blue-Animal mt-1 mb-1" onClick={itemSave}>Add item</button>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Table columns={colum1} data={otherItemList} />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-end">
              <Col sm="2">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={AddData1}>Save</button>
              </Col>
              <Col sm="2">
                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal >
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ fontSize: "14px" }}
        limit={1}
      />

      {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

      {
        isLoading ?
          <div className="circleText">
            <div className="progressText">{progressData}</div>
            <div className="">
              <span className="loader-circle-13"></span>
            </div>
          </div>
          : false
      }
    </>
  )
}
export default AITStock    
