import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Offcanvas, Modal, Accordion } from "react-bootstrap";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    Checkbox,
    ListItemText,
    OutlinedInput,
} from "@mui/material";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import addImage from "../../Photo/add-image 1.png";
import closeIcon from "../../Photo/Close.png";
import breeding from '../../Photo/breeding.svg';
import './Transfer.css'
import { BiImageAdd } from "react-icons/bi";
import { AiFillPlusCircle } from "react-icons/ai";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import "react-toastify/dist/ReactToastify.css";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { Link, useNavigate } from 'react-router-dom';
import { tab } from '@testing-library/user-event/dist/tab';
import { ToastContainer, toast } from 'react-toastify';
import Searcable from '../../Componet/Dropdown/Searcable';
import MuiInput from '../../Componet/InputFild/muiInput';
import moment from 'moment/moment';

const Addanimal = (props) => {
    const { AddAnimal, Cattleregistration, MilkTestApi, BulkMilkApi } = API_URLS;
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    async function getTableData(credentials) {
        return fetch(AddAnimal.GetTableData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function getEditData(credentials) {
        return fetch(AddAnimal.EditData, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function getRouteData(credentials) {
        return fetch(AddAnimal.RoutData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function getSexData(credentials) {
        return fetch(AddAnimal.SexData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getSpecies(credentials) {
        return fetch(AddAnimal.SpeciesData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getStaffData(credentials) {
        return fetch(AddAnimal.StaffData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getSireData(credentials) {
        return fetch(AddAnimal.SireData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function addBreedingSave(credentials) {
        return fetch(Cattleregistration.saveBreedingReproduction, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const selectedToDate = new Date().toISOString().slice(0, 10);
    const locaformate = moment(selectedToDate).toISOString("L")
    console.log(locaformate)

    const navigate = useNavigate()
    const [searchFilter, setSearchFilter] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const [dcs, setDcs] = useState("")
    const [route, setRoute] = useState("")
    const [tagId, setTagId] = useState("")
    const [birth, setBirth] = useState("")
    const [date, setDate] = useState(selectedToDate);
    const [sex, setSex] = useState("")
    const [breed, setBreed] = useState("")
    const [species, setSpecies] = useState("")
    const [calvingDate, setCalvingDate] = useState("")
    const [calf, setCalf] = useState("")
    const [milkInKgTotal, setMilkInKgTotal] = useState("")
    const [milkInKgAvg, setMilkInKgAvg] = useState("")
    const [dryDate, setDryDate] = useState("")
    const [dryDays, setDryDays] = useState("")
    const [noOfDose, setNoOfDose] = useState("")
    const [sire, setSire] = useState("")
    const [paternalDetails, setPaternalDetails] = useState("")
    const [dam, setDam] = useState("")
    const [paternalDam, setPaternalDam] = useState("")
    const [doctor, setDoctor] = useState("")
    const [hornNo, setHornNo] = useState("")
    const [purpose, setPurpose] = useState("")
    const [place, setPlace] = useState("")
    const [color, setColor] = useState("")
    const [hornDistance, setHornDistance] = useState("")
    const [aiTagNo, setAiTagNo] = useState("")
    const [birthWeight, setBirthWeight] = useState("")
    const [parity, setParity] = useState("")
    const [role, setRole] = useState("normal")
    const [calfId, setCalfId] = useState("")
    const [reproductive, setReproductive] = useState("")
    const [calvingNote, setCalvingNote] = useState("")
    const [peakDays, setPeakDays] = useState("")
    const [peakYield, setPeakYield] = useState("")
    const [dryingReason, setDryingReason] = useState("")
    const [name, setName] = useState("")
    const [editId, setEditId] = useState("")
    const [speciesId, setSpeciesId] = useState("")
    const [staff, setStaff] = useState("")
    const [milkingDays, setMilkingDays] = useState("")
    const [code, setCode] = useState("")
    const [registrationDate, setRegistrationDate] = useState(selectedToDate)
    const [lat, setLat] = useState("")
    const [lastSire, setLastSire] = useState("")
    const [long, setLong] = useState("")
    const [lot, setLot] = useState("")
    const [marketValue, setMarketValue] = useState("")
    const [sensorNo, setSensorNo] = useState("")
    const [zone, setZone] = useState("")
    const [paternalSire, setPaternalSire] = useState("")
    const [sexFlg, setSexFlg] = useState("")
    const [age, setAge] = useState("")
    const logOut = () => { navigate("/") }
    const [animalName, setAnimalName] = useState("")
    const [numberOfCalving, setNumberOfCalving] = useState("")
    const [pdDays, setPdDays] = useState("")
    const [pragPdDate, setPragPdDate] = useState("")
    const [aiDays, setAiDays] = useState("")
    const [totalMilk, setTotalMilk] = useState("")
    const [mTotal, setMTotal] = useState("")

    const [saveAddImage, setSaveAddImage] = useState("");
    const [screenImage, setScreenImage] = useState("")
    const [displayImage, setDisplayImage] = useState("")
    const [imgFile1, setImgFile1] = useState([]);

    const [calvinCalDate, setCalvinCalDate] = useState("")
    const [heatCalDate, setHeatCalDate] = useState("")
    const [pdCalDate, setPdCalDate] = useState("")
    const [dryCalDate, setDryCalDate] = useState("")

    const [breedCalvinCalDate, setBreedCalvinCalDate] = useState("")
    const [breedHeatCalDate, setBreedHeatCalDate] = useState("")
    const [breedPdCalDate, setBreedPdCalDate] = useState("")
    const [breedDryCalDate, setBreedDryCalDate] = useState("")

    const [dDryDate, setDDryDate] = useState("")
    const [dDryDays, setDDryDays] = useState("")
    const [pragSire, setPragSire] = useState("")
    const [pragStaff, setPragStaff] = useState("")
    const [heatSqnc, setHeatSqnc] = useState("")
    const [suqnc, setSuqnc] = useState("")
    const [aiDate, setAiDate] = useState("")
    const [sirename, setSirename] = useState("")
    const [calvingDryTypeTab, setCalvingDryTypeTab] = useState(1)


    const [subBreeding, setSubBreeding] = useState(1)
    const [subAIBreeding, setSubAIBreeding] = useState(1)
    const [calvingTypeTab, setCalvingTypeTab] = useState(1)
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animalindex, setAnimalIndex] = useState(0)
    const [calvingTab, setCalvingTab] = useState(0)
    const [calvingSubTab, setCalvingSubTab] = useState(0)
    const [milkTab, setMilkTab] = useState(1)
    const [dryTab, setDryTab] = useState(1)
    const [breedingTab, setBreedingTab] = useState(0)

    const [heightOffSet, setHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [editIdData, setEditIdData] = useState(false)

    const [routeList, setRouteList] = useState([])
    const [farmer, setFarmer] = useState([])
    const [birthList, setBirthList] = useState([])
    const [sexList, setSexList] = useState([])
    const [speciesList, setSpeciesList] = useState([])
    const [breedList, setBreedList] = useState([])
    const [calfList, setCalfList] = useState([])
    const [paternalSireList, setPaternalSireList] = useState([])
    const [damList, setDamList] = useState([])
    const [paternalDamList, setPaternalDamList] = useState([])
    const [purposeList, setPurposeList] = useState([])
    const [doctorList, setDoctorList] = useState([])
    const [animalListData, setAnimalListData] = useState([])
    const [dryingList, setDryingList] = useState([])
    const [reproductiveList, setReproductiveList] = useState([])
    const [totalMilkYield, setTotalMilkYield] = useState([])
    const [staffList, setStaffList] = useState([])
    const [sireList, setSireList] = useState([])
    const [dcsList, setDcsList] = useState([])
    const [farmerList, setFarmerList] = useState([])
    const [editData, setEditData] = useState([])
    const [animalList, setAnimalList] = useState([])

    const removeImage = (e) => {
        setImgFile1("");
        setSaveAddImage("");
    }
    const imageHandle = (e) => {
        setImgFile1(e.target.files[0]);
        setSaveAddImage(URL.createObjectURL(e.target.files[0]));
    };

    useEffect(() => {
        if (calvingSubTab === 0) {
            const currentDate = new Date();
            const futureDate = new Date(currentDate.getTime() - milkingDays * 24 * 60 * 60 * 1000);
            const calvingDateFuture = moment(futureDate).toISOString("L");
            setCalvinCalDate(calvingDateFuture);

            const HeatDateFuture = moment(calvingDateFuture).subtract(300, 'days').toISOString("L");
            setHeatCalDate(HeatDateFuture);

            const pdDateFuture = moment(HeatDateFuture).add(90, 'days').toISOString("L");
            setPdCalDate(pdDateFuture);
        }
        if (calvingSubTab === 1) {
            const calvingDateCurrent = moment.utc(calvingDate).local().toISOString();
            setCalvinCalDate(calvingDateCurrent);

            const HeatDateFuture = moment(calvingDateCurrent).subtract(300, 'days').toISOString("L");
            setHeatCalDate(HeatDateFuture);

            const pdDateFuture = moment(HeatDateFuture).add(90, 'days').toISOString("L");
            setPdCalDate(pdDateFuture);
        }
        if (calvingSubTab === 1) {
            const calvingDateCurrent = moment.utc(calvingDate).local().toISOString();
            setCalvinCalDate(calvingDateCurrent);

            const HeatDateFuture = moment(calvingDateCurrent).subtract(300, 'days').toISOString("L");
            setHeatCalDate(HeatDateFuture);

            const pdDateFuture = moment(HeatDateFuture).add(90, 'days').toISOString("L");
            setPdCalDate(pdDateFuture);
        }
        if (calvingSubTab === 2 && dryTab == 2) {
            const currentDate = new Date();
            const dryDateSub = new Date(currentDate.getTime() - dDryDays * 24 * 60 * 60 * 1000);
            const dryDateFuture = moment(dryDateSub).toISOString("L");
            setDryCalDate(dryDateFuture)

            const calvingFutureDate = moment(dryDateFuture).subtract(300, 'days').toISOString("L");
            setCalvinCalDate(calvingFutureDate);

            const HeatDateFuture = moment(calvingFutureDate).subtract(300, 'days').toISOString("L");
            setHeatCalDate(HeatDateFuture);

            const pdDateFuture = moment(HeatDateFuture).add(90, 'days').toISOString("L");
            setPdCalDate(pdDateFuture);

        }

        if (milkTab === 1) {
            setMilkInKgAvg("")
            // console.log("Harsh", milkInKgTotal)

            const mor = milkInKgTotal / milkingDays
            setMTotal(mor)
        }

        if (milkTab === 2) {
            setMilkInKgTotal("")

            const totalMilkCount = milkInKgAvg * milkingDays
            setTotalMilk(totalMilkCount)
        }

        // Ai Save/breeding
        if (subBreeding === 1) {
            const currentDate = new Date();
            const pdDateFurture = new Date(currentDate.getTime() - pdDays * 24 * 60 * 60 * 1000);
            const PdDate = moment(pdDateFurture).toISOString("L");
            setBreedPdCalDate(PdDate);

            const HeatDateFuture = moment(PdDate).subtract(90, 'days').toISOString("L");
            setBreedHeatCalDate(HeatDateFuture);

            setBreedCalvinCalDate("");
            setBreedDryCalDate("");
        }
        if (subBreeding === 2) {
            const pdDateCurrent = moment.utc(pragPdDate).local().toISOString();
            setBreedPdCalDate(pdDateCurrent);

            const HeatDateFuture = moment(pdDateCurrent).subtract(90, 'days').toISOString("L");
            setHeatCalDate(HeatDateFuture);

            setBreedCalvinCalDate("");
            setBreedDryCalDate("");
        }
        if (subAIBreeding === 1) {
            const currentDate = new Date();
            const futureDate = new Date(currentDate.getTime() - aiDays * 24 * 60 * 60 * 1000);
            const heatDateFuture = moment(futureDate).toISOString("L");
            setBreedHeatCalDate(heatDateFuture);

            setBreedCalvinCalDate("");
            setBreedDryCalDate("");
            setBreedPdCalDate("");
        }
        if (subAIBreeding === 2) {
            const heatDateCurrent = moment.utc(aiDate).local().toISOString();
            setBreedHeatCalDate(heatDateCurrent);

            setBreedCalvinCalDate("");
            setBreedDryCalDate("");
            setBreedPdCalDate("");
        }


    }, [calvingSubTab, milkingDays, calvingDate, milkTab, milkInKgAvg, mTotal, milkInKgTotal]);

    const resetStates = () => {
        setRoute('');
        setDcs('');
        setFarmer('');
        setTagId('');
        setBirth('');
        setAge('');
        setSex('');
        setAnimalName('');
        setSpecies('');
        setBreed('');
        setNumberOfCalving('');
        setMilkingDays('');
        setMilkInKgTotal('');
        setSire(null);
        setStaff(null);
        setPdDays('')
        setPragStaff(null);
        setPragSire(null);
        setNoOfDose('');
        setHeatSqnc('');
        setSuqnc('');
        // NumberValidation('');
        // setMilkingDays('');
        // selectSire('');
        // setStaff('');
        setCalf(null);
        // setSire('');
        // setBreedingTab('');
        // setSubBreeding('');
    };


    const saveAnimal = async () => {
        const parts = date.split("-");
        const convertedDate = parts[0] + "/" + parts[1] + "/" + parts[2];

        localStorage.removeItem("editAnimal")
        localStorage.removeItem("Parity")
        localStorage.removeItem("Fld")

        // console.log(mTotal)
        // console.log(milkInKgAvg)
        // console.log(totalMilk)


        // console.log(calvinCalDate)
        // console.log(heatCalDate)
        // console.log(pdCalDate)
        // console.log(dryCalDate)
        // console.log("sire:" + sire);
        if (route !== "" && birth !== "") {
            await fetch(`${API_KEY}/v1/api/web/animal/save-animal`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({
                    birthWeight, breed, code: "", dam, farmer, herd: route, inputDate: birth, lastSire, lat, long, lot: dcs, marketValue, name: animalName, parity: numberOfCalving !== "" ? numberOfCalving : "1", paternalDam, paternalSire, photo: "", registrationDate, sensorNo, sexFlg: sex, sire: sire.toString() != "" ? sire.id : "0", species, staff, tagId, zone,
                    createdAt: convertedDate, updatedAt: ""
                })
            })
                .then(res => res.json())
                .then(res => {
                    toast.success("Data Save Successfully");
                    console.log("animal list : " + setAnimalList(res));
                    // setTimeout(() => {
                    //     navigate("/Cattle-Registration")
                    // }, 2000);
                    resetStates();
                })
                .catch(error => { toast.error(error) })
        }
        else {
            toast.error("Please fill all field");
        }


        if (milkInKgAvg !== "" || milkInKgTotal !== "") {
            await fetch(`${BulkMilkApi.saveAPi}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${added_by}`,
                },
                body: JSON.stringify({
                    tagId: tagId,
                    inputDate: locaformate,
                    parity: numberOfCalving !== "" ? numberOfCalving : "1",
                    morningYield: milkTab === 2 ? milkInKgAvg : mTotal,
                    eveningYield: null,
                    nightYield: null,
                    midnightYield: null,
                    fat: null,
                    snf: null,
                    lactose: milkTab === 1 ? milkInKgTotal : totalMilk,
                    protein: null,
                    officialMilk: null,
                    createdAt: locaformate
                }),
            })

                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    resetStates()
                })
                .catch(error => { toast.error(error) });
        }

        // saveBreeding
        if (calvingTab === 1) {
            await fetch(`${Cattleregistration.saveBreedingReproduction}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({
                    AICost: null,
                    createdAt: locaformate,
                    createdByUser: 207,
                    lastUpdatedByUser: 207,
                    updatedAt: locaformate,
                    zone: "0",
                    // AIDays: "",
                    // AITname: "",
                    // CalvingPDDays: "",
                    // insertflag: "",
                    // PDdays: "",
                    // PDname: "",
                    // PDResult: "",
                    // Pregdays: "",
                    // Sirename: sire.Name !== "" ? sire.Name : "Unknown",
                    TagId: tagId,
                    Parity: numberOfCalving !== "" ? numberOfCalving : "1",
                    HeatSeq: 0,
                    HeatDate: heatCalDate,
                    RemPD1: 1,
                    RemPD2: 1,
                    PDDate: pdCalDate,
                    DateOfCalving: calvinCalDate,
                    DateOfDry: dryCalDate,
                    DryTreatment: "DryTreatment Text",
                    Flag: "Flag Text",
                    RetantionOfPlecenta: 1,
                    Comments: "Comments Text",
                    ReproductionProblemNote: "ReproductionProblemNote Text",
                    MobileOrDesktopEntryFlg: "D",
                    TotalAIDose: 0,
                    AbortionSeq: 0,
                    Colostrum: 1,
                    ENTRY: "I",
                    Lat: 0.0,
                    Long: 0.0,
                    details: 2,
                    inseminatorStaff: staff,
                    sire: sire.id,
                    pdBy: 1,
                    service: 1,
                    pd1: calvingTab === 1 ? "3" : "",
                    pd2: calvingTab === 1 ? "3" : "",
                    Sex: sex,
                    CalfSex: calf,
                    CalvingType: calvingDryTypeTab,
                    calvingTypeOption: calvingDryTypeTab,
                    DryReason: null,
                    id: 0,
                    ServerId: ""
                })
            })
                .then(res => res.json())
                .then(res => { resetStates() })
                .catch(error => { toast.error(error) })
        }
        if (breedingTab === 1) {
            await fetch(`${Cattleregistration.saveBreedingReproduction}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({
                    AICost: null,
                    createdAt: locaformate,
                    createdByUser: 207,
                    lastUpdatedByUser: 207,
                    updatedAt: locaformate,
                    zone: "0",
                    // AIDays: "",
                    // AITname: "",
                    // CalvingPDDays: "",
                    // insertflag: "",
                    // PDdays: "",
                    // PDname: "",
                    // PDResult: "",
                    // Pregdays: "",
                    // Sirename: sire.Name !== "" ? sire.Name : "Unknown",
                    TagId: tagId,
                    Parity: numberOfCalving !== "" ? numberOfCalving : "1",
                    HeatSeq: heatSqnc,
                    HeatDate: breedHeatCalDate,
                    RemPD1: 1,
                    RemPD2: 1,
                    PDDate: breedPdCalDate,
                    DateOfCalving: breedCalvinCalDate,
                    DateOfDry: breedDryCalDate,
                    DryTreatment: "DryTreatment Text",
                    Flag: "Flag Text",
                    RetantionOfPlecenta: 1,
                    Comments: "Comments Text",
                    ReproductionProblemNote: "ReproductionProblemNote Text",
                    MobileOrDesktopEntryFlg: "D",
                    TotalAIDose: noOfDose,
                    AbortionSeq: 0,
                    Colostrum: 1,
                    ENTRY: "I",
                    Lat: 0.0,
                    Long: 0.0,
                    details: 2,
                    inseminatorStaff: pragStaff,
                    sire: sire.id,
                    pdBy: 1,
                    service: 1,
                    pd1: breedingTab === 1 ? "3" : "",
                    pd2: breedingTab === 1 ? "3" : "",
                    Sex: sex,
                    CalfSex: calf,
                    CalvingType: calvingDryTypeTab,
                    calvingTypeOption: calvingDryTypeTab,
                    DryReason: 0,
                    id: 0,
                    ServerId: ""
                })
            })
                .then(res => res.json())
                .then(res => { resetStates() })
                .catch(error => { toast.error(error) })
        }
    }

    const editFn = (e) => {
        setEditId(e.id)
        setSpeciesId(e["species"]["id"])
        setModalShow(true);
        setName(e.Name);
    }
    const selectBreed = (e) => {
        setSpecies(e)
        fetchBreedata(e)
    }
    const selectRoute = (e) => {
        setRoute(e)
        fetchDcsData(e)
    }
    const selectDcs = (e) => {
        setDcs(e)
        fetchFarmerData(e)
    }

    const fetchBreedata = async (e) => {
        await fetch(`${API_KEY}/camp/fill-combo/breed?species=${e}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then(res => { setBreedList(res) });
    }
    const fetchDcsData = async (e) => {
        await fetch(`${API_KEY}/camp/fill-combo/dcs?herd=${e}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then(res => { setDcsList(res) });
    }
    const fetchFarmerData = async (e) => {
        await fetch(`${API_KEY}/camp/fill-combo/farmer?lot=${e}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then(res => { setFarmerList(res) });
    }

    const fetchAllAPi = async () => {
        const res = await getRouteData()
        const res1 = await getSexData()
        const res2 = await getSpecies()
        const res3 = await getStaffData()
        const res4 = await getSireData()

        if (res) { setRouteList(res) }
        if (res1) { setSexList(res1) }
        if (res2) { setSpeciesList(res2) }
        if (res3) { setStaffList(res3) }
        if (res4) { setSireList(res4) }
    }
    const Save = async () => {
        const res = await getEditData({ company: 1, id: editId, name, species: speciesId, user: "201" })
        if (res) { setModalShow(false) }
    }

    const fetchData = async () => {
        const res = await getTableData()
        if (res) { setAnimalListData(res.data) }
    }

    const colum = [
        {
            name: "Heat Data",
            selector: "BreedType",
            sortable: true
        },
        {
            name: "Service",
            cell: (e) => (<p>{e["species"]["Name"]}</p>),
            sortable: true
        },
        {
            name: "SireId",
            selector: "",
            sortable: true
        },
        {
            name: "Inseminator",
            selector: ""
        },
        {
            name: "PD Result",
            selector: ""
        },
        {
            name: "PD Result",
            selector: ""
        },
        {
            name: "F",
            selector: ""
        },
        {
            name: "G",
            cell: (e) => (<><img src={edit} width={18} onClick={() => editFn(e)} /></>),
        },
    ]

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        fetchData()
        fetchAllAPi()
        setRoute(editData.herdName)
        let objEdit = JSON.parse(localStorage.getItem("editAnimal"))
        editData.push(objEdit)
        for (let index = 0; index < editData.length; index++) {
            const element = editData[index];
            if (element) {
                setEditIdData(true)
                setRoute(element["herdName"])
                setDcs(element["lotId"])
                setFarmer(element["farmerName"])
                setTagId(element["TagId"])
                setBirth(element["DOB"])
                setAge(element["Age"])
                // setRegistrationDate(element["ServiceDate"])
                setSpecies(element["speciesName"])
                setBreed(element["breedId"])
                setSex(element["sexName"])
            }
        }
    }, [])


    const names = ['Problem 1', 'Problem 2', 'Problem 3',];

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setReproductiveList(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const plushChange = () => {
        navigate("/OwnerFarmer")
    }

    const selectSire = (e) => {
        const selectedSire = e.target.value;
        setSire(selectedSire);
    }


    const NumberValidation = (e, f) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        if (f == "numberOfCalving") {
            setNumberOfCalving(numericValue);
        }
        else if (f == "milkingDays") {
            setMilkingDays(numericValue)
        }
        else if (f == "milkInKgTotal") {
            setMilkInKgTotal(numericValue)
        }
        else if (f == "milkInKgAvg") {
            setMilkInKgAvg(numericValue)
        }
        else if (f == "pdDays") {
            setPdDays(numericValue)
        }
        else if (f == "noOfDose") {
            setNoOfDose(numericValue)
        }
        else if (f == "heatSqnc") {
            setHeatSqnc(numericValue)
        }
        else if (f == "suqnc") {
            setSuqnc(numericValue)
        }
        else if (f == "dDryDays") {
            setDDryDays(numericValue)
        }
        else if (f == "aiDays") {
            setAiDays(numericValue)
        }
    };

    useEffect(() => {
        if (sexList.length > 1) {
            const defaultSex = editIdData ? sexList[1].Name : sexList[1].ID;
            setSex(defaultSex); // Set the default value after component initialization
        }
    }, [sexList, editIdData]); // Ensure useEffect is triggered when these dependencies change

    const handleChange1 = (e) => {
        setSex(e.target.value);
    };

    return (
        <>
            <Container fluid>
                <Row id="addAnimal">
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Cattle/detailsregistration/add</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end">
                                    <img src={profile} width={40} />
                                </Col>
                            </Row>
                            <Row className="sub-header1 align-items-center justify-content-between">
                                <Col className="d-flex align-items-center justify-content-start">
                                    <div className="ms-2">
                                        <img src={breeding} width={40} />
                                    </div>
                                    <div className="ms-4">
                                        <h5 className="mb-0 mt-1">Cattle Details</h5>
                                        <p className="mb-1 mt-1">File all the information about animal and its purchase, parent details</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3 align-items-center p-2'>
                                <Col xs="6" xl="3" xxl="1" className={currentIndex === 0 ? "d-flex align-items-center tab1" : "d-flex "} onClick={() => { setCurrentIndex(0); }}>
                                    <div className="ms-2">
                                        <img src={breeding} width={30} />
                                    </div>
                                    <span className='ms-2 mainHeading'>General Properties</span>
                                </Col>
                                <Col xs="6" xl="3" xxl="1" className={currentIndex === 1 && "align-items-center tab1"} onClick={() => { setCurrentIndex(1); }}>
                                    <div className='mainHeading'> Previous Breeding and Milk</div>
                                </Col>
                            </Row>

                            <Row className='align-items-center'>
                                {
                                    currentIndex === 0 &&
                                    <>
                                        <Row className='mt-3'>
                                            <Col sm="2" xl="2" xs="6" className=''>
                                                <FormControl variant="standard" fullWidth>
                                                    <InputLabel id="demo-simple-select-standard-label" style={{fontSize:"13px"}}>Select Route</InputLabel>
                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={route} onChange={(e) => { selectRoute(e.target.value); }}>
                                                        {routeList.map((e) => (
                                                            <MenuItem className="drop_value" key={e.ID} value={editIdData == true ? e.Name : e.ID}>
                                                                {e.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                            <Col sm="1" xl="1" xs="6" className='d-flex align-items-center ' style={{ width: "30px" }}>

                                            </Col>
                                            <Col sm="2" xl="2" xs="6" className='' >
                                                <FormControl variant="standard" fullWidth >
                                                    <InputLabel id="demo-simple-select-standard-label"style={{fontSize:"13px"}} >Select Dcs</InputLabel>
                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={dcs} onChange={(e) => { selectDcs(e.target.value); }}>
                                                        {dcsList.map((e) => (
                                                            <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                {e.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Col>

                                            <Col sm="2" xl="2" xs="6" className=''>
                                                <FormControl variant="standard" fullWidth>
                                                    <InputLabel id="demo-simple-select-standard-label" style={{fontSize:"13px"}}>Select Farmer</InputLabel>
                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={farmer} onChange={(e) => { setFarmer(e.target.value); }}>
                                                        {farmerList.map((e) => (
                                                            <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                {e.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                            <Col sm="1" xl="1" xs="6" className='d-flex align-items-center '  >
                                                <AiFillPlusCircle onClick={plushChange} />
                                            </Col>
                                            <Col sm="2" xl="2" xs="6" className=''>
                                                {/* <Input label="Enter TagId" type="number" value={tagId} onChange={(e) => { setTagId(e.target.value) }} /> */}
                                                <MuiInput style={{fontSize:"13px"}} label="Enter TagId" type="text" value={tagId} onChange={(e) => { setTagId(e.target.value) }} />
                                            </Col>
                                            <Col sm="2" xl="2" xs="6" className='mt-0' id="imgaeAdd">
                                                <Col md="4" className="">
                                                    <label> <img className={saveAddImage && "ms-3 prfoileImage"} src={saveAddImage ? saveAddImage : addImage} style={saveAddImage ? { width: "80px" } : { width: "20px" }} />
                                                        <input type="file" onChange={imageHandle} multiple accept="image/png, image/jpeg, image/*" style={{ display: "none" }} />
                                                    </label>
                                                    {
                                                        saveAddImage && <div className="cancelImage">
                                                            <img src={closeIcon} onClick={removeImage} style={{ width: "18px" }} />
                                                        </div>
                                                    }
                                                </Col>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col sm="2" xl="2" xs="6" className=''>
                                                <div className="">
                                                    <sub className="mb-0 pb-0" style={{fontSize:"13px"}}>Select Birth Date</sub>
                                                    <input type="date" className="dateSelect_bg" value={birth} onChange={e => setBirth(e.target.value)} />
                                                </div>
                                            </Col>
                                            <Col sm="1" xl="1" xs="6" className='d-flex align-items-center ' style={{ width: "30px" }}>
                                                OR
                                            </Col>
                                            <Col sm="3" xl="2" xs="6" className=''>
                                                {/* <Input type="number" label="Enter Age" value={age} onChange={(e) => { setAge(e.target.value) }} /> */}
                                                <MuiInput style={{fontSize:"13px"}} type="number" label="Enter Age" value={age} onChange={(e) => { setAge(e.target.value) }} />
                                            </Col>
                                            <Col sm="3" xl="2" xs="6" className=''>
                                                <div className="">
                                                    <sub className="mb-0 pb-0" style={{fontSize:"13px"}}>Registration Date</sub>
                                                    <input type="date" className="dateSelect_bg" value={registrationDate} onChange={e => setRegistrationDate(e.target.value)} />
                                                </div>
                                            </Col>

                                            <Col sm="1" xl="1" xs="6" className='d-flex align-items-center '  >
                                                {/* <AiFillPlusCircle /> */}
                                            </Col>

                                            <Col sm="3" xl="2" xs="6" className=''>
                                                <FormControl variant="standard" fullWidth>
                                                    <InputLabel id="demo-simple-select-standard-label" style={{fontSize:"13px"}}>Select Sex</InputLabel>
                                                    {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sex} onChange={(e) => { setSex(e.target.value); }}>
                                                        {sexList.map((e) => (
                                                            <MenuItem className="drop_value" key={e.ID} value={editIdData ? e.Name : e.ID}>
                                                                {e.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select> */}
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={sex}
                                                        onChange={handleChange1}
                                                    >
                                                        {sexList.map((item) => (
                                                            <MenuItem
                                                                className="drop_value"
                                                                key={item.ID}
                                                                value={editIdData ? item.Name : item.ID}
                                                            >
                                                                {item.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                            <Col sm="2" xl="3" xs="6" className='mt-0' id="imgaeAdd">
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>

                                            <Col sm="2" xl="2" xs="6" className=''>
                                                {/* <Input label="Enter Animal Name" type="text" value={animalName} onChange={(e) => { setAnimalName(e.target.value) }} /> */}
                                                <MuiInput style={{fontSize:"13px"}} label="Enter Animal Name" type="text" value={animalName} onChange={(e) => { setAnimalName(e.target.value) }} />
                                            </Col>
                                            <Col sm="1" xl="1" xs="6" className='d-flex align-items-center ms-auto' style={{ width: "32px" }} >
                                            </Col>
                                            <Col sm="2" xl="2" xs="6" className=''>
                                                <FormControl variant="standard" fullWidth>
                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Select Species</InputLabel>
                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={species} onChange={(e) => { selectBreed(e.target.value); }}>
                                                        {speciesList.map((e) => (
                                                            <MenuItem className="drop_value" key={e.ID} value={editIdData ? e.Name : e.ID}>
                                                                {e.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                            {/* <Col sm="1" xl="1" xs="6" className='d-flex align-items-center '  >
                                                <AiFillPlusCircle width={40} />
                                            </Col> */}
                                            <Col sm="3" xl="2" xs="6" className=''>
                                                <FormControl variant="standard" fullWidth>
                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Select Farm Breed</InputLabel>
                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={breed} onChange={(e) => { setBreed(e.target.value); }}>
                                                        {breedList.map((e) => (
                                                            <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                {e.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                            </Col>
                                            <Col sm="1" xl="1" xs="6" className='d-flex align-items-center '  >

                                            </Col>

                                            <Col sm="3" xl="2" xs="6" className=''>
                                                <FormControl variant="standard" fullWidth>
                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Select Breed</InputLabel>
                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={breed} onChange={(e) => { setBreed(e.target.value); }}>
                                                        {breedList.map((e) => (
                                                            <MenuItem className="drop_value" key={e.ID} value={editIdData ? e.Name : e.ID}>
                                                                {e.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                            <Col className='d-flex align-items-center ms-auto' >
                                                <AiFillPlusCircle width={40} />
                                            </Col>

                                        </Row>
                                        <Row className='mt-3'>
                                            <Col sm="2" xl="2" xs="7" className=''>
                                                <input type="checkbox" className='me-1' />Allow All User
                                            </Col>
                                            <Col sm="1" xl="1" xs="7" className=''>
                                                <input type="checkbox" className='me-1' />Suspend
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Row>
                            {
                                currentIndex === 0 &&
                                <>
                                    <Row className='justify-content-center' style={{ marginTop: "30px" }}>
                                        <Col className={animalindex === 0 ? "d-flex align-items-center tab1 selected" : "addTab1"} onClick={() => { setAnimalIndex(0); }} style={{ display: 'flex', justifyContent: 'center', padding: "10px 20px", border: '1px solid black', backgroundColor: animalindex === 0 ? 'rgb(211, 211, 211)' : 'inherit' }}>
                                            <div className='tabHeading'>Breeding Details/Milk</div>
                                        </Col>
                                        <Col className={animalindex === 1 ? "align-items-center tab1 selected" : "addTab"} onClick={() => { setAnimalIndex(1); }} style={{ display: 'flex', justifyContent: 'center', padding: "10px 20px", border: '1px solid black', backgroundColor: animalindex === 1 ? 'rgb(211, 211, 211)' : 'inherit' }}>
                                            <div className='tabHeading'> Purchase Details</div>
                                        </Col>
                                        <Col className={animalindex === 2 ? "align-items-center tab1 selected" : "addTab"} onClick={() => { setAnimalIndex(2); }} style={{ display: 'flex', justifyContent: 'center', padding: "10px 20px", border: '1px solid black', backgroundColor: animalindex === 2 ? 'rgb(211, 211, 211)' : 'inherit' }}>
                                            <div className='tabHeading'> Parent Details</div>
                                        </Col>
                                        <Col className={animalindex === 3 ? "align-items-center tab1 selected" : "addTab"} onClick={() => { setAnimalIndex(3); }} style={{ display: 'flex', justifyContent: 'center', padding: "10px 20px", border: '1px solid black', backgroundColor: animalindex === 3 ? 'rgb(211, 211, 211)' : 'inherit' }}>
                                            <div className='tabHeading'> Insurance Details</div>
                                        </Col>
                                        <Col className={animalindex === 4 ? "align-items-center tab1 selected" : "addTab"} onClick={() => { setAnimalIndex(4); }} style={{ display: 'flex', justifyContent: 'center', padding: "10px 20px", border: '1px solid black', backgroundColor: animalindex === 4 ? 'rgb(211, 211, 211)' : 'inherit' }}>
                                            <div className='tabHeading'> Other Details</div>
                                        </Col>
                                    </Row>

                                    {
                                        animalindex === 0 &&
                                        <>
                                            <div style={{ paddingTop: "45px" }} className='paritySection' >
                                                <Row>
                                                    <Col xl="2" xs="12" className='ms-0 mt-3'>
                                                        {/* <Input type="number" label="Number Of Calving" value={numberOfCalving} onChange={(e) => { setNumberOfCalving(e.target.value) }} /> */}
                                                        <MuiInput style={{fontSize:"13px"}} label="Number Of Calving" value={numberOfCalving} onChange={(e) => { NumberValidation(e, "numberOfCalving") }} />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className='align-items-start justify-content-start' id="addAnimal">
                                                    <Col xl="4" xs="12" className='ms-0 mt-2 d-flex calvingTab align-items-center'>
                                                        <h6 className='ms-0 mt-2 me-2 statusName'>Milking Status :-</h6>
                                                        <button className={calvingTab === 0 ? "calTab" : ""} onClick={() => { setCalvingTab(0); }}>None</button>
                                                        <button className={calvingTab === 1 ? "calTab" : ""} onClick={() => { setCalvingTab(1); }}>Milking</button>
                                                        <button className={calvingTab === 2 ? "calTab" : ""} onClick={() => { setCalvingTab(2); }}>Dry</button>
                                                    </Col>
                                                </Row>
                                                {/* {calvingTab === 1 && (
                                                        <Col xl="3" xs="12" className='ms-1 mt-2 d-flex calvingTab align-items-center'>
                                                            <>
                                                                <button className={calvingSubTab === 0 ? "calTab mt-2 ms-4" : "mt-2 ms-4"} onClick={() => { setCalvingSubTab(0); }}>Days In Milking</button>
                                                                <button className={calvingSubTab === 1 ? "calTab mt-2" : "mt-2"} onClick={() => { setCalvingSubTab(1); }}>Last Calving</button>
                                                            </>
                                                        </Col>
                                                    )} */}
                                                <Row>
                                                    <Col className='calvingTab' xl="4" xs="12">
                                                        {calvingTab === 1 &&
                                                            <>
                                                                <button className={calvingSubTab === 0 ? "calTab mt-2 ms-0" : "mt-2 ms-0"} onClick={() => { setCalvingSubTab(0); }}>Days In Milking</button>
                                                                <button className={calvingSubTab === 1 ? "calTab mt-2" : "mt-2"} onClick={() => { setCalvingSubTab(1); }}>Last Calving</button>
                                                                {
                                                                    calvingSubTab === 0 &&
                                                                    <Row>
                                                                        <Col sm="12" xl="4" xs="12" className='mt-3'>
                                                                            {/* <Input type="number" label="Milking Days" value={milkingDays} onChange={(e) => { setMilkingDays(e.target.value) }} /> */}
                                                                            <MuiInput style={{fontSize:"13px"}} label="Milking Days" value={milkingDays} onChange={(e) => { NumberValidation(e, "milkingDays") }} />
                                                                        </Col>
                                                                        {/* <div className='d-flex'> */}
                                                                        <Col sm="12" xl="4" xs="12" className='pt-4'>
                                                                            <FormControl variant="standard" fullWidth>
                                                                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Sire</InputLabel>
                                                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={selectSire}>
                                                                                    {sireList.map((e) => (
                                                                                        <MenuItem className="drop_value" key={e.id} value={e}>
                                                                                            {e.Name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Col>
                                                                        <Col xs="12" xl="4" className='pt-4'>
                                                                            <FormControl variant="standard" fullWidth>
                                                                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Inseminator</InputLabel>
                                                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { setStaff(e.target.value); }}>
                                                                                    {staffList.map((e) => (
                                                                                        <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                                            {e.Name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Col>
                                                                        {/* </div> */}
                                                                    </Row>
                                                                }
                                                                {
                                                                    calvingSubTab === 1 &&
                                                                    <Row>
                                                                        <Col md="3" xl="4" xs="12" className='mt-3 ps-2'>
                                                                            <Input type="date" style={{fontSize:"13px"}} label="Caving Dates" value={calvingDate} onChange={(e) => { setCalvingDate(e.target.value) }} />
                                                                            {/* <MuiInput type="date" label="Caving Dates" value={calvingDate} onChange={(e) => { setCalvingDate(e.target.value) }}/> */}
                                                                        </Col>
                                                                        {/* <div className='d-flex'> */}
                                                                        <Col xl="4" xs="12" className='pt-3'>
                                                                            <FormControl variant="standard" fullWidth>
                                                                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Sire</InputLabel>
                                                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={selectSire} >
                                                                                    {breedList.map((e) => (
                                                                                        <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                                            {e.Name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Col>
                                                                        <Col xs="12" xl="4" className='pt-3'>
                                                                            <FormControl variant="standard" fullWidth>
                                                                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Inseminator</InputLabel>
                                                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { setStaff(e.target.value); }}>
                                                                                    {staffList.map((e) => (
                                                                                        <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                                            {e.Name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Col>
                                                                        {/* </div> */}
                                                                    </Row>
                                                                }
                                                            </>
                                                        }

                                                    </Col>
                                                    <Col xl="4">
                                                        {
                                                            calvingTab === 1 &&
                                                            <>
                                                                {/* <h6 className='ms-3'>Calving Type :-</h6> */}
                                                                <Col sm="12" xl="12" xs="6" className='ms-3 mt-2 d-flex calvingTab'>
                                                                    <button className={calvingDryTypeTab === 1 ? "calTab" : ""} onClick={() => { setCalvingDryTypeTab(1); }}>Normal</button>
                                                                    <button className={calvingDryTypeTab === 2 ? "calTab" : ""} onClick={() => { setCalvingDryTypeTab(2); }}>Abnormal</button>
                                                                </Col>
                                                                {
                                                                    calvingTypeTab === 0 &&
                                                                    <>
                                                                        <Col xl="3" xs="12" className='ms-3 pt-3'>
                                                                            <FormControl variant="standard" fullWidth>
                                                                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Calf Sex</InputLabel>
                                                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={calf} onChange={(e) => { setCalf(e.target.value); }}>
                                                                                    <MenuItem className="drop_value" value="1">Male</MenuItem>
                                                                                    <MenuItem className="drop_value" value="2">FeMale</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Col>
                                                                    </>
                                                                }
                                                                {
                                                                    calvingTypeTab === 1 &&
                                                                    <>
                                                                        <Col xl="3" xs="12" className='pt-4 ms-3'>
                                                                            <FormControl variant="standard" fullWidth>
                                                                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Calf Sex</InputLabel>
                                                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={calf} onChange={(e) => { setCalf(e.target.value); }}>
                                                                                    <MenuItem className="drop_value" value="1">Male</MenuItem>
                                                                                    <MenuItem className="drop_value" value="2">FeMale</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Col>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </Col>
                                                    <Col xl="4" xs="12">
                                                        {
                                                            calvingTab === 1 &&
                                                            <>
                                                                {/* <h6 className='ms-3'>Milk Detail:-</h6> */}
                                                                <Col xl="4" xs="12" className='ms-3 mt-2 d-flex calvingTab'>
                                                                    <button className={milkTab === 1 ? "calTab" : ""} onClick={() => { setMilkTab(1); }}>Total Milk</button>
                                                                    <button className={milkTab === 2 ? "calTab" : ""} onClick={() => { setMilkTab(2); }}>Avg Milk</button>
                                                                </Col>
                                                                <Col xs="12">
                                                                    {
                                                                        milkTab === 1 &&
                                                                        <Col xl="12" xs="12" className='mt-2'>
                                                                            <MuiInput style={{fontSize:"13px"}} label="Enter Total Milking (kg)" value={milkInKgTotal} onChange={(e) => { NumberValidation(e, "milkInKgTotal") }} />
                                                                            {/* <MuiInput type="number" label="Enter Milking kg"  value={milkInKg} onChange={(e) => { setMilkInKg(e) }}  /> */}
                                                                        </Col>
                                                                    }
                                                                    {
                                                                        milkTab === 2 &&
                                                                        <Col xl="12" xs="12" className='mt-2'>
                                                                            <MuiInput style={{fontSize:"13px"}} label="Enter Avg Milking (kg)" value={milkInKgAvg} onChange={(e) => { NumberValidation(e, "milkInKgAvg") }} />
                                                                            {/* <MuiInput type="text" label="Enter Milking kg"  value={milkInKg} onChange={(e) => { setMilkInKg(e) }} /> */}
                                                                        </Col>
                                                                    }
                                                                </Col>
                                                            </>
                                                        }
                                                    </Col>
                                                    {/* <Col xl="12" className='mt-4'>
                                                        {
                                                            calvingTab === 2 &&
                                                            <>
                                                                <Row>
                                                                    <Col sm="12" xl="6" xs="12" className='mt-2 d-flex calvingTab'>
                                                                        <button className={calvingTypeTab === 1 ? "calTab" : ""} onClick={() => { setCalvingTypeTab(1); }}>Normal</button>
                                                                        <button className={calvingTypeTab === 2 ? "calTab" : ""} onClick={() => { setCalvingTypeTab(2); }}>Abnormal</button>
                                                                    </Col>
                                                                    <Col sm="12" xl="6" xs="12" className='mt-2 d-flex calvingTab'>
                                                                        <button className={dryTab === 1 ? "calTab" : ""} onClick={() => { setDryTab(1); }}>Dry Days</button>
                                                                        <button className={dryTab === 2 ? "calTab" : ""} onClick={() => { setDryTab(2); }}>Last Dry Date</button>
                                                                    </Col>
                                                                </Row>
                                                                {
                                                                    (calvingTypeTab === 1 || calvingTypeTab === 2) &&
                                                                    <>
                                                                        <div className='d-flex'>
                                                                            <Col xl="4" xs="12" className='mt-3'>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Calf Sex</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={calf} onChange={(e) => { setCalf(e.target.value); }}>
                                                                                        <MenuItem className="drop_value" value="1">Male</MenuItem>
                                                                                        <MenuItem className="drop_value" value="2">FeMale</MenuItem>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            
                                                                        </div>
                                                                        <div className='d-flex'>
                                                                            <Col xl="4" xs="12" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sire</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={(e) => { setSire(e.target.value); }}>
                                                                                        {sireList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            <Col xs="12" xl="6" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Inseminator</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { setStaff(e.target.value); }}>
                                                                                        {staffList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            {
                                                                                dryTab === 1 ? <Col xl="12" xs="12" className='mt-2'>
                                                                                    <MuiInput label="Dry Days" value={dDryDays} onChange={(e) => { setDDryDays(e.target.value) }} />
                                                                                </Col> : <Col xl="8" xs="6" className='mt-3'>
                                                                                    <Input type="date" label="Dry Date" value={dDryDate} onChange={(e) => { setDDryDate(e.target.value) }} />
                                                                                </Col>
                                                                            }

                                                                        </div>

                                                                    </>
                                                                }
                                                                
                                                            </>
                                                        }
                                                    </Col> */}

                                                    <Col xl="12" className='mt-4 ms-2 '>
                                                        {
                                                            calvingTab === 2 &&
                                                            <>
                                                                <Row className='ps-0'>
                                                                    <Col sm="12" xl="6" xs="12" className='mt-2 calvingTab'>
                                                                        <button className={calvingTypeTab === 1 ? "calTab" : ""} onClick={() => { setCalvingTypeTab(1); }}>Normal</button>
                                                                        <button className={calvingTypeTab === 2 ? "calTab" : ""} onClick={() => { setCalvingTypeTab(2); }}>Abnormal</button>

                                                                        {
                                                                            (calvingTypeTab === 1 || calvingTypeTab === 2) &&
                                                                            <Row className='px-2'>
                                                                                <Col xl="3" xs="12" className='mt-3'>
                                                                                    <FormControl variant="standard" fullWidth>
                                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Calf Sex</InputLabel>
                                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={calf} onChange={(e) => { setCalf(e.target.value); }}>
                                                                                            <MenuItem className="drop_value" value="1">Male</MenuItem>
                                                                                            <MenuItem className="drop_value" value="2">FeMale</MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Col>
                                                                                <Col xl="3" xs="12" className='mt-3'>
                                                                                    <FormControl variant="standard" fullWidth>
                                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Sire</InputLabel>
                                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={(e) => { setSire(e.target.value); }}>
                                                                                            {sireList.map((e) => (
                                                                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                                                    {e.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Col>
                                                                                <Col xs="12" xl="3" className='mt-3'>
                                                                                    <FormControl variant="standard" fullWidth>
                                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Inseminator</InputLabel>
                                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { setStaff(e.target.value); }}>
                                                                                            {staffList.map((e) => (
                                                                                                <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                                                    {e.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                    <Col sm="12" xl="6" xs="12" className='mt-2 calvingTab'>
                                                                        <button className={dryTab === 1 ? "calTab" : ""} onClick={() => { setDryTab(1); }}>Dry Days</button>
                                                                        <button className={dryTab === 2 ? "calTab" : ""} onClick={() => { setDryTab(2); }}>Last Dry Date</button>

                                                                        {
                                                                            (dryTab == 1 || dryTab == 2) &&
                                                                            <Row className='px-0'>
                                                                                {
                                                                                    dryTab === 1 ? <Col xl="3" xs="12" className='mt-2'>
                                                                                        <MuiInput style={{fontSize:"13px"}} label="Dry Days" value={dDryDays} onChange={(e) => { NumberValidation(e, "dDryDays") }} />
                                                                                    </Col> : <Col xl="3" xs="6" className='mt-3'>
                                                                                        <Input style={{fontSize:"13px"}} type="date" label="Dry Date" value={dDryDate} onChange={(e) => { NumberValidation(e, "dDryDays") }} />
                                                                                    </Col>
                                                                                }
                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                </Row>

                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                                <hr></hr>

                                                {/*  */}
                                                <Row className='align-items-start justify-content-start mt-3' id="addAnimal">
                                                    <Col md="3" xl="3" xs="6" className='ms-0 mt-2 mb-3 d-flex calvingTab px-0'>
                                                        <h6 className='mt-2 me-2 ms-0 statusName'>Breeding Status :-</h6>
                                                        <button className={breedingTab === 0 ? "calTab" : ""} onClick={() => { setBreedingTab(0); }}>None</button>
                                                        <button className={breedingTab === 1 ? "calTab" : ""} onClick={() => { setBreedingTab(1); }}>Pregnent</button>
                                                        <button className={breedingTab === 2 ? "calTab" : ""} onClick={() => { setBreedingTab(2); }}>A.I</button>
                                                    </Col>
                                                    <Col className='calvingTab mt-3 mb-3' xl="12">

                                                        {
                                                            breedingTab === 1 &&
                                                            <>
                                                                <Col md="3" xl="3" xs="6" className='ms-0 px-0 mt-2 mb-3 d-flex calvingTab'>
                                                                    <button className={subBreeding === 1 ? "calTab" : ""} onClick={() => { setSubBreeding(1); }}>P.D Days</button>
                                                                    <button className={subBreeding === 2 ? "calTab" : ""} onClick={() => { setSubBreeding(2); }}>P.D Date</button>
                                                                </Col>

                                                                {
                                                                    subBreeding === 1 &&
                                                                    <>
                                                                        <Row className='d-flex align-items-center px-0'>
                                                                            <Col md="3" xl="2" xs="6" className='px-0'>
                                                                                <MuiInput label="Pragnent Days" value={pdDays} onChange={(e) => { NumberValidation(e, "pdDays") }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Sire</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pragSire} onChange={(e) => { setPragSire(e.target.value); }}>
                                                                                        {sireList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Inseminator</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pragStaff} onChange={(e) => { setPragStaff(e.target.value); }}>
                                                                                        {staffList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Dose" value={noOfDose} onChange={(e) => { NumberValidation(e, "noOfDose") }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Heat Sequence" value={heatSqnc} onChange={(e) => { NumberValidation(e, "heatSqnc") }} />
                                                                            </Col>
                                                                            {/* <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                <MuiInput label="No of Sequence" value={suqnc} onChange={(e) => { setSuqnc(e.target.value) }} />
                                                                            </Col> */}
                                                                        </Row>

                                                                    </>
                                                                }
                                                                {
                                                                    subBreeding === 2 &&
                                                                    <>
                                                                        <Col className='d-flex align-items-center px-0'>
                                                                            <Col md="3" xl="2" xs="6" className='px-0'>
                                                                                <Input type="date" label="PD Dates" value={pragPdDate} onChange={(e) => { setPragPdDate(e.target.value) }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Sire</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pragSire} onChange={(e) => { setPragSire(e.target.value); }}>
                                                                                        {sireList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Inseminator</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pragStaff} onChange={(e) => { setPragStaff(e.target.value); }}>
                                                                                        {staffList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                {/* <Input label="No of Dose" type="number" value={noOfDose} onChange={(e) => { setNoOfDose(e) }} /> */}
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Dose" value={noOfDose} onChange={(e) => { NumberValidation(e, "noOfDose") }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                {/* <Input label="No of Dose" type="number" value={noOfDose} onChange={(e) => { setNoOfDose(e) }} /> */}
                                                                                <MuiInpu style={{fontSize:"13px"}}t label="No of Heat Sequence" value={heatSqnc} onChange={(e) => { NumberValidation(e, "heatSqnc") }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                {/* <Input label="No of Dose" type="number" value={noOfDose} onChange={(e) => { setNoOfDose(e) }} /> */}
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Sequence" value={suqnc} onChange={(e) => { NumberValidation(e, "suqnc") }} />
                                                                            </Col>
                                                                        </Col>

                                                                    </>
                                                                }
                                                            </>

                                                        }
                                                        {
                                                            breedingTab === 2 &&
                                                            <>
                                                                <Col md="3" xl="3" xs="6" className='ms-0 px-0 mt-2 mb-3 d-flex calvingTab'>
                                                                    <button className={subAIBreeding === 1 ? "calTab" : ""} onClick={() => { setSubAIBreeding(1); }}>A.I Days</button>
                                                                    <button className={subAIBreeding === 2 ? "calTab" : ""} onClick={() => { setSubAIBreeding(2); }}>A.I Date</button>
                                                                </Col>

                                                                {
                                                                    subBreeding === 1 &&
                                                                    <>
                                                                        <Col className='d-flex align-items-center px-0'>
                                                                            <Col md="3" xl="2" xs="6" className='px-0'>
                                                                                <MuiInput label="A.I Days" value={aiDays} onChange={(e) => { NumberValidation(e, "aiDays") }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Sire</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pragSire} onChange={(e) => { setPragSire(e.target.value); }}>
                                                                                        {sireList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Inseminator</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pragStaff} onChange={(e) => { setPragStaff(e.target.value); }}>
                                                                                        {staffList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                {/* <Input label="No of Dose" type="number" value={noOfDose} onChange={(e) => { setNoOfDose(e) }} /> */}
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Dose" value={noOfDose} onChange={(e) => { NumberValidation(e, "noOfDose") }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                {/* <Input label="No of Dose" type="number" value={noOfDose} onChange={(e) => { setNoOfDose(e) }} /> */}
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Heat Sequence" value={heatSqnc} onChange={(e) => { NumberValidation(e, "heatSqnc") }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                {/* <Input label="No of Dose" type="number" value={noOfDose} onChange={(e) => { setNoOfDose(e) }} /> */}
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Sequence" value={suqnc} onChange={(e) => { NumberValidation(e, "suqnc") }} />
                                                                            </Col>
                                                                        </Col>

                                                                    </>
                                                                }
                                                                {
                                                                    subBreeding === 2 &&
                                                                    <>
                                                                        <Col className='d-flex align-items-center'>
                                                                            <Col md="3" xl="2" xs="6" className=''>
                                                                                <Input type="date" label="AI Dates" value={aiDate} onChange={(e) => { setAiDate(e.target.value) }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Sire</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pragSire} onChange={(e) => { setPragSire(e.target.value); }}>
                                                                                        {sireList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className=''>
                                                                                <FormControl variant="standard" fullWidth>
                                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Inseminator</InputLabel>
                                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pragStaff} onChange={(e) => { setPragStaff(e.target.value); }}>
                                                                                        {staffList.map((e) => (
                                                                                            <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                                                {e.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                {/* <Input label="No of Dose" type="number" value={noOfDose} onChange={(e) => { setNoOfDose(e) }} /> */}
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Dose" value={noOfDose} onChange={(e) => { setNoOfDose(e.target.value) }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                {/* <Input label="No of Dose" type="number" value={noOfDose} onChange={(e) => { setNoOfDose(e) }} /> */}
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Heat Sequence" value={heatSqnc} onChange={(e) => { setHeatSqnc(e.target.value) }} />
                                                                            </Col>
                                                                            <Col md="3" xl="2" xs="6" className='mt-2'>
                                                                                {/* <Input label="No of Dose" type="number" value={noOfDose} onChange={(e) => { setNoOfDose(e) }} /> */}
                                                                                <MuiInput style={{fontSize:"13px"}} label="No of Sequence" value={suqnc} onChange={(e) => { setSuqnc(e.target.value) }} />
                                                                            </Col>
                                                                        </Col>

                                                                    </>
                                                                }
                                                            </>

                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }

                                    {
                                        animalindex === 2 &&
                                        <>
                                            <div style={{ paddingTop: "45px" }} >
                                                <Row className='justify-content-between'>
                                                    <Col md="3" xl="4" xs="6" className='mb-3'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Sire</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={(e) => { setSire(e.target.value); }}>
                                                                {sireList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mb-3'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Paternal Sire</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={paternalDetails} onChange={(e) => { setPaternalDetails(e.target.value); }}>
                                                                {paternalSireList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mb-3'>
                                                    </Col>

                                                </Row>
                                                <Row className='justify-content-between'>
                                                    <Col md="3" xl="4" xs="6" className='mb-3'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Dam</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={dam} onChange={(e) => { setDam(e.target.value); }}>
                                                                {damList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mb-3'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Paternal Dam</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={paternalDam} onChange={(e) => { setPaternalDam(e.target.value); }}>
                                                                {paternalDamList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mb-3'>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }

                                    {
                                        animalindex === 4 &&
                                        <>
                                            <div style={{ paddingTop: "45px" }} >
                                                <Row className='justify-content-between'>
                                                    <Col md="3" xl="4" xs="6" className='mt-2'>
                                                        {/* <Input label="Market Value" type="number" value={marketValue} onChange={(e) => { setMarketValue(e) }} /> */}
                                                        <MuiInput style={{fontSize:"13px"}} label="Market Value" type="number" value={marketValue} onChange={(e) => { setMarketValue(e) }} />
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mt-2'>
                                                        {/* <Input label="A.I Tag No" type="number" value={aiTagNo} onChange={(e) => { setAiTagNo(e) }} /> */}
                                                        <MuiInput style={{fontSize:"13px"}} label="A.I Tag No" type="number" value={aiTagNo} onChange={(e) => { setAiTagNo(e) }} />
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mt-2'>
                                                        {/* <Input label="Birth Wt.(Kg)" type="number" value={birthWeight} onChange={(e) => { setBirthWeight(e) }} /> */}
                                                        <MuiInput style={{fontSize:"13px"}} label="Birth Wt.(Kg)" type="number" value={birthWeight} onChange={(e) => { setBirthWeight(e) }} />
                                                    </Col>
                                                </Row>
                                                <Row className='justify-content-between'>
                                                    <Col md="3" xl="4" xs="6" className='mt-2'>
                                                        {/* <Input label="No. ring on Horn" type="number" value={hornNo} onChange={(e) => { setHornNo(e) }} /> */}
                                                        <MuiInput style={{fontSize:"13px"}} label="No. ring on Horn" type="number" value={hornNo} onChange={(e) => { setHornNo(e) }} />
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mb-3'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Rearing Purpose</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={purpose} onChange={(e) => { setPurpose(e.target.value); }}>
                                                                {purposeList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mt-2'>
                                                        <Input style={{fontSize:"13px"}} label="Place No" type="number" value={place} onChange={(e) => { setPlace(e) }} />
                                                    </Col>
                                                </Row>
                                                <Row className='justify-content-between'>
                                                    <Col md="3" xl="4" xs="6" className='mt-2'>
                                                        <Input style={{fontSize:"13px"}} label="Horn Distance (Inch)" type="number" value={hornDistance} onChange={(e) => { setHornDistance(e) }} />
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mt-2'>
                                                        <Input style={{fontSize:"13px"}} label="Color" type="number" value={color} onChange={(e) => { setColor(e) }} />
                                                    </Col>
                                                    <Col md="3" xl="4" xs="6" className='mb-3'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Doctor</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={doctor} onChange={(e) => { setDoctor(e.target.value); }}>
                                                                {doctorList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                </>
                            }

                            {
                                currentIndex === 1 &&
                                <>
                                    <Row>
                                        <Col md="3" xl="4" xs="6" className='mt-2'>
                                            <Input style={{fontSize:"13px"}} label="Parity" type="number" value={parity} onChange={(e) => { setParity(e) }} />
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col>
                                            <Table columns={colum} data={animalListData} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                        </Col>
                                    </Row>
                                    <Row className='mt-2 align-items-end'>
                                        <Col xl="4" xs="6" className='mt-2'>
                                            <Input label="Calving Date" type="date" value={calvingDate} onChange={(e) => { setCalvingDate(e) }} />
                                        </Col>
                                        <Col xl="4" xs="6" className=''>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Sex</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sex} onChange={(e) => { setSex(e.target.value); }}>
                                                    <MenuItem className="drop_value" value="Male">Male</MenuItem>
                                                    <MenuItem className="drop_value" value="Male">FeMale</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col xs="6" xl="4" className='mt-2'>
                                            <Input style={{fontSize:"13px"}} label="Calf Id" type="number" value={calfId} onChange={(e) => { setCalfId(e) }} />
                                        </Col>
                                        <Col xs="6" xl="4" sm="3" className="mt-2 justify-content-center text-center align-items-center d-flex">
                                            <span className='ms-0 me-1 calvingHead'>Type of Calving :</span>
                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" className="ms-2 mt-1" value={role} name="row-radio-buttons-group">
                                                <FormControlLabel value="normal" control={<Radio className="p-1" />} onChange={e => setRole(e.target.value)} label="Normal" className="mt-1" />
                                                <FormControlLabel value="abnormal" control={<Radio className="p-1" />} onChange={e => setRole(e.target.value)} label="Abnormal" className="mt-1" />
                                            </RadioGroup>
                                        </Col>
                                        <Col xl="4" xs="6" className=''>
                                            <FormControl sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="demo-multiple-checkbox-label" style={{fontSize:"13px"}}>Reproductive Problem</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    value={reproductiveList}
                                                    onChange={handleChange}
                                                    input={<OutlinedInput label="Reproductive Problem" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {names.map((name) => (
                                                        <MenuItem key={name} value={name}>
                                                            <Checkbox checked={reproductiveList.indexOf(name) > -1} />
                                                            <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col xl="4" xs="6" className='mt-2'>
                                            <Input style={{fontSize:"13px"}} label="Calving Note" type="text" value={calvingNote} onChange={(e) => { setCalvingNote(e) }} />
                                        </Col>
                                        <Col xl="4" xs="6" className='mt-2'>
                                            <Input style={{fontSize:"13px"}} label="Total Milk Yield" type="number" value={totalMilkYield} onChange={(e) => { setTotalMilkYield(e) }} />
                                        </Col>
                                        <Col xl="4" xs="6" className='mt-2'>
                                            <Input style={{fontSize:"13px"}} label="Peak Yield" type="number" value={peakYield} onChange={(e) => { setPeakYield(e) }} />
                                        </Col>
                                        <Col xl="4" xs="6" className='mt-2'>
                                            <Input style={{fontSize:"13px"}} label="Peak Days" type="number" value={peakDays} onChange={(e) => { setPeakDays(e) }} />
                                        </Col>
                                        <Col xl="4" xs="6" className='mt-2'>
                                            <Input style={{fontSize:"13px"}} label="Dry Date" type="date" value={dryDate} onChange={(e) => { setDryDate(e) }} />
                                        </Col>
                                        <Col xl="4" xs="6" className=''>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}>Drying Reason</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={dryingReason} onChange={(e) => { setDryingReason(e.target.value); }}>
                                                    <MenuItem className="drop_value" value="Dry 1">Dry 1</MenuItem>
                                                    <MenuItem className="drop_value" value="Dry 2">Dry 2</MenuItem>
                                                    <MenuItem className="drop_value" value="Dry 3">Dry 3</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </>
                            }
                            <Row className='justify-content-end mt-2' style={{ padding: "0 138px" }}>
                                <Col xs="3" sm="1" xl="1" ><button className="btn-blue-Animal mt-1" onClick={saveAnimal}>Save</button></Col>
                                <Col xs="3" sm="1" xl="1" ><button className="btn-blue-Animal mt-1">Cancel</button></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container >

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Update Breed</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='justify-content-between'>
                            <Col md="3" xl="4" xs="6" className='mt-2'>
                                {/* <Input label="Name" type="text" value={name} onChange={e => setName(e.target.value)} /> */}
                                <div className="form-field">
                                    <div className="form-field__control">
                                        <input id="email" type="text" className="form-field__input" value={name} onChange={e => setName(e.target.value)} />
                                        <label style={{fontSize:"13px"}} for="email" className="form-field__label">Name</label>
                                        <div className="form-field__bar"></div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3" xl="4" xs="6" className='mb-3'>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "13px" }}></InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={purpose} onChange={(e) => { setPurpose(e.target.value); }}>
                                        {purposeList.map((e) => (
                                            <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end ">
                            <Col xl="1">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                            </Col>
                            <Col xl="1">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>



            {
                show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)
            }

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />
        </>
    )
}
export default Addanimal
