import React, { useState } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Searcable from '../../Componet/Dropdown/Searcable';
import BackBtn from '../../Componet/Button/BackBtn';
const { Project_Zone } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getProject(credentials) {
    return fetch(Project_Zone.GetProject, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getData(credentials) {
    return fetch(Project_Zone.GetData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function transferData(credentials) {
    return fetch(Project_Zone.TransferData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

const TransferProject = () => {
    const API_KEY = serviceUrl;

    const [heightOffSet, setHeight] = useState(0);

    const [search, setSearch] = useState("");
    const [center, setCenter] = useState("");
    const [routeVisit, setRouteVisit] = useState("");
    const [searchFilter, setSearchFilter] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [centerList, setCenterList] = useState([]);
    const [farmerList, setFarmerList] = useState([]);
    const [projectList, setProjectList] = useState([])
    const [rowsList, setRowsList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const fatchAnimalData = async () => {
        const res = await getProject();
        if (res) {
            setCenterList(res);
            console.log(res);
        }
    }
    const SearchFn = (e) => {
        const data = e.target.value;
        // if (data !== '') {
        //     const results = projectList.filter((user) => {
        //         return user.ID.toLowerCase().startsWith(data.toLowerCase())
        //     });
        //     setSearchFilter(results);
        // } else {
        //     setSearchFilter(projectList);
        // }
        // setSearch(data);

        fetch(`${API_KEY}/cattle-detail/get-transfer-search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ dataType: "Animal", search: data })
        })
            .then(res => res.json())
            .then((result) => { setProjectList(result) })
    }
    const SelectedRowFn = (rows) => {
        setRowsList(rows.selectedRows);
    }

    useEffect(() => {
        fatchAnimalData();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, []);



    const View = async () => {
        setIsLoading(true)
        const res = getData({ ahc: null, dataType: "Project", farmer: null, lot: null, project: center, route: null })
        if (res) {
            setProjectList(res.data);
            setIsLoading(false);
        }
    }

    let ids = (rowsList.map(i => i.ID));

    const Transfer = async () => {
        setIsLoading(true)
        const res = await transferData({ dataType: "Farmer", farmer: null, herd: routeVisit, id: ids, project: null, uid: 1 })
        if (res) { toast.success(res.status); setIsLoading(false); setProgressData("0%") }
    }


    const colum = [
        {
            name: "ID",
            selector: "ID",
            sortable: true

        },
        {
            name: "Lot Code",
            selector: "Lot Code",
            sortable: true

        },
        {
            name: "DCS Name",
            selector: "DCS NAme",
            sortable: true

        },
        {
            name: "Farmer Code",
            selector: "Farmer Code",
            sortable: true

        },
        {
            name: "SAP Code",
            selector: "Farmer Code",
            sortable: true

        },
        {
            name: "Farmer Name",
            selector: "SAP Name",
            sortable: true

        },
        {
            name: "Farmer MobileNumber",
            selector: "Farmer MobileNumber",
            sortable: true

        },
        {
            name: "Suspended",
            selector: "Suspended",
        },
    ];

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Transfer/ Project Transfer</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="align-items-center justify-content-end" id='heightOffsetStock'>
                                <Col lg="10" md="8" className='justify-content-start'>
                                    <Row className='justify-content-start'>
                                        <Col xxl="2" xl="3" sm="6" className='justify-content-start ms-3'>
                                            <FormControl variant="standard" fullWidth>
                                                {/* <InputLabel>Project</InputLabel>
                                                <Select value={center} onChange={e => setCenter(e.target.value)}>
                                                    {centerList.map((e) => (
                                                        <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                            {e.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}
                                                <Searcable
                                                    placeholder="Project"
                                                    options={centerList}
                                                    onChange={(selectedOption) => setCenter(selectedOption.value)}
                                                    value={center}
                                                    labelKey="Name"
                                                    valueKey="id"
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col lg="1" md="2" sm="3" xs="6" className="mt-2 align-items-center justify-content-end">
                                            <button className="btn-blue-Animal mt-1 mb-1" onClick={(e) => View(e)}>View</button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="2" md="2" sm="3" xs="6" className="mt-2 ps-2 align-items-center justify-content-end">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col xl="9" xxl="9" lg="9" md="9" sm="12">
                                    <Table columns={colum} data={searchFilter ? searchFilter : projectList} height={`calc(82vh - (${heightOffSet}px + 200px))`} pagination selectableRows onSelectedRowsChange={SelectedRowFn} />
                                </Col>
                                <Col xxl="3" xl="3" lg="3" md="3" sm="12">
                                    <div className='mainCard'>
                                        <Row className='p-2 align-items-center justify-content-center'>
                                            <Col xl="12" xxl="10" md="12" sm="6" className='align-items-center justify-content-center cardHeading'>
                                                <Col className='mt-2'>
                                                    <div className='boxBg text-center p-2 align-items-center'>
                                                        <h6 className='fw-bold m-0'>Select the Destination Herd</h6>
                                                    </div>
                                                </Col>
                                                <Col className='mt-1'>
                                                    <div className='boxBg1 px-2 pb-2'>
                                                        <FormControl variant="standard" fullWidth>
                                                            {/* <InputLabel>Project</InputLabel>
                                                            <Select value={routeVisit} onChange={e => setRouteVisit(e.target.value)}>
                                                                {centerList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select> */}
                                                            <Searcable
                                                                placeholder="Project"
                                                                options={centerList}
                                                                onChange={(selectedOption) => setRouteVisit(selectedOption)}
                                                                value={routeVisit}
                                                                labelKey="Name"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Col>
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center mt-4 text-center'>
                                            <Col>
                                                Click on the below to make changes
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center justify-content-center'>
                                            <Col xl="4" sm="3" xs="6" className="mt-2 d-flex align-items-center">
                                                <button className="btn-blue-Animal mt-1 mb-3" onClick={Transfer}>Transfer</button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}


            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

        </>
    )
}
export default TransferProject    
