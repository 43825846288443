import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import Sidenav from "../../Componet/Sidenav/Sidenav";

import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useNavigate } from 'react-router-dom';
import Searcable from '../../Componet/Dropdown/Searcable';
const { OpeningBalanceApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getZone(credentials) {
    return fetch(OpeningBalanceApi.CenterDetails, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getAit(credentials) {
    return fetch(OpeningBalanceApi.AitDetails, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getData(credentials) {
    return fetch(OpeningBalanceApi.GetData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function saveData(credentials) {
    return fetch(OpeningBalanceApi.SaveData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

const Opening_Balance = () => {

    const API_KEY = serviceUrl;
    const selectedToDate = new Date().toISOString().slice(0, 10);

    const [center, setCenter] = useState("");
    const [ait, setAit] = useState("");

    const [NormalBatchno, setNormalBatchno] = useState("");
    const [NormalSemen, setNormalSemen] = useState("");
    const [sortedBatchno, setSortedBatchno] = useState("");
    const [SortedSemen, setSortedSemen] = useState("");
    const [date, setDate] = useState(selectedToDate);
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [progressData, setProgressData] = useState("0%");
    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [balanceList, setBalanceList] = useState([]);
    const [centerList, setCenterList] = useState([]);
    const [aitList, setAitList] = useState([]);

    const balanceGetData = async () => {
        const res = await getZone();
        const res1 = await getAit()
        if (res) {
            setCenterList(res);
        }
        if (res1) {
            setAitList(res1);
        }
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        balanceGetData();
    }, [])

    const View = async () => {
        setIsLoading(true)
        const res = await getData({ dataType: 4, staff: ait.value, inputDate: date })
        if (res) { setBalanceList(res); setIsLoading(false) }
    }

    const Save = async () => {
        const res = await saveData();
        if (res) {
            setBalanceList(res)
        }
    }


    const colum = [
        {
            name: "ID",
            selector: "ID",
            cell: (e) => (<p style={{paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }} className='ms-auto'>{e["ID"]}</p>),
            width: "100px",
            sortable: true
        },
        {
            name: "Code",
            selector: "Code",
            cell: (e) => (<p style={{paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }} className='ms-auto'>{e["Code"]}</p>),
            sortable: true
        },
        {
            name: "Name",
            selector: "Name",
            cell: (e) => (<p style={{ paddingLeft: "5px", width: "100%", display: "flex", justifyContent: "flex-start"}} className='ms-auto'>{e["Name"]}</p>),
            sortable: true
        },
        {
            name: "Normal Batchno",
            selector: "Normal Batchno",
            cell: (e) => (<TextField label="Normal Batchno" value={NormalBatchno} onChange={(e) => { setNormalBatchno(e.target.value); }} data={balanceList} variant="standard" />),
            sortable: true
        },
        {
            name: "Normal Semen",
            selector: "Normal Semen",
            cell: (e) => (<TextField label="Normal Semen" value={NormalSemen} onChange={(e) => { setNormalSemen(e.target.value); }} data={balanceList} variant="standard" />),
            sortable: true
        },
        {
            name: "Sorted Semen",
            selector: "Sorted Semen",
            cell: (e) => (<TextField label="Sorted Semen" value={SortedSemen} onChange={(e) => { setSortedSemen(e.target.value); }} data={balanceList} variant="standard" />),
            sortable: true
        },
        {
            name: "Sorted Batchno",
            selector: "sorted Batchno",
            cell: (e) => (<TextField label="Sorted Batchno" value={sortedBatchno} onChange={(e) => { setSortedBatchno(e.target.value); }} data={balanceList} variant="standard" />),
            sortable: true
        },
        {
            name: "Action",
            cell: (e) => (<button type="button" style={{width:"80%" , padding:"5px", border: "none", boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)", backgroundColor: "#43668E", color: "#FFFFFF", borderRadius: "5px" }} onClick={(e) => { Save() }}>Save</button>),
            width: "80px",
            sortable: true
        },
    ]


    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Medicine / Opening Balance</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end">
                                    <img src={profile} width={40} />
                                </Col>
                            </Row>

                            <Row className="align-items-end p-2" id='heightOffsetStock'>
                                <Col xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Center</InputLabel>
                                        <Select value={center} onChange={(e) => { setCenter(e.target.value) }}>
                                            {centerList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Center"
                                            options={centerList}
                                            onChange={(selectedOption) => setCenter(selectedOption)}
                                            value={center}
                                            labelKey="Name"
                                            valueKey="Name"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>AIT</InputLabel>
                                        <Select value={ait} onChange={e => setAit(e.target.value)}>
                                            {aitList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="AIT"
                                            options={aitList}
                                            onChange={(selectedOption) => setAit(selectedOption)}
                                            value={ait}
                                            labelKey="Name"
                                            valueKey="id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs="6" sm="3" md="2" >
                                    <sub className="mb-0 pb-0">Date</sub>
                                    <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                </Col>
                                <Col xs="6" sm="2" md="2" xl="1" className="mt-2 ms-auto d-flex align-items-end justify-content-end">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={View}>View</button>
                                </Col>
                            </Row>

                            {/* <Table columns={colum} data={balanceList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination /> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {balanceList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={balanceList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

        </>
    )
}

export default Opening_Balance;