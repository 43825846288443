import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import {
    TextField, FormControl, InputLabel,
    // Select,
    MenuItem, Switch
} from "@mui/material";

import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidenav from "../../Componet/Sidenav/Sidenav";
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS } from "../../API/Api";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import BackBtn from '../../Componet/Button/BackBtn';
const { MppAPI } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
const label = { inputProps: { "aria-label": "Switch demo" } };


async function getConsult(credentials) {
    return fetch(MppAPI.Select, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getYear(credentials) {
    return fetch(MppAPI.SelectYear, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getCamp(credentials) {
    return fetch(MppAPI.CampSelect, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getSimple(credentials) {
    return fetch(MppAPI.SimpleMaster, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getMaster(credentials) {
    return fetch(MppAPI.ResultMaster, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function SaveData(credentials) {
    return fetch(MppAPI.AnimalSave, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

export default function AnimalWise() {

    const selectedToDate = new Date().toISOString().slice(0, 10);

    const [consult, setConsult] = useState("");
    const [cmtRound, setCmtRound] = useState(1);
    const [followUpTest, setFollowUpTest] = useState(1);
    const [year, setYear] = useState("");
    const [date, setDate] = useState("");
    const [camp, setCamp] = useState("");
    const [simple, setSimple] = useState("");
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [heightOffSet, setHeight] = useState(0);
    const [progressData, setProgressData] = useState("0%");
    const [sampleType, setSampleType] = useState("")
    const [diableOption, setDiableOption] = useState(true)

    const [dscList, setDscList] = useState([]);
    const [centerList, setCenterList] = useState([]);
    const [routeVisitList, setRouteVisitList] = useState([]);
    const [campList, setCampList] = useState([]);
    const [simpleList, setSimpleList] = useState([]);
    const [masterList, setMasterList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const [pooled, setPooled] = useState(true)
    const handleShow = () => { setHeaderShow(!headerShow); }

    const milkType = ["-", "+", "++", "+++", "+++", "NA"];

    const fatchAnimalData = async () => {
        const res = await getConsult({ id: 5 });
        const res1 = await getYear();
        const res2 = await getCamp();
        const res3 = await getSimple();
        const res4 = await getMaster();

        if (res) { setCenterList(res); }
        if (res1) { setRouteVisitList(res1); }
        if (res2) { setCampList(res2); }
        if (res3) { setSimpleList(res3); }
        if (res4) { setMasterList(res4); }
    }


    console.log(dscList)
    const SimpleValueSet = (e1, e) => {
        console.log(e1.Sample_Type);
    }

    const colum = [
        {
            name: "Famer Code",
            selector: "Famer Code",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "110px" }}>{c["Famer Code"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Farmer Name",
            selector: "Farmer Name",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "100%" }}>{c["Farmer Name"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Tag ID",
            selector: "Tag ID",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "130px" }}>{c["Tag ID"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Sample Type",
            cell: (e1) => {
                const defaultLabel = simpleList.find(item => item.id === 1)?.name;
                setSampleType(defaultLabel);
                return (
                    <div style={{ padding: "8px", margin: "6%" }} >
                        <Select
                            placeholder="Sample Type"
                            onChange={(selectedOption) => { SampleFn(selectedOption, e1) }}
                            options={simpleList.map((item) => ({ value: item.id, label: item.name }))}
                            defaultValue={{ value: 1, label: defaultLabel }}
                        />
                    </div>
                );
            },
            width: "210px"
        },
        {
            name: "ALQ Mix",
            cell: (e1) =>
                <div style={{ padding: "10px", width: "160px" }} >
                    < Select
                        placeholder="ALQ Mix"
                        isDisabled={e1.pooled ? e1.pooled : e1.diableOption}
                        onChange={(selectedOption) => SelectFn(selectedOption, e1)}
                        // value={milkType.find((item) => item == e1.ALQMix)}
                        options={milkType.map((item) => ({ value: item, label: item }))}
                    />
                </div>,
            sortable: true
        },
        {
            name: "FLQ",
            cell: (e1) => (
                <div style={{ padding: "10px", width: "160px" }} >
                    <Select
                        placeholder="FLQ"
                        isDisabled={e1.pooled ? e1.pooled : e1.diableOption}
                        onChange={(selectedOption) => FLQFn(selectedOption, e1)}
                        options={milkType.map((item) => ({ value: item, label: item }))}
                    />
                </div>
            ),
            sortable: true
        },
        {
            name: "HLQ",
            cell: (e1) =>
                <div style={{ padding: "10px", width: "160px" }} >
                    <Select
                        placeholder="HLQ"
                        isDisabled={e1.pooled ? e1.pooled : e1.diableOption}
                        onChange={(selectedOption) => HLQFn(selectedOption, e1)}
                        // value={milkType.find((item) => item === e1['HLQ'])}
                        options={milkType.map((item) => ({ value: item, label: item }))}
                    />
                </div>,
            sortable: true
        },
        {
            name: "HRQ",
            cell: (e1) =>
                <div style={{ padding: "10px", width: "160px" }} >
                    <Select
                        placeholder="HRQ"
                        isDisabled={e1.pooled ? e1.pooled : e1.diableOption}
                        onChange={(selectedOption) => FLQFn(selectedOption, e1)}
                        // value={milkType.find((item) => item === e1['HRQ'])}
                        options={milkType.map((item) => ({ value: item, label: item }))}
                    />
                </div>,
            sortable: true
        },
        {
            name: "FRQ",
            cell: (e1) =>
                <div style={{ padding: "10px", width: "160px" }} >
                    <Select
                        placeholder="FRQ"
                        isDisabled={e1.pooled ? e1.pooled : e1.diableOption}
                        onChange={(selectedOption) => FRQFn(selectedOption, e1)}
                        // value={milkType.find((item) => item === e1['FRQ'])}
                        options={milkType.map((item) => ({ value: item, label: item }))}
                    />
                </div>,
            sortable: true
        },
        {
            name: "1st Medicine",
            selector: "1st Medicine",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "110px" }}>{c["1st Medicine"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "After 10 days Medicine",
            selector: "2nd Medicine",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "110px" }}>{c["2nd Medicine"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Other Medicine",
            selector: "Other Medicine",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "110px" }}>{c["Other Medicine"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Positive",
            cell: (e) => (<Switch {...label} defaultChecked={e.Result ? true : false} />),
        },
        {
            name: "Result",
            selector: "Result",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "110px" }}>{c["1st Medicine"]}</div>
                </>
            )
        },
        {
            name: "Action",
            cell: (e) =>
                <div style={{ margin: "6%" }}>
                    <button style={{ width: "100%", padding: "9px" }} type="button" className='SaveBtn' onClick={() => saveFn(e)}>Save</button>
                </div>
        },
    ]

    const View = async () => {
        const inputDate = date ? moment(date).format("DD/MM/YYYY") : moment(selectedToDate).format("DD/MM/YYYY");
        if (consult) {
            setIsLoading(true);
            await fetch(MppAPI.AnimalView, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ cmtRound: parseInt(cmtRound), followUpTest: 1, inputDate, staff: consult, year: 2022 })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((value) => {
                    if (value) {
                        const modifiedValue = value.map((item, index) => ({
                            ...item,
                            diableOption: diableOption,
                        }));

                        setDscList(modifiedValue);
                        setIsLoading(false);
                        setProgressData("0%");
                    }
                })
        }
    }

    const SampleFn = (inputEv, index) => {
        setSampleType(inputEv)
        const value = inputEv.label;
        if (value === "Pooled") {
            setDscList(dscList.map((o) => {
                if (o === index) { return { ...index, 'Sample Type': value, pooled: true } }
                else { return o };
            }));
        }
        else {
            setDscList(dscList.map((o) => {
                if (o === index) { return { ...index, 'Sample Type': value, pooled: false, diableOption: false } }
                else { return o };
            }));
        }
    }


    const SelectFn = (inputEv, index) => {
        const value = inputEv.label;
        setDscList(dscList.map((o) => {
            if (o === index) { return { ...index, ALQMix: value } }
            else { return o };
        }));
    }
    const FLQFn = (inputEv, index) => {
        const value = inputEv.label;
        setDscList(dscList.map((o) => {
            if (o === index) { return { ...index, FLQ: value } }
            else { return o };
        }));
    }
    const HLQFn = (inputEv, index) => {
        const value = inputEv.label;
        setDscList(dscList.map((o) => {
            if (o === index) { return { ...index, HLQ: value } }
            else { return o };
        }));
    }
    const HRQFn = (inputEv, index) => {
        const value = inputEv.label;
        setDscList(dscList.map((o) => {
            if (o === index) { return { ...index, HRQ: value } }
            else { return o };
        }));
    }
    const FRQFn = (inputEv, index) => {
        const value = inputEv.label;
        setDscList(dscList.map((o) => {
            if (o === index) { return { ...index, FRQ: value } }
            else { return o };
        }));
    }

    const saveFn = async (e) => {
        const date = moment(selectedToDate).format("MM/DD/YYYY");
        const alqMix = (e.ALQMix == "+" ? "1" : (e.ALQMix == "++" ? "2" : "3"));
        const flq = (e.FLQ == "+" ? "1" : (e.FLQ == "++" ? "2" : "3"));
        const frq = (e.FRQ == "+" ? "1" : (e.FRQ == "++" ? "2" : "3"));
        const hlq = (e.HLQ == "+" ? "1" : (e.HLQ == "++" ? "2" : "3"));
        const hrq = (e.HRQ == "+" ? "1" : (e.HRQ == "++" ? "2" : "3"));

        const res = await SaveData({
            alqMix,
            camp,
            cmtRound,
            createdAt: date,
            details: null,
            farmer: e['Famer Code'],
            herd: null,
            flq,
            frq,
            hlq,
            hrq,
            followTest: followUpTest,
            herd: null,
            id: "0",
            inputDate: date,
            lot: null,
            medicine1: null,
            medicine2: null,
            medicine3: null,
            result: "2",
            sampletype: "1",
            tagId: e['Tag ID'],
            testedBy: consult,
            updatedAt: null,
            yearMaster: year
        })
        if (res) {
            toast.success(res.msg)
        }
        else {
            toast.error(res.error)
        }
    }

    const AddAnimal = () => {
        navigate("/resgistraion/add")
    }


    useEffect(() => {
        fatchAnimalData();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, [pooled, dscList]);

    return (
        <>
            <Container fluid id="AnimalWise">
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Project / MPP / Animal Wise Test</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end" id='heightOffsetStock'>
                                <Col xl="3" md="4" sm="5">
                                    <Select
                                        placeholder="Consult"
                                        onChange={(selectedOption) => setConsult(selectedOption.value)}
                                        options={centerList.map((item) => ({ value: item.ID, label: item.name }))}
                                    />
                                </Col>
                                <Col xl="1" md="2" sm="3" xs="6" className='justify-content-start'>
                                    <TextField label="cmtRound" fullWidth variant="standard" value={cmtRound} onChange={e => setCmtRound(e.target.value)} />
                                </Col>
                                <Col xl="1" sm="2" xs="6" className='justify-content-start'>
                                    <TextField label="FollowUpTest" fullWidth variant="standard" value={followUpTest} onChange={e => setFollowUpTest(e.target.value)} />
                                </Col>
                                <Col xl="1" md="2" sm="3" xs="6" className='justify-content-start'>
                                    <Select
                                        placeholder="Year"
                                        onChange={(selectedOption) => setYear(selectedOption.value)}
                                        options={routeVisitList.map((item) => ({ value: item.id, label: item.YearName }))}
                                    />
                                </Col>
                                <Col xl="1" md="2" sm="3" xs="6" className='justify-content-start'>
                                    <Select
                                        placeholder="Camp"
                                        onChange={(selectedOption) => setYear(selectedOption.value)}
                                        options={campList.map((item) => ({ value: item.ID, label: item.CampNo }))}
                                    />
                                </Col>
                                <Col xl="1" md="2" sm="3" xs="6" className='justify-content-start'>
                                    <div className="mt-1">
                                        <sub className="mb-0 pb-0">Date</sub>
                                        <input type="date" className="dateSelect_bg" defaultValue={selectedToDate} onChange={e => setDate(e.target.value)} />
                                    </div>
                                </Col>
                                <Col lg="1" md="2" sm="3" xs="6" className="mt-2 ms-auto">
                                    <button className="btn-blue-Animal mt-2" onClick={AddAnimal}>Add Animal</button>
                                </Col>
                                <Col lg="1" md="2" sm="3" xs="6" className="mt-2">
                                    <button className="btn-blue-Animal mt-2" onClick={(e) => View(e)}>View</button>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Table columns={colum} data={dscList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

        </>
    )
};
