import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { downloadExcel } from "react-export-table-to-excel";

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import './supplier.css';



import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { borderRadius, margin } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Searcable from '../../Componet/Dropdown/Searcable';
const { SupplieStockApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getZone(credentials) {
  return fetch(SupplieStockApi.GetZone, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(res => res.json());
}


async function getStaff(credentials) {
  return fetch(SupplieStockApi.GetStaff, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(res => res.json());
}

async function getSire(credentials) {
  return fetch(SupplieStockApi.GetSire, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(res => res.json());
}



const SupplierStock = () => {
  const API_KEY = serviceUrl;
  const selectedToDate = new Date().toISOString().slice(0, 10);

  const [center, setCenter] = useState("")
  const [date, setDate] = useState("")
  const [sire, setSire] = useState("")
  const [role, setRole] = useState("sire")
  const [staff, setStaff] = useState("")
  const [selectRole, setSelectRole] = useState('Normal')
  const [normal, setNormal] = useState("")
  const [sorted, setSorted] = useState("")
  const [both, setBoth] = useState("")
  const [date1, setDate1] = useState("")
  const [date2, setDate2] = useState(selectedToDate)
  const navigate = useNavigate()
  const logOut = () => { navigate("/") }

  const [show, setShow] = useState(false);
  const [headerShow, setHeaderShow] = useState(true);
  const handleClose = () => { setShow(false); };
  const handleShow = () => { setHeaderShow(!headerShow); }

  const [supplierList, setSupplierList] = useState([])
  const [centerList, setCenterList] = useState([])
  const [sireList, setSireList] = useState([])
  const [staffList, setStaffList] = useState([])

  const getStockData = async () => {
    const res = await getZone();
    const res1 = await getStaff();
    const res2 = await getSire();

    if (res) { setCenterList(res) }
    if (res1) { setStaffList(res1) }
    if (res2) { setSireList(res2) }
  }


  useEffect(() => {
    getStockData()
  }, [])


  const nameColum = [
    {
      name: "Name",
      selector:"Name",
      cell: (e) => (
        <>
          <label for="Name" className='ms-2'>{e.Name}</label>
        </>
      ),
      sortable: true

    }
  ]

  const handleDownloadExcel = () => {
    const opt = `Sement And Other Report ${date1} ${date2}`;
    const header = Object.keys(supplierList).map((key, e) => (key));

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: supplierList
      }
    });
  }

  const ApplyFn = () => {
    getStockData();
  }

  const colum = [
    {
      name: "S",
      selector: "",
    },
  ]
  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                  <button type="button" id="navToggle" class="navbar-toggler d-block" for="opensiderMenu" onClick={handleShow} style={{ border: "none", cursor: "pointer" }}><img src={menu} width={20} /></button>
                  <h5 className="ms-4 mt-2">Straw / Supplier Stock</h5>
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                  <img src={profile} width={40} />
                  <div className="dropdpwn_Open">
                    <Row className="text-center align-items-center">
                      <Col xs="12" className="menuHover mb-3">
                        <Row >
                          <Col className="" xs="3">
                            <span className=""><MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="menuHover" onClick={logOut}>
                        <Row>
                          <Col className="" xs="3">
                            <span className=""><GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-end justify-content-between p-2">
                <Col md="4" xl="1" sm="2" className='align-items-end justify-content-start'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Center</InputLabel>
                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={center} onChange={(e) => { setCenter(e.target.value) }}>
                      {centerList.map((e) => (
                        <MenuItem className="drop_value" key={e.id} value={e.Name}>
                          {e.Name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Searcable
                      placeholder="Center"
                      options={centerList}
                      onChange={(selectedOption) => setCenter(selectedOption)}
                      value={center}
                      labelKey="Name"
                      valueKey="id"
                    />
                  </FormControl>
                </Col>
                <Col md="4" xl="3" sm="3" className="mt-4 justify-content-center text-center align-items-center">
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={role} name="row-radio-buttons-group">
                    <FormControlLabel value="sire" control={<Radio className="p-1" />} onChange={e => setRole(e.target.value)} label="Sire" className="mt-1" />
                    <FormControlLabel value="staff" control={<Radio className="p-1" />} onChange={e => setRole(e.target.value)} label="Staff" className="mt-1" />
                    <div className='mt-2 align-items-center'>
                      <input type="checkbox" id="All" value="All" className="me-2" />All
                    </div>
                  </RadioGroup>
                </Col>
                <Col md="4" xl="1" sm="2" className=' justify-content-start staff-sire-search'>
                  {
                    role === "sire" && <FormControl variant="standard" fullWidth>
                      {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sire</InputLabel>
                      <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={(e) => { setSire(e.target.value) }}>
                        {sireList.map((e) => (
                          <MenuItem className="drop_value" key={e.id} value={e.Name}>
                            {e.Name}
                          </MenuItem>
                        ))}
                      </Select> */}
                      <Searcable
                        placeholder="Sire"
                        options={sireList}
                        onChange={(selectedOption) => setSire(selectedOption)}
                        value={sire}
                        labelKey="Name"
                        valueKey="Name"
                      />

                    </FormControl>
                  }
                  {
                    role === "staff" && <FormControl variant="standard" fullWidth>
                      {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Staff</InputLabel>
                      <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { setStaff(e.target.value) }}>
                        {staffList.map((e) => (
                          <MenuItem className="drop_value" key={e.id} value={e.Name}>
                            {e.code} - {e.Name}
                          </MenuItem>
                        ))}
                      </Select> */}
                      <Searcable
                        placeholder="Staff"
                        options={staffList.map((e) => ({ Name: `${e.code} - ${e.Name}`, value: e.Name }))}
                        onChange={(selectedOption) => setStaff(selectedOption.value)}
                        // value={staff}
                        labelKey="Name"
                        valueKey="value"
                      />
                    </FormControl>
                  }

                </Col>
                <Col md="3" sm="2" xl="1" className='m-0'>
                  <sub className="mb-0 pb-0">From Date</sub>
                  <input type="date" className="dateSelect_bg" id="date-1" value={date1} onChange={(e) => { setDate1(e.target.value) }} />
                </Col>
                <Col md="3" sm="2" xl="1" className='m-0'>
                  <sub className="mb-0 pb-0">To Date</sub>
                  <input type="date" className="dateSelect_bg" id="date-2" value={date2} onChange={(e) => { setDate2(e.target.value) }} />
                </Col>
                <Col md="5" sm="5" xl="3" className="mt-4 align-items-center">
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={selectRole} name="row-radio-buttons-group">
                    <FormControlLabel value="Normal" control={<Radio className="p-1" />} onChange={e => setSelectRole(e.target.value)} label="Normal" className="mt-1" />
                    <FormControlLabel value="Sorted" control={<Radio className="p-1" />} onChange={e => setSelectRole(e.target.value)} label="Sorted" className="mt-1" />
                    <FormControlLabel value="Both" control={<Radio className="p-1" />} onChange={e => setSelectRole(e.target.value)} label="Both" className="mt-1" />
                  </RadioGroup>
                </Col>
                <Col md="2" sm="2" xl="1" className="align-items-center justify-content-end">
                  <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                </Col>
                <Col md="2" sm="2" xl="1" className=" align-items-center justify-content-end">
                  <button className="btn-blue-Animal mt-1 mb-1" onClick={ApplyFn}>Apply</button>
                </Col>
              </Row>

              <Row className="mt-4 align-items-center">
                <Col md="4" xl="2" xs="12">
                  <div style={{ backgroundColor: "" }}>
                    <Col className='align-items-center'>
                      {
                        role === "sire" && <Table columns={nameColum} data={sireList} height="70vh" selectableRows />
                      }
                      {
                        role === "staff" && <Table columns={nameColum} data={staffList} height="70vh" selectableRows />
                      }

                    </Col>
                  </div>
                </Col>
                <Col md="8" xl="10" xs="12">
                  <Table columns={colum} data={supplierList} height="70vh" pagination />
                </Col>
              </Row>

            </Container>
          </Col>
        </Row>
      </Container>
      {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
    </>
  )
}
export default SupplierStock    
