import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas, Modal, Accordion } from "react-bootstrap";
import Sidenav from "../../../Componet/Sidenav/Sidenav";
import menu from '../../../Photo/menu.svg'
import profile from '../../../Photo/profile.svg';
import Input from '../../../Componet/InputFild/Input';
import Table from '../../../Componet/DataTable/Table';
import remove from "../../../Photo/master/remove.svg";
import { ToastContainer, toast } from 'react-toastify';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Select from 'react-select';

import closeButton from "../../../Photo/milk-union/close button.svg";
import './Supplier.css'
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
// import Searcable from '../../Componet/Dropdown/Searcable';
import Searcable from '../../../Componet/Dropdown/Searcable';
import { useNavigate } from 'react-router-dom';
import { API_URLS, serviceUrl } from '../../../API/Api';
import { STORAGE_KEY } from '../../../constant/common';
import moment from 'moment/moment';
import { downloadExcel } from 'react-export-table-to-excel';

const { AitStockApi, SupplieStockApi, SemenSupplierApi, SemenRequestApi, TransferApi, TransferAnimal, DistributionAPi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getSaffData(credentials) {
  return fetch(AitStockApi.GetSire, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json());
}

async function getitem(credentials) {
  return fetch(DistributionAPi.GetItem, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json());
}

async function getSupplierData(credentials) {
  return fetch(SupplieStockApi.GetStaff, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json());
}

async function getRouteData(credentials) {
  return fetch(SemenSupplierApi.SemenRoute, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json());
}

async function getSireData(credentials) {
  return fetch(SupplieStockApi.GetSire, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json());
}

async function getEditdetail(credentials) {
  return fetch(DistributionAPi.EditDetails, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json());
}

async function getItemData(credentials) {
  return fetch(SemenSupplierApi.GetItemData, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json());
}

async function getZone(credentials) {
  return fetch(TransferApi.GetZone, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(res => res.json());
}

async function getVisitRoute(credentials) {
  return fetch(TransferAnimal.GetVisitRoute, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(res => res.json());
}

// async function getSemenData(credentials) {
//   return fetch(SemenSupplierApi.GetSemenData, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       "Accept": "application/json",
//       "Authorization": `Bearer ${added_by}`,
//     },
//     body: JSON.stringify(credentials)
//   })
//     .then(data => data.json());
// }

const SemenReqest = () => {
  const API_KEY = serviceUrl;
  const selectedToDate = new Date().toISOString().slice(0, 10);

  const [date, setDate] = useState("");
  const [staff, setStaff] = useState("");
  const [editId, setEditId] = useState("");
  const [bench, setBench] = useState("");
  const [qty, setQty] = useState("");
  const [sire, setSire] = useState("");
  const [semen, setSemen] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [item, setItem] = useState("");
  const [supplied, setSupplied] = useState("");
  const navigate = useNavigate();
  const logOut = () => { navigate("/") };
  const [fromDate, setFromDate] = useState("");
  const [route, setRoute] = useState("");
  const [Visit, setVisit] = useState("");
  const [toDate, setToDate] = useState("");
  const [supplier, setSupplier] = useState("");
  const [supplier2, setSupplier2] = useState("");
  const [progressData, setProgressData] = useState("0%");
  const [status, setStatus] = useState("");
  const [staff2, setStaff2] = useState("");
  const [Phonenum, setPhonenum] = useState("");
  const [center, setCenter] = useState("");
  const [dcs, setDcs] = useState("");

  const [show, setShow] = useState(false);
  const [headerShow, setHeaderShow] = useState(true);
  const handleClose = () => { setShow(false); };
  const handleShow = () => { setHeaderShow(!headerShow); }

  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [supplierList, setSupplierList] = useState([]);
  const [supplierList2, setSupplierList2] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [sireList, setSireList] = useState([]);
  const [semenList, setSemenList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [VisitList, setVisitList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [staffList2, setStaffList2] = useState([]);
  const [strawList, setStrawList] = useState([]);
  const [otherItemList, setOtherItemList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [dcsList, setDcsList] = useState([]);
  const USERID = localStorage.getItem("USERID")

  const AddData = async (row) => {
    setIsLoading(true)
    await fetch(SemenSupplierApi.SaveStraw, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify({
        RequestDate: row?.Date,
        RequestQuantity: row['Request ID'] ? row['Request ID'] : null,
        SireVoucherType: 2,
        Sires: [{ SireCode: sire, Normal: semen, batchnonormal: bench, Sorted: null, batchnosorted: null }],
        Staff: parseFloat(staff) ? parseFloat(staff) : 0,
        VoucherDate: toDate,
        Quantity: qty,
        createdAt: moment(),
        createdByUser: USERID,
        dcs: null,
        isEdit: 0,
        lastUpdatedByUser: USERID,
        supplierId: supplier2,
        updatedAt: moment(),
      })
    })
      .then(data => data.json())
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) { controller.close(); return; }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      })
      .then((result) => { setIsLoading(false); })
      .catch((error) => setIsLoading(false))

    await fetch(SemenSupplierApi.SaveItem, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },


      body: JSON.stringify({
        Items: [
          { Item: itemList[0].Name, ItemCode: itemCode, quantity: qty }
        ],
        RequestDate: null,
        RequestQuantity: null,
        Staff: parseFloat(staff) ? parseFloat(staff) : 0,
        VoucherDate: date,
        VoucherType: 2,
        batchno: null,
        createdAt: null,
        createdByUser: null,
        dcs: null,
        isEdit: 0,
        lastUpdatedByUser: null,
        supplierId: 0,
        updatedAt: null,

      })
    })
      .then(data => data.json())
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) { controller.close(); return; }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      })
      .then((result) => { setIsLoading(false); })
      .catch((error) => setIsLoading(false))
    setModalShow(false);
    // toast.success("Data Saved successfully.....");
    setOtherItemList([]);
    setStrawList([])
  }

  const fetchData = async () => {
    const res = await getSaffData();
    const res1 = await getSupplierData();
    const res2 = await getRouteData();
    const res3 = await getVisitRoute();
    console.log(res1);
    if (res) { setStaffList(res) }
    if (res1) { setSupplierList(res1) }
    if (res2) { setRouteList(res2) }
    if (res3) { setVisitList(res3) }
  }

  const getSemenData = async () => {
    console.log(sireList);
    setIsLoading(true)
    await fetch(SemenSupplierApi.GetSemenData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify({ staffId: staff.toString(), inputDate: fromDate, billDate: toDate, route: route.toString(), status: status, supplier: supplier.toString() })
    })
      .then((response) => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) {
                      controller.close();
                      return;
                    }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      }
      )
      .then((res) => res.json())
      .then((result) => {
        setSemenList(result);
        setIsLoading(false)
      })
      .catch((error) => { setIsLoading(false) })
  }

  const openData = async () => {
    const res = await getSireData()
    if (res) {
      setSireList(res)
      setShow(true)
    }
  }

  const download = (e) => {
    const date = moment(e["Bill Date"]);
    const momentObj = moment(date);
    const dateOnly = momentObj.format('YYYY-MM-DD');
    setIsLoading(true)
    fetch(`${SemenRequestApi.dowunloadData}?staff=${e.staffid}&billDate=${dateOnly}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
    })
      .then((response) => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) {
                      controller.close();
                      return;
                    }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      }
      )
      .then((res) => res.json())
      .then((result) => { console.log(result); setIsLoading(false) })
      .catch((error) => { setIsLoading(false) })
  }

  const [datarow, setdatarow] = useState()

  const Suply = async (row) => {
    try {
      setdatarow(row)
      const res4 = await getEditdetail({ id: row.staffcode });
      const res5 = await getitem();
      if (res5) {
        setItem(res5)
        console.log(res5);
        setItemList(res5)
      }

      console.log(res5);
      setVisit(res4[0]?.centername);

      if (res4[0]) {
        setDcs(res4[0]?.dcscode + "-" + res4[0]?.dcsname)
      } else {
        setDcs("")
      }

      setPhonenum(res4[0]?.Mobile)
      console.log(res4[0]?.centername);

      if (supplierList.length > 0) {
        setSupplier2(supplierList[0].id);
      }

      openData();
      setModalShow(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed 
    }
  };


  const delateHandle = (e) => {
    setStrawList(strawList.filter(item => item.index !== e.index))
  }
  const delateHandle1 = (e) => {
    setOtherItemList(otherItemList.filter(item => item.index !== e.index))
  }

  const AddStraw = () => {
    setStrawList((prev) => {
      const newIndex = prev.length + 1;
      const newStrawItem = { index: newIndex, staff, sire, semen, bench, qty };
      console.log("New Straw Item:", newStrawItem);
      return [...prev, newStrawItem];
    });
  };

  const itemSave = () => {
    setOtherItemList((prev) => {
      const newIndex = prev.length + 1;
      return [...prev, { index: newIndex, item, itemCode, supplied }]
    })
  }

  const handleDownloadExcel = () => {
    const header = [];
    const opt = `AIT Semen Request ${date}`;
    colum.map((e, index) => {
      if (index != 0) {
        header.push(e.name);
      }
    });

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: semenList,
      },
    });
  };



  const handelpdf = async (e) => {
    try {
      const date = moment(e["Bill Date"]);
      const momentObj = moment(date);
      const dateOnly = momentObj.format('YYYY-MM-DD');
      const response = await fetch(`${SemenRequestApi.dowunloadData}?staff=${e.staffid}&billDate=${toDate}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
          'Authorization': `Bearer ${added_by}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);

      window.open(pdfUrl, '_blank');

      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error('API request failed:', error);
    }
  };


  const fatchDcsData = async (e) => {
    if (e) {
      fetch(`${API_KEY}/cattle-detail/getVisitLot/` + e, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${added_by}`
        },
      })
        .then(res => res.json())
        .then((result) => { setDcsList(result); console.log(result); })
    }
  }

  const selectDsc = (e) => {
    console.log(e)
    setDcs(e);
    // fatchFarmerData(e);
    fatchDcsData(e)
  }

  const colum1 = [
    {
      name: "Action",
      cell: (e) => (
        <>
          <img
            src={remove}
            onClick={() => delateHandle1(e)}
            style={{ width: "15px" }}
          />
        </>
      ),
    },
    {
      name: "Item name",
      selector: "item",
      cell: (row) => {
        const matchedItem = itemList.find((item) => item.id === row.item);
        return matchedItem ? matchedItem.Name : "";
      },
      sortable: true
    },
    {
      name: "Item Code",
      selector: "itemCode",
      sortable: true
    },
    {
      name: "Supplied",
      selector: "supplied",
      sortable: true
    },
  ]

  const colum21 = [
    {
      name: "Action",
      cell: (e) => (
        <>
          <img
            src={remove}
            onClick={() => delateHandle(e)}
            style={{ width: "15px" }}
          />
        </>
      ),
    },
    {
      name: "Sire",
      selector: "sire",
      cell: (row) => {
        const matchedSire = sireList.find((sire) => sire.id === row.sire);
        return matchedSire ? matchedSire.Name : "";
      },
      sortable: true
    },
    {
      name: "Semen Type",
      selector: "semen",
      sortable: true
    },
    {
      name: "Batch No",
      selector: "bench",
      sortable: true
    },
    {
      name: "Qty",
      selector: "qty",
      sortable: true
    },
  ]

  const colum = [
    {
      name: "Actions",
      cell: (e) => (
        <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
          {(() => {
            const matchResult = e["Item Type"] && e["Item Type"] == "dsd";
            // if (!matchResult) {
            //   return <></>;
            // } else 
            if (e.Status == "Dispatch" || e.Status == "On the Way") {
              return (
                <div className='d-flex justify-content-between'>
                  <button
                    className="m-2 btn-sq"
                    onClick={() => { Suply(e); setStaff2(e.staffcode) }}
                    style={{ borderRadius: "10px", border: "none" }}
                  >
                    Supply
                  </button>

                  <button
                    className="btn-sq m-2"
                    style={{ backgroundColor: "#F44336", borderRadius: "10px", border: "none" }}
                    onClick={() => handelpdf(e)}
                  >
                    Download
                  </button>
                </div>
              );
            }
            if (e.Status == "Requested" || e.Status == "Approved" || e.Status == "reject") {
              return (
                <button
                  className="m-2 btn-sq"
                  onClick={() => { Suply(e); setStaff2(e.staffcode) }}
                  style={{ borderRadius: "10px", border: "none" }}
                >
                  Supply
                </button>
              );
            } else if (e.Status == null) {
              return <></>
            } else {
              return (
                <button
                  className="btn-sq m-2"
                  style={{ backgroundColor: "#F44336", borderRadius: "10px", border: "none" }}
                  onClick={() => handelpdf(e)}
                >
                  Download
                </button>
              );
            }
          })()}
        </div>
      ),
      width: "200px",
      sortable: true
    },
    {
      name: "Item Type",
      cell: (e) => (<div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px", alignItems: "center", justifyContent: "center" }}>
        {e['Item Type']}
      </div>),
      paddingTop: "20px",
      paddingBottom: "30px",

      width: "280px",
      sortable: true
    },
    {
      name: "Item Name",
      cell: (e) => (
        <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
          {e['Item Name']}
        </div>),
      sortable: true
    },
    {
      name: "DCS",
      cell: (e) => (
        <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
          {e.DCS}
        </div>),
      sortable: true
    },
    {
      name: "Date",
      cell: (e) => {
        const date = moment(e['Date']);
        const formattedDate = date.isValid() ? date.format('DD/MM/YYYY') : '';

        return (
          <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
            {formattedDate}
          </div>
        );
      },
      sortable: true
    },
    {
      name: "Request ID",
      cell: (e) => (
        <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
          {e['Request ID']}
        </div>
      ),
      sortable: true
    },
    {
      name: "Bill Date",
      cell: (e) => {
        const billDate = moment(e['Bill Date']);
        const formattedBillDate = billDate.isValid() ? billDate.format('DD-MM-YYYY') : '';

        return (
          <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
            {formattedBillDate}
          </div>
        );
      },
      sortable: true
    },
    {
      name: "Type",
      cell: (e) => (<div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
        {e['Type']}
      </div>),
      sortable: true
    },
    // {
    //   name: "Status",
    //   cell: (e) => (<p className='p-0 m-0'>{e['Status']}</p>),
    // },
    {
      name: "Status",
      cell: (e) => (
        <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
          {e['Status']}
        </div>
      ),
      sortable: true
    },
    {
      name: "Route",
      cell: (e) => (
        <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
          {e['Route']}
        </div>),
      sortable: true
    },
    {
      name: "Staff Code",
      cell: (e) => (
        <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
          {e['staffcode']}
        </div>),
      width: "100px",
      sortable: true
    },
    {
      name: "Stat",
      cell: (e) => (
        <div className={`p-0 m-0 ${e.Status != null ? 'highlight-row' : 'paddrow'}`} style={{ height: "100%", width: "100%", paddingLeft: "16px", paddingRight: "16px" }}>
          {e['stat']}
        </div>),
      sortable: true
    },
  ]

  const rowClassName = (row) => {
    // const status = row['Status'];
    // Add the class 'highlight-row' if the status is not null
    // return status !== null ? 'highlight-row' : '';
  };

  const selectItem = (e) => {
    setItem(e)
    setItemCode(e)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    setFromDate(getCurrentDate());
    setToDate(getCurrentDate());
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                  <button type="button" id="navToggle" class="navbar-toggler d-block" for="opensiderMenu" onClick={handleShow} style={{ border: "none", cursor: "pointer" }}><img src={menu} width={20} /></button>
                  <h5 className="ms-4 mt-2">Straw / Semen Supplier / Semen Reqest</h5>
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                  <img src={profile} width={40} />
                  <div className="dropdpwn_Open">
                    <Row className="text-center align-items-center">
                      <Col xs="12" className="menuHover mb-3">
                        <Row >
                          <Col className="" xs="3">
                            <span className=""><MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="menuHover" onClick={logOut}>
                        <Row>
                          <Col className="" xs="3">
                            <span className=""><GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>



              <Row className="align-items-end ">
                <Col md="2" sm="2" xs="4" xxl="2" className='justify-content-start'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Supplier</InputLabel> */}
                    {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={supplier} onChange={(e) => { setSupplier(e.target.value) }}>
                      {supplierList.map((e) => (
                        <MenuItem className="drop_value" key={e.id} value={e.id}>
                          {e.code} - {e.Name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Select
                      placeholder="Search Supplier"
                      onChange={(selectedOption) => {
                        setSupplier(selectedOption.value);
                      }}
                      options={supplierList.map((item) => ({
                        value: item.id,
                        label: `${item.code} - ${item.Name}`,
                      }))}
                    />
                  </FormControl>
                </Col>
                <Col md="2" sm="2" xs="4" xxl="1" className='justify-content-start staffDropdown'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Staff</InputLabel> */}
                    {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { setStaff(e.target.value) }}>
                      {staffList.map((e) => (
                        <MenuItem className="drop_value" key={e.id} value={e.id}>
                          {e.code} - {e.Name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Select
                      placeholder="Search Staff"
                      onChange={(selectedOption) => {
                        setStaff(selectedOption.value);
                      }}
                      options={staffList.map((item) => ({
                        value: item.id,
                        label: `${item.code} - ${item.Name}`,
                      }))}
                    />
                  </FormControl>
                </Col>
                <Col md="2" sm="2" xs="4" xxl="1" className='justify-content-start routedropdwn'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Route</InputLabel> */}
                    {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={route} onChange={(e) => { setRoute(e.target.value) }}>
                      {routeList.map((e) => (
                        <MenuItem className="drop_value" key={e.id} value={e.id}>
                          {e.Name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Select
                      placeholder="Search Route"
                      onChange={(selectedOption) => {
                        setRoute(selectedOption.value);
                      }}
                      options={routeList.map((item) => ({
                        value: item.id,
                        label: item.Name
                      }))}
                    />
                  </FormControl>
                </Col>
                <Col md="2" sm="2" xs="4" xxl="1">
                  <div>
                    <sub className="mb-0 pb-0">From Date</sub>
                    <input type="date" className="dateSelect_bg" id="date-1" value={fromDate} onChange={(e) => { setFromDate(e.target.value) }} />
                  </div>
                </Col>
                <Col md="2" sm="2" xs="4" xxl="1">
                  <div className="mt-1">
                    <sub className="mb-0 pb-0">Bill Date</sub>
                    <input type="date" className="dateSelect_bg" id="date-2" value={toDate} onChange={(e) => { setToDate(e.target.value) }} />
                  </div>
                </Col>
                <Col md="2" sm="2" xs="4" xxl="1" className='justify-content-start'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Status</InputLabel> */}
                    {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={status} onChange={(e) => { setStatus(e.target.value) }}>
                      <MenuItem className="drop_value" value="Approved">Approved</MenuItem>
                      <MenuItem className="drop_value" value="Dispatch">Dispatch</MenuItem>
                      <MenuItem className="drop_value" value="On the way">On the way</MenuItem>
                      <MenuItem className="drop_value" value="Reject">Reject</MenuItem>
                      <MenuItem className="drop_value" value="Received">Received</MenuItem>
                      <MenuItem className="drop_value" value="Requested">Requested</MenuItem>
                    </Select> */}

                    <Select
                    placeholder="Status"
                    onChange={(selectedOption) => setStatus(selectedOption.value)}
                    options={[
                      {value: "Approved" , label:"Approved"},
                      {value: "Dispatch" , label:"Dispatch"},
                      {value: "On the way" , label:"On the way"},
                      {value: "Reject" , label:"Reject"},
                      {value: "Received" , label:"Received"},
                      {value: "Requested" , label:"Requested"},
                    ]}
                    />
                  </FormControl>
                </Col>
                {/* <Col xs="4" sm="2" md="2" xxl="1" className="mt-3 ms-auto align-items-center justify-content-end">
                  <button className="btn-blue-Animal" onClick={getSemenData}>Stock Supply</button>
                </Col> */}
                <Col xs="4" sm="2" md="2" xxl="1" className="mt-3 ms-auto align-items-center justify-content-end">
                  <button className="btn-blue-Animal" onClick={getSemenData}>Apply</button>
                </Col>
                <Col xs="4" sm="2" md="2" xxl="1" className="mt-3 align-items-center justify-content-end">
                  <button className="btn-blue-Animal" onClick={handleDownloadExcel}>Export</button>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <Table columns={colum} data={semenList} height="78vh" pagination />
                </Col>
              </Row>

            </Container>
          </Col>
        </Row>
      </Container>

      <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter" className=''>Add Semen Supplier</Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='align-tems-between justify-content-between'>
              <Col md="2" sm="3">
                <FormControl variant="standard" fullWidth>

                  {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={supplier2} onChange={(e) => { setSupplier2(e.target.value) }}>
                    {supplierList.map((e) => (
                      <MenuItem className="drop_value" key={e.id} value={e.id}>
                        {e.code} - {e.Name}
                      </MenuItem>
                    ))}
                  </Select> */}
                  {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Supplier</InputLabel> */}
                  <Select
                    placeholder="Supplier"
                    onChange={(selectedOption) => {
                      setSupplier2(selectedOption.value);
                    }}
                    options={supplierList.map((item) => ({
                      value: item.id,
                      label: `${item.code} - ${item.Name}`,
                    }))}
                  />
                </FormControl>
              </Col>
              <Col md="2">

                <TextField label="staff Code" id="standard-basic" value={staff2} disabled fullWidth variant="standard" />

              </Col>
              <Col md="2">
                <TextField label="Center" id="standard-basic" value={Visit} disabled fullWidth variant="standard" />

              </Col>
              <Col md="2">

                <TextField label="DCS" id="standard-basic" value={dcs} disabled fullWidth variant="standard" />

              </Col>
              <Col md="2">

                <TextField label="Phonenumber" id="standard-basic" value={Phonenum} fullWidth variant="standard" />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Add Staw</Accordion.Header>
                    <Accordion.Body>
                      <Row className='align-tems-center justify-content-start'>
                        <Col md="3" sm="3">
                          <FormControl variant="standard" fullWidth>
                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sire</InputLabel> */}
                            {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={(e) => { setSire(e.target.value) }}>
                              {sireList.map((e) => (
                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                  {e.Name}
                                </MenuItem>
                              ))}
                            </Select> */}
                            {sireList && (
                              <Select
                                placeholder="Sire"
                                value={sire !== "" ? { value: sire, label: sireList.find(item => item.id === sire)?.Name } : ""}
                                onChange={(selectedOption) => setSire(selectedOption ? selectedOption.value : "")}
                                options={sireList.map((e) => ({ value: e.id, label: e.Name }))}
                              />

                            )}
                          </FormControl>
                        </Col>
                        <Col md="3">
                          <FormControl variant="standard" fullWidth>
                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Semen</InputLabel> */}
                            {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={semen} onChange={(e) => { setSemen(e.target.value) }}>
                              <MenuItem className="drop_value" value="0">Normal Semen</MenuItem>
                              <MenuItem className="drop_value" value="1">Sex Semen</MenuItem>
                            </Select> */}
                            <Select
                              placeholder="Semen"
                              value={semen !== "" ? { value: semen, label: semen === "0" ? "Normal Semen" : "Sex Semen" } : ""}
                              onChange={(selectedOption) => setSemen(selectedOption ? selectedOption.value : "")}
                              options={[
                                { value: "0", label: "Normal Semen" }, // Option for Normal Semen
                                { value: "1", label: "Sex Semen" },     // Option for Sex Semen
                              ]}
                            />
                          </FormControl>
                        </Col>
                        <Col md="3">
                          <TextField label="Bench No" id="standard-basic" value={bench} onChange={(e) => { setBench(e.target.value); }} fullWidth variant="standard" />
                        </Col>
                        <Col md="3">
                          <TextField label="Qty" id="standard-basic" value={qty} onChange={(e) => { setQty(e.target.value); }} fullWidth variant="standard" />
                        </Col>
                      </Row>
                      <Row className='align-tems-end justify-content-end'>
                        <Col xs="4" sm="4" md="3" className="mt-2 d-flex align-items-center text-end justify-content-end">
                          <button className="btn-blue-Animal mt-1 mb-1" onClick={AddStraw}>Add Straw</button>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Table columns={colum21} data={strawList} />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header >Add Other Item</Accordion.Header>
                    <Accordion.Body>
                      <Row className='align-tems-center justify-content-end'>
                        <Col md="4">
                          <FormControl variant="standard" fullWidth>
                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Item Name</InputLabel> */}
                            {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={item} onChange={(e) => { selectItem(e.target.value) }}>
                              {itemList.map((e) => (
                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                  {e.Name}
                                </MenuItem>
                              ))}
                            </Select> */}

                            <Select
                              placeholder="Item Name"
                              value={item !== "" ? { value: item, label: itemList.find(e => e.id === item)?.Name } : ""}
                              onChange={(selectedOption) => selectItem(selectedOption ? selectedOption.value : "")}
                              options={itemList.map((e) => ({ value: e.id, label: e.Name }))}
                            />
                            {/* </Col> */}
                          </FormControl>
                        </Col>
                        <Col md="4">
                          <TextField label="Item Code" id="standard-basic" value={itemCode} disabled onChange={(e) => { setItemCode(e.target.value); }} fullWidth variant="standard" />
                        </Col>
                        <Col md="4">
                          <TextField label="Supplied" id="standard-basic" value={supplied} onChange={(e) => { setSupplied(e.target.value); }} fullWidth variant="standard" />
                        </Col>
                        <Col xs="4" sm="2" md="3" className="mt-2 d-flex align-items-center text-end justify-content-end">
                          <button className="btn-blue-Animal mt-1 mb-1" onClick={itemSave}>Add item</button>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Table columns={colum1} data={otherItemList} />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </Modal.Body >
        <Modal.Footer>
          <Container>
            <Row className="justify-content-end">
              <Col sm="2">
                <button className="btn-blue-Animal mt-1" onClick={(e) => handelpdf(e)} style={{ background: "#E16659" }}> Download </button>
              </Col>
              <Col sm="2">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { AddData(datarow) }}>Save</button>
              </Col>
              <Col sm="2">
                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal >

      {
        isLoading ?
          <div className="circleText">
            < div className="progressText" > {progressData}</div >
            <div className="">
              <span className="loader-circle-13"></span>
            </div>
          </div >
          :
          <></>
      }


      {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ fontSize: "14px" }}
      />
    </>
  )
}
export default SemenReqest


