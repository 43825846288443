import { Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
} from "@mui/material";
import { Select } from '@mui/material';
// import Select from 'react-select';

import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Searcable from '../../Componet/Dropdown/Searcable';
import BackBtn from '../../Componet/Button/BackBtn';
import { downloadExcel } from 'react-export-table-to-excel';
const { Shift_allocationApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

const label = { inputProps: { "aria-label": "Switch demo" } };


const ShiftAllocation = () => {

    async function getData(credentials) {
        return fetch(Shift_allocationApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function addData(credentials) {
        return fetch(Shift_allocationApi.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    async function updateData(credentials) {
        return fetch(Shift_allocationApi.UpdateDetails, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const [fromTime, setFromTime] = useState("")
    const [toTime, setToTime] = useState("")
    const [search, setSearch] = useState("")
    const [editId, setEditId] = useState("")
    const [deleteId, setDeleteId] = useState();
    const [Shift, setShift] = useState("")
    const [shiftAllow, setShiftAllow] = useState("")
    const [center, setCenter] = useState("")
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");

    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    // const [isSuspended, setIsSuspended] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)
    const [editToggle, setEditToggle] = useState(false);


    const [shiftAllocatList, setShiftAllocatList] = useState([])
    const [shiftList, setShiftList] = useState([])
    const [centerList, setCenterList] = useState([])


    const shifallocatGetData = async () => {
        setIsLoading(true)
        const res = await getData();
        if (res) {
            setShiftAllocatList(res.data);
            setIsLoading(false)
        }
    }

    const formApi = () => {
        //shift
        fetch(`${API_KEY}/master/shift`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setShiftList(result.data);
            });

        //zone
        fetch(`${API_KEY}/zone/getall`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setCenterList(result);
            });
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        shifallocatGetData()
        formApi()
    }, [])


    const AddData = async () => {
        setFromTime(moment(fromTime).format("hh:mm a"))
        setToTime(moment(toTime).format("hh:mm a"))
        if (editId) {
            // const res = await updateData({ id: editId, fromTime, shift: shiftAllow, toTime, zone: center, company: "1", user: "201" });
            // if (res) {
            //     setShiftAllocatList(res.data);
            //     setModalShow(false)
            //     shifallocatGetData()
            // }

            fetch(`${API_KEY}/master/shift_allocation/` + editId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ fromTime, shift: shiftAllow, toTime, zone: center, id: 0 })
            })
                .then((res) => res.json())
                .then((result) => {
                    shifallocatGetData();
                    setDeleteShow(false)
                    setModalShow(false);
                });
        }
        else {
            const res = await addData({ shift: shiftAllow, fromTime, toTime, zone: center, id: 0 });
            if (res) {
                setShiftAllocatList(res.data);
                setModalShow(false)
                shifallocatGetData()
            }

        }
    }

    const Add = () => {
        setModalShow(true);
        setEditId("")
        setEditToggle("");
        setEditId("");
        setFromTime("")
        setToTime("")
        setCenter("")
        setShiftAllow("")
    }

    const updateFn = (e) => {
        setModalShow(true)
        setEditId(e.id)
        setEditToggle(true);
        setShiftAllow(e.shift)
        setEditId(e.id);
        setCenter(e.zone)
        setFromTime(e.fromTime)
        setToTime(e.toTime)
        setModalShow(true);
    };


    const deleteData = (id) => {
        // setShift(e.shift)

        fetch(`${API_KEY}/master/shift_allocation/` + id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                shifallocatGetData();
                setDeleteShow(false)
                // setModalShow(false);
            });
    }

    const colum = [
        {
            name: "ID",
            selector: "id",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{c["id"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Shift",
            selector: "shift",
            cell: (e) => <>{
                shiftList.map((i) => {
                    if (i.id == e.shift) {
                        return (
                            <>
                                <div style={{ paddingLeft: "5px", width: "100%", display: "flex", justifyContent: "flex-start" }}>{i.Name}</div>
                            </>
                        )
                    }
                })
            }</>,
            sortable: true
        },
        {
            name: "Center",
            selector: "zoneName",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "100%", display: "flex", justifyContent: "flex-start" }}>{c["zoneName"]}</div>
                </>
            ),
            sortable: true
        },
        // {
        //     name: "Contact person",
        //     selector: "mobile",
        // },
        {
            name: "From Time",
            selector: "fromTime",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{c["fromTime"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "To Time",
            selector: "toTime",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{c["toTime"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                    <img
                        src={remove}
                        onClick={() => { setDeleteId(e.id); setShift(e.shift); setDeleteShow(true); }}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
    ]

    const handledExcel = () => {
        // Define the keys you want to export
        const keysToExport = ["id", "shift", "zoneName", "fromTime", "toTime"];

        const filteredData = shiftAllocatList?.map(item => {
            const filteredItem = {};
            keysToExport.forEach(key => {
                // Convert boolean to string if the key is "IsSuspended"
                filteredItem[key] = key === "IsSuspended" ? String(item[key]) : item[key];
            });
            return filteredItem;
        });

        // Prepare the header row
        const header = keysToExport.map(key => {
            const column = colum.find(col => col.selector === key);
            return column ? column.name : key;
        });

        // Download Excel
        downloadExcel({
            fileName: "Shift Allocation",
            sheet: "data",
            tablePayload: {
                header,
                body: filteredData
            }
        });
    }

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = shiftAllocatList.filter((d) => {
                return d.Shift.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(shiftAllocatList);
        }
        setSearch(data);
    }
    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}> <img src={menu} width={20} /> </button>
                                    <h5 className="ms-4 mt-2">Master / Shift Allocation</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-between p-2 d-flex" id='heightOffsetStock'>
                                <Col sm="8" lg="4" xl="3" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1 me-2" onClick={() => Add()}>Add Shift Allocation</button>
                                    <button className="btn-blue-Animal mt-1 mb-1 ms-2" onClick={() => handledExcel()}>Export</button>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            {/* <Table columns={colum} data={searchFilter ? searchFilter : shiftAllocatList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination /> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {shiftAllocatList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : shiftAllocatList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {
                            shiftList.map((i) => {
                                if (i.id == Shift) {
                                    return (
                                        <>Delete {i.Name} ?</>
                                    )
                                }
                            })
                        }


                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => deleteData(deleteId)}>Delete</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>


            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update Shift" : "Add New Shift"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="6">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ fontSize: "18px" }}
                                    >
                                        Shift
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={shiftAllow} onChange={(e) => { setShiftAllow(e.target.value); }}>
                                        {shiftList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* <Searcable
                                        placeholder="Shift"
                                        options={shiftList}
                                        onChange={(selectedOption) => setShiftAllow(selectedOption)}
                                        value={shiftAllow}
                                        labelKey="Name"
                                        valueKey="id"
                                    /> */}
                                    {/* <Select
                                        placeholder="Shift"
                                        onChange={(selectedOption) => {
                                            setShiftAllow(selectedOption.value);
                                        }}
                                        options={shiftList.map((item) => ({
                                            value: item.id,
                                            label: item.Name,
                                        }))}
                                    /> */}
                                    {/* <Select value={shiftAllow} onChange={(e) => setShiftAllow(e.target.value)}>
                                        {shiftList.map((option) => (
                                            <option key={option.id} value={option.Name}>
                                                {option.Name}
                                            </option>
                                        ))}
                                    </Select> */}
                                </FormControl>
                            </Col>
                            <Col xl="6">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ fontSize: "18px" }}
                                    >
                                        Center
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={center} onChange={(e) => { setCenter(e.target.value); }}>
                                        {centerList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* <Searcable
                                        placeholder="Center"
                                        options={centerList}
                                        onChange={(selectedOption) => setCenter(selectedOption)}
                                        value={center}
                                        labelKey="Name"
                                        valueKey="id"
                                    /> */}
                                    {/* <Select
                                        placeholder="Center"
                                        onChange={(selectedOption) => {
                                            setCenter(selectedOption.value);
                                        }}
                                        options={centerList.map((item) => ({
                                            value: item.id,
                                            label: item.Name,
                                        }))}
                                    /> */}
                                    {/* <Select value={center} onChange={(e) => setCenter(e.target.value)}>
                                        {centerList.map((option) => (
                                            <option key={option.id} value={option.Name}>
                                                {option.Name}
                                            </option>
                                        ))}
                                    </Select> */}
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "14px" }}>
                                    From Date
                                </InputLabel>
                                <TextField type="date" value={fromTime} onChange={(e) => { setFromTime(e.target.value); }} id="standard-basic" data={shiftAllocatList} fullWidth variant="standard" />

                            </Col>
                            <Col xl="6">
                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "14px" }}>
                                    To Date
                                </InputLabel>
                                <TextField type="date" value={toTime} onChange={(e) => { setToTime(e.target.value); }} id="standard-basic" data={shiftAllocatList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Switch {...label} />
                                Is Suspended : No
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => AddData(e)}>{editToggle ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}


        </>

    )
}

export default ShiftAllocation;