import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './input.css';


const MuiInput = (props) => {
    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField className="" type={props.type?props.type:"text"} id="filled-basic" label={props.label} variant="filled" value={props.value} onChange={props.onChange}/>
        </Box>
    )
}

export default MuiInput