import React, { useState } from 'react'
import { Col, Container, Row, Offcanvas, } from "react-bootstrap";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { downloadExcel } from "react-export-table-to-excel";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";


import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { borderRadius, margin } from '@mui/system';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import Searcable from '../../Componet/Dropdown/Searcable';
import BackBtn from '../../Componet/Button/BackBtn';
const { UserLoginDetail } = API_URLS;


const UserWiseLoginDetail = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    async function getStaff(credentials) {
        return fetch(UserLoginDetail.GetStaff, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getGroup(credentials) {
        return fetch(UserLoginDetail.GetGroup, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function getData(credentials) {
        return fetch(UserLoginDetail.GetData, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const [search, setSearch] = useState("")
    const [group, setGroup] = useState(null)
    const [staff, setStaff] = useState("")
    const date = new Date();
    const newDate = date.toISOString().slice(0, 10);
    const [progressData, setProgressData] = useState("0%");
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0)
    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [searchFilter, setSearchFilter] = useState("")

    const [userLogin, setUserLogin] = useState([])
    const [groupList, setGroupList] = useState([])
    const [staffList, setStaffList] = useState([])

    console.log(groupList);

    const dataFilter = async (e, concept) => {
        console.log(e);
        setIsLoading(true)
        if (concept == "staff") {
            setStaff(e)
            fetch(`${API_KEY}/v1/api/web/account/get-appinfo`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ staffId: e.value ? e.value : null, userType: "0" })
            })
                .then((res) => res.json())
                .then((result) => { setUserLogin(result); setIsLoading(false); setProgressData("0%"); })
        }
        else if (concept == "group") {
            setGroup(e)
            fetch(`${API_KEY}/v1/api/web/account/get-appinfo`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ staffId: e.value ? e.value : null, userType: "0" })
            })
                .then((res) => res.json())
                .then((result) => { setUserLogin(result); setIsLoading(false); setProgressData("0%"); })
        }
    }

    const fetchData = () => {
        fetch(
            `${API_KEY}/group/getall?company_filter=1`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                setGroupList(result);
            });
    }

    const handledExcel = () => {
        const opt = `User Master_${newDate}`;
        const header = ["Username", "User Type", "Date", "AppVersion", "Status", "Mobile", "MobileVersion", "AndroidVersion"];

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header,
                body: userLogin
            }
        });
    }

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = userLogin.filter((user) => {
                return user.Username.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(userLogin);
        }
        setSearch(data);
    }

    const getLoginData = async () => {
        setIsLoading(true)
        const res = await getStaff()
        const res1 = await getData({ staffId: staff ? staff : null, userType: "0" })
        if (res) { setStaffList(res); setIsLoading(false) }
        if (res1) {
            setUserLogin(res1); setIsLoading(false)
        }
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        getLoginData()
        fetchData()
    }, [])


    const colum = [
        {
            name: "Username",
            selector: "Username",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px" }}>{c.Username}</div>
                </>
            ),
            sortable: true

        },
        {
            name: "UserType",
            selector: "User Type",
            cell: (e) => (
                    <p style={{margin:"5px"}}>{e["User Type"]}</p>
            ),
            sortable: true

        },
        {
            name: "Date",
            // ceil: (e) => (<><p>{moment(e["Date"]).format("YYYY-MM-DD")}</p></>),
            cell: (e) => <p style={{ paddingRight: "5px" }} className='m-0'>{moment(e.Date).format("DD-MM-YYYY")}</p>,
        },
        {
            name: "AppVersion",
            selector: "AppVersion",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px" }}>{c.AppVersion}</div>
                </>
            ),
            sortable: true

        },
        {
            name: "MobileVersion",
            selector: "MobileVersion",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px" }}>{c.MobileVersion}</div>
                </>
            ),
            sortable: true

        },
        {
            name: "AndroidVersion",
            selector: "AndroidVersion",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px" }}>{c.AndroidVersion}</div>
                </>
            ),
            sortable: true

        },
    ]
    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">User Management / User Wise Login Detail</h5>
                                </Col>
                                {/* <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col> */}
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-end p-2 d-flex" id='heightOffsetStock'>
                                <Col md="2" sm="3" xs="6">
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Select staff</InputLabel> 
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { dataFilter(e.target.value, "staff") }}>
                                            {staffList.map((e) => (
                                                <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Select staff"
                                            value={staff}
                                            options={staffList}
                                            onChange={(selectedOption) => dataFilter(selectedOption, "staff")}
                                            labelKey="Name"
                                            valueKey="ID"
                                        />
                                    </FormControl>
                                </Col>
                                <Col md="2" sm="3" xs="6">
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Select group</InputLabel> 
                                         <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={group} onChange={(e) => { dataFilter(e.target.value, "group") }}> 
                                            {groupList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Select group"
                                            value={group}
                                            options={groupList}
                                            onChange={(selectedOption) => dataFilter(selectedOption, "group")}
                                            labelKey="Name"
                                            valueKey="id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col md="2" sm="3" xs="6">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                                <Col sm="2" md="2" xs="6" xl="1" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={() => handledExcel()}>Export</button>
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : userLogin} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {userLogin.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : userLogin} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
}
export default UserWiseLoginDetail

