import React, { useState, useEffect } from 'react'
import {
  Col,
  Container,
  Row,
  Offcanvas,
  Modal,
  Accordion
} from 'react-bootstrap'
import {
  TextField,
  FormControl,
  InputLabel,
  // Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Switch
} from '@mui/material'
import Select from 'react-select'
import Sidenav from '../../Componet/Sidenav/Sidenav'
import menu from '../../Photo/menu.svg'
import profile1 from '../../Photo/profile.svg'
import Input from '../../Componet/InputFild/Input'
import Table from '../../Componet/DataTable/Table'
import edit from '../../Photo/master/edit.svg'
import remove from '../../Photo/master/remove.svg'
import closeButton from '../../Photo/milk-union/close button.svg'
import './Userdetails.css'
import { MdOutlineVpnKey } from 'react-icons/md'
import { GoSignOut } from 'react-icons/go'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackBtn from '../../Componet/Button/BackBtn'
import ReactDOMServer from 'react-dom/server'

import { STORAGE_KEY } from '../../constant/common'
import { API_URLS, serviceUrl } from '../../API/Api'
import { Link, useNavigate } from 'react-router-dom'
import { tab } from '@testing-library/user-event/dist/tab'
import { downloadExcel } from 'react-export-table-to-excel'
import Searcable from '../../Componet/Dropdown/Searcable'
import { DatePicker } from '@mui/x-date-pickers'
const { UserManagApi, SocietyApi, FarmerApi, LoginAPI, visitCancellation } =
  API_URLS

const Userdetails = () => {
  const API_KEY = serviceUrl

  // const API_KEY = "https://hais.hap.in";

  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN)

  async function getData (credentials) {
    return fetch(UserManagApi.GetDetail, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(res => res.json())
  }
  async function getZone (credentials) {
    return fetch(UserManagApi.GetZone, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(res => res.json())
  }
  async function getGroup (credentials) {
    return fetch(UserManagApi.GetGroup, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(res => res.json())
  }
  async function getAllHerd (credentials) {
    return fetch(visitCancellation.GetFilterUser, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(res => res.json())
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const navigate = useNavigate()

  const [checkboxData, setCheckboxData] = useState([])
  const [allSelect, setAllSelect] = useState('')
  const [routeName, setRouteName] = useState([])

  const [search, setSearch] = useState('')
  const [searchlot, setSearchlot] = useState('')
  const [group, setGroup] = useState('')
  const [userName, setUserName] = useState('')
  const [contactPerson, setContactPerson] = useState('')
  const [mobile, setMobile] = useState()
  const [appType, setAppType] = useState('')
  const [qr, setQr] = useState('')
  const [zone, setZone] = useState('')
  const [editId, setEditId] = useState('')
  const [role, setRole] = useState('herd')
  const [tableId, setTableId] = useState('')
  const [route, setRoute] = useState('')
  const [index, setIndex] = useState(1)
  const [subIndex, setSubIndex] = useState(1)
  const [searchFilter, setSearchFilter] = useState('')
  const [searchFilterlot, setSearchFilterlot] = useState('')
  const [searchFilterherd, setSearchFilterherd] = useState('')
  const [id, setId] = useState('')
  const [user, setUser] = useState('')
  const logOut = () => {
    navigate('/')
  }
  const [userId, setUserId] = useState('')
  const [lotData, setLotData] = useState('')
  const [selectedRouteName, setSelectedRouteName] = useState('')
  const [companyFilterName, setCompanyFilterName] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const [heightOffSet, setHeight] = useState(0)
  const [details, setDetails] = useState(true)
  const [profile, setProfile] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [progressData, setProgressData] = useState('0%')
  const [headerShow, setHeaderShow] = useState(true)
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setHeaderShow(!headerShow)
  }
  const [modalShow, setModalShow] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)
  const [ceateChecked, setCeateChecked] = useState(false)
  const [subCreate, setSubCreate] = useState(false)
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheckAll1, setIsCheckAll1] = useState(false)
  const [isCheckAll2, setIsCheckAll2] = useState(false)
  const [isCheckAll3, setIsCheckAll3] = useState(false)
  const [isCheckAll4, setIsCheckAll4] = useState(false)
  const [isSuspended, setIsSuspended] = useState(false)

  const [userDetails, setUserDetails] = useState([])

  const [groupList, setGroupList] = useState([])
  const [groupDetail, setGropDetail] = useState([])
  const [zoneList, setZoneList] = useState([])
  const [herdProfile, setHerdProfile] = useState([])
  const [lotProfile, setLotProfile] = useState([])
  const [farmerProfile, setFarmerProfile] = useState([])
  const [herdStructure, setHerdStructure] = useState([])
  const [filteredCountries, setFilteredCountries] = useState([])
  const [routeList, setRouteList] = useState([])
  const [lotStructure, setLotStructure] = useState([])
  const [farmerStructure, setFarmerStructure] = useState([])
  const [mobileList, setMobileList] = useState([])
  const [userCrudResult, setUserCrudResult] = useState([])
  const [rowsList, setRowsList] = useState([])
  const [idList, setIdList] = useState([])
  const [lotList, setLotList] = useState([])
  const [contactPersonList, setContactPersonList] = useState([])
  const [pageList, setPageList] = useState([])
  const [isCheck, setIsCheck] = useState([])
  const [isCheck1, setIsCheck1] = useState([])
  const [isCheck2, setIsCheck2] = useState([])
  const [isCheck3, setIsCheck3] = useState([])
  const [lotDataList, setLotDataList] = useState([])
  const [farmerList, setFarmerList] = useState([])
  const [data2, setData2] = useState([])
  const [applicationTypeList, setApplicationTypeList] = useState([
    { id: 'M', name: 'Mobile' },
    { id: 'D', name: 'Dashboard' },
    { id: 'DM', name: 'Dashboard and Mobile' }
  ])

  const [selectedCreateCheckboxes, setSelectedCreateCheckboxes] = useState([])
  const [selectedViewCheckboxes, setSelectedViewCheckboxes] = useState([])

  const [data, setData] = useState([])
  const [herdIdAssigned, setHerdIdAssigned] = useState([])
  const [herdIdUnAssign, setHerdIdUnAssign] = useState([])

  const [lotAssigned, setLotAssigned] = useState([])
  const [lotUnAssign, setLotUnAssign] = useState([])

  const [farmerIdAssigned, setFarmerIdAssigned] = useState([])
  const [farmerIdUnAssign, setFarmerIdUnAssign] = useState([])

  const herdNames = {}

  const EnterThroughQrCode = [
    { value: '1', label: 'Yes' },
    { value: '2', label: 'No' }
  ]

  const dataFilter = e => {
    console.log(e)
    setIsLoading(true)
    setGroup(e)
    fetch(
      `${API_KEY}/user/get_table_data?offset=0&limit=15&column_name=Username&sort_order=asc&search_term=&group_filter=${e}&company_filter=1`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )
      .then(res => res.json())
      .then(result => {
        setUserDetails(result.data)
        setIsLoading(false)
      })
  }

  const herd = e => {
    setZone(e)
    setIsLoading(true)
    fetch(`${API_KEY}/herd/getall`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      }
    })
      .then(res => res.json())
      .then(result => {
        setIsLoading(false)
        setRouteList(result)
        setHerdProfile(result)
      })
      .catch(error => {
        setIsLoading(false)
      })
  }

  const handleCheckboxChange = itemId => {
    const updatedCheckboxData = checkboxData.map(item =>
      item.id === itemId ? { ...item, Assigned: !item.Assigned } : item
    )
    setCheckboxData(updatedCheckboxData)
  }

  const herdRoute = async selectedId => {
    const selectedRoute = routeList.find(route => route.id === selectedId)

    if (!selectedRoute) {
      return
    }

    console.log(selectedRoute.Name)
    setSelectedRouteName(selectedRoute.Name)

    setRoute(selectedId)
    setIsLoading(true)

    const response1 = await fetch(
      `${API_KEY}/lot/getbyherd_id?herd_id=${selectedId}&company_filter=1`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )

    const result1 = await response1.json()
    let nameofroute = result1.filter(v => v.Name)
    setRouteName(nameofroute)
    setLotProfile(result1)

    const response2 = await fetch(
      `${SocietyApi.GetByHerdId}?name&herdId=${selectedId}&company_filter=10&user=7`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        },
        herd: selectedId
      }
    )

    const result2 = await response2.json()
    const modifiedResult = result2.map(item => ({
      ...item,
      Assigned: lotStructure.some(
        lot => lot.lotName === item.Name && lot.herdName === selectedRoute.Name
      )
        ? true
        : item.Assigned
    }))
    console.log(modifiedResult)
    setLotDataList(modifiedResult)
    setLotProfile(modifiedResult)
    setIsLoading(false)
  }

  const farmerDataGet = async e => {
    setIsLoading(true)

    fetch(
      `${FarmerApi.GetDetails}?offset=0&limit=15000000000000&column_name=Name&sort_order=asc&search_term&company_filter=10&user=7&lot_filter=${e}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )
      .then(res => res.json())
      .then(result => {
        const modifiedResult4 = result['data'].map(item => ({
          ...item,
          Assigned: farmerStructure.some(farmer => farmer.id === item.id)
            ? true
            : item.Assigned
        }))
        setFarmerProfile(modifiedResult4)
        setIsLoading(false)
      })

    setTimeout(() => {
      fetch(`${API_KEY}/user/get-farmer-structure?company=10&user=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      })
        .then(res => res.json())
        .then(result => {
          setFarmerStructure(result)
        })
    }, 10000)
  }

  const selctZone = async e => {
    setZone(e)
    fetch(`${API_KEY}/staff/get_by_name?name=&vo=${group}&zone=${e}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      }
    })
      .then(res => res.json())
      .then(result => {
        setIsLoading(false)
        setContactPersonList(result)
      })
  }

  const selctGroup = async e => {
    setGroup(e)
    fetch(`${API_KEY}/staff/get_by_name?name=&vo=${e}&zone=${zone}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      }
    })
      .then(res => res.json())
      .then(result => {
        setIsLoading(false)
        setContactPersonList(result)
      })
  }

  const refreshFn = () => {
    setModalShow(false)
    userGetData()
    setGroup('')
    setAppType('')
    setUserName('')
    setEditId('')
    setMobile('')
    setQr('')
    setZone('')
    setContactPerson('')
    setNewPassword('')
  }

  const AddData = async () => {
    const res = await getData()
    if (res) {
      console.log(res.data)

      setIsLoading(true)

      let userExists = false

      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index]
        if (element.Username === userName) {
          toast.error(`User already exists!`)
          userExists = true
          setIsLoading(false)
          break
        }
      }

      if (!userExists) {
        if (mobile === '') {
          toast.error('Please Enter Your Mobile No.')
        } else {
          await fetch(`${API_KEY}/user/add`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${added_by}`
            },
            body: JSON.stringify({
              appType,
              company: '10',
              id: 0,
              mobile,
              mobileDetails: '',
              qrCode: qr,
              user: '7',
              userGroup: group,
              staff: contactPerson,
              zone: zone,
              username: userName
            })
          })
            .then(res => res.json())
            .then(result => {
              setModalShow(false)
              toast.success(result.status)
              refreshFn()
              setIsLoading(false)
            })
            .catch(error => {
              console.error('Error:', error)
              setIsLoading(false)
            })

          await fetch(`${LoginAPI.changePassword}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${added_by}`
            },
            body: JSON.stringify({ userId: editId, Password: newPassword })
          })
            .then(res => res.json())
            .then(result => {
              console.log(result)
              setModalShow(false)
              toast.success(result.status)
              refreshFn()
            })
        }
      }
    }

    // await fetch(`${API_KEY}/user/add`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         "Accept": "application/json",
    //         "Authorization": `Bearer ${added_by}`
    //     },
    //     body: JSON.stringify({ appType, company: "10", id: 0, mobile, mobileDetails: "", qrCode: qr, user: "7", userGroup: group, staff: contactPerson, zone: zone, username: userName }),
    // })
    //     .then(res => res.json())
    //     .then((result) => { setModalShow(false); toast.success(result.status); refreshFn() })

    // await fetch(`${LoginAPI.changePassword}`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         "Accept": "application/json",
    //         "Authorization": `Bearer ${added_by}`
    //     },
    //     body: JSON.stringify({ userId: editId, Password: newPassword }),
    // })
    //     .then(res => res.json())
    //     .then((result) => { console.log(result); setModalShow(false); toast.success(result.status); refreshFn() })
  }

  const UpdateData = async () => {
    await fetch(`${API_KEY}/user/edit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({
        id: editId,
        appType,
        company: '10',
        mobile,
        mobileDetails: '',
        qrCode: qr,
        user: '7',
        userGroup: group,
        staff: contactPerson,
        zone: zone,
        username: userName
      })
    })
      .then(res => res.json())
      .then(result => {
        setModalShow(false)
        refreshFn()
      })

    await fetch(`${LoginAPI.changePassword}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ userId: userName, Password: newPassword })
    })
      .then(res => res.json())
      .then(result => {
        console.log(result)
        setModalShow(false)
        toast.success(result.status)
        refreshFn()
      })
  }

  const fetchData = async () => {
    const res1 = await getZone()
    const res2 = await getGroup()
    if (res1) {
      setZoneList(res1)
    }
    if (res2) {
      setGroupList(res2)
    }
  }

  const userGetData = async () => {
    setIsLoading(true)
    const res = await getData()
    const res1 = await getGroup()

    if (res) {
      setUserDetails(res.data)
      setIsLoading(false)
    }
    if (res1) {
      setGropDetail(res1)
      setIsLoading(false)
    }
  }

  const Add = async () => {
    refreshFn()
    setModalShow(true)
    fetchData()
  }

  useEffect(() => {
    let divHeight = document.getElementById('heightOffsetStock')?.offsetHeight

    setFilteredCountries(
      herdProfile.filter(e =>
        e.name?.toLowerCase().includes(search.toLowerCase())
      )
    )
    setHeight(divHeight)
    userGetData()

    if (ceateChecked) {
      setSubCreate(true)
    } else {
      setSubCreate(false)
    }
  }, [appType])

  const Open = async e => {
    const res = await getZone()
    if (res) {
      setZoneList(res)
    }
    getData(e)
    setUserId(e.id)

    setTableId(e.id)
    setUserName(e.Username)
    setGroup(e.group.Name)
    setMobile(e.Mobile)
    setDetails(false)
    setProfile(true)
    const response1 = await fetch(
      `${API_KEY}/user/get-herd-structure?company=10&user=${e.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )
    const result1 = await response1.json()
    setHerdStructure(result1)

    // const name1 = result1.map(v => v.Name);
    // console.log(name1);
    // setName(name1);

    // const response2 = await fetch(`${API_KEY}/herd/getall?company_filter=1&zone=0`, {
    //     method: "GET",
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Accept": "application/json",
    //         "Authorization": `Bearer ${added_by}`,
    //     },
    // });
    // const result2 = await response2.json();
    // const updatedData = result2.map(item => ({ ...item, Assigned: false }));
    // const updatedRes = { ...res, data: updatedData };
    // for (let i = 0; i < updatedRes.data.length; i++) {
    //     const res2 = updatedRes.data[i].Name;
    //     const res1 = result1.some((v) => v.Name === res2);
    //     updatedRes.data[i].Assigned = res1;

    // }

    // console.log(updatedData);

    const response2 = await fetch(
      `${API_KEY}/herd/getall?company_filter=1&zone=0`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )
    const result2 = await response2.json()

    const updatedData = result2.map(item => {
      const res1 = result1.some(v => v.Name === item.Name)
      return { ...item, Assigned: res1 }
    })

    setData(updatedData)

    setIsLoading(false)
    setHerdProfile(updatedData)

    // -------------------------------------------------------------------------------------------------------

    await fetch(
      `${API_KEY}/user/get-herd-lot-structure?company=10&user=${e.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )
      .then(res => res.json())
      .then(result => {
        setLotStructure(result)
      })

    await fetch(
      `${API_KEY}/user/get-farmer-structure?company=10&user=${e.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )
      .then(res => res.json())
      .then(result => {
        setFarmerStructure(result)
      })

    await fetch(`${API_KEY}/user/getbyid?id=${e.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      }
    })
      .then(res => res.json())
      .then(result => {})

    await fetch(`${API_KEY}/herd/get_by_name?name=`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      }
    })
      .then(res => res.json())
      .then(result => {
        setRouteList(result)
        console.log(result)
      })

    await fetch(
      `${API_KEY}/user/get_feature_matrix_by_userid?company=10&user=${e.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )
      .then(res => res.json())
      .then(result => {
        setMobileList(result)

        const createEnabledIds = result
          .filter(item => item.CanCreate)
          .map(item => item.id)

        setSelectedCreateCheckboxes(createEnabledIds)

        const createEnabledIds1 = result
          .filter(item => item.CanView)
          .map(item => item.id)

        setSelectedViewCheckboxes(createEnabledIds)
      })
  }

  const checkBox = () => {
    for (let i = 0; i < herdProfile.length; i++) {
      const recordExists = herdStructure.some(
        item => item.id.toString() === herdProfile[i].id.toString()
      )
      if (recordExists) {
        herdProfile[i].Assigned = true
      } else {
        herdProfile[i].Assigned = false
      }
    }
  }

  const SearchFn = e => {
    const data = e.target.value
    if (data !== '') {
      const results = userDetails.filter(user => {
        return user.Username.toLowerCase().startsWith(data.toLowerCase())
      })
      setSearchFilter(results)
    } else {
      setSearchFilter(userDetails)
    }
    setSearch(data)
  }

  const setSearch1 = e => {
    const data = e.target.value
    console.log(data)
    if (data !== '') {
      const results = lotProfile.filter(names => {
        return (
          names.Name.toLowerCase().startsWith(data.toLowerCase()) ||
          names.Code.toLowerCase().startsWith(data.toLowerCase())
        )
      })
      const result1 = herdProfile.filter(names => {
        return (
          names.Name.toLowerCase().startsWith(data.toLowerCase()) ||
          names.code.toLowerCase().startsWith(data.toLowerCase())
        )
      })
      setSearchFilterlot(results)
      setSearchFilterherd(result1)
    } else {
      setSearchFilterlot(lotProfile)
      setSearchFilterherd(herdProfile)
    }
    setSearchlot(data)
  }

  const getHerdData = e => {
    fetch(`${API_KEY}/user/get-herd-structure?company=10&user=${e.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      }
    })
      .then(res => res.json())
      .then(result => {
        setHerdStructure(result)
      })
  }

  const Save = async () => {
    if (role == 'herd') {
      setIsLoading(true)
      // const herdIdAssigned = [];
      // const herdIdUnAssign = [];

      // herdProfile.forEach(item => {
      //     if (item.Assigned) {
      //         herdIdAssigned.push(item.id);
      //     } else if (item.Assigned === false) {
      //         herdIdUnAssign.push(item.id);
      //     }
      // });
      await fetch(`${API_KEY}/user/assignment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        },
        body: JSON.stringify({
          farmerOnly: false,
          herdOnly: true,
          herdIdAssigned: herdIdAssigned,
          herdIdUnAssign: herdIdUnAssign,
          lotOnly: false,
          searchTerm: '',
          userId: tableId
        })
      })
        .then(response => {
          const contentLength = response.headers.get('content-length')
          let loaded = 0
          return new Response(
            new ReadableStream({
              start (controller) {
                const reader = response.body.getReader()
                read()
                function read () {
                  reader.read().then(progressEvent => {
                    if (progressEvent.done) {
                      controller.close()
                      return
                    }
                    loaded += progressEvent.value.byteLength
                    const percentageComplete =
                      Math.round((loaded / contentLength) * 100) + '%'
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value)
                    read()
                  })
                }
              }
            })
          )
        })
        .then(res => res.json())
        .then(result => {
          setRouteList(result)
          toast.success('Assignment Successful')
          setIsLoading(false)
          setProgressData('0%')
        })

      setTimeout(async () => {
        const response1 = await fetch(
          `${API_KEY}/user/get-herd-structure?company=10&user=${tableId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${added_by}`
            }
          }
        )

        const result1 = await response1.json()
        if (result1) {
          setHerdStructure(result1)
        }
      }, 2000)
    } else if (role === 'lot') {
      setIsLoading(true)
      fetch(`${API_KEY}/user/assignment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        },
        body: JSON.stringify({
          farmerOnly: false,
          herdId: route,
          herdOnly: false,
          lotIdAssigned: lotAssigned,
          lotIdUnAssign: lotUnAssign,
          lotOnly: true,
          searchTerm: '',
          userId: tableId
        })
      })
        .then(response => {
          const contentLength = response.headers.get('content-length')
          let loaded = 0
          return new Response(
            new ReadableStream({
              start (controller) {
                const reader = response.body.getReader()
                read()
                function read () {
                  reader.read().then(progressEvent => {
                    if (progressEvent.done) {
                      controller.close()
                      return
                    }
                    loaded += progressEvent.value.byteLength
                    const percentageComplete =
                      Math.round((loaded / contentLength) * 100) + '%'
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value)
                    read()
                  })
                }
              }
            })
          )
        })
        .then(res => res.json())
        .then(result => {
          toast.success('Assignment Successful')
          setIsLoading(false)
          setProgressData('0%')
        })

      setTimeout(async () => {
        await fetch(
          `${API_KEY}/user/get-herd-structure?company=10&user=${tableId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${added_by}`
            }
          }
        )
          .then(res => res.json())
          .then(result => {
            setHerdStructure(result)
          })

        await fetch(
          `${API_KEY}/user/get-herd-lot-structure?company=10&user=${tableId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${added_by}`
            }
          }
        )
          .then(res1 => res1.json())
          .then(result => {
            setLotStructure(result)
            console.log(result)
          })
      }, 2000)
    } else if (role === 'farmer') {
      setIsLoading(true)
      fetch(`${API_KEY}/user/assignment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        },
        body: JSON.stringify({
          farmerOnly: true,
          herdId: route,
          herdOnly: false,
          farmerIdAssigned: farmerIdAssigned,
          farmerIdUnAssign: farmerIdUnAssign,
          lotOnly: false,
          searchTerm: '',
          userId: tableId
        })
      })
        .then(response => {
          const contentLength = response.headers.get('content-length')
          let loaded = 0
          return new Response(
            new ReadableStream({
              start (controller) {
                const reader = response.body.getReader()
                read()
                function read () {
                  reader.read().then(progressEvent => {
                    if (progressEvent.done) {
                      controller.close()
                      return
                    }
                    loaded += progressEvent.value.byteLength
                    const percentageComplete =
                      Math.round((loaded / contentLength) * 100) + '%'
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value)
                    read()
                  })
                }
              }
            })
          )
        })
        .then(res => res.json())
        .then(result => {
          toast.success('Assignment Successful')
          setIsLoading(false)
          setProgressData('0%')
        })

      setTimeout(() => {
        fetch(
          `${API_KEY}/user/get-farmer-structure?company=10&user=${userId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${added_by}`
            }
          }
        )
          .then(res => res.json())
          .then(result => {
            setFarmerStructure(result)
          })
      }, 10000)
      // fetch(`${API_KEY}/user/get-herd-structure?company=10&user=${tableId}`, {
      //     method: "GET",
      //     headers: {
      //         "Content-Type": "application/json",
      //         "Accept": "application/json",
      //         "Authorization": `Bearer ${added_by}`,
      //     },
      // })
      //     .then((res) => res.json())
      //     .then((result) => {
      //         setHerdStructure(result);
      //     });

      // await fetch(`${API_KEY}/user/get-herd-lot-structure?company=10&user=${tableId}`, {
      //     method: "GET",
      //     headers: {
      //         "Content-Type": "application/json",
      //         "Accept": "application/json",
      //         "Authorization": `Bearer ${added_by}`,
      //     },
      // })
      //     .then((res1) => res1.json())
      //     .then((result) => {
      //         setLotStructure(result)
      //         console.log(result);
      //     });
    }
  }

  const SaveMobileData = async () => {
    setIsLoading(true)
    const requestBody = mobileList.map(item => ({
      id: item.id,
      CanCreate: selectedCreateCheckboxes.includes(item.id),
      CanView: selectedViewCheckboxes.includes(item.id),
      CanEdit: item.CanEdit,
      CanDelete: item.CanDelete,
      Grouping: item.Grouping,
      Name: item.Name
    }))
    fetch(`${API_KEY}/user/save_feature_matrix_by_userid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ feature: requestBody, user: userId })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start (controller) {
              const reader = response.body.getReader()
              read()
              function read () {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(res => res.json())
      .then(result => {
        setMobileList(result)
        toast.success('Feature list Saved!')
        setIsLoading(false)
        setProgressData('0%')
      })
  }

  // const SaveMobileData = async () => {
  //     const requestBody = mobileList.map(item => ({
  //         id: item.id,
  //         CanCreate: selectedCreateCheckboxes.includes(item.id),
  //         CanView: selectedViewCheckboxes.includes(item.id),
  //         CanEdit: item.CanEdit,
  //         CanDelete: item.CanDelete,
  //         Grouping: item.Grouping,
  //         Name: item.Name,
  //     }));

  //     fetch(`${API_KEY}/user/save_feature_matrix_by_userid`, {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json",
  //             "Accept": "application/json",
  //             "Authorization": `Bearer ${added_by}`,
  //         },
  //         body: JSON.stringify({ feature: requestBody, user: userId })
  //     })
  //         .then(response => response.json())
  //         .then((result) => {

  //             setMobileList(result);
  //             toast.success("Feature list Saved!");
  //             setIsLoading(false);
  //             setProgressData("0%");
  //         });
  // };

  const editDetails = async e => {
    console.log(e)
    setZone(e)
    await fetch(
      `${API_KEY}/staff/get_by_name?name=&vo=${e.g_id}&zone=${
        e.zone.id ? e.zone.id : 0
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )
      .then(res => res.json())
      .then(result => {
        setIsLoading(false)
        setContactPersonList(result)
      })
    fetchData()
    setEditId(e.id)
    setModalShow(true)
    setAppType(e.AppType)
    setUserName(e.Username)
    setQr(e.QRCode)
    setMobile(e.Mobile)
    setZone(e.zone.id)
    setGroup(e['group']['id'])
    setContactPerson(e.staffObj.id)
  }

  const deleteData = async e => {
    setId(e.id)
    setUser(e.Username)
    setDeleteShow(true)
  }

  const delateUser = async () => {
    setDeleteShow(false)
    await fetch(`${API_KEY}/user/delete?id=${id}`, {
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      }
    })
      .then(res => res.json())
      .then(result => {
        setModalShow(false)
        refreshFn()
      })
  }

  const colum = [
    {
      name: 'User Name',
      selector: 'Username',
      cell: c => (
        <>
          <div
            style={{
              paddingLeft: '5px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            {c['Username']}
          </div>
        </>
      ),
      sortable: true
    },
    {
      name: 'Mobile',
      selector: 'Mobile',
      cell: c => (
        <>
          <div
            style={{
              paddingRight: '5px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            {c.Mobile}
          </div>
        </>
      ),
      sortable: true
    },
    {
      name: 'Application Type',
      selector: 'AppType',
      cell: c => (
        <>
          <div
            style={{
              paddingLeft: '5px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            {c.AppType}
          </div>
        </>
      ),
      sortable: true
    },
    {
      name: 'Zone Name',
      selector: 'zoneName',
      cell: c => (
        <>
          <div
            style={{
              paddingLeft: '5px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            {c.zoneName}
          </div>
        </>
      ),
      sortable: true
    },
    {
      name: 'Group',
      selector: 'group.Name',
      cell: c => (
        <>
          <div
            style={{
              paddingLeft: '5px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            {c.group.Name}
          </div>
        </>
      ),
      sortable: true
    },
    {
      name: 'Staff Name',
      selector: 'staffName',
      cell: c => (
        <>
          <div
            style={{
              paddingLeft: '5px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            {c.staffName}
          </div>
        </>
      ),
      sortable: true
    },
    {
      name: 'User Profile',
      cell: e => (
        <>
          <div style={{ margin: '6%' }}>
            <button
              style={{
                backgroundColor: '#43668E',
                color: '#FFFFFF',
                border: 'none',
                width: '110px',
                padding: '5px'
              }}
              onClick={() => {
                Open(e)
              }}
            >
              Open
            </button>
          </div>
        </>
      )
    },
    {
      name: 'Is Suspended',
      selector: 'IsSuspended',
      cell: d => (
        <Switch
          {...label}
          defaultChecked={d.IsSuspended}
          onChange={e => {
            handleChange(e, d)
          }}
        />
      )
    },
    {
      name: 'Actions',
      cell: e => (
        <>
          <img
            src={edit}
            onClick={() => editDetails(e)}
            style={{ width: '15px', margin: '10px' }}
          />
          <img
            src={remove}
            onClick={() => deleteData(e)}
            style={{ width: '15px' }}
          />
        </>
      )
    }
  ]

  const handleSelectAll = () => {
    const updatedData = data.map(item => ({
      ...item,
      Assigned: !isCheckAll
    }))

    setData(updatedData)
    setHerdProfile(updatedData)
    setIsCheckAll(!isCheckAll)

    console.log()
  }

  const handleSelectAll6 = () => {
    const updatedData = data.map(item => ({
      ...item,
      Assigned: !isCheckAll
    }))
    console.log(updatedData)

    setData(updatedData)
    setHerdProfile(updatedData)
    setIsCheckAll(!isCheckAll)

    console.log()
  }

  const handleSelectAll1 = event => {
    const selectAllChecked = event.target.checked
    const updatedLotProfile = lotProfile.map(item => ({
      ...item,
      Assigned: selectAllChecked
    }))

    setLotProfile(updatedLotProfile)

    const updatedLotAssigned = selectAllChecked
      ? updatedLotProfile.map(item => item.id)
      : []

    setLotAssigned(updatedLotAssigned)
    setLotUnAssign([])
  }

  const handleSelectAll4 = event => {
    const selectAllChecked = event.target.checked
    const updatedFarmerProfile = farmerProfile.map(item => ({
      ...item,
      Assigned: selectAllChecked
    }))

    setFarmerProfile(updatedFarmerProfile)

    const updatedFarmerAssigned = selectAllChecked
      ? updatedFarmerProfile.map(item => item.id)
      : []

    setFarmerIdAssigned(updatedFarmerAssigned)
    setFarmerIdUnAssign([])
  }

  const handleSelectAll5 = event => {
    console.log(event)
    const selectAllChecked = event.target.checked
    const updatedFarmerProfile = farmerList.map(item => ({
      ...item,
      Assigned: selectAllChecked
    }))

    setFarmerList(updatedFarmerProfile)

    const updatedFarmerAssigned = selectAllChecked
      ? updatedFarmerProfile.map(item => item.id)
      : []

    setFarmerIdAssigned(updatedFarmerAssigned)
    setFarmerIdUnAssign([])
  }

  const handleSelectAll2 = event => {
    const isChecked = event.target.checked
    setIsCheckAll2(isChecked)
    setSelectedCreateCheckboxes(
      isChecked ? mobileList.map(item => item.id) : []
    )
    setSelectedViewCheckboxes(isChecked ? mobileList.map(item => item.id) : [])
  }
  const handleSelectAll3 = event => {
    const isChecked = event.target.checked
    setIsCheckAll3(isChecked)
    setSelectedViewCheckboxes(isChecked ? mobileList.map(item => item.id) : [])
  }

  herdProfile.forEach(e => {
    herdStructure.forEach(element => {
      if (e.id == element.id) {
        isCheck.push(e.id)
      } else {
        isCheck.filter(item => item !== e.id)
      }
    })
  })

  const handleClick = (checked, id) => {
    const updatedHerdProfile = herdProfile.map(item => {
      if (item.id.toString() === id.toString()) {
        return {
          ...item,
          Assigned: checked
        }
      }
      return item
    })
    setHerdProfile(updatedHerdProfile)
  }

  const handleCheckboxClick = (checked, id) => {
    const currentHerdIdAssigned = [...herdIdAssigned]
    const currentHerdIdUnAssign = [...herdIdUnAssign]
    if (checked) {
      if (!currentHerdIdAssigned.includes(id)) {
        currentHerdIdAssigned.push(id)
      }
      const index = currentHerdIdUnAssign.indexOf(id)
      if (index !== -1) {
        currentHerdIdUnAssign.splice(index, 1)
      }
    } else {
      if (!currentHerdIdUnAssign.includes(id)) {
        currentHerdIdUnAssign.push(id)
      }
      const index = currentHerdIdAssigned.indexOf(id)
      if (index !== -1) {
        currentHerdIdAssigned.splice(index, 1)
      }
    }

    setHerdIdAssigned(currentHerdIdAssigned)
    setHerdIdUnAssign(currentHerdIdUnAssign)

    const updatedData = data.map(item => {
      if (item.id === id) {
        return { ...item, Assigned: checked }
      }
      return item
    })

    setData(updatedData)
    setHerdProfile(updatedData)
  }

  const handleCheckboxClickFarmer = (d, f) => {
    // const currentFarmerIdAssigned = [...farmerIdAssigned];
    // const currentFarmerIdUnAssign = [...farmerIdUnAssign];
    // if (checked) {
    //     if (!currentFarmerIdAssigned.includes(id)) {
    //         currentFarmerIdAssigned.push(id);
    //     }
    //     const index = currentFarmerIdUnAssign.indexOf(id);
    //     if (index !== -1) {
    //         currentFarmerIdUnAssign.splice(index, 1);
    //     }
    // } else {
    //     if (!currentFarmerIdUnAssign.includes(id)) {
    //         currentFarmerIdUnAssign.push(id);
    //     }
    //     const index = currentFarmerIdAssigned.indexOf(id);
    //     if (index !== -1) {
    //         currentFarmerIdAssigned.splice(index, 1);
    //     }
    // }

    // setFarmerIdAssigned(currentFarmerIdAssigned);
    // setFarmerIdUnAssign(currentFarmerIdUnAssign);
    // const updatedData = data.map(item => {
    //     if (item.id === id) {
    //         return { ...item, Assigned: checked };
    //     }
    //     return item;
    // });
    // setData(updatedData);
    // setFarmerProfile(updatedData);

    console.log(d)
    const updatedFarmerProfile = farmerProfile.map(item => {
      if (item.id.toString() === d.id.toString()) {
        return {
          ...item,
          Assigned: f.target.checked
        }
      }
      return item
    })

    console.log(updatedFarmerProfile)
    setFarmerProfile(updatedFarmerProfile)

    const updatedFarmerAssigned = updatedFarmerProfile
      .filter(item => item.Assigned)
      .map(item => item.id)

    const updatedFarmerUnAssign = updatedFarmerProfile
      .filter(item => !item.Assigned)
      .map(item => item.id)

    setFarmerIdAssigned(updatedFarmerAssigned)
    setFarmerIdUnAssign(updatedFarmerUnAssign)
  }

  const handleClick1 = (d, f) => {
    const updatedLotProfile = lotProfile.map(item => {
      if (item.id.toString() === d.id.toString()) {
        return {
          ...item,
          Assigned: f.target.checked
        }
      }
      return item
    })

    setLotProfile(updatedLotProfile)

    const updatedLotAssigned = updatedLotProfile
      .filter(item => item.Assigned)
      .map(item => item.id)

    const updatedLotUnAssign = updatedLotProfile
      .filter(item => !item.Assigned)
      .map(item => item.id)

    setLotAssigned(updatedLotAssigned)
    setLotUnAssign(updatedLotUnAssign)
  }

  const handleClick5 = (d, f) => {
    // console.log(d);
    // console.log(f);
    // console.log(farmerProfile)
    // console.log(farmerList)
    // const updatedFarmerProfile = farmerList.map(item => {
    //     if (item.id.toString() === d.id.toString()) {
    //         return {
    //             ...item,
    //             Assigned: f.target.checked
    //         };
    //     }
    //     return item;
    // });
    // console.log(updatedFarmerProfile)
    // setFarmerList(updatedFarmerProfile);
    // const updatedFarmerAssigned = updatedFarmerProfile
    //     .filter(item => item.Assigned)
    //     .map(item => item.id);
    // const updateFarmerUnAssign = updatedFarmerProfile
    //     .filter(item => !item.Assigned)
    //     .map(item => item.id);
    // setFarmerIdAssigned(updatedFarmerAssigned);
    // setFarmerIdUnAssign(updateFarmerUnAssign);
  }

  // const handleClick2 = (e, f) => {
  //     const id = e.id
  //     const { checked } = f.target;
  //     setMobileList(mobileList.map((o) => {
  //         if (o.id == id) { return { ...id, CanCreate: checked } }
  //         else { return o };
  //     }));
  //     // if (checked) {
  //     //     setIsCheck2((prevIsCheck) => [...prevIsCheck, id]);
  //     // } else {
  //     //     setIsCheck2(isCheck2.filter((item) => item !== id));
  //     // }
  // };

  // const handleClick3 = (e, f) => {
  //     const id = e.id
  //     const { checked } = f.target;
  //     if (checked) {
  //         setIsCheck3((prevIsCheck) => [...prevIsCheck, id]);
  //     } else {
  //         setIsCheck3(isCheck3.filter((item) => item !== id));
  //     }
  // };

  // const handleClick2 = (e, f) => {
  //     const id = e.id;
  //     const { checked } = f.target;

  //     setSelectedCreateCheckboxes(prevSelectedCreateCheckboxes =>
  //         checked
  //             ? [...prevSelectedCreateCheckboxes, id]
  //             : prevSelectedCreateCheckboxes.filter(item => item !== id)
  //     );

  //     setSelectedViewCheckboxes(prevSelectedViewCheckboxes =>
  //         checked
  //             ? [...prevSelectedViewCheckboxes, id]
  //             : prevSelectedViewCheckboxes.filter(item => item !== id)
  //     );

  //     setMobileList(prevMobileList =>
  //         prevMobileList.map(item =>
  //             item.id === id ? { ...item, CanCreate: checked } : item
  //         )
  //     );
  // };

  const handleClick2 = (e, f) => {
    const id = e.id

    const { checked } = f.target

    setSelectedCreateCheckboxes(prevSelectedCreateCheckboxes =>
      checked
        ? [...prevSelectedCreateCheckboxes, id]
        : prevSelectedCreateCheckboxes.filter(item => item !== id)
    )

    setSelectedViewCheckboxes(prevSelectedViewCheckboxes =>
      checked
        ? [...prevSelectedViewCheckboxes, id]
        : prevSelectedViewCheckboxes.filter(item => item !== id)
    )

    const name = userCrudResult.map(v => {
      console.log(v)
    })

    setMobileList(prevMobileList =>
      prevMobileList.map(item =>
        item.id === id ? { ...item, CanCreate: checked } : item
      )
    )
  }

  const handleClick3 = (e, f) => {
    const id = e.id
    const { checked } = f.target

    setSelectedViewCheckboxes(prevSelectedViewCheckboxes =>
      checked
        ? [...prevSelectedViewCheckboxes, id]
        : prevSelectedViewCheckboxes.filter(item => item !== id)
    )

    setMobileList(prevMobileList =>
      prevMobileList.map(item =>
        item.id === id ? { ...item, CanView: checked } : item
      )
    )
  }

  // const handleClick3 = (e, f) => {
  //     const id = e.id;
  //     const { checked } = f.target;

  //     setSelectedViewCheckboxes(prevSelectedViewCheckboxes =>
  //         checked
  //             ? [...prevSelectedViewCheckboxes, id]
  //             : prevSelectedViewCheckboxes.filter(item => item !== id)
  //     );

  //     // Update the CanView property of the corresponding item in mobileList
  //     setMobileList(prevMobileList =>
  //         prevMobileList.map(item =>
  //             item.id === id ? { ...item, CanView: checked } : item
  //         )
  //     );
  // };

  const herdColum = [
    {
      name: 'Code',
      selector: 'code',
      width: '90px',
      sortable: true
    },
    {
      name: 'Name',
      selector: 'Name',
      sortable: true
    },
    {
      name: 'Contact Person',
      selector: 'ContactPerson',
      sortable: true
    },
    {
      name: (
        <>
          <input
            type='checkbox'
            name='Assigned'
            id='Assigned'
            onChange={handleSelectAll}
            checked={isCheckAll}
            // checked={d.Assigned}
          />
          <div className='ms-1'>Assigned ?</div>
        </>
      ),
      cell: d => (
        <div className='d-flex align-items-center'>
          <input
            type='checkbox'
            checked={d.Assigned}
            onChange={e => handleCheckboxClick(e.target.checked, d.id)}
          />
          {/* <div className='ms-2'>{d.Name}</div> */}
        </div>
      ),
      sortable: true
    }
  ]

  const herdColum1 = [
    {
      cell: e => <p>- {e.Name}</p>
    }
  ]
  const herdColum2 = [
    {
      cell: e => (
        <p>
          - {e.lotName}({e.herdName})
        </p>
      )
    }
  ]

  const herdColum3 = [
    {
      cell: e => <p>- {e.Name}</p>
    }
  ]
  const lotColum = [
    {
      name: 'Code',
      selector: 'Code',
      width: '90px',
      sortable: true
    },
    {
      name: 'Name',
      selector: 'Name',
      sortable: true
    },
    {
      name: (
        <>
          <input
            type='checkbox'
            name='selectAll'
            id='selectAll'
            onChange={handleSelectAll1}
            isChecked={isCheckAll1}
          />
          <div className='ms-1'>Assigned ?</div>
        </>
      ),
      cell: d => {
        return (
          <div key={d.id}>
            <input
              type='checkbox'
              id={d.id}
              onChange={f => handleClick1(d, f)}
              checked={d.Assigned}
            />
            {/* {d.Name} */}
          </div>
        )
      },
      sortable: true
    }
  ]

  const farmerColum = [
    {
      name: 'Code',
      selector: 'code',
      width: '90px',
      sortable: true
    },
    {
      name: 'Name',
      selector: 'Name',
      sortable: true
    },
    {
      name: (
        <>
          <input
            type='checkbox'
            name='selectAll'
            id='selectAll'
            onChange={handleSelectAll4}
            isChecked={isCheckAll4}
          />
          <div className='ms-1'>Assigned ?</div>
        </>
      ),
      cell: d => (
        <div className='d-flex align-items-center'>
          <input
            type='checkbox'
            id={d.id}
            onChange={f => handleCheckboxClickFarmer(d, f)}
            checked={d.Assigned}
            // onChange={(e) => handleCheckboxClickFarmer(e.target.checked, d.id)}
          />
          {/* <div className='ms-2'>{d.Name}</div> */}
        </div>
      ),
      sortable: true
    }
  ]

  const mobileColum = [
    {
      name: 'Select All',
      cell: e => (
        <>
          <div>{e.Name.slice(6)}</div>
        </>
      ),
      sortable: true
    },

    {
      name: (
        <>
          <input
            type='checkbox'
            name='selectAll'
            id='selectAll'
            onChange={handleSelectAll2}
            checked={isCheckAll2}
          />
          <div className='ms-1'>CREATE</div>
        </>
      ),
      cell: d => (
        <div>
          <input
            type='checkbox'
            id={d.id}
            onChange={f => handleClick2(d, f)}
            checked={selectedCreateCheckboxes.includes(d.id)}
          />
        </div>
      ),
      sortable: true
    },
    {
      name: (
        <>
          <input
            type='checkbox'
            name='selectAll'
            id='selectAll'
            onChange={handleSelectAll3}
            checked={isCheckAll3}
          />
          <div className='ms-1'>VIEW</div>
        </>
      ),
      cell: d => (
        <div key={d.id}>
          <input
            type='checkbox'
            id={d.id}
            onChange={f => handleClick3(d, f)}
            checked={selectedViewCheckboxes.includes(d.id)}
          />
        </div>
      ),
      sortable: true
    }
  ]

  const SelectedRowFn = rows => {
    let ids = idList.map(i => i).join(', ')
    const updatedRowsList = rows.selectedRows.map(row => {
      if (ids.includes(row.id)) {
        return { ...row, checked: true }
      } else {
        return row
      }
    })
    setRowsList(updatedRowsList)
  }

  const handledExcel = () => {
    const header = []
    const opt = `User Master`
    colum.map(e => {
      header.push(e.name)
    })

    downloadExcel({
      fileName: opt,
      sheet: 'data',
      tablePayload: {
        header,
        body: userDetails
      }
    })
  }

  const handleChange = async (e, d) => {
    setIsSuspended(e.target.checked)

    await fetch(`${UserManagApi.DeactiveApi}/${d.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ is_deleted: e.target.checked })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start (controller) {
              const reader = response.body.getReader()
              read()
              function read () {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(res => res.json())
      .then(result => {
        userGetData()
        setIsLoading(false)
        setProgressData('0%')
      })
  }

  const newPasswordFn = async e => {
    await fetch(`${LoginAPI.changePassword}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ userId: editId, Password: e })
    })
      .then(res => res.json())
      .then(result => {
        setModalShow(false)
        toast.success(result.status)
        refreshFn()
      })
  }

  const backBtn = () => {
    if (profile == true) {
      userGetData()
      setProfile(false)

      // setFilteredCountries(
      //     herdProfile.filter((e) =>
      //         e.name?.toLowerCase().includes(search.toLowerCase())
      //     )
      // );
      // setHeight(divHeight);
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (
            <Col lg='2' className='hideNavbar'>
              {' '}
              <Sidenav />
            </Col>
          )}
          <Col className='header' lg={headerShow ? '10' : '12'}>
            <Container fluid>
              <Row className='pt-1 sub-header align-items-center justify-content-between'>
                <Col
                  md='10'
                  sm='10'
                  xs='10'
                  className='py-2 d-flex align-items-center'
                >
                  <button
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    onClick={handleShow}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <button
                    style={{ border: 'none' }}
                    className='hide_Navbar'
                    onClick={() => setShow(true)}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <h5 className='ms-4 mt-2'>User Management / User Detail</h5>
                </Col>
                <Col
                  md='1'
                  sm='1'
                  xs='10'
                  className='py-2 d-flex align-items-center text-end ms-auto'
                >
                  <BackBtn
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    lable='Back'
                    onClick={backBtn}
                  />
                </Col>
                <Col md='1' sm='1' xs='2' className='text-end profile_dropdown'>
                  <img src={profile1} width={40} />
                  <div className='dropdpwn_Open'>
                    <Row className='text-center align-items-center'>
                      <Col xs='12' className='menuHover mb-3'>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs='12' className='menuHover' onClick={logOut}>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              {details && (
                <>
                  <Row
                    className='align-items-end justify-content-between p-2'
                    id='heightOffsetStock'
                  >
                    {/* <Col sm="2" md="2" xl="1" xs="6" className="mt-2 d-flex align-items-center justify-content-start">
                                            <button className="btn-blue-Animal mt-1 mb-1" onClick={BackPage}>Back</button>
                                        </Col> */}
                    <Col
                      md='3'
                      xs='6'
                      xl='2'
                      className='mt-2 d-flex align-items-end justify-content-start'
                    >
                      <button
                        className='btn-blue-Animal mt-1 mb-1'
                        onClick={Add}
                      >
                        Add User/Member
                      </button>
                    </Col>
                    <Col md='3' xl='2' xs='6' className='ms-auto'>
                      <Select
                        placeholder='Select group'
                        // value={group}
                        onChange={selectedOption =>
                          dataFilter(selectedOption.value)
                        }
                        // value={milkType.find((item) => item === e1['FRQ'])}
                        options={groupDetail.map(item => ({
                          value: item.id,
                          label: item.Name
                        }))}
                      />
                      {/* <Searcable
                                                placeholder="Select group"
                                                options={groupDetail}
                                                onChange={(selectedOption) => dataFilter(selectedOption.value)}
                                                labelKey="Name"
                                                valueKey="id"
                                            /> */}
                    </Col>
                    <Col md='3' xl='2' xs='6' className='align-items-center'>
                      <Input
                        label='Search'
                        value={search}
                        onChange={e => SearchFn(e)}
                      />
                    </Col>
                    <Col
                      sm='2'
                      md='2'
                      xl='1'
                      xs='6'
                      className='mt-2 d-flex align-items-center justify-content-start'
                    >
                      <button
                        className='btn-blue-Animal mt-1 mb-1'
                        onClick={() => handledExcel()}
                      >
                        Export
                      </button>
                    </Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col
                      xs='12'
                      sm='12'
                      className={
                        userDetails.length > 0 ? 'MainTableBorder' : ''
                      }
                    >
                      <Table
                        columns={colum}
                        data={searchFilter ? searchFilter : userDetails}
                        height={`calc(100vh - (${heightOffSet}px + 135px))`}
                        pagination
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Modal
                aria-labelledby='contained-modal-title-vcenter'
                centered
                show={deleteShow}
                onHide={() => setModalShow(false)}
              >
                <Modal.Header style={{ background: '#DFDFDF' }}>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    Confirm Delete ?
                  </Modal.Title>
                  <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                  <Container>Delete {user}?</Container>
                </Modal.Body>
                <Modal.Footer>
                  <Container>
                    <Row className='justify-content-end'>
                      <Col xl='3'>
                        <button
                          className='btn-blue-Animal mt-1'
                          style={{ background: '#43668E' }}
                          onClick={delateUser}
                        >
                          Delete
                        </button>
                      </Col>
                      <Col xl='3'>
                        <button
                          className='btn-blue-Animal mt-1'
                          onClick={() => setDeleteShow(false)}
                          style={{ background: '#E16659' }}
                        >
                          {' '}
                          Cancel{' '}
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Footer>
              </Modal>

              {profile && (
                <>
                  <Row className='mainProfile mt-2 profileTop align-items-center justify-content-start text-center'>
                    {/* <Col className='mainProfile d-flex align-items-center justify-content-start text-center'> */}
                    <Col md='2' xs='4'>
                      <h5>UserName</h5>
                      <h4>{userName}</h4>
                    </Col>
                    <Col md='2' xs='4'>
                      <h5>UserGroup</h5>
                      <h4>{group}</h4>
                    </Col>
                    <Col md='2' xs='4'>
                      <h5>Contact</h5>
                      <h4>{mobile}</h4>
                    </Col>
                    <Col md='2' xs='4'>
                      <h5>Device manufacturer</h5>
                    </Col>
                    <Col md='2' xs='4'>
                      <h5>Device OS</h5>
                    </Col>
                    <Col
                      sm='3'
                      md='2'
                      xs='4'
                      xl='2'
                      xxl='1'
                      className='align-items-center justify-content-start'
                    >
                      <button className='btn-blue-Animal Deactive mb-2'>
                        Deactivate
                      </button>
                    </Col>
                    {/* </Col> */}
                  </Row>
                  <Row className='mt-2 mb-5 align-items-center justify-content-start pe-2 ps-2 mainProfile'>
                    <Row className='herd d-flex align-items-center justify-content-start text-center'>
                      <Col
                        xs='6'
                        md='3'
                        xxl='2'
                        className={index === 1 && 'active'}
                        onClick={() => {
                          setIndex(1)
                        }}
                        id='heightOffsetStock'
                      >
                        <h6>Herd/Lot/Farmer Assigned</h6>
                      </Col>
                      <Col
                        xs='6'
                        md='3'
                        xxl='2'
                        className={index === 2 && 'active'}
                        onClick={() => {
                          setIndex(2)
                        }}
                      >
                        <h6>User CRUD Permission</h6>
                      </Col>
                    </Row>

                    {index === 1 && (
                      <>
                        <Row className='mb-2 align-items-end'>
                          <Col
                            md='4'
                            xl='3'
                            xs='6'
                            className='mt-2 justify-content-center text-center align-items-end'
                          >
                            <RadioGroup
                              row
                              aria-labelledby='demo-row-radio-buttons-group-label'
                              className='justify-content-center'
                              value={role}
                              name='row-radio-buttons-group'
                            >
                              <FormControlLabel
                                value='herd'
                                control={<Radio />}
                                onChange={e => setRole(e.target.value)}
                                label='Herd'
                                className='mt-1'
                              />
                              <FormControlLabel
                                value='lot'
                                control={<Radio />}
                                onChange={e => setRole(e.target.value)}
                                label='Lot'
                                className='mt-1'
                              />
                              <FormControlLabel
                                value='farmer'
                                control={<Radio />}
                                onChange={e => setRole(e.target.value)}
                                label='Farmer'
                                className='mt-1'
                              />
                            </RadioGroup>
                          </Col>
                          {(role === 'herd' || role === 'lot') && (
                            <>
                              <Col
                                md='3'
                                xl='2'
                                xs='6'
                                className='mt-2 justify-content-center text-start align-items-end ms-auto'
                              >
                                <Select
                                  placeholder='Zone'
                                  onChange={selectedOption =>
                                    herd(selectedOption.value)
                                  }
                                  options={zoneList.map(item => ({
                                    value: item.id,
                                    label: item.Name
                                  }))}
                                />
                              </Col>
                            </>
                          )}

                          {role === 'lot' && (
                            <Col
                              xl='2'
                              xs='6'
                              md='3'
                              className='mt-2 justify-content-center text-start align-items-center'
                            >
                              <Select
                                placeholder='Herd/Route'
                                onChange={selectedOption =>
                                  herdRoute(selectedOption.value)
                                }
                                options={routeList.map(item => ({
                                  value: item.id,
                                  label: item.Name
                                }))}
                              />
                            </Col>
                          )}
                          {role === 'farmer' && (
                            <>
                              <Col
                                xl='2'
                                xs='6'
                                md='3'
                                className='mt-2 justify-content-center text-start align-items-center ms-auto'
                              >
                                <Select
                                  placeholder='Herd/Route'
                                  onChange={selectedOption =>
                                    herdRoute(selectedOption.value)
                                  }
                                  options={routeList.map(item => ({
                                    value: item.id,
                                    label: item.Name
                                  }))}
                                />
                              </Col>
                              <Col
                                xl='2'
                                xs='6'
                                md='3'
                                className='mt-2 justify-content-center text-start align-items-center'
                              >
                                <Select
                                  placeholder='Lot'
                                  onChange={selectedOption =>
                                    farmerDataGet(selectedOption.value)
                                  }
                                  options={lotDataList.map(item => ({
                                    value: item.id,
                                    label: item.Name
                                  }))}
                                />
                              </Col>
                            </>
                          )}
                          <Col
                            xl='2'
                            md='3'
                            xs='6'
                            className='mt-2 justify-content-center align-items-center'
                          >
                            <Input
                              label='Search'
                              value={searchlot}
                              onChange={e => setSearch1(e)}
                            />
                          </Col>
                        </Row>

                        {role === 'herd' && (
                          <>
                            <Row className='justify-content-start align-items-start pe-2'>
                              <Col
                                md='3'
                                xs='12'
                                className={
                                  herdStructure.length > 0
                                    ? 'MainTableBorder m-1 mobileTable'
                                    : ''
                                }
                                style={{ width: '31.9%' }}
                              >
                                <div className='mainProfile text-start'>
                                  <Col>
                                    <h6>Assigned Herds</h6>
                                  </Col>
                                  <hr />
                                  <Col>
                                    {/* {herdStructure.map((item) => {
                                                                            return (<p>- {item.Name}</p>)
                                                                        })} */}
                                    <Table
                                      columns={herdColum1}
                                      data={herdStructure}
                                      height={`calc(100vh - (${heightOffSet}px + 0px))`}
                                    />
                                  </Col>
                                </div>
                              </Col>
                              <Col
                                xs='12'
                                sm='8'
                                className={
                                  herdProfile.length > 0
                                    ? 'MainTableBorder m-1 ms-auto'
                                    : ''
                                }
                              >
                                <Row className='justify-content-center align-items-center'>
                                  <div className='mainProfile py-2 d-flex justify-content-center align-items-center '>
                                    <Col>
                                      <h6>Herds to Assign</h6>
                                    </Col>
                                  </div>
                                  <hr />
                                </Row>
                                <Row className='mt-2'>
                                  <Col>
                                    <Table
                                      columns={herdColum}
                                      data={
                                        searchFilterherd
                                          ? searchFilterherd
                                          : herdProfile
                                      }
                                      height={`calc(100vh - (${heightOffSet}px + 380px))`}
                                      pagination
                                      customStyles
                                    />
                                    <Col xl='2' sm='2' className='ms-auto'>
                                      <button
                                        className='btn-blue-Animal mt-1'
                                        style={{ background: '#43668E' }}
                                        onClick={Save}
                                      >
                                        Save
                                      </button>
                                    </Col>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        )}

                        {role === 'lot' && (
                          <>
                            <Row
                              className='justify-content-start align-items-start pe-2'
                              id='heightOffsetStock'
                            >
                              <Col
                                xs='12'
                                sm='3'
                                className={
                                  lotStructure.length > 0
                                    ? 'MainTableBorder m-1 mobileTable'
                                    : ''
                                }
                                style={{ width: '31.9%' }}
                              >
                                <div className='mainProfile text-start'>
                                  <Col>
                                    <h6>Assigned Lots</h6>
                                  </Col>
                                  <hr />
                                  {/* <Col>
                                                                        {lotList.map((item, index) => { return (<p>- {item.lotName}</p>) })}
                                                                    </Col> */}
                                  <Col>
                                    <Table
                                      columns={herdColum2}
                                      data={lotStructure}
                                      height={`calc(100vh - (${heightOffSet}px + 20px))`}
                                    />
                                  </Col>
                                </div>
                              </Col>
                              <Col
                                xs='12'
                                sm='8'
                                className={
                                  lotProfile.length > 0
                                    ? 'MainTableBorder m-1 ms-auto'
                                    : 'overflow-hidden'
                                }
                              >
                                <Row className='justify-content-center align-items-center'>
                                  <div className='mainProfile py-2 d-flex justify-content-center align-items-center'>
                                    <Col>
                                      <h6>Lots to Assign</h6>
                                    </Col>
                                  </div>
                                  <hr className='mt-1' />
                                </Row>
                                <Row className='mt-2'>
                                  <Col>
                                    <Table
                                      columns={lotColum}
                                      data={
                                        searchFilterlot
                                          ? searchFilterlot
                                          : lotProfile
                                      }
                                      height={`calc(100vh - (${heightOffSet}px + 270px))`}
                                      pagination
                                    />
                                    <Col xl='2'>
                                      <button
                                        className='btn-blue-Animal mt-1'
                                        style={{ background: '#43668E' }}
                                        onClick={Save}
                                      >
                                        Save
                                      </button>
                                    </Col>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        )}

                        {role === 'farmer' && (
                          <>
                            <Row className='justify-content-center align-items-start pe-2'>
                              <Col md='3'>
                                <div className='mainProfile text-start'>
                                  <Col>
                                    <h6>Assigned Farmers</h6>
                                  </Col>
                                  <hr />
                                  {/* <Col>
                                                                        {lotList.map((item, index) => { return (<p>- {item.lotName}</p>) })}
                                                                    </Col> */}
                                  <Col>
                                    <Table
                                      columns={herdColum3}
                                      data={farmerStructure}
                                      height={`calc(100vh - (${heightOffSet}px + 400px))`}
                                    />
                                  </Col>
                                </div>
                              </Col>
                              <Col md='9'>
                                <Row className='justify-content-center align-items-center'>
                                  <div className='mainProfile py-2 d-flex justify-content-center align-items-center'>
                                    <Col>
                                      <h6>Farmers to Assign</h6>
                                    </Col>
                                  </div>
                                </Row>
                                <Row className='mt-2'>
                                  <Col>
                                    <Table
                                      columns={farmerColum}
                                      data={farmerProfile}
                                      height={`calc(100vh - (${heightOffSet}px + 400px))`}
                                      pagination
                                    />
                                    <Col xl='2'>
                                      <button
                                        className='btn-blue-Animal mt-1'
                                        style={{ background: '#43668E' }}
                                        onClick={Save}
                                      >
                                        Save
                                      </button>
                                    </Col>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    )}

                    {index === 2 && (
                      <>
                        <Row className='mt-3 herd text-center mt-5 fs-6'>
                          <Col
                            xs='6'
                            xl='2'
                            md='2'
                            xxl='1'
                            className={subIndex === 1 && 'active'}
                            onClick={() => {
                              setSubIndex(1)
                            }}
                          >
                            <h6>Dashboard</h6>
                          </Col>
                          <Col
                            xs='6'
                            xl='2'
                            md='2'
                            xxl='1'
                            className={subIndex === 2 && 'active'}
                            onClick={() => {
                              setSubIndex(2)
                            }}
                          >
                            <h6>Mobile</h6>
                          </Col>
                        </Row>
                        {subIndex === 1 && (
                          <>
                            <Row className='justify-content-end mt-4'>
                              <Col md='2' xs='3'>
                                CREATE
                              </Col>
                              <Col md='2' xs='3'>
                                View
                              </Col>
                              <Col md='2' xs='3'>
                                Edit
                              </Col>
                              <Col md='2' xs='3'>
                                Delete
                              </Col>
                            </Row>
                            <Row>
                              <Col md='2' xs='3' className='mt-3'>
                                <button
                                  className='btn-blue-Animal mt-1'
                                  style={{ background: '#43668E' }}
                                  onClick={Save}
                                >
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </>
                        )}
                        {subIndex === 2 && (
                          <>
                            <Row className='mt-2 justify-content-center text-center'>
                              <Col className='mt-4'>
                                <Table
                                  columns={mobileColum}
                                  data={mobileList}
                                  height={`calc(100vh - (${heightOffSet}px + 400px))`}
                                  pagination
                                />
                              </Col>
                            </Row>
                            <Row className='mt-2 justify-content-start'>
                              <Col xl='2' sm='2'>
                                <button
                                  className='btn-blue-Animal mt-1'
                                  style={{ background: '#43668E' }}
                                  onClick={SaveMobileData}
                                >
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                  </Row>
                </>
              )}
            </Container>
          </Col>
        </Row>
      </Container>

      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header style={{ background: '#DFDFDF' }}>
          <Modal.Title id='contained-modal-title-vcenter'>
            {editId ? 'Update User' : 'Add new User'}
          </Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xl='6'>
                <TextField
                  label='Username'
                  id='standard-basic'
                  value={userName}
                  onChange={e => {
                    setUserName(e.target.value)
                  }}
                  data={userDetails}
                  fullWidth
                  variant='standard'
                />
              </Col>
              <Col xl='6'>
                <TextField
                  label='Mobile'
                  value={mobile}
                  onChange={e => {
                    setMobile(e.target.value)
                  }}
                  id='standard-basic'
                  data={userDetails}
                  fullWidth
                  variant='standard'
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xl='6'>
                {/* <Select
                                    placeholder="Application Type"
                                    onChange={(selectedOption) => setAppType(selectedOption.value)} // Make sure setAppType is defined and working correctly
                                    options={applicationTypeList.map((item) => ({ value: item.id, label: item.name }))} // Check that applicationTypeList is populated correctly
                                    value={{
                                        value: appType ? appType : "Application Type", // Verify that appType is defined and has a valid value
                                        label: applicationTypeList.find(item => item.id == appType)?.name // Ensure that the .find() method works as expected   
                                            ? applicationTypeList.find(item => item.id == appType)?.name
                                            : "Application Type"
                                    }}
                                /> */}
                <Searcable
                  placeholder='Application Type'
                  onChange={selectedOption => setAppType(selectedOption.value)}
                  options={applicationTypeList}
                  value={{
                    value: appType ? appType : 'Application Type',
                    label: applicationTypeList.find(item => item.id === appType)
                      ?.name
                      ? applicationTypeList.find(item => item.id === appType)
                          ?.name
                      : 'Application Type'
                  }}
                  valueKey='id'
                  labelKey='name'
                />
              </Col>
              <Col xl='6'>
                {/* <Select
                                    placeholder="Enter Through QR Code"
                                    onChange={(selectedOption) => setQr(selectedOption.value)}
                                    // value={applicationTypeList.find((item) => item.id === qr)}
                                    options={[
                                        { value: '1', label: 'Yes' },
                                        { value: '2', label: 'No' }
                                    ]}
                                /> */}
                <Searcable
                  placeholder='Enter Through QR Code'
                  onChange={selectedOption => setQr(selectedOption.value)}
                  options={qr}
                  labelKey='label'
                  // valueKey="value"
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xl='6'>
                <Select
                  placeholder='Zone'
                  onChange={selectedOption => selctZone(selectedOption.value)}
                  options={zoneList.map(item => ({
                    value: item.id,
                    label: item.Name
                  }))}
                  value={{
                    value: zone ? zone : 'Application Type',
                    label: zoneList.find(item => item.id == zone)?.Name
                      ? zoneList.find(item => item.id == zone)?.Name
                      : 'Zone'
                  }}
                />

                {/* <Searcable  
                                    placeholder="Zone"
                                    onChange={(selectedOption) => selctZone(selectedOption.value)}
                                    value={{ value: zone ? zone : "Application Type", label: zoneList.find(item => item.id == zone)?.Name ? zoneList.find(item => item.id == zone)?.Name : "Zone" }}
                                    options={zoneList}
                                    labelKey="Name"
                                    valueKey="id"

                                /> */}
              </Col>
              <Col xl='6'>
                {/* <Select
                                    placeholder="User Group"
                                    onChange={(selectedOption) => selctGroup(selectedOption.value)}
                                    options={groupList.map((item) => ({ value: item.id, label: item.Name }))}
                                    value={{ value: group, label: groupList.find(item => item.id == group)?.Name ? groupList.find(item => item.id == group)?.Name : "User Group" }}
                                /> */}
                <Searcable
                  placeholder='User Group'
                  onChange={selectedOption => selctGroup(selectedOption.value)}
                  options={groupList}
                  value={{
                    value: group,
                    label: groupList.find(item => item.id == group)?.Name
                      ? groupList.find(item => item.id == group)?.Name
                      : 'User Group'
                  }}
                  labelKey='Name'
                  valueKey='id'
                />
              </Col>
            </Row>
            <Row className='mt-3 align-items-end'>
              <Col xl='6'>
                {/* <Select
                                    placeholder="Contact Person (Staff)"
                                    onChange={(selectedOption) => setContactPerson(selectedOption.value)}
                                    options={contactPersonList.map((item) => ({ value: item.id, label: `${item.Name} | ID:${item.id}` }))}
                                    value={{ value: contactPerson, label: contactPersonList.find(item => item.id == contactPerson)?.Name ? contactPersonList.find(item => item.id == contactPerson)?.Name : "Contact Person (Staff)" }}
                                /> */}
                <Searcable
                  placeholder='Contact Person (Staff)'
                  onChange={selectedOption =>
                    setContactPerson(selectedOption.value)
                  }
                  options={contactPersonList.map(item => ({
                    value: item.id,
                    label: `${item.code} - ${item.Name}`
                  }))}
                  value={{
                    value: contactPerson,
                    label: contactPerson
                      ? `${
                          contactPersonList.find(
                            item => item.id == contactPerson
                          )?.code
                        } - ${
                          contactPersonList.find(
                            item => item.id == contactPerson
                          )?.Name || ''
                        }`
                      : 'Contact Person (Staff)'
                  }}
                  labelKey='label'
                  valueKey='value'
                />
              </Col>
              <Col xl='6'>
                <TextField
                  label='Old Password'
                  disabled
                  value={oldPassword}
                  onChange={e => {
                    setOldPassword(e.target.value)
                  }}
                  id='standard-basic'
                  data={userDetails}
                  fullWidth
                  variant='standard'
                />
              </Col>
              <Col xl='6' className='mt-3'>
                <TextField
                  label='New Password'
                  value={newPassword}
                  onChange={e => {
                    setNewPassword(e.target.value)
                  }}
                  id='standard-basic'
                  data={userDetails}
                  fullWidth
                  variant='standard'
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className='justify-content-between'>
              <Col xl='2'>
                <button
                  className='btn-blue-Animal mt-1'
                  style={{ background: '#43668E' }}
                  onClick={editId ? UpdateData : e => AddData(e)}
                >
                  {editId ? 'Update' : 'Save'}
                </button>
              </Col>
              <Col xl='2'>
                <button
                  className='btn-blue-Animal mt-1'
                  onClick={() => setModalShow(false)}
                  style={{ background: '#E16659' }}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      {show && (
        <Offcanvas className='hide_Navbar' show={show} onHide={handleClose}>
          <Container>
            <Row>
              <Col>
                <Sidenav />
              </Col>
            </Row>
          </Container>
        </Offcanvas>
      )}

      {isLoading ? (
        <div className='circleText'>
          <div className='progressText'>{progressData}</div>
          <div className=''>
            <span className='loader-circle-13'></span>
          </div>
        </div>
      ) : (
        false
      )}

      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        style={{ fontSize: '14px' }}
      />
    </>
  )
}
export default Userdetails
