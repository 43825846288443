import React, { useEffect } from 'react'
import { useState } from 'react';
import { Col, Container, Modal, Offcanvas, Row } from 'react-bootstrap';
import Sidenav from '../Componet/Sidenav/Sidenav';
import menu from '../Photo/menu.svg'
import profile from '../Photo/profile.svg';
import './Doctortrack.css'
import { FaUserMd } from "react-icons/fa";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../constant/common";
import { API_URLS, serviceUrl } from "../API/Api";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
import Input from '../Componet/InputFild/Input';
import { useNavigate } from 'react-router-dom';
import Searcable from '../Componet/Dropdown/Searcable';
const { DoctorApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

const Doctortrack = () => {

    async function getZone(credentials) {
        return fetch(DoctorApi.getZone, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function getUser(credentials) {
        return fetch(DoctorApi.getUser, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function getData(credentials) {
        return fetch(DoctorApi.getData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getTrackingData(credentials) {
        return fetch(DoctorApi.getDoctorTrackData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function filterUser(credentials) {
        return fetch(DoctorApi.getDoctorTrackData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function userListdata(credentials) {
        return fetch(DoctorApi.filterbyUser, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const selectedToDate = new Date().toISOString().slice(0, 10);
    const [center, setCenter] = useState("");
    const [ait, setAit] = useState("");
    const [sort, setSort] = useState("")
    const [order, setOrder] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const [pending, setPending] = useState("")
    const [complated, setComplated] = useState("")
    const [cancelled, setCancelled] = useState("")
    const [reallocated, setReallocated] = useState("")
    const [visitId, setVisitId] = useState("")
    const [centerName, setCenterName] = useState("")
    const [reqDate, setReqDate] = useState("")
    const [reqTime, setReqTime] = useState("")
    const [visitDate, setVisitDate] = useState("")
    const [visitTime, setVisitTime] = useState("")
    const [idNo, setIdNo] = useState("")
    const [cost, setCost] = useState("")
    const [diseases, setDiseases] = useState("")
    const [farmerName, setFarmerName] = useState("")
    const [farmerCode, setFarmerCode] = useState("")
    const [sapCode, setSapCode] = useState("")
    const [mobileNo, setMobileNo] = useState("")
    const [dcsName, setDcsName] = useState("")
    const [dcsCode, setDcsCode] = useState("")
    const [voName, setVoName] = useState("")
    const [complaint, setComplaint] = useState("")
    const [diagnosis, setDiagnosis] = useState("")
    const [medicine, setMedicine] = useState("")
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }


    const [date, setDate] = useState(selectedToDate);

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const [com, setCom] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [doctorList, setDoctorList] = useState([])
    const [centerList, setCenterList] = useState([]);
    const [aitList, setAitList] = useState([]);
    const [sortList, setSortList] = useState([])
    const [orderList, setOrderList] = useState([])
    const [trackingList, setTrackingList] = useState([])
    const [countList, setCountList] = useState([])
    const [visitList, setVisitList] = useState([])
    const [userList, setUserList] = useState([])

    const SortbYData = [
        { value: 'Request Time' },
        { value: 'Complete Time' },
        { value: 'Time Interval' },
        { value: 'Visit ID' },
        { value: 'Visit status' },
        { value: 'Place status' },
        { value: 'Zone status' },



    ];
    const SortOrder = [
        { value: 'ASC' },
        { value: 'DESC' },
    ]

    const getDoctorData = async () => {
        const res = await getZone()
        const res1 = await getUser()
        const res2 = await userListdata()

        if (res) { setCenterList(res); }
        if (res1) { setAitList(res1) }
        if (res2) { setUserList(res2) }
    }


    const selectCenter = (e) => {
        console.log(e);
        const id = e.value
        const Name = e.label
        setCenter(Name)
        fatchData(id)
    }
    const selectUser = (e) => {

        setAit(e.value)
        userData(e)
    }

    const fatchData = async (e) => {
        setIsLoading(true)
        fetch(`${API_KEY}/tracking?datestr=${date}&user_id=201&zone_id=${e ? e : 0}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => {
                setDoctorList(result["paravetList"]);
                setPending(result["summaryCount"][0]["currentCount"]);
                setComplated(result["summaryCount"][1]["currentCount"]);
                setCancelled(result["summaryCount"][2]["currentCount"]);
                setReallocated(result["summaryCount"][3]["currentCount"]);
                setIsLoading(false);
                setProgressData("0%");
            })

    }

    const userData = async (e) => {
        setIsLoading(true)
        fetch(`${DoctorApi.filterbyUser}?username=${e}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => {
                setAitList(result)
                fatchData()
                setIsLoading(false);
                setProgressData("0%");
            })
    }

    const modalData = async (e) => {
        console.log(e)
        setModalShow(true)
        setIsLoading(true)
        fetch(`${API_KEY}/health/visit/details/${e.VisitID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => {
                setIsLoading(false);
                setProgressData("0%");
                setVisitId(result["Visit ID"])
                setCenterName(result["Center Name"])
                setReqDate(result["RequestDate"])
                setReqTime(result["RequestTime"])
                setVisitDate(result["visitDate"])
                setVisitTime(result["VisitTime"])
                setIdNo(result["IDNo"])
                setDiseases(result["Diseases"])
                setCost(result["Cost"])
                setFarmerName(result["Farmer Name"])
                setFarmerCode(result["Farmer Code"])
                setSapCode(result["SAP Code"])
                setMobileNo(result["Mobile No"])
                setDcsName(result["DCSName"])
                setDcsCode(result["DCSCode"])
                setVoName(result["VO Name"])
                setComplaint(result["Complaint"])
                setDiagnosis(result["Diagnosis"])
                setMedicine(result["Medicine Given"])
            })
    }
    useEffect(() => {
        getDoctorData()
        fatchData()

    }, [])

    // doctorList.forEach(element => {
    //     if (element["Visitstatus"] === "C") {

    //     }
    // });
    const filteredData = doctorList.filter((item, index, self) => {
        return self.findIndex(i => i.UID === item.UID) === index;
    });



    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Dudhsagar / DoctorTracking</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end p-2" id='heightOffsetStock'>
                                <Col xl="6" md="5" className='d-flex'>
                                    <button className="btn-blue-Animal pendingBtn me-2">Pending: {pending}</button>
                                    <button className="btn-blue-Animal complateBtn me-2">Completed: {complated}</button>
                                    <button className="btn-blue-Animal cancelBtn me-2">Cancelled: {cancelled}</button>
                                    <button className="btn-blue-Animal realloactBtn me-2">Reallocated: {reallocated}</button>
                                </Col>
                                <Col xxl="2" xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Center</InputLabel>
                                        <Select value={center} onChange={(e) => { selectCenter(e.target.value) }}>
                                            {centerList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Center"
                                            options={centerList}
                                            onChange={(selectedOption) => selectCenter(selectedOption)}
                                            // value={center}
                                            labelKey="Name"
                                            valueKey="id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs="6" sm="3" md="2" >
                                    <sub className="mb-0 pb-0">Date</sub>
                                    <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                </Col>
                                <Col xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>User</InputLabel>
                                        <Select value={ait} onChange={e => selectUser(e.target.value)}>
                                            {userList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.username}>
                                                    {e.username}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="User"
                                            options={userList}
                                            onChange={(selectedOption) => selectUser(selectedOption.value)}
                                            value={ait}
                                            labelKey="username"
                                            valueKey="username"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs="6" sm="2" md="2" className='justify-content-end ms-auto mt-2'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Sort By</InputLabel>
                                        <Select value={sort} onChange={e => setSort(e.target.value)}>
                                            <MenuItem className="drop_value" value="Request Time">Request Time</MenuItem>
                                            <MenuItem className="drop_value" value="Complete Time">Complete Time</MenuItem>
                                            <MenuItem className="drop_value" value="Time Interval">Time Interval</MenuItem>
                                            <MenuItem className="drop_value" value="Visit ID">Visit ID</MenuItem>
                                            <MenuItem className="drop_value" value="Visit status">Visit status</MenuItem>
                                            <MenuItem className="drop_value" value="Place">Place</MenuItem>
                                            <MenuItem className="drop_value" value="Zone">Zone</MenuItem>
                                        </Select> */}
                                        <Searcable
                                            placeholder="Sort By"
                                            onChange={(selectedOption) => setSort(selectedOption.value)}
                                            options={SortbYData}
                                            labelKey="value"
                                            valueKey="value"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Sort Order</InputLabel>
                                        <Select value={order} onChange={e => setOrder(e.target.value)}>
                                            <MenuItem className="drop_value" value="ASC">ASC</MenuItem>
                                            <MenuItem className="drop_value" value="DESC">DESC</MenuItem>
                                        </Select> */}
                                        <Searcable
                                            placeholder="Sort  Order"
                                            onChange={(selectedOption) => setOrder(selectedOption.value)}
                                            options={SortOrder}
                                            labelKey="value"
                                            valueKey="value"
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className='align-items-center'>
                                {
                                    filteredData.map((e1) => {
                                        let T = doctorList.filter((e) => e.UID === e1.UID).length;
                                        let C = doctorList.filter((e) => e.UID === e1.UID && e.Visitstatus === "C").length;
                                        let P = doctorList.filter((e) => e.UID === e1.UID && e.Visitstatus === "P").length;
                                        let R = doctorList.filter((e) => e.UID === e1.UID && e.Visitstatus === "R").length;
                                        let N = doctorList.filter((e) => e.UID === e1.UID && e.Visitstatus === "N").length;

                                        return (
                                            <Col xxl="12" className='backgroundBG p-2 d-flex align-items-center' key={e1.UID}>
                                                <>
                                                    <Col xl="3" style={{ width: "210px" }}>
                                                        <h5>{e1.UID}</h5>
                                                        <p>Visited: T:{T} C:{C} N:0 R:{R} P:{P}</p>
                                                        <p>Avg Time Int: N:1.9 E:0 L:0</p>
                                                        <p>Zone:{e1.zone}</p>
                                                        <p>Travelled: 0.00 Km</p>
                                                    </Col>
                                                    <div className='d-flex subProfile text-center'>
                                                        {
                                                            doctorList.map((e) => {
                                                                if (e.UID === e1.UID) {
                                                                    console.log(e);
                                                                    return (
                                                                        <Col key={e.VisitID} className='icon' onClick={() => modalData(e)}>
                                                                            <p className='dName'>{e.name}</p>
                                                                            <p className={e["Visitstatus"] == "C" ? "dCom" : "dVisit"}>VisitID:{e.VisitID}</p>
                                                                            <p className={e["Visitstatus"] == "C" ? "dCom" : "dVisit"}>RT:{e.RequestDate.slice(10)}</p>
                                                                            <FaUserMd className='mt-2 mb-2 text-center' color='#43668e' />
                                                                            <p className='ct'>CT:</p>
                                                                            <p className={e["Visitstatus"] == "C" ? "dComBold" : "dDatatype"}>{e.DataType}</p>
                                                                        </Col >
                                                                    );
                                                                }
                                                                return null;
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            </Col>
                                        );
                                    })
                                }
                            </Row>

                        </Container>
                    </Col>
                </Row>
            </Container >

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-center justify-content-start">
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Visit Id" type="text" value={visitId} onChange={(e) => setVisitId(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Center Name" type="text" value={centerName} onChange={(e) => setCenterName(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Request Date" type="text" value={reqDate} onChange={(e) => setReqDate(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Request Time" type="text" value={reqTime} onChange={(e) => setReqTime(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2' >
                                <TextField id="standard-basic" label="Visit Date" type="text" value={visitDate} onChange={(e) => setVisitDate(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Visit Time" type="text" value={visitTime} onChange={(e) => setVisitTime(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Id No" type="text" value={idNo} onChange={(e) => setIdNo(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Diseases" type="text" value={diseases} onChange={(e) => setDiseases(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Cost" type="text" value={cost} onChange={(e) => setCost(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Farmer Name" type="text" value={farmerName} onChange={(e) => setFarmerName(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Farmer Code" type="text" value={farmerCode} onChange={(e) => setFarmerCode(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="SAP Code" type="text" value={sapCode} onChange={(e) => setSapCode(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Mobile No" type="text" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="DCS Name" type="text" value={dcsName} onChange={(e) => setDcsName(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="DCS Code" type="text" value={dcsCode} onChange={(e) => setDcsCode(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Vo Name" type="text" value={voName} onChange={(e) => setVoName(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Complaint" type="text" value={complaint} onChange={(e) => setComplaint(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="6" className='mb-2'>
                                <TextField id="standard-basic" label="Diagnosis" type="text" value={diagnosis} onChange={(e) => setDiagnosis(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="12" className='mb-2'>
                                <TextField id="standard-basic" label="Medicine Given" type="text" value={medicine} onChange={(e) => setMedicine(e.target.value)} fullWidth variant="standard" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3" md="2"><button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>
            }

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
}

export default Doctortrack