import React, { useState } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { serviceUrl } from '../../API/Api';


const MedicineDistribution = () => {

    const API_KEY = serviceUrl;

    const [center, setCenter] = useState("");
    const [ait, setAit] = useState("");
    const [date, setDate] = useState("");

    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [medicineList, setMedicineList] = useState([]);
    const [centerList, setCenterList] = useState([]);
    const [aitList, setAitList] = useState([]);

    const colum = [
        {
            name: "TagId",
            selector: "",
            sortable: true
        },
        {
            name: "Date of Vaccination",
            selector: "",
            sortable: true
        },
        {
            name: "Batch No",
            selector: "",
            sortable: true
        },
        {
            name: "Dose",
            selector: "",
            sortable: true
        },
        {
            name: "Vaccine Name",
            selector: "",
            sortable: true
        },
        {
            name: "Vaccine Type",
            selector: "",
            sortable: true
        },
        {
            name: "Medicine Route",
            selector: "",
            sortable: true
        },
        {
            name: "Done By",
            selector: "",
            sortable: true
        },
        {
            name: "Done By",
            selector: "",
            sortable: true
        },
    ]
    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Medicine / Medicine Distribution</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            {/* <Row className="align-items-center justify-content-between p-2">
                                <Col className='d-flex justify-content-start align-items-end'>
                                    <Col md="2" sm="2" xs="2" className='justify-content-start'>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Center</InputLabel>
                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={center} onChange={(e) => { setCenter(e.target.value) }}>
                                                {centerList.map((e) => (
                                                    <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                        {e.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col md="2" sm="2" xs="2" className='justify-content-start'>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>AIT</InputLabel>
                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={ait} onChange={(e) => { setAit(e.target.value) }}>
                                                {aitList.map((e) => (
                                                    <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                        {e.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col md="2" sm="2" xs="2">
                                        <div>
                                            <sub className="mb-0 pb-0">From Date</sub>
                                            <input type="date" className="dateSelect_bg" id="date-1" />
                                        </div>
                                    </Col>
                                </Col>
                                <Col xs="3" sm="2 m-auto" md="2" className="mt-2 d-flex align-items-center justify-content-end">
                                    <button className="btn-blue-Animal mt-1 mb-1">View</button>
                                </Col>
                            </Row> */}

                            {/* <Row className="mt-4">
                                <Col>
                                    <Table columns={colum} data={medicineList} height="70vh" pagination />
                                </Col>
                            </Row> */}

                        </Container>
                    </Col>
                </Row>
            </Container>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
        </>
    )
}
export default MedicineDistribution    
