import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import {
  Col,
  Container,
  Row,
  Offcanvas,
  Accordion,
  Modal,
} from "react-bootstrap";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";

import Table from "../../Componet/DataTable/Table";
import remove from "../../Photo/master/remove.svg";
import edit from "../../Photo/master/edit.svg";
// import closeButton from "../../Photo/milk-union/close button.svg";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";

import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import Searcable from "../../Componet/Dropdown/Searcable";
import BackBtn from "../../Componet/Button/BackBtn";
import { downloadExcel } from 'react-export-table-to-excel';
const { Herd, ZoneApi, CC, visitCancellation } = API_URLS;


export default function RouteHerd() {
  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

  async function addData(credentials) {
    return fetch(Herd.AddDetails, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json());
  }

  async function getAllHerd(credentials) {
    return fetch(visitCancellation.GetFilterUser, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json());
  }

  const API_KEY = serviceUrl;
  // const API_KEY = "https://hais.hap.in";
  const [breedList, setBreedList] = useState([]);

  const [show, setShow] = useState(false);
  const [headerShow, setHeaderShow] = useState(true);
  const handleClose = () => { setShow(false); };
  const handleShow = () => { setHeaderShow(!headerShow); }


  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [manager, setManager] = useState("0");
  const [search, setSearch] = useState("")
  const [user, setUser] = useState("")
  const [searchFilter, setSearchFilter] = useState("");
  const [progressData, setProgressData] = useState("0%");

  const [extensionOfficerStaff, setExtensionOfficerStaff] = useState("0");
  const [exOfficerStaffId, setExOfficerStaffId] = useState("");
  const [exOfficerStaffName, setExOfficerStaffName] = useState("");
  const [deleteId, setDeleteId] = useState()

  const [zone, setZone] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [zoneName, setZoneName] = useState("");

  const [cc, setCc] = useState("");
  const [ccId, setCcId] = useState("");
  const [ccName, setCcName] = useState("");
  const [editId, setEditId] = useState("");

  const [heightOffSet, setHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [Routelist, setRoutelist] = useState([]);
  const [ZoneList, setZoneList] = useState([]);
  const [CcList, setCcList] = useState([]);
  const [isSuspended, setIsSuspended] = useState(false)

  const [editToggle, setEditToggle] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false)
  const navigate = useNavigate()
  const logOut = () => { navigate("/") }

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const fetchData = async () => {
    await fetch(`${visitCancellation.GetFilterUser}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
    }
    )
      .then((res) => res.json())
      .then((result) => {
        setRoutelist(result);
      });

    //Zone:

    await fetch(`${ZoneApi.GetZoneByName}?name=`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${added_by}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setZoneList(result);
      });

    //Cc:

    await fetch(`${CC.GetCcByName}?name=`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCcList(result);
      });
  };

  const herdGetData = async () => {
    setIsLoading(true)
    await fetch(visitCancellation.GetFilterUser, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
    })
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) {
                      controller.close();
                      return;
                    }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      })
      .then((res) => res.json())
      .then((result) => { setIsLoading(false); setRoutelist(result); setProgressData("0%"); })
    // const res = await getData();
    // if (res) {
    //   setRoutelist(res.data);
    //   setIsLoading(false)
    // }
  }

  // const getAllData = async () => {
  //   const res = await getAllHerd()
  //   if (res) { console.log(res) }
  // }

  useEffect(() => {
    fetchData();
    herdGetData();
    let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
    setHeight(divHeight);
  }, []);

  const deleteDetails = (id) => {
    // setUser(e.Name)
    fetch(`${API_KEY}/herd/delete?id=` + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        herdGetData();
        setDeleteShow(false)
      });

  };

  const setSelectedZone = (i) => {
    ZoneList.map(e => {
      if (e.Name == i.label) {
        setZone(e)
        console.log(e);
      }
    })
  }

  const AddData = async () => {
    const res = await addData({ id: 0, code, cc: cc, name, company: 1, isSuspended, lat: parseInt(latitude) ? parseInt(latitude) : 0, lng: parseInt(longitude) ? parseInt(longitude) : 0, zone: zone.label, manager: manager ? manager : "0", extensionOfficer: extensionOfficerStaff ? extensionOfficerStaff : "0", user: "201" });
    if (res) {
      herdGetData()
      setModalShow(false)
      toast.success("Herd add successfull")
    }
  }

  const Add = () => {
    setModalShow(true)
    setEditId("")
    setCode("")
    setName("")
    setLatitude("")
    setLongitude("")
    setManager("")
    setExtensionOfficerStaff("")
    setZone("")
    setCc("")
    setIsSuspended(false)
  }

  const UpdateData = async () => {
    await fetch(`${API_KEY}/herd/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify({
        cc: cc ? cc.value.toString() : null,
        code: code,
        extensionOfficer: extensionOfficerStaff || null,
        id: editId,
        isSuspended: isSuspended,
        lat: latitude,
        lng: longitude,
        manager: manager || null,
        name: name,
        zone: zone ? zone.value.toString() : null,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        fetchData();
        herdGetData();
        setModalShow(false);

      });

    setEditId("")
  };

  const handleChange = async (e, d) => {
    setIsLoading(true)
    setIsSuspended(e.target.checked)
    await fetch(`${Herd.SuspendApi}?id=${d.id}&deactivate=${e.target.checked}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
    })
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) {
                      controller.close();
                      return;
                    }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      })
      .then((res) => res.json())
      .then((result) => { toast.success("Success suspended status changed. "); setIsLoading(false); setProgressData("0%"); })
  }


  const colum = [
    {
      name: "Code",
      selector: "code",
      cell: (c) => (
        <>
          <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{c["code"]}</div>
        </>
      ),
      sortable: true
    },
    {
      name: "Name",
      selector: "Name",
      cell: (c) => (
        <>
          <div style={{ paddingLeft: "5px", width: "100%", display: "flex", justifyContent: "flex-start" }}>{c["Name"]}</div>
        </>
      ),
      sortable: true
    },
    {
      name: "CC",
      selector: "cc",
      cell: (e) => (<div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{e.cc !== null ? e.cc : ""}</div>),
      sortable: true
    },
    {
      name: "Zone",
      selector: "zone",
      cell: (e) => (<div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{e.zone !== null ? e.zone : ""}</div>),
      sortable: true
    },
    {
      name: "Manager",
      selector: "managerStaff",
      cell: (e) => (<div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{e.managerStaff !== null ? e.managerStaff.Name : ""}</div>),
      sortable: true
    },
    {
      name: "Extension Officer",
      selector: "extensionOfficerStaff",
      cell: (e) => (<>{<div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{e.extensionOfficerStaff !== null ? e.extensionOfficerStaff.Name : ""}</div>}</>),
      sortable: true
    },
    {
      name: "Latitude",
      selector: "Lat",
      cell: (e) => (<div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{e.Lat !== null ? e.Lat : ""}</div>),
      sortable: true
    },
    {
      name: "Longitude",
      selector: "Lng",
      cell: (e) => (<div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{e.Lng !== null ? e.Lng : ""}</div>),
      sortable: true
    },
    {
      name: "Is Suspended",
      selector: "IsSuspended",
      cell: (d) => <Switch {...label} defaultChecked={d.IsSuspended} onChange={(e) => { handleChange(e, d) }} />,
    },
    {
      name: "Actions",
      cell: (e) => (
        <>
          <img
            src={edit}
            onClick={() => editproduct(e)}
            style={{ width: "15px", margin: "10px" }}
          />
          <img
            src={remove}
            onClick={() => { setDeleteShow(true); setDeleteId(e.id); setUser(e.Name); }}
            style={{ width: "15px" }}
          />
        </>
      ),
    },
  ];

  const handledExcel = () => {
    // Define the keys you want to export
    const keysToExport = ["id", "code", "Name", "cc", "zone", "managerStaff", "extensionOfficerStaff", "Lat", "Lng", "Mobile", "staffMobile", "IsSuspended"];

    // Filter the zoneList array to include only the required keys
    const filteredData = Routelist.map(item => {
      const filteredItem = {};
      keysToExport.forEach(key => {
        // Convert boolean to string if the key is "IsSuspended"
        filteredItem[key] = key === "IsSuspended" ? String(item[key]) : item[key];
      });
      return filteredItem;
    });

    // Prepare the header row
    const header = keysToExport.map(key => {
      const column = colum.find(col => col.selector === key);
      return column ? column.name : key;
    });

    // Download Excel
    downloadExcel({
      fileName: "Zone Sub Center",
      sheet: "data",
      tablePayload: {
        header,
        body: filteredData
      }
    });
  }

  const editproduct = (e) => {
    console.log(e)
    setEditId(e.id)
    setEditToggle(true);
    setEditId(e.id);
    setCode(e.code);
    setName(e.Name);
    setLatitude(e.Lat);
    setLongitude(e.Lng);
    setManager(e.Manager);
    setIsSuspended(e.IsSuspended)

    // setExtensionOfficerStaff(e.extensionOfficerStaff);
    // setExOfficerStaffId(e.extensionOfficerStaff.id)
    // setExOfficerStaffName(e.extensionOfficerStaff.Name)

    setZone(e.zone);
    // setZoneId(e.zone.id);
    // setZoneName(e.zone.Name);

    // setCc(e.cc.Name);
    // setCcId(e.cc.id);
    // setCcName(e.cc.Name);
    setModalShow(true);

  };

  const SearchFn = (e) => {
    const data = e.target.value;
    if (data !== '') {
      const results = Routelist.filter((d) => {
        return d.Name.toLowerCase().startsWith(data.toLowerCase());
      });
      setSearchFilter(results);
    } else {
      setSearchFilter(Routelist);
    }
    setSearch(data);
  }



  return (
    <>
      <Container fluid>
        <Row> {headerShow && (<Col lg="2" className="hideNavbar"><Sidenav /></Col>)}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col
                  md="10"
                  sm="10"
                  xs="10"
                  className="py-2 d-flex align-items-center"
                >
                  <button style={{ border: "none" }} onClick={handleShow}>
                    <img src={menu} width={20} />
                  </button>
                  <h5 className="ms-4 mt-2">Master /Route Herd</h5>
                </Col>
                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                  <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                  <img src={profile} width={40} />
                  <div className="dropdpwn_Open">
                    <Row className="text-center align-items-center">
                      <Col xs="12" className="menuHover mb-3">
                        <Row >
                          <Col className="" xs="3">
                            <span className=""><MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="menuHover" onClick={logOut}>
                        <Row>
                          <Col className="" xs="3">
                            <span className=""><GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center justify-content-between p-2 d-flex" id='heightOffsetStock'>
                <Col sm="8" lg="4" xl="3" className="mt-2 d-flex align-items-center justify-content-start">
                  {/* <button className="btn-blue mt-1" data-toggle="modal" data-target="#exampleModalCenter">Add Herd/Route</button> */}
                  <button className="btn-blue-Animal mt-1 mb-1 me-2" onClick={Add}> Add Herd/Route</button>
                  <button className="btn-blue-Animal mt-1 mb-1 ms-2" onClick={() => handledExcel()}>Export</button>
                </Col>
                <Col xxl="2" lg="3" md="4" sm="5">
                  <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                </Col>
              </Row>
              {/* <Row className="mt-2">
                <Col>
                  <Table columns={colum} data={searchFilter ? searchFilter : Routelist} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                </Col>
              </Row> */}
              <Row className="mt-2" id='heightOffsetStock'>
                {Routelist.length > 0 ?
                  <div className="blue_bg DataTableBorder mt-1">
                    <Table columns={colum} data={searchFilter ? searchFilter : Routelist} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                  </div>
                  :
                  <Row>
                    <Col>
                      <div className="NoTableBorder ms-2" >
                        <div className="d-flex tabledata colmBreed">{
                          colum.map((i) => {
                            return (
                              <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                            )
                          })
                        }
                        </div>
                        <h6 className="text-center justify-calign-items-center mt-5 noData m-auto">No Data</h6>
                      </div>
                    </Col>
                  </Row>
                }
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            Delete {user} ?
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-end">
              <Col xl="3">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => deleteDetails(deleteId)}>Delete</button>
              </Col>
              <Col xl="3">
                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        {/* closeButton */}
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            {editId ? "Update Herd" : "Add New Herd"}
          </Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xl="6">
                <TextField
                  label="Code"
                  id="standard-basic"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  fullWidth
                  variant="standard"
                />
              </Col>
              <Col xl="6">
                <TextField
                  label="Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  id="standard-basic"
                  fullWidth
                  variant="standard"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <TextField
                  label="Latitude"
                  value={latitude}
                  onChange={(e) => {
                    setLatitude(e.target.value);
                  }}
                  id="standard-basic"
                  fullWidth
                  variant="standard"
                />
              </Col>
              <Col xl="6">
                <TextField
                  label="Longitude"
                  value={longitude}
                  onChange={(e) => {
                    setLongitude(e.target.value);
                  }}
                  id="standard-basic"
                  fullWidth
                  variant="standard"
                />
              </Col>

            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <TextField
                  label="Manager"
                  id="standard-basic"
                  value={manager}
                  onChange={(e) => {
                    setManager(e.target.value);
                  }}
                  fullWidth
                  variant="standard"
                />
              </Col>
              <Col xl="6">
                <TextField
                  label="Extension Officer"
                  id="standard-basic"
                  value={extensionOfficerStaff}
                  onChange={(e) => {
                    setExtensionOfficerStaff(e.target.value);
                  }}
                  fullWidth
                  variant="standard"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <FormControl
                  variant="standard"
                  style={{ border: "1px soild black" }}
                  fullWidth
                >
                  {/* <InputLabel
                    id="demo-simple-select-standard-label"
                    style={{ fontSize: "18px" }}
                  >
                    Zone
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={zone}
                    onChange={(e) => {
                      setZone(e.target.value);
                    }}
                  >
                    {ZoneList.map((e) => (
                      <MenuItem
                        className="drop_value"
                        key={e.id}
                        value={e.Name}
                      >
                        {e.Name}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Searcable
                    placeholder="Zone"
                    options={ZoneList}
                    onChange={(selectedOption) => { setZone(selectedOption); }}
                    value={zone}
                    labelKey="Name"
                    otherKey="code"
                    valueKey="id"
                  />
                </FormControl>
              </Col>
              <Col xl="6">
                <FormControl variant="standard" fullWidth>
                  {/* <InputLabel
                    id="demo-simple-select-standard-label"
                    style={{ fontSize: "18px" }}
                  >
                    CC
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={cc}
                    onChange={(e) => {
                      setCc(e.target.value);
                    }}
                  >
                    {CcList.map((e) => (
                      <MenuItem
                        className="drop_value"
                        key={e.id}
                        value={e.Name}
                      >
                        {e.Name}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Searcable
                    placeholder="CC"
                    options={CcList}
                    onChange={(selectedOption) => setCc(selectedOption)}
                    value={cc}
                    labelKey="Name"
                    otherKey="code"
                    valueKey="id"
                  />
                </FormControl>
              </Col>
              <Col className="mt-4">
                <Switch {...label} onChange={(e) => setIsSuspended(e.target.checked)} defaultChecked={isSuspended} />
                Is Suspended : {isSuspended ? "Yes" : "No"}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-end">
              <Col xl="2">
                <button
                  className="btn-blue-Animal mt-1"
                  style={{ background: "#43668E" }}
                  onClick={
                    editToggle
                      ? UpdateData
                      : AddData
                  }
                >
                  {editId ? "Update" : "Save"}
                </button>
              </Col>
              <Col xl="2">
                <button
                  className="btn-blue-Animal mt-1"
                  onClick={() => setModalShow(false)}
                  style={{ background: "#E16659" }}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ fontSize: "14px" }}
      />
      {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

      {
        isLoading ?
          <div className="circleText">
            <div className="progressText">{progressData}</div>
            <div className="">
              <span className="loader-circle-13"></span>
            </div>
          </div>
          : false
      }
    </>
  );
}
