import React, { useEffect } from 'react'
import { useState } from 'react'
import { Col, Container, Offcanvas, Row } from 'react-bootstrap'
import Sidenav from '../Componet/Sidenav/Sidenav'
import menu from '../Photo/menu.svg'
import profile from '../Photo/profile.svg'
import { MdOutlineVpnKey } from 'react-icons/md'
import { GoSignOut } from 'react-icons/go'
import * as XLSX from 'xlsx/xlsx'
import './AnimalHistory.css'

import { STORAGE_KEY } from '../constant/common'
import { API_URLS, serviceUrl } from '../API/Api'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import Input from '../Componet/InputFild/Input'
import Table from '../Componet/DataTable/Table'
import moment from 'moment'
import { downloadExcel } from 'react-export-table-to-excel'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Searcable from '../Componet/Dropdown/Searcable'
import 'react-toastify/dist/ReactToastify.css'
import BackBtn from '../Componet/Button/BackBtn'
const { BreedingDetailsApi } = API_URLS

const AnimalHistory = () => {
  async function editAnimal (credentials) {
    return fetch(BreedingDetailsApi.editAnimal, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(res => res.json())
  }
  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN)
  // const [shouldRefresh, setShouldRefresh] = useState(false);

  // useEffect(() => {
  //     if (shouldRefresh) {
  //         viewFn();
  //         setShouldRefresh(false);
  //     }
  // }, [shouldRefresh]);

  const API_KEY = serviceUrl
  const selectedToDate = new Date().toISOString().slice(0, 10)

  const [progressData, setProgressData] = useState('0%')
  const [searchFilter, setSearchFilter] = useState('')

  const navigate = useNavigate()
  const logOut = () => {
    navigate('/')
  }

  const [isLoading, setIsLoading] = useState(false)
  const [heightOffSet, setHeight] = useState(0)
  const [fileName, setFileName] = useState('Import')
  const [show, setShow] = useState(false)
  const [headerShow, setHeaderShow] = useState(true)
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setHeaderShow(!headerShow)
  }
  const [milkList, setMilkList] = useState([])

  const column = [
    {
      name: 'Animal no.',
      selector: 'Animal no.',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Animal no.']}
        </div>
      ),
      width: '90px',
      sortable: true
    },
    {
      name: 'D.O.B',
      selector: 'D.O.B',
      cell: e => (
        <div className='text-right px-1' style={{ paddingRight: '5px' }}>
          {moment(e['D.O.B'], 'DD-MM-YYYY').format('DD-MM-YYYY')}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'Sire',
      selector: 'Sire',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Sire']}
        </div>
      ),
      width: '90px',
      sortable: true
    },
    {
      name: 'Dam',
      selector: 'Dam',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Dam']}
        </div>
      ),
      width: '90px',
      sortable: true
    },
    {
      name: 'Parity',
      selector: 'Parity',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Parity']}
        </div>
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Bull No.',
      selector: 'Bull No.',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Bull No.']}
        </div>
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Date of A.I.',
      selector: 'Date of A.I.',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {moment(e['Date of A.I.'], 'DD-MM-YYYY').format('DD-MM-YYYY')}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'Date of Calving',
      selector: 'Date of Calving',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {moment(e['Date of Calving'], 'DD-MM-YYYY').format('DD-MM-YYYY')}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'Calf Id',
      selector: 'Calf Id',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Calf Id']}
        </div>
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Sex of Calf',
      selector: 'Sex of Calf',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Sex of Calf']}
        </div>
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Calf weight (KG)',
      selector: 'Calf wight(KG)',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Calf wight(KG)']}
        </div>
      ),
      width: '90px',
      sortable: true
    },
    {
      name: 'Date of Dry',
      selector: 'Date of Dry',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {moment(e['Date of Dry'], 'DD-MM-YYYY').format('DD-MM-YYYY')}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'No. Of Teats',
      selector: 'No. Of Teats',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['No. Of Teats']}
        </div>
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Lactation Length (L.L.) days',
      selector: 'Lactation Length(L.L.)days',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Lactation Length(L.L.)days']}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'Total Lactation Yield (KG)',
      selector: 'Total Lactation Yield(KG)',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Total Lactation Yield(KG)']}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: '305 days Lactation Yield (KG)',
      selector: '305 days Lactation Yield(KG)',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['305 days Lactation Yield(KG)']}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'Peak Yield (KG)',
      selector: 'Peak Yield(KG)',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Peak Yield(KG)']}
        </div>
      ),
      width: '90px',
      sortable: true
    },
    {
      name: 'Date of Peak yield',
      selector: 'Date of Peak yield',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {moment(e['Date of Peak yield'], 'DD-MM-YYYY').format('DD-MM-YYYY')}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'Remarks',
      selector: 'Remarks',
      cell: e => (
        <div className='ms-auto' style={{ paddingRight: '5px' }}>
          {e['Remarks']}
        </div>
      ),
      width: '120px',
      sortable: true
    }
  ]

  const formatDate = excelDate => {
    if (!excelDate) return null
    const MS_PER_DAY = 24 * 60 * 60 * 1000
    const date = new Date((excelDate - 1) * MS_PER_DAY + Date.UTC(1899, 11, 30))
    if (excelDate >= 60) {
      date.setDate(date.getDate() + 1)
    }
    if (!isNaN(date.getTime())) {
      return date
    } else {
      console.log('Invalid date:', excelDate)
      return null
    }
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = e => {
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

      const formattedData = json.slice(1).map(row => ({
        'Animal no.': row[0],
        'D.O.B': formatDate(row[1]),
        Sire: row[2],
        Dam: row[3],
        Parity: row[4],
        'Bull No.': row[5],
        'Date of A.I.': formatDate(row[6]),
        'Date of Calving': formatDate(row[7]),
        'Calf Id': row[8],
        'Sex of Calf': row[9],
        'Calf wight(KG)': row[10],
        'Date of Dry': formatDate(row[11]),
        'No. Of Teats': row[12],
        'Lactation Length(L.L.)days': row[13],
        'Total Lactation Yield(KG)': row[14],
        '305 days Lactation Yield(KG)': row[15],
        'Peak Yield(KG)': row[16],
        'Date of Peak yield': formatDate(row[17]),
        Remarks: row[18]
      }))

      setMilkList(formattedData)
    }

    reader.readAsArrayBuffer(file)
  }

  const MAX_CONCURRENT_REQUESTS = 20 // Increase this number based on your server's capacity

  const SaveFn = async () => {
    if (milkList.length > 0) {
      setIsLoading(true)

      try {
        const validMilkList = milkList
          .map(e => {
            const dateOfAI = moment(e['Date of A.I.'], 'YYYY-MM-DD', true)
            const dateOfCalving = moment(
              e['Date of Calving'],
              'YYYY-MM-DD',
              true
            )
            const dateOfDry = moment(e['Date of Dry'], 'YYYY-MM-DD', true)
            const dateOfPeak = moment(
              e['Date of Peak yield'],
              'YYYY-MM-DD',
              true
            )

            return {
              ...e,
              'Date of A.I.': dateOfAI.isValid()
                ? dateOfAI.format('YYYY-MM-DD')
                : null,
              'Date of Calving': dateOfCalving.isValid()
                ? dateOfCalving.format('YYYY-MM-DD')
                : null,
              'Date of Dry': dateOfDry.isValid()
                ? dateOfDry.format('YYYY-MM-DD')
                : null,
              'Date of Peak yield': dateOfPeak.isValid()
                ? dateOfPeak.format('YYYY-MM-DD')
                : null
            }
          })
          .filter(e => e['Animal no.']) // Only proceed with records having 'Animal no.'

        const buildPayload = e => ({
          TagId: e['Animal no.'],
          Parity: e.Parity === '*' ? 0 : e.Parity || 0,
          HeatDate: e['Date of A.I.'] === '*' ? '' : e['Date of A.I.'] || '',
          sire: e['Bull No.'] === '*' ? '' : e['Bull No.'] || '',
          Staff: e.Staff === '*' ? '' : e.Staff || '',
          PDDate: e['Date of A.I.'] === '*' ? '' : e['Date of A.I.'] || '',
          calvingdate:
            e['Date of Calving'] === '*' ? '' : e['Date of Calving'] || '',
          CalvigType: e.CalvigType === '*' ? 0 : e.CalvigType || 0,
          DateOfDry: e['Date of Dry'] === '*' ? '' : e['Date of Dry'] || '',
          DryTreatment: e.DryTreatment === '*' ? 0 : e.DryTreatment || 0,
          DryReason: e.DryReason === '*' ? 0 : e.DryReason || 0,
          TotalMilk:
            e['Total Lactation Yield(KG)'] === '*'
              ? 0
              : e['Total Lactation Yield(KG)'] || 0,
          HunTotalMilk:
            e['305 days Lactation Yield(KG)'] === '*'
              ? 0
              : e['305 days Lactation Yield(KG)'] || 0,
          Peakyeald: e['Peak Yield(KG)'] === '*' ? 0 : e['Peak Yield(KG)'] || 0,
          Peakdate:
            e['Date of Peak yield'] === '*'
              ? ''
              : e['Date of Peak yield'] || '',
          noofteats: e['No. Of Teats'] === '*' ? 0 : e['No. Of Teats'] || 0,
          Calfid: e['Calf Id'] === '*' ? 0 : e['Calf Id'] || 0,
          Calfsex: e['Sex of Calf'] === '*' ? '' : e['Sex of Calf'] || '',
          Calfwt: e['Calf wight(KG)'] === '*' ? 0 : e['Calf wight(KG)'] || 0,
          Heatseq: e.Heatseq === '*' ? 0 : e.Heatseq || 0
        })

        const handleBatchRequests = async batch => {
          const promises = batch.map(async e => {
            const payload = buildPayload(e)
            try {
              const response = await fetch(
                `${API_KEY}/api/Breeding/editanimal`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${added_by}`
                  },
                  body: JSON.stringify(payload)
                }
              )

              if (!response.ok) {
                if (response.status === 503) {
                  // Optional: Implement retry logic here if needed
                }
                throw new Error('Failed to update data')
              }

              const result = await response.json()
              console.log('Updated record:', result.data)
            } catch (error) {
              console.error('Error updating record:', error.message)
              // Optional: Implement retry logic here if needed
            }
          })

          // Use Promise.allSettled to handle errors gracefully
          const results = await Promise.allSettled(promises)
          results.forEach((result, index) => {
            if (result.status === 'rejected') {
              console.error(
                `Error updating record at index ${index}:`,
                result.reason
              )
              // Optional: Implement further error handling or retry logic here
            }
          })
        }

        for (
          let i = 0;
          i < validMilkList.length;
          i += MAX_CONCURRENT_REQUESTS
        ) {
          const batch = validMilkList.slice(i, i + MAX_CONCURRENT_REQUESTS)
          await handleBatchRequests(batch)
          // Optional: Add a delay between batches if needed
          // await new Promise(res => setTimeout(res, 2000));
        }

        toast.success('Data Updated Successfully..!!')
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error('Batch update failed:', error.message)
        toast.error(`Failed to update data: ${error.message}`)
      }
    } else {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (
            <Col lg='2' className='hideNavbar'>
              {' '}
              <Sidenav />
            </Col>
          )}
          <Col className='header' lg={headerShow ? '10' : '12'}>
            <Container fluid>
              <Row className='pt-1 sub-header align-items-center justify-content-between'>
                <Col
                  md='10'
                  sm='10'
                  xs='10'
                  className='py-2 d-flex align-items-center'
                >
                  <button style={{ border: 'none' }} className='hideNavbar1'>
                    <img src={menu} width={20} />
                  </button>
                  <button style={{ border: 'none' }} className='hide_Navbar'>
                    <img src={menu} width={20} />
                  </button>
                  <h5 className='ms-4 mt-2'>Animal History</h5>
                </Col>
                <Col
                  md='1'
                  sm='1'
                  xs='10'
                  className='py-2 d-flex align-items-center text-end ms-auto'
                >
                  <BackBtn
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    lable='Back'
                    onClick={() => {
                      navigate('/')
                    }}
                  />
                </Col>
                <Col md='1' sm='1' xs='2' className='text-end profile_dropdown'>
                  <img src={profile} width={40} />
                  <div className='dropdpwn_Open'>
                    <Row className='text-center align-items-center'>
                      <Col xs='12' className='menuHover mb-3'>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs='12' className='menuHover' onClick={logOut}>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className='align-items-end div-2' id='heightOffsetStock'>
                <Col
                  md='3'
                  xxl='2'
                  xl='2'
                  xs='6'
                  className='align-items-center ms-auto'
                >
                  <Input label='Search' />
                </Col>
                <Col xxl='1' md='1'>
                  <button className='btn-blue-Animal mt-1 mb-1'>View</button>
                </Col>
                <Col xxl='1' md='1'>
                  <button className='btn-blue-Animal mt-1 mb-1'>Export</button>
                </Col>
                <Col xxl='1' md='1'>
                  <button
                    className='btn-blue-Animal mt-1 mb-1'
                    onClick={SaveFn}
                  >
                    Save
                  </button>
                </Col>
                <Col xxl='1' md='1'>
                  {/* <button className="btn-blue-Animal mt-1 mb-1" onClick={handleFileChange}>Import</button> */}
                  <button className='btn-blue-Animal mt-1 mb-1'>
                    <label className='file-input-label mb-0'>
                      {fileName}
                      <input
                        type='file'
                        accept='.xlsx'
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </label>
                  </button>
                </Col>
              </Row>
              <Row className='mt-2' id='heightOffsetStock'>
                {milkList?.length > 0 ? (
                  <div className='blue_bg DataTableBorder mt-1'>
                    <Table
                      columns={column}
                      data={searchFilter ? searchFilter : milkList}
                      height={`calc(100vh - (${heightOffSet}px + 210px))`}
                      pagination
                    />
                  </div>
                ) : (
                  <Row>
                    <Col>
                      <div className='NoTableBorder ms-2'>
                        <div className='d-flex tabledata colmBreed'>
                          {column.map(i => {
                            return (
                              <Col
                                key={i.name}
                                className={`blankColumns ${i.name.replace(
                                  ' ',
                                  '_'
                                )}`}
                                style={{ backgroundColor: 'rgb(169,169,169)' }}
                                height={`calc(100vh - (${heightOffSet}px + 20px))`}
                              >
                                {i.name}
                              </Col>
                            )
                          })}
                        </div>
                        <h6 className='text-center align-items-center mt-5 noData ms-5'>
                          No Data
                        </h6>
                      </div>
                    </Col>
                  </Row>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      {show && (
        <Offcanvas className='hide_Navbar' show={show} onHide={handleClose}>
          <Container>
            <Row>
              <Col>
                <Sidenav />
              </Col>
            </Row>
          </Container>
        </Offcanvas>
      )}

      {isLoading ? (
        <div className='circleText'>
          <div className='progressText'>{progressData}</div>
          <div className=''>
            <span className='loader-circle-13'></span>
          </div>
        </div>
      ) : (
        false
      )}
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        style={{ fontSize: '14px' }}
        limit='1'
      />
    </>
  )
}

export default AnimalHistory
