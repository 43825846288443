import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas, Modal, Accordion } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import Select from 'react-select';
import {
    TextField,
    FormControl,
    InputLabel,
    // Select,
    MenuItem,
} from "@mui/material";
import closeButton from "../../Photo/milk-union/close button.svg";
import moment from 'moment/moment';
import { downloadExcel } from "react-export-table-to-excel";
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import Dowunload from '../../Photo/Download.png'
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { borderRadius, margin } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Searcable from '../../Componet/Dropdown/Searcable';
const { DistributionAPi, SemenRequestApi, SupplieStockApi, SemenSupplierApi, Animal_Zone } = API_URLS;

const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getStaff(credentials) {
    return fetch(DistributionAPi.GetStaff, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getZone(credentials) {
    return fetch(DistributionAPi.GetZone, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getVisitRoute(credentials) {
    return fetch(Animal_Zone.GetVisitRoute, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getItem(credentials) {
    return fetch(DistributionAPi.GetItem, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getSupplier(credentials) {
    return fetch(DistributionAPi.GetSupplier, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function editDetails(credentials) {
    return fetch(DistributionAPi.EditDetails, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getSupplierData(credentials) {
    return fetch(SupplieStockApi.GetStaff, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}
async function getSireData(credentials) {
    return fetch(SupplieStockApi.GetSire, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}
async function VillageDataGet(credentials) {
    return fetch(DistributionAPi.VillageData, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getEditdetail(credentials) {
    return fetch(DistributionAPi.EditDetails, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}
async function getitem(credentials) {
    return fetch(DistributionAPi.GetItem, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

const StrawDistribution = () => {
    const API_KEY = serviceUrl;
    const selectedToDate = new Date().toISOString().slice(0, 10);


    const [date, setDate] = useState(selectedToDate)
    const [staff, setStaff] = useState("")
    const [editId, setEditId] = useState("")
    const [bench, setBench] = useState("")
    const [qty, setQty] = useState("")
    const [sire, setSire] = useState("")
    const [semen, setSemen] = useState("")
    const [itemCode, setItemCode] = useState("")
    const [item, setItem] = useState("")
    const [supplied, setSupplied] = useState("")
    const [supplier, setSupplier] = useState()
    const [number, setNumber] = useState("")
    const [center, setCenter] = useState("")
    const [dcs, setDcs] = useState("")
    const [Code, setCode] = useState("")
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }
    const [heightOffSet, setHeight] = useState(0);
    const [progressData, setProgressData] = useState("0%");
    const [isLoading, setIsLoading] = useState(false);

    const [supplier2, setSupplier2] = useState("");
    const [Visit, setVisit] = useState("");
    const [staff2, setStaff2] = useState("");
    const [Phonenum, setPhonenum] = useState("");
    const [routeVisit, setRouteVisit] = useState("");
    const [route, setRoute] = useState("");

    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [modalShow, setModalShow] = useState(false);

    const [distributionList, setDistributionList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [sireList, setSireList] = useState([])
    const [semenList, setSemenList] = useState([])
    const [itemList, setItemList] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [centerList, setCenterList] = useState([])
    const [dcsList, setDcsList] = useState([])
    const [strawList, setStrawList] = useState([]);
    const [otherItemList, setOtherItemList] = useState([]);
    const [routeVisitList, setRouteVisitList] = useState([]);
    const [routeList, setRouteList] = useState([]);


    const SemenData = [
        { value: 'Normal Semen' },
        { value: 'Sex Semen' },
    ];

    const fatchRouteData = async (e) => {
        if (e) {
            fetch(`${API_KEY}/cattle-detail/getVisitRoutes/` + e, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setRouteList(result) });
        }
    }

    const fatchDcsData = async (e) => {
        if (e) {
            fetch(`${API_KEY}/cattle-detail/getVisitLot/` + e, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setDcsList(result) })
        }
    }

    const selectCenter = (e) => {
        setCenter(e);
        fatchRouteData(e);
        console.log(setCenter(e));
    }

    const selectRoute = (e) => {
        setRouteVisit(e);
        fatchDcsData(e);
    }

    const selectDsc = (e) => {
        console.log(e)
        setDcs(e);
        // fatchFarmerData(e);
        fatchDcsData(e)
    }

    const fetchData = async () => {
        setIsLoading(true)
        await fetch(`${API_KEY}/straw-management/getGridSemenSupply?staff=${staff}&billDate=${date}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((response) => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            }
            )
            .then((res) => res.json())
            .then((result) => {
                setIsLoading(false)
                setDistributionList(result);
                setDcsList(result)
            })
            .catch((error) => { setIsLoading(false) })
    }


    const getStrawData = async () => {
        const res = await getStaff()
        const res1 = await getSupplier()
        const res2 = await getZone()
        const res3 = await getItem()
        const res4 = await getVisitRoute()

        if (res) { setStaffList(res) }
        if (res1) { setSupplierList(res1) }
        if (res2) { setCenterList(res2) }
        if (res3) { setItemList(res3) }
        if (res4) { setRouteVisitList(res4) }
    }

    const editDetails = (e) => {
        console.log(e)
        getStrawData()
        setModalShow(true)
        setStaff(e["Staff.Code"])
    }
    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        fetchData()
        getStrawData()
        fatchRouteData()
        fatchDcsData()
    }, [])

    const AddData = async () => {
        setIsLoading(true)
        await fetch(SemenSupplierApi.SaveStraw, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({
                RequestDate: null,
                RequestQuantity: null,
                SireVoucherType: 2,
                Sires: [{ SireCode: sire, Normal: qty, batchnonormal: bench, Sorted: null, batchnosorted: null }],
                Staff: parseFloat(staff) ? parseFloat(staff) : 0,
                VoucherDate: date,
                createdAt: null,
                createdByUser: null,
                dcs: null,
                isEdit: 0,
                lastUpdatedByUser: null,
                supplierId: 0,
                updatedAt: null
            })
        })
            .then(data => data.json())
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((result) => {
                setIsLoading(false);
            })

            .catch((error) => setIsLoading(false))

        await fetch(SemenSupplierApi.SaveItem, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },


            body: JSON.stringify({
                Items: [
                    { Item: itemList[0].Name, ItemCode: itemCode, quantity: qty }
                ],
                RequestDate: null,
                RequestQuantity: null,
                Staff: parseFloat(staff) ? parseFloat(staff) : 0,
                VoucherDate: date,
                VoucherType: 2,
                batchno: null,
                createdAt: null,
                createdByUser: null,
                dcs: null,
                isEdit: 0,
                lastUpdatedByUser: null,
                supplierId: 0,
                updatedAt: null,

            })
        })
            .then(data => data.json())
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((result) => { setIsLoading(false); })
            .catch((error) => setIsLoading(false))

        setModalShow(false)
        setPhonenum("");
        setBench("");
        setQty("");
        setItemCode("");
        setSupplied("");
        setStrawList("");
        setOtherItemList("");
    }

    const Add = () => {
        getStrawData()
        openData();
        setModalShow(true)
    }
    const Supplied = () => { }

    const colum = [
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <div>
                        <img src={edit} onClick={() => { editDetails(e) }} />
                        <img src={remove} className='ms-1' /> {/* //  onClick={(e) => { deleteDetails(e) }} */}
                        <img src={Dowunload} className='ms-1' width="18" />{/* //  onClick={(e) => { saveDetails(e) }} */}
                    </div>
                </>
            ),
            width: "100px",
        },
        {
            name: "Request ID",
            selector: "Request ID",
            width: "90px",
            sortable: true

        },
        {
            name: "Challan Number",
            selector: "Challan Number",
            width: "120px",
            sortable: true

        },
        {
            name: "Staff.Code",
            cell: (e) => (<p className='p-0 m-0'>{e['Staff.Code']}</p>),
            width: "100px",
            sortable: true

        },
        {
            name: "Staff Name",
            selector: "Staff Name",
            sortable: true

        },
        {
            name: "Dcs Code",
            selector: "DCS Code",
            width: "100px",
            sortable: true

        },
        {
            name: "Dcs Name",
            selector: "DCS Name",
            sortable: true

        },
        {
            name: "Route",
            selector: "Route",
            sortable: true,
            width: "80px",
        },
        {
            name: "Semen",
            selector: "Semen",
            sortable: true

        },
        {
            name: "Other Item",
            selector: "Other Item",
            sortable: true

        },
        {
            name: "Supply",
            cell: (e) => (<button type="button" style={{ border: "none", boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)", backgroundColor: "#43668E", color: "#FFFFFF", borderRadius: "5px", padding: "5px", margin: "5px" }} onClick={(e) => { Supplied() }}>Supplied</button>),
            width: "130px",
        },
    ]

    const colum21 = [
        {
            name: "Action",
            cell: (e) => (
                <>
                    <img
                        src={remove}
                        onClick={() => delateHandle(e)}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
        {
            name: "Sire",
            selector: "sire",
            cell: (row) => {
                const matchedSire = sireList.find((sire) => sire.id === row.sire);
                return matchedSire ? matchedSire.Name : "";
            },
        },
        {
            name: "Semen Type",
            selector: "semen",
        },
        {
            name: "Batch No",
            selector: "bench",
        },
        {
            name: "Qty",
            selector: "qty",
        },
    ]

    const colum1 = [
        {
            name: "Action",
            cell: (e) => (
                <>
                    <img
                        src={remove}
                        onClick={() => delateHandle1(e)}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
        {
            name: "Item name",
            selector: "item",
            cell: (row) => {
                const matchedItem = itemList.find((item) => item.id === row.item);
                return matchedItem ? matchedItem.Name : "";
            },
        },
        {
            name: "Item Code",
            selector: "itemCode",
        },
        {
            name: "Supplied",
            selector: "supplied",
        },
    ]

    const handleDownloadExcel = () => {
        const opt = `Sement And Other Report ${date}`;
        const header = Object.keys(distributionList).map((key, e) => (key));

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header,
                body: distributionList
            }
        });

    }

    const filterStaff = async (e) => {
        await fetch(`${API_KEY}/straw-management/getGridSemenSupply?staff=${e}&billDate=${date}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((response) => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            }
            )
            .then((res) => res.json())
            .then((result) => {
                setDistributionList(result);
            });
    }

    const selectStaff = (e) => {
        console.log(e);
        setStaff(e)
        filterStaff(e)
    }

    const delateHandle = (e) => {
        setStrawList(strawList.filter(item => item.index !== e.index))
    }

    const delateHandle1 = (e) => {
        setOtherItemList(otherItemList.filter(item => item.index !== e.index))
    }


    const handelpdf = async (e) => {
        try {
            const date = moment(e["Bill Date"]);
            const momentObj = moment(date);
            const dateOnly = momentObj.format('YYYY-MM-DD');
            const response = await fetch(`${SemenRequestApi.dowunloadData}?staff=${e.staffid}&billDate=${dateOnly}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                    'Authorization': `Bearer ${added_by}`,
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const pdfBlob = await response.blob();
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');
            URL.revokeObjectURL(pdfUrl);
        } catch (error) {
            console.error('API request failed:', error);
        }
    };
    const openData = async () => {
        const res = await getSireData()
        if (res) {
            setSireList(res)
            setShow(true)
        }
    }
    const Suply = async (row) => {
        try {
            if (supplierList.length > 0) {
                setSupplier2(supplierList[0].id);
            }
            openData();
            setModalShow(true);
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle the error as needed 
        }
    };
    const AddStraw = () => {
        setStrawList((prev) => {
            const newIndex = prev.length + 1;
            const newStrawItem = { index: newIndex, staff, sire, semen, bench, qty };
            console.log("New Straw Item:", newStrawItem);
            return [...prev, newStrawItem];
        });
    };
    const selectItem = (e) => {
        setItem(e)
        setItemCode(e)
    }
    const itemSave = () => {
        setOtherItemList((prev) => {
            const newIndex = prev.length + 1;
            return [...prev, { index: newIndex, item, itemCode, supplied }]
        })
    }
    // console.log(sireList);
    console.log(itemList);
    // console.log(staffList);
    // console.log(centerList);
    // console.log(routeVisitList);
    // console.log(dcsList);

    // useEffect(() => {
    //     getSireData(sire)
    //         .then((data) => setSireList(data))
    //         .catch((error) => {
    //             console.error("Error fetching data:", error);
    //             // Handle the error, possibly set sireList to a default value or show an error message
    //         });
    // }, [sire]);


    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Straw / Straw Distribution</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-between p-2" id='heightOffsetStock'>
                                <Col xs="2" sm="2" md="2" xl="1" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add</button>
                                </Col>
                                <Col className='d-flex align-items-end justify-content-end'>
                                    <Col md="2" sm="2" xs="3" className='justify-content-end'>
                                        <FormControl variant="standard" fullWidth>
                                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Supplier</InputLabel>
                                           <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={supplier} onChange={(e) => { setSupplier(e.target.value) }}>
                                               {supplierList.map((e) => (
                                                   <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                       {e.code} - {e.Name}
                                                   </MenuItem>
                                               ))}
                                           </Select> */}
                                            <Searcable
                                                placeholder="Supplier"
                                                options={supplierList.map((e) => ({ Name: `${e.code} - ${e.Name}`, value: e.Name }))}
                                                onChange={(selectedOption) => setSupplier(selectedOption.value)}
                                                // value={supplier ? { Name: `${supplier.code} - ${supplier.Name}`, value: supplier.Name } : null}
                                                labelKey="Name"
                                                valueKey="value"
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col md="2" sm="2" xs="3" className='justify-content-end'>
                                        <FormControl variant="standard" fullWidth>
                                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Staff</InputLabel>
                                           <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { selectStaff(e.target.value) }}>
                                               {staffList.map((e) => (
                                                   <MenuItem className="drop_value" key={e.id} value={e.ID}>
                                                       {e.Code} - {e.Paravet_Name}
                                                   </MenuItem>
                                               ))}
                                           </Select> */}
                                            <Searcable
                                                placeholder="Staff"
                                                options={staffList.map((e) => ({ Name: `${e.Code} - ${e.Paravet_Name}`, value: e.ID }))}
                                                onChange={(selectedOption) => selectStaff(selectedOption.value)}
                                                // value={staff}
                                                labelKey="Name"
                                                valueKey="value"
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col md="2" sm="2" xs="3" className='mt-3'>
                                        <TextField type="date" value={date} onChange={(e) => { setDate(e.target.value); }} id="standard-basic" data={distributionList} fullWidth variant="standard" />
                                    </Col>
                                    <Col xs="3" sm="2" md="2" xl="1" className="mt-2 d-flex align-items-center justify-content-end">
                                        <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                                    </Col>
                                </Col>
                            </Row>
                            {/* <Row className="mt-4">
                               <Col>
                                   <Table columns={colum} data={distributionList} height="78vh" pagination />
                               </Col>
                           </Row> */}
                            <Row className="mt-2" >
                                {colum.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={distributionList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter" className=''>Straw Distribution</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='align-tems-center justify-content-start'>
                            <Col md="4" sm="3">
                                {/* <FormControl variant="standard" fullWidth>
                                   <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Supplier</InputLabel>
                                   <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={supplier2} onChange={(e) => { setSupplier2(e.target.value) }}>
                                       {supplierList.map((e) => (
                                           <MenuItem className="drop_value" key={e.id} value={e.id}>
                                               {e.code} - {e.Name}
                                           </MenuItem>
                                       ))}
                                   </Select>
                               </FormControl> */}
                                <Select
                                    placeholder="Supplier"
                                    onChange={(selectedOption) => setSupplier2(selectedOption)}
                                    options={supplierList.map((e) => ({ value: e.id, label: e.Name }))}
                                />
                            </Col>
                            <Col md="4">
                                {/* <FormControl variant="standard" fullWidth>
                                   <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Staff</InputLabel>
                                   <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff2} onChange={(e) => { setStaff2(e.target.value) }}>
                                       {staffList.map((e) => (
                                           <MenuItem className="drop_value" key={e.ID} value={e.ID}>
                                               {e.Code} - {e.Paravet_Name}
                                           </MenuItem>
                                       ))}
                                   </Select>
                               </FormControl> */}
                                <Select
                                    placeholder="Staff"
                                    onChange={(selectedOption) => setStaff2(selectedOption.value)}
                                    options={staffList.map((e) => ({ value: e.ID, label: e.Paravet_Name }))}
                                />
                                {/* <TextField label="staff Code" id="standard-basic" value={staff2} onChange={(e) => { setStaff2(e.target.value); }} fullWidth variant="standard" /> */}
                            </Col>
                            <Col md="4  align-items-end">
                                {/* <FormControl variant="standard" fullWidth>
                                   <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Center</InputLabel> */}
                                {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={center} onChange={(e) => selectCenter(e.target.value)}>
                                       {centerList.map((e) => (
                                           <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                               {e.Name}
                                           </MenuItem>
                                       ))}
                                   </Select> */}
                                <Select
                                    placeholder="Center"
                                    onChange={(selectedOption) => selectCenter(selectedOption.value)}
                                    options={centerList.map((e) => ({ value: e.id, label: e.Name }))}
                                />
                                {/* <Searcable
                                       placeholder="Center"
                                       options={centerList.map((e) => ({ Name: `${e.Code} - ${e.Name}`, value: e.Name }))}
                                       onChange={(selectedOption) => setCenter(selectedOption.value)}
                                       value={center}
                                       labelKey="Name "
                                       valueKey="Name"
                                   /> */}
                                {/* </FormControl> */}
                            </Col>

                            <Col md="4" className='mt-2'>
                                {/* <FormControl variant="standard" fullWidth>
                                   <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Route</InputLabel>
                                   <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={routeVisit} onChange={(e) => selectRoute(e.target.value)}>
                                       {routeVisitList.map((e) => (
                                           <MenuItem className="drop_value" key={e.Id} value={e.name}>
                                               {e.name}
                                           </MenuItem>
                                       ))}
                                   </Select>
                               </FormControl> */}
                                {/* <TextField label="DCS" id="standard-basic" value={dcs} onChange={(e) => { setDcs(e.target.value); }} fullWidth variant="standard" /> */}
                                <Select
                                    placeholder="Route"
                                    onChange={(selectedOption) => selectRoute(selectedOption.value)}
                                    options={routeVisitList.map((e) => ({ value: e.Id, label: e.name }))}
                                />
                            </Col>
                            <Col md="4" className='mt-2'>
                                {/* <FormControl variant="standard" fullWidth>
                                   <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>DCS</InputLabel>
                                   <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={dcs} onChange={(e) => selectDsc(e.target.value)}>
                                       {dcsList.map((e) => (
                                           <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                               {e.Name}
                                           </MenuItem>
                                       ))}
                                   </Select>
                               </FormControl> */}
                                <Select
                                    placeholder="Dcs"
                                    onChange={(selectedOption) => selectDsc(selectedOption.value)}
                                    options={dcsList.map((e) => ({ value: e.Id, label: e.name }))}
                                />
                                {/* <TextField label="DCS" id="standard-basic" value={dcs} onChange={(e) => { setDcs(e.target.value); }} fullWidth variant="standard" /> */}
                            </Col>
                            <Col md="4">
                                <TextField label="Phonenumber" id="standard-basic" value={Phonenum} onChange={(e) => { setPhonenum(e.target.value); }} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Add Staw</Accordion.Header>
                                        <Accordion.Body>
                                            <Row className='align-tems-center justify-content-between'>
                                                <Col md="2" sm="3" className='mt-2'>
                                                    {/* <FormControl variant="standard" fullWidth>
                                                       <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sire</InputLabel>
                                                       <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={(e) => { setSire(e.target.value) }}>
                                                           {sireList.map((e) => (
                                                               <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                   {e.Name}
                                                               </MenuItem>
                                                           ))}
                                                       </Select>
                                                   </FormControl> */}
                                                    <div>
                                                        {sireList && (
                                                            <Select
                                                                placeholder="Sire"
                                                                onChange={(selectedOption) => setSire(selectedOption.value)}
                                                                options={sireList.map((e) => ({ value: e.id, label: e.Name }))}
                                                            />
                                                        )}
                                                    </div>
                                                    {/* <Select
                                                  placeholder="Sire"
                                                        onChange={(selectedOption) => setSire(selectedOption.value)}
                                                        options={sireList.map((e) => ({ value: e.id, label: e.Name }))}
                                                    /> */}
                                                </Col>
                                                <Col md="2" className='mt-2'>
                                                    {/* <FormControl variant="standard" fullWidth>
                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Semen</InputLabel>
                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={semen} onChange={(e) => { setSemen(e.target.value) }}>
                                                            <MenuItem className="drop_value" value="0">Normal Semen</MenuItem>
                                                            <MenuItem className="drop_value" value="1">Sex Semen</MenuItem>
                                                        </Select>
                                                    </FormControl> */}
                                                    <Select
                                                        placeholder="Semen"
                                                        onChange={(selectedOption) => setSemen(selectedOption.value)}
                                                        options={[
                                                            { value: "0", label: "Normal Semen" },
                                                            { value: "1", label: "Sex Semen" },
                                                        ]}
                                                    />
                                                </Col>
                                                <Col md="2">
                                                    <TextField label="Bench No" id="standard-basic" value={bench} onChange={(e) => { setBench(e.target.value); }} fullWidth variant="standard" />
                                                </Col>
                                                <Col md="2">
                                                    <TextField label="Qty" id="standard-basic" value={qty} onChange={(e) => { setQty(e.target.value); }} fullWidth variant="standard" />
                                                </Col>
                                                <Col xs="4" sm="4" md="2" className="mt-2 d-flex align-items-center text-end justify-content-end">
                                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={AddStraw}>Add Straw</button>
                                                </Col>
                                                </Row>
                                                <Row className='align-tems-end justify-content-end'>
                                               
                                                </Row>
                                            <Row className="mt-4">
                                                <Col>
                                                    <Table columns={colum21} data={strawList} />
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header >Add Other Item</Accordion.Header>
                                        <Accordion.Body>
                                            <Row className='align-tems-center justify-content-end'>
                                                <Col md="3" className='mt-2'>
                                                    {/* <FormControl variant="standard" fullWidth>
                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Item Name</InputLabel>
                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={item} onChange={(e) => { selectItem(e.target.value) }}>
                                                            {itemList.map((e) => (
                                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                    {e.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl> */}
                                                    <Select
                                                        placeholder="Item Name"
                                                        onChange={(selectedOption) => selectItem(selectedOption.value)}
                                                        options={itemList.map((e) => ({ value: e.id, label: e.Name }))}
                                                    />
                                                </Col>
                                                <Col md="3">
                                                    <TextField label="Item Code" id="standard-basic" value={itemCode} disabled onChange={(e) => { setItemCode(e.target.value); }} fullWidth variant="standard" />
                                                </Col>
                                                <Col md="3">
                                                    <TextField label="Supplied" id="standard-basic" value={supplied} onChange={(e) => { setSupplied(e.target.value); }} fullWidth variant="standard" />
                                                </Col>
                                                <Col xs="4" sm="2" md="3" className="mt-2 d-flex align-items-center text-end justify-content-end">
                                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={itemSave}>Add item</button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col>
                                                    <Table columns={colum1} data={otherItemList} />
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col sm="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={AddData}>Save</button>
                            </Col>
                            <Col sm="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal >

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
}
export default StrawDistribution    
