import React, { useEffect } from 'react'
import { useState } from 'react';
import { Col, Container, Offcanvas, Row } from 'react-bootstrap';
import Sidenav from '../../Componet/Sidenav/Sidenav';
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { downloadExcel } from 'react-export-table-to-excel';
import Searcable from '../../Componet/Dropdown/Searcable';
import BackBtn from '../../Componet/Button/BackBtn';
const { DoctorApi, MilkTestApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);


const headers = {
    Authorization: `Bearer ${added_by}`,
    // Other headers...
};

// axios.get(DoctorApi.getZone, { headers })
//     .then(response => {
//         // Handle response
//         console.log(response)
//     })
//     .catch(error => {
//         // Handle error
//         console.log(error)
//     });
// const headers = {
//     'Authorization': 'Bearer your-token-goes-here'
// };

fetch(DoctorApi.getZone, { headers })
    .then(response => {
        // Handle the response
    })
    .catch(error => {
        // Handle any errors
    });

async function getZone(credentials) {
    return fetch(DoctorApi.getZone, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getAllData(credentials) {
    return fetch(MilkTestApi.getAll, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}


const Milk = () => {
    const API_KEY = serviceUrl;
    const selectedToDate = new Date().toISOString().slice(0, 10);
    const [center, setCenter] = useState("");
    const [ait, setAit] = useState("");
    const [sort, setSort] = useState("")
    const [order, setOrder] = useState("")
    const [society, setSociety] = useState("")
    const [farmer, setFarmer] = useState("")
    const [route, setRoute] = useState("")
    const [search, setSearch] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const [searchFilter, setSearchFilter] = useState("")
    const [Fat, setFat] = useState("")
    const [Snf, setSnf] = useState("")
    const [Lactose, setLactose] = useState("")
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [date, setDate] = useState(selectedToDate);

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [doctorList, setDoctorList] = useState([])
    const [centerList, setCenterList] = useState([]);
    const [trackingList, setTrackingList] = useState([])
    const [routeList, setRouteList] = useState([])
    const [farmerList, setFarmerList] = useState([])
    const [societyList, setSocietyList] = useState([])
    const [milkList, setMilkList] = useState([])

    const getDoctorData = async () => {
        const res = await getZone()
        if (res) { setCenterList(res) }
    }


    const handleDownloadExcel = () => {
        const header = []
        const opt = `MilkEntry ${date}`;
        colum.map((e) => {
            header.push(e.name)
        })

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header,
                body: milkList
            }
        });
    }

    const fatchData = async (e, e1, e2) => {
        // const res = await getAllData({ ahc: e, date: "05/09/2023", dcs: "", farmer: e2, route: e1 })
        // if (res) { setMilkList(res) }
        setIsLoading(true)
        await fetch(`${API_KEY}/production/milk/milk-entry`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ ahc: null, date, dcs: null, farmer: null, route: null })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setMilkList(result); setIsLoading(false); setProgressData("0%"); })
            .catch((error) => { setIsLoading(false) })
    }


    const selectCenter = (e) => {
        setCenter(e);
        fatchRouteData(e);
        fatchData(e)
    }

    const fatchRouteData = async (e) => {
        if (e.value) {
            fetch(`${API_KEY}/cattle-detail/getVisitRoutes/` + e.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setRouteList(result); });

        }
    }

    const selectRoute = (e) => {
        setRoute(e);
        fatchSocietyData(e);
        fatchData(e)
    }
    const fatchSocietyData = async (e) => {
        if (e.value) {
            fetch(`${API_KEY}/cattle-detail/getVisitLot/` + e.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setSocietyList(result) })
        }
    }

    const selectSociety = (e) => {
        setSociety(e);
        fatchFarmerData(e);
        fatchData(e)
    }
    const fatchFarmerData = async (e) => {
        if (e.value) {
            fetch(`${API_KEY}/cattle-detail/getVisitFarmer/` + e.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setFarmerList(result) })
        }
    }


    useEffect(() => {
        getDoctorData()
        // fatchData()
    }, [])

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = milkList.filter((user) => {
                return user.Tagid.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(milkList);
        }
        setSearch(data);
    }

    const textHandle = (e) => {
        setFat(e.target.value)
    }
    const textHandle1 = (e) => {
        setLactose(e.target.value)
    }
    const textHandle2 = (e) => {
        setSnf(e.target.value)
    }

    const saveFn = (e) => {
        fetch(`${API_KEY}/production/milk/insert-milk`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ bottleNo: "", boxNo: "", fat: Fat, id: e.ID, lactose: Lactose, snf: Snf })
        })
            .then(res => res.json())
            .then((result) => { setMilkList(result); fatchData() })

    }

    const colum = [
        {
            name: "ID",
            selector: "ID",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["ID"]}</p>,

            width: "90px",
            sortable: true
        },
        {
            name: "Tag Id",
            selector: "Tagid",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["Tagid"]}</p>,
            width: "130px",
            sortable: true
        },
        {
            name: "DCS Code",
            selector: "DCS Code",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["DCS Code"]}</p>,
            sortable: true
        },
        {
            name: "DCS Name",
            selector: "DCS Name",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["DCS Name"]}</p>,
            sortable: true
        },
        {
            name: "Farmer Code",
            selector: "Farmer Code",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["Farmer Code"]}</p>,
        },
        {
            name: "Farmer Name",
            selector: "Farmer Name",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["Farmer Name"]}</p>,
            sortable: true,
            width: "250px"
        },
        {
            name: "Morning Yield",
            selector: "Morningyield",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["Morningyield"]}</p>,
            sortable: true
        },
        {
            name: "Evening Yield",
            selector: "Eveningyield",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["Eveningyield"]}</p>,
            sortable: true
        },
        {
            name: "Total",
            selector: "total",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["total"]}</p>,
            sortable: true
        },
        {
            name: "BoxNo",
            selector: "BoxNo",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["BoxNo"]}</p>,
            sortable: true
        },
        {
            name: "BottleNo",
            selector: "BottleNo",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["BottleNo"]}</p>,
            sortable: true
        },
        {
            name: "Fat",
            selector: "Fat",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["Fat"]}</p>,
            sortable: true
        },
        {
            name: "Snf",
            selector: "snf",
            cell: (e) => <p  style={{ paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end" }}>{e["snf"]}</p>,
            sortable: true
        },
        // {
        //     name: "fat",
        //     cell: (e) => <input type='text' className="inpField1" value={e.Fat} onChange={(e) => textHandle(e)} />,
        //     width: "70px"
        // },
        // {
        //     name: "snf",
        //     cell: (e) => <input type='text' className="inpField1" value={e.Snf} onChange={(e) => textHandle1(e)} />,
        //     width: "70px"
        // },
        // {
        //     name: "Lactose",
        //     cell: (e) => <input type='text' className="inpField1" value={e.Lactose} onChange={(e) => textHandle2(e)} />,
        //     width: "80px"
        // },
        // {
        //     name: "Actions",
        //     cell: (e) => (<><button className='saveMilk' onClick={() => saveFn(e)}>Save</button></>),
        //     width: "220px"
        // },
    ]


    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Milk Entry / Official  Test</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end">
                                    <img src={profile} width={40} />
                                </Col>
                            </Row>
                            <Row className="align-items-end div-2" id='heightOffsetStock'>
                                <Col xxl="2" xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Center</InputLabel>
                                        <Select value={center} onChange={(e) => selectCenter(e.target.value)}>
                                            {centerList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Center"
                                            options={centerList}
                                            onChange={(selectedOption) => selectCenter(selectedOption)}
                                            value={center}
                                            labelKey="Name"
                                            valueKey="id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Route</InputLabel>
                                        <Select value={route} onChange={e => selectRoute(e.target.value)}>
                                            {routeList.map((e) => (
                                                <MenuItem className="drop_value" key={e.Id} value={e.Id}>
                                                    {e.name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Route"
                                            options={routeList}
                                            onChange={(selectedOption) => selectRoute(selectedOption)}
                                            value={route}
                                            labelKey="name"
                                            valueKey="Id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Society</InputLabel>
                                        <Select value={society} onChange={e => selectSociety(e.target.value)}>
                                            {societyList.map((e) => (
                                                <MenuItem className="drop_value" key={e.Id} value={e.Id}>
                                                    {e.name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Society"
                                            options={societyList}
                                            onChange={(selectedOption) => selectSociety(selectedOption)}
                                            value={society}
                                            labelKey="name"
                                            valueKey="Id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Farmer</InputLabel>
                                        <Select value={farmer} onChange={e => setFarmer(e.target.value)}>
                                            {farmerList.map((e) => (
                                                <MenuItem className="drop_value" key={e.Id} value={e.Id}>
                                                    {e.name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Farmer"
                                            options={farmerList}
                                            onChange={(selectedOption) => setFarmer(selectedOption)}
                                            value={farmer}
                                            labelKey="name"
                                            valueKey="Id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xl="1" xs="6" xxl="1" sm="3" md="2" >
                                    <sub className="mb-0 pb-0">Date</sub>
                                    <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                </Col>
                                <Col md="3" xxl="1" xl="2" xs="6" className='align-items-center'>
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                                <Col xxl="1">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                                </Col>
                                <Col xxl="1 ms-auto">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={() => fatchData()}>View</button>
                                </Col>
                            </Row>
                            {/* <Row className='mt-2 justify-content-center text-center'>
                                <Col className="mt-4">
                                    <Table columns={colum} data={searchFilter ? searchFilter : milkList} height={`calc(100vh - (${heightOffSet}px + 215px))`} pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {milkList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : milkList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
}

export default Milk