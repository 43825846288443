import React, { useEffect, useState } from "react";
import './login.css';
import { Col, Container, Row } from "react-bootstrap";

import { useLocation, useNavigate } from 'react-router-dom';
import { STORAGE_KEY } from "../constant/common";
import { API_URLS } from "../API/Api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
const { LoginAPI, LoginAPIHap, UserManagApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function loginUser(credentials) {
    return fetch(LoginAPI.login, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}
async function loginHapData(credentials) {
    return fetch(LoginAPIHap.login, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}
async function getUserData(credentials) {
    return fetch(LoginAPI.GetInfo, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Accept": "application/json", "Authorization": `Bearer ${added_by}` },
        body: JSON.stringify(credentials),

    })
        .then(data => data.json())
}

async function getUser(credentials) {
    return fetch(UserManagApi.GetDetail, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

export default function Login() {

    const navigate = useNavigate();
    const location = useLocation()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [progressData, setProgressData] = useState("0%");

    const currentUrl = location.pathname;
    localStorage.setItem("path", currentUrl);

    // const loginFn = async () => {
    //     if (username === "") {
    //         toast.error("Please Enter Username");
    //     }
    //     else if (password === "") {
    //         toast.error("Please Enter Passsword");
    //     }
    //     else {
    //         const res = await loginUser({ username, password, rememberMe: false })
    //         if (res) {
    //             toast.success("LogIn Successful");
    //             if (username === "Pmmanger") {
    //                 localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
    //                 localStorage.setItem("USERNAME", username)
    //                 navigate('/CampMaster');
    //             }
    //             else {
    //                 toast.success("LogIn Successful");
    //                 localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
    //                 localStorage.setItem("USERNAME", username)
    //                 navigate('/Overview');
    //             }
    //         }
    //     }
    //     // if (currentUrl === "demo.herdman.in" || currentUrl === "/") {
    //     //     const res = await loginUser({ username, password, rememberMe: false })
    //     //     if (res) {
    //     //         localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
    //     //         localStorage.setItem("USERNAME", username)
    //     //         navigate('/Overview');
    //     //     }
    //     //     else {
    //     //         alert("Invalid username or password");
    //     //     }
    //     // }
    //     // else {
    //     //     const res = await loginHapData({ username, password, rememberMe: false })
    //     //     if (res) {
    //     //         localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
    //     //         localStorage.setItem("USERNAME", username)
    //     //         navigate('/Overview');
    //     //     }
    //     //     else {
    //     //         alert("Invalid username or password");
    //     //     }
    //     // }

    // }

    // const loginFn = async () => {
    //     if (username === "") {
    //         toast.error("Please Enter Username");
    //     } else if (password === "") {
    //         toast.error("Please Enter Passsword");
    //     } else {
    //         try {
    //             const res = await loginUser({ username, password, rememberMe: false });
    //             if (res.token) {
    //                 toast.success("LogIn Successful");
    //                 if (username === "Pmmanger") {
    //                     localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
    //                     localStorage.setItem("USERNAME", username);
    //                     navigate('/CampMaster');
    //                 } else {
    //                     navigate('/Overview');
    //                     toast.success("LogIn Successful");
    //                     localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
    //                     localStorage.setItem("USERNAME", username);
    //                 }
    //                 const res1 = await getUser()
    //                 if (res1) {
    //                     console.log(res1)
    //                     const user = res1.data.find(e => e.Username === username);
    //                     if (user) {
    //                         localStorage.setItem("GROUP", user.group.id);
    //                     }
    //                 }
    //             }
    //         } catch (error) {
    //             toast.error("Username or password Not Found");
    //         }
    //     }
    // };   

    // const loginFn = async () => {
    //     if (username === "") {
    //         toast.error("Please Enter Username");
    //     } else if (password === "") {
    //         toast.error("Please Enter Password");
    //     } else {
    //         try {
    //             const res = await loginUser({ username, password, rememberMe: false });

    //             if (res) {
    //                 localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
    //                 localStorage.setItem("USERNAME", username);
    //                 try {
    //                     const res1 = await getUser();
    //                     if (res1 && res1.data) {
    //                         const user = res1.data.find(e => e.Username === username);
    //                         toast.success("LogIn Successful");
    //                         navigate('/Overview');
    //                         console.log(user)
    //                         if (user) {
    //                             if (username === "Pmmanger") {
    //                                 navigate('/CampMaster');
    //                             } else {
    //                                 navigate('/Overview');
    //                             }
    //                             localStorage.setItem("GROUP", user.group.id);
    //                         }
    //                     }
    //                 } catch (getUserError) {
    //                     // console.error("Error during getUser:", getUserError);
    //                     // toast.error("Error getting user data");
    //                 }
    //             }
    //         } catch (error) {
    //             toast.error("Username or password Not Found");
    //         }
    //     }
    // };


    // const loginFn = async () => {
    //     try {
    //         if (username === "") {
    //             toast.error("Please Enter Username");
    //         } else if (password === "") {
    //             toast.error("Please Enter Password");
    //         } else {
    //             const res = await loginUser({ username, password, rememberMe: false });

    //             if (res) {
    //                 localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
    //                 localStorage.setItem("USERNAME", username);

    //                 toast.success("LogIn Successful");
    //                 if (username === "Pmmanger") {
    //                     localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
    //                     localStorage.setItem("USERNAME", username);
    //                     navigate('/CampMaster');
    //                 } else {
    //                     const res1 = await getUser();
    //                     if (res1) {
    //                         const user = res1.data.find(e => e.Username === username);
    //                         if (user) {
    //                             console.log(user.group.id)
    //                             localStorage.setItem("GROUP", user.group.id);
    //                         }
    //                     }
    //                     navigate('/Overview');
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.error("Login Error:", error);
    //         toast.error("Username and Password not match");
    //     }
    // };


    const loginFn = async () => {
        if (username === "") {
            toast.error("Please Enter Username");
        } else if (password === "") {
            toast.error("Please Enter Passsword");
        } else {
            setIsLoading(true)
            try {
                const res = await loginUser({ username, password});
                 localStorage.setItem("USERID", res.user.id);
                if (res.token) {
                    
                    localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token);
                    localStorage.setItem("USERNAME", username);
                   
                    // const res1 = await getUser();                    
                    const data1 = fetch(UserManagApi.GetDetail, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            "Accept": "application/json",
                            "Authorization": `Bearer ${res.token}`,
                        },
                    })
                        .then(response => {
                            const contentLength = response.headers.get('content-length');
                            let loaded = 0;
                            return new Response(
                                new ReadableStream({
                                    start(controller) {
                                        const reader = response.body.getReader();
                                        read();
                                        function read() {
                                            reader.read()
                                                .then((progressEvent) => {
                                                    if (progressEvent.done) {
                                                        controller.close();
                                                        return;
                                                    }
                                                    loaded += progressEvent.value.byteLength;
                                                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                                    setProgressData(percentageComplete)
                                                    controller.enqueue(progressEvent.value);
                                                    read();
                                                })
                                        }
                                    }
                                })
                            );
                        })
                        .then(res1 => res1.json())
                        .then((result) => {
                            const user = result.data.find(e => e.Username === username)
                            if (user) {
                                toast.success("LogIn Successful");
                                setIsLoading(false);
                                setProgressData("0%");
                                localStorage.setItem("GROUP", user.group.id);
                                if (user.group.id === 35) {
                                    console.log(true)
                                    navigate('/Visit_Reallocation_User');
                                }
                                else {
                                    console.log(false)
                                    navigate('/Overview');
                                }

                            }
                        });
                }

            } catch (error) {
                setIsLoading(false)
                toast.error("Username or password Not Found");
            }
        }
    };



    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Container fluid className="login-main-bg pt-5">
                <Row className="mt-5 text-center align-items-center justify-content-center">
                    <Col xl="3" className="login-sub">
                        <Row className="mt-4">
                            <Col>
                                <h2>HERDMAN</h2>
                                <p>Sign in to your account</p>
                            </Col>
                        </Row>
                        <Row className="mt-4 justify-content-center">
                            <Col xl="10">
                                <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} className="ps-3" />
                            </Col>
                        </Row>
                        <Row className="mt-3 justify-content-center">
                            <Col xl="10">
                                <input type={showPassword ? 'text' : 'password'} placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} className="ps-3 passInput" />
                                <div className="eyeImg" onClick={handleTogglePassword}>{!showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}</div>
                                <p className="text-end">Forget Password ?</p>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-5 justify-content-center">
                            <Col xl="10">
                                <button onClick={loginFn}>Sign In</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }



            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />
        </>
    )
};
