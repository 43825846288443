import React, { useEffect, useState } from 'react'
import './breedingdetails.css'
import { Col, Container, Row, Offcanvas, Modal } from 'react-bootstrap'
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import remove from '../../Photo/master/remove.svg'
import edit from '../../Photo/master/edit.svg'

import Sidenav from '../../Componet/Sidenav/Sidenav'
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg'
import breeding from '../../Photo/breeding.svg'
import closeButton from '../../Photo/milk-union/close button.svg'
import Select from 'react-select'

import moment, { invalid } from 'moment'
import Dropdown from '../../Componet/Dropdown/Dropdown'
import Table from '../../Componet/DataTable/Table'
import { STORAGE_KEY } from '../../constant/common'
import { API_URLS } from '../../API/Api'
import { useNavigate, useParams } from 'react-router-dom'
import { MdOutlineVpnKey } from 'react-icons/md'
import { GoSignOut } from 'react-icons/go'
import Input from '../../Componet/InputFild/Input'
import dayjs from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { BsTriangleFill } from 'react-icons/bs'
import { BiChevronsRight } from 'react-icons/bi'
import BackBtn from '../../Componet/Button/BackBtn'

const { BreedingDetailsApi, AddAnimal, Cattleregistration, DisposalApi } =
  API_URLS
let partiy_drop_new

export default function BreedingDetails () {
  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN)

  async function getUserData (credentials) {
    return fetch(BreedingDetailsApi.fillGrid, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getLifeTimeData (credentials) {
    return fetch(BreedingDetailsApi.getLifeTimeProduction, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getDryoffData (credentials) {
    return fetch(BreedingDetailsApi.dryOff, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function saveAiData (credentials) {
    return fetch(BreedingDetailsApi.saveAi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function calvingDataBreed (credentials) {
    return fetch(BreedingDetailsApi.calvingSaveparity, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getPdData (credentials) {
    return fetch(BreedingDetailsApi.saveParitySave, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getSireData (credentials) {
    return fetch(AddAnimal.SireData, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getStaffData (credentials) {
    return fetch(AddAnimal.StaffData, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getTreeData (credentials) {
    return fetch(Cattleregistration.TreeByArea, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function calfDataSave (credentials) {
    return fetch(BreedingDetailsApi.calfSave, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function dryReasonData (credentials) {
    return fetch(BreedingDetailsApi.dryResonsApi, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function EditAI (credentials) {
    return fetch(BreedingDetailsApi.editAi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }

  let TagId
  let Parity
  let HerdId
  let LotId
  let FarmerId
  let StatusId
  let BreedingStatusId

  useEffect(() => {
    const fetchData = () => {
      TagId = localStorage.getItem('Fld')
      Parity = localStorage.getItem('Parity')
      HerdId = localStorage.getItem('HerdId')
      LotId = localStorage.getItem('LotId')
      FarmerId = localStorage.getItem('FarmerId')
    }
    fetchData()
    const interval = setInterval(fetchData, 5000)
    return () => clearInterval(interval)
  }, [])

  const lastWeek = new Date()
  const selectedToDate = new Date().toISOString().slice(0, 10)
  const navigate = useNavigate()
  const currentDate = dayjs()

  const [idNo, setIdNo] = useState(TagId)
  const [parity, setParity] = useState(Parity)
  const [parity_drop, setParityDrop] = useState(Parity)
  const [search, setSearch] = useState('')
  const [dateOfDry, setDateOfDry] = useState('')
  const [heatDate, setHeatDate] = useState('')
  const [dryTreatment, setDryTreatment] = useState('1')
  const [dryReason, setDryReason] = useState('')
  const [sire, setSire] = useState('')
  const [service, setService] = useState('')
  const [editId, setEditId] = useState('')
  const [inseminator, setInseminator] = useState('')
  const [aiDate, setAiDate] = useState('')
  const [Dose, setDose] = useState('')
  const [pdDate, setPdDate] = useState('')
  const [pdDone, setPdDone] = useState('')
  const [pdDoneID, setPdDoneID] = useState('')
  const [pdResult, setPdResult] = useState('')
  const [calvingDate, setCalvingDate] = useState(selectedToDate)
  const [calvingType, setCalvingType] = useState('1')
  const [calfSex, setCalfSex] = useState('')
  const [calfId, setCalfId] = useState('')
  const [reproductionProblemNote, setReproductionProblemNote] = useState('')
  const [currentDate1, setCurrentDate1] = React.useState(dayjs())

  const [AIT, setAIT] = useState('')
  const [herdCalvin, setHerdCalvin] = useState('')
  const [progressData, setProgressData] = useState('0%')
  const [userLastData, setUserLastData] = useState('')
  const [lotCalvin, setLotCalvin] = useState('')
  const [farmerCalvin, setFarmerCalvin] = useState('')
  const [calfId1, setCalfId1] = useState('')
  const [parityIdNo, setParityIdNo] = useState(Parity)
  const [index, setIndex] = useState(1)
  const [calfSex2, setCalfSex2] = useState('')
  const [herdName, setHerdName] = useState('')
  const [lotName, setLotName] = useState('')
  const [farmerName, setFarmerName] = useState('')
  const [statusName, setStatusName] = useState('')
  const [breedingStatusName, setBreedingStatusName] = useState('')
  const [herdSelectId, setHerdSelectId] = useState('')
  const [lotSelectId, setLotSelectId] = useState('')
  const [farmerSelectId, setFarmerSelectId] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [deleteParityId, setDeleteParityId] = useState('')
  const [pdResultId, setPdResultId] = useState('')
  const [sireId, setSireId] = useState('')
  const [tableParity, setTableParity] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [searchFilter1, setSearchFilter1] = useState('')
  const [pdDoneUpdate, setPdDoneUpdate] = useState('')
  const [pdResultUpdate, setPdResultUpdate] = useState('')
  const [pdDoneUpdateId, setPdDoneUpdateId] = useState(null)
  const [pdResultUpdateId, setPdResultUpdateId] = useState(null)

  const [heat, setHeat] = useState(true)
  const [calving, setCalving] = useState(false)
  const [dry, setDry] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)
  const [deleteShow1, setDeleteShow1] = useState(false)
  const [pdModalShow, setPdModalShow] = useState(false)
  const [editModalShow, setEditModalShow] = useState('')
  const [editModalShow1, setEditModalShow1] = useState('')

  const [refFnAct, setRefFnAct] = useState(false)

  const [heightOffSet, setHeight] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [headerShow, setHeaderShow] = useState(true)
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setHeaderShow(!headerShow)
  }
  const [update, setUpdate] = useState(false)
  const [aiSave, setAiSave] = useState(true)
  const [parityStatus, setParityStatus] = useState(false)

  const [lastRecord1, setLastRecord1] = useState(null)

  const [userDataList, setUserDataList] = useState([])
  const [lifeDataList, setLifeDataList] = useState([])
  const [pdDataList, setPdDataList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [sireList, setSireList] = useState([])
  const [inseminatorList, setInseminatorList] = useState([])
  const [columnData, setColumnData] = useState([])
  const [dataList, setDataList] = useState([])
  const [disposalType, setDisposalType] = useState([])
  const [dryReasonList, setDryReasonList] = useState([])
  const [calfSexList, setCalfSexList] = useState([
    { value: '1', label: 'Male' },
    { value: '2', label: 'FeMale' }
  ])

  const [pdDone1, setPdDone1] = useState('')
  const [pdDate1, setPdDate1] = useState('')
  const [pdResult1, setPdResult1] = useState('')

  const menuitm = ['1', '2', '3']

  const manualOptions = [
    { value: '1', label: 'A.I' },
    { value: '2', label: 'NATURAL' },
    { value: '3', label: 'SKIP' },
    { value: '4', label: 'E.T. (Embryo Transfer)' },
    { value: '5', label: 'Natural and A.I' }
  ]

  const doseOption = [
    { value: '1', label: '1' },
    { value: '2', label: '2' }
  ]

  const pdResultList = [
    { value: '1', label: 'EMPTY' },
    { value: '2', label: 'DOUBTFUL' },
    { value: '3', label: 'PREGNANT' },
    { value: '4', label: 'PDRESULTLIST' }
  ]

  const logOut = () => {
    navigate('/')
  }

  const custMe = () => {
    setHeat(true)
    setCalving(false)
    setDry(false)
  }
  const sum = () => {
    setCalving(true)
    setHeat(false)
    setDry(false)
  }
  const sum_ = () => {
    setDry(true)
    setHeat(false)
    setCalving(false)
  }

  const parirtSelectFn = async e => {
    // console.log(e)
    setCalvingDate(e['Calving Date'])
    setParityStatus(true)
    setDateOfDry(e['Dry Date'])
    setTableParity(e.parity)
    await fetch(BreedingDetailsApi.fillGrid, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ tagid: idNo, Parity: parseInt(e.parity) })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start (controller) {
              const reader = response.body.getReader()
              read()
              function read () {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(data => data.json())
      .then(result => {
        // console.log(result)
        setPdDone1(result[0]['PD Done'])
        setPdDate1(result[0]['PD Date'])
        setPdResult1(result[0]['PD2 Result'])
        // console.log('res :' + pdResult1)
        // console.log('done :' + pdDone1)
        // console.log('date :' + pdDate1)
        setUserDataList(result)
        const record = res[res.length - 1]
        setLastRecord1(record)
        // console.log('lastRecord1 : ' + JSON.stringify(lastRecord1))

        // console.log('user : ' + userDataList)
        setIsLoading(false)
        setProgressData('0%')
      })
  }

  const selectedOption = pdResultList.find(item => item.value === pdResult1)
  const selectedLabel = selectedOption ? selectedOption.label : pdResult1

  const calvingdeleteFn = async () => {
    setIsLoading(true)
    await fetch(
      `${BreedingDetailsApi.deleteTagId1}/${deleteParityId}/${idNo}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        }
      }
    )
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start (controller) {
              const reader = response.body.getReader()
              read()
              function read () {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(data => data.json())
      .then(result => {
        toast.success('Deleted Successfully!')
        setDeleteShow1(!deleteShow1)
        setIsLoading(false)
        setProgressData('0%')
        refFn()
        getData()
      })
      .catch(error => {
        console.error('Error:', error)
        setIsLoading(false)
      })
  }

  const deleteFn = async () => {
    setIsLoading(true)
    await fetch(`${BreedingDetailsApi.deleteTagId}/${parseInt(deleteId)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      }
      // body: JSON.stringify({ id: deleteId })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start (controller) {
              const reader = response.body.getReader()
              read()
              function read () {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(data => data.json())
      .then(result => {
        toast.success('Deleted Successfully!')
        setDeleteShow(!deleteShow)
        setIsLoading(false)
        setProgressData('0%')
        refFn()
        getData()
      })
  }
  const editFn = e => {
    // console.log(JSON.stringify(e) + '  e')
    // console.log(idNo + '  id')
    setEditId(e.ID)
    setEditModalShow(true)
    setAiDate(dayjs(e['A.IDate']))
    setService(e.Service)
    setInseminator(e.Inseminator)
    setSire(e.Sire)
    setDose(e.Dose)
    setPdDone(e['PD Done'])
    setPdResult(e['PD2 Result'])
    setPdDate1(dayjs(e['PD Date']))

    const selectedSireLabel = e['Sire']
    const selectedSireValue = sireList.find(
      item => item.Name === selectedSireLabel
    )?.id

    setSire(e.Sire)
    setSireId(selectedSireValue)

    const selectedPdResultLabel1 = e['PD2 Result']
    const selectedPdResultValue1 = inseminatorList.find(
      item => item.label === selectedPdResultLabel
    )?.value
    setPdDoneID(selectedPdResultValue1)

    const selectedPdResultLabel = e['PD2 Result']
    const selectedPdResultValue = pdResultList.find(
      item => item.label === selectedPdResultLabel
    )?.value
    setPdResult(e['PD2 Result'])
    setPdResultId(selectedPdResultValue)
    subDetails(idNo)
  }

  const Editfn1 = e => {
    setEditModalShow1(true)
    setPdDone1(e['PD Done'])
    setPdDate1(dayjs(e['PD Date']))
    setPdResult1(e['PD1 Result'])
  }

  const deleteItem1 = e => {
    setDeleteParityId(e.parity)
    getData()
    setDeleteShow1(!deleteShow1)
  }

  const deleteItem = e => {
    setDeleteId(e.ID)
    getData()
    setDeleteShow(!deleteShow)
  }
  const column = [
    {
      name: 'Action',
      cell: e => (
        <div>
          <img
            src={edit}
            className='ms-2'
            width={18}
            onClick={() => editFn(e)}
          />
          <img
            src={remove}
            className='ms-2'
            width={18}
            onClick={() => deleteItem(e)}
          />
        </div>
      ),
      width: '70px'
    },
    {
      name: 'No.',
      selector: 'Sl No',
      cell: e => (
        <div
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
          className=''
        >
          {e['Sl No']}
        </div>
      ),
      width: '50px',
      sortable: true
    },
    {
      name: 'A.I Date',
      selector: 'A.IDate',
      cell: e => (
        <div
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {dayjs(e['A.IDate']).format('DD-MM-YYYY')}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'Service',
      selector: 'Service',
      cell: e => (
        <div
          style={{
            paddingLeft: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
          className=''
        >
          {e['Service']}
        </div>
      ),
      width: '80px',
      sortable: true
    },
    {
      name: 'Insemniator',
      selector: 'Inseminator',
      cell: e => (
        <div
          style={{
            paddingLeft: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
          className=''
        >
          {e['Inseminator']}
        </div>
      ),
      width: '160px',
      sortable: true
    },
    {
      name: 'Sire',
      selector: 'Sire',
      cell: e => (
        <div
          style={{
            paddingLeft: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
          className=''
        >
          {e['Sire']}
        </div>
      ),
      width: '80px',
      sortable: true
    },
    {
      name: 'Dose',
      selector: 'Dose',
      cell: e => (
        <div
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
          className=''
        >
          {e['Dose']}
        </div>
      ),
      width: '65px',
      sortable: true
    },
    {
      name: 'PD Date',
      selector: 'PD Date',
      cell: e => {
        const formattedDate = moment(e['PD Date']).format('DD-MM-YYYY')
        // console.log('formattedDate  ' + formattedDate)
        return (
          <div className='' style={{ paddingRight: '5px' }}>
            {formattedDate === 'Invalid date' ? '' : formattedDate}
          </div>
        )
      },
      width: '120px',
      sortable: true
    },
    {
      name: 'PD Done',
      selector: 'PD Done',
      cell: e => (
        <div
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
          className=''
        >
          {e['PD Done']}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'PD2 Result',
      selector: 'PD2 Result',
      cell: e => (
        <div
          style={{
            paddingLeft: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
          className=''
        >
          {e['PD2 Result']}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'PD Result',
      selector: 'PD Result',
      cell: e => (
        <div
          style={{
            paddingLeft: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
          className=''
        >
          {e['PD Result']}
        </div>
      ),
      width: '100px',
      sortable: true
    },
    {
      name: 'H.I',
      selector: 'HI',
      cell: e => (
        <div
          style={{
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
          className=''
        >
          {e['HI']}
        </div>
      ),
      width: '55px',
      sortable: true
    }
  ]

  const column2 = [
    {
      name: 'No.',
      selector: '',
      width: '30px',
      sortable: true
    },
    {
      name: 'Treatment',
      cell: e => (
        <Dropdown
          label=''
          menuList={menuitm}
          placeholder='Heat Treaement'
          className='mt-2'
        />
      ),
      sortable: true
    },
    {
      name: 'Observation.',
      selector: row => row.Observation,
      sortable: true
    }
  ]

  const column3 = [
    {
      name: '',
      cell: e => (
        <div
          className='align-items-center d-flex rightArrow'
          onClick={() => parirtSelectFn(e)}
          style={{ cursor: 'pointer' }}
        >
          <BiChevronsRight size='25' color='green' className='me-2' />
        </div>
      ),
      width: '50px',
      sortable: true
    },
    {
      name: 'Action',
      cell: e => (
        <div>
          <img
            src={edit}
            className='ms-2'
            width={18}
            onClick={() => Editfn1(e)}
          />
          <img
            src={remove}
            className='ms-2'
            width={18}
            onClick={() => deleteItem1(e)}
          />
        </div>
      ),
      width: '60px',
      sortable: true
    },
    {
      name: 'Parity',
      selector: 'parity',
      cell: e => (
        <div
          className='align-items-center d-flex rightArrow'
          onClick={() => parirtSelectFn(e)}
          style={{ cursor: 'pointer' }}
        >
          <div>{e.parity}</div>
        </div>
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Calving Date',
      selector: 'Calving Date',
      cell: e => <>{moment(e['Calving Date']).format('DD-MM-YYYY')}</>,
      width: '111px',
      sortable: true
    },
    {
      name: 'Sire',
      selector: 'Sire',
      cell: e => <div className='ms-auto'>{e['Sire']}</div>,
      width: '90px',
      sortable: true
    },
    {
      name: 'Heat Seq',
      selector: 'Heat Seq',
      cell: e => <div className='ms-auto'>{e['Heat Seq']}</div>,
      width: '70px',
      sortable: true
    },
    {
      name: 'Calving Type',
      selector: 'Calving Type',
      cell: e => (
        <div
          className='ms-auto'
          style={{
            paddingLeft: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          {e['Calving Type'] ? e['Calving Type'] : ''}
        </div>
      ),
      width: '115px',
      sortable: true
    },
    {
      name: 'Calf ID',
      selector: 'Calf ID',
      cell: e => <div className='ms-auto'>{e['Calf ID']}</div>,
      width: '70px',
      sortable: true
    },
    {
      name: 'Calf Sex',
      selector: 'Calf Sex',
      cell: e => <div className='ms-auto'>{e['Calf Sex']}</div>,
      width: '70px',
      sortable: true
    },
    {
      name: 'Dry Date',
      selector: 'Dry Date',
      cell: e => (
        <div className='ms-auto'>
          {e['Dry Date'] ? moment(e['Dry Date']).format('DD-MM-YYYY') : ''}
        </div>
      ),
      width: '120px',
      sortable: true
    },
    {
      name: 'SP',
      selector: 'SP',
      cell: e => <div className='ms-auto'>{e['SP']}</div>,
      width: '70px',
      sortable: true
    },
    {
      name: 'CC',
      selector: 'CC',
      cell: e => <div className='ms-auto'>{e['CC']}</div>,
      width: '70px',
      sortable: true
    },
    {
      name: 'DP',
      selector: 'DP',
      cell: e => <div className='ms-auto'>{e['DP']}</div>,
      width: '70px',
      sortable: true
    },
    {
      name: 'CI',
      selector: 'CI',
      cell: e => <div className='ms-auto'>{e['CI']}</div>,
      width: '70px',
      sortable: true
    },
    {
      name: 'Avg Yield',
      selector: 'Avg Yield',
      cell: e => <div className='ms-auto'>{e['Avg Yield']}</div>,
      width: '90px',
      sortable: true
    },

    {
      name: 'MI',
      selector: 'MI',
      cell: e => <div className='ms-auto'>{e['MI']}</div>,
      width: '70px',
      sortable: true
    },
    {
      name: 'Milk Yield',
      selector: 'Milk Yield',
      cell: e => <div className='ms-auto'>{e['Milk Yield']}</div>,
      width: '100px',
      sortable: true
    },
    {
      name: 'Peak Days',
      selector: 'Peak Days',
      cell: e => <div className='ms-auto'>{e['Peak Days']}</div>,
      width: '80px',
      sortable: true
    },
    {
      name: 'Peak Yield',
      selector: 'Peak Yield',
      cell: e => <div className='ms-auto'>{e['Peak Yield']}</div>,
      width: '100px',
      sortable: true
    }
  ]

  const RefreshSubDetails = () => {
    localStorage.removeItem('Fld')
    localStorage.removeItem('Parity')
    localStorage.removeItem('HerdId')
    localStorage.removeItem('LotId')
    localStorage.removeItem('FarmerId')
    localStorage.removeItem('StatusId')
    localStorage.removeItem('BreedingStatusId')
  }
  const refFn1 = () => {
    // setIsLoading(false)
    // setProgressData('0%')
    // getDataById()
    refFn()
    getData()
    getRefreshAPi()
    // setUserDataList([])
    // setLifeDataList([])
    // setSireList([])
    // setInseminatorList([])
    // setHerdName('')
    // setHerdSelectId(null)
    // setLotSelectId(null)
    // setFarmerSelectId(null)
    // setFarmerName('')
    // setStatusName('')
    // setBreedingStatusName('')
    // setLotName('')
    // setHerdCalvin(null)
    // setLotCalvin(null)
    // setFarmerCalvin(null)
    // setParityIdNo(null)
    // selectIdNo('')
    // setParityStatus(false)
    // setTableParity('')
    // setIdNo(null)
    // setParity('')
    // setHerdSelectId('')
    // setHerdCalvin('')
    // localStorage.removeItem('Fld')
    // localStorage.removeItem('Parity')
    // localStorage.removeItem('HerdId')
    // localStorage.removeItem('LotId')
    // localStorage.removeItem('FarmerId')
    // localStorage.removeItem('StatusId')
    // localStorage.removeItem('BreedingStatusId')
  }

  const subDetails = async e => {
    // setHerdName('')
    // setHerdSelectId('')
    // setLotSelectId('')
    // setFarmerSelectId('')
    // setFarmerName('')
    // setStatusName('')
    // setBreedingStatusName('')
    // setLotName('')
    // setHerdCalvin('')
    // setLotCalvin('')
    // setFarmerCalvin('')
    // setParityIdNo('')
    try {
      // console.log('aa')
      const response = await fetch(
        `${Cattleregistration.AnimalData1}?id=${e}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${added_by}`
          }
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const result = await response.json()

      if (result.animalData) {
        setHerdName(result.animalData.herdName)
        setHerdSelectId(result.animalData.herdId)
        setLotSelectId(result.animalData.lotId)
        setFarmerSelectId(result.animalData.farmerId)
        setFarmerName(result.animalData.farmerName)
        setStatusName(result.animalData.statusName)
        setBreedingStatusName(result.animalData.BreedingStatus)
        setLotName(result.animalData.lotName)

        const lastParity = result.parityFilter
          ? result.parityFilter[result.parityFilter.length - 1]?.parity
          : ''

        setHerdCalvin(result.animalData.herdId)
        setLotCalvin(result.animalData.lotId)
        setFarmerCalvin(result.animalData.farmerId)
        setParityIdNo(lastParity)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  // useEffect(() => {
  //   // console.log('sub : ' + idNo)

  //   const callSubDetails = () => {
  //     subDetails(idNo)
  //   }

  //   callSubDetails()

  //   const intervalId = setInterval(callSubDetails, 5000)

  //   return () => clearInterval(intervalId)
  // }, [idNo])

  useEffect(() => {
    // console.log('lastRecord1 : ' + JSON.stringify(lastRecord1))
    const fetchData = async () => {
      try {
        const res3 = await getSireData()
        const res4 = await getStaffData()

        if (res3) {
          setSireList(res3)
          setIsLoading(false)
        }
        if (res4) {
          setInseminatorList(res4)
          setIsLoading(false)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        setIsLoading(false)
      }
    }
    subDetails(TagId ? TagId : idNo)
    fetchData()
  }, [])

  const selectIdNo = async e => {
    // console.log('lastRecord1 : ' + JSON.stringify(lastRecord1))

    setUserDataList([])
    setLifeDataList([])
    setSireList([])
    setInseminatorList([])
    // RefreshSubDetails();
    const res1 = await getLifeTimeData({ detailsId: e.label ? e.label : 0 })
    setLifeDataList(res1)
    // console.log('setLifeDataList : ' + JSON.stringify(lifeDataList))
    setSearchFilter(res1)

    // const p1 = res1[0].parity;
    setIsLoading(true)
    try {
      subDetails(e.label)
      //   console.log('Priyank ' + e.label)
      setIdNo(e.label)
      setIsLoading(true)
      const res1 = await getLifeTimeData({ detailsId: e.label })
      const res3 = await getSireData()
      const res4 = await getStaffData()

      if (res1) {
        setLifeDataList(res1)
        setIsLoading(false)
      }
      if (res3) {
        setSireList(res3)
        setIsLoading(false)
      }
      if (res4) {
        setInseminatorList(res4)
        setIsLoading(false)
      }
      await fetch(Cattleregistration.AnimalGet, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        },
        body: JSON.stringify({ tagId: e.label })
      })
        .then(response => {
          const contentLength = response.headers.get('content-length')
          let loaded = 0
          return new Response(
            new ReadableStream({
              start (controller) {
                const reader = response.body.getReader()
                read()
                function read () {
                  reader.read().then(progressEvent => {
                    if (progressEvent.done) {
                      controller.close()
                      return
                    }
                    loaded += progressEvent.value.byteLength
                    const percentageComplete =
                      Math.round((loaded / contentLength) * 100) + '%'
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value)
                    read()
                  })
                }
              }
            })
          )
        })
        .then(res => res.json())
        .then(result => {
          partiy_drop_new = result[13]['Fld'].toString()
          setParityDrop(result[13]['Fld'].toString())
          setParity(result[13]['Fld'])
          setIsLoading(false)
          setProgressData('0%')
        })
      // console.log("Value2" + parity_drop);

      await fetch(BreedingDetailsApi.fillGrid, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        },
        body: JSON.stringify({ tagid: e.label, Parity: partiy_drop_new })
      })
        .then(response => {
          const contentLength = response.headers.get('content-length')
          let loaded = 0
          return new Response(
            new ReadableStream({
              start (controller) {
                const reader = response.body.getReader()
                read()
                function read () {
                  reader.read().then(progressEvent => {
                    if (progressEvent.done) {
                      controller.close()
                      return
                    }
                    loaded += progressEvent.value.byteLength
                    const percentageComplete =
                      Math.round((loaded / contentLength) * 100) + '%'
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value)
                    read()
                  })
                }
              }
            })
          )
        })
        .then(data => data.json())
        .then(result => {
          setUserDataList(result)
          const lastRecord = result[result.length - 1]
          setLastRecord1(lastRecord)

          // let lastRecord = result.slice(-1)[0];
          // let pregnantValue = lastRecord["PD Result"];
          // if (pregnantValue.includes("PREGNANT")) {
          //     setAiSave(false)
          // }
          // else {
          //     setAiSave(true)
          // }
          setIsLoading(false)
          setProgressData('0%')
        })
    } catch (error) {
      // console.log(error);
      setIsLoading(false)
    }
  }

  const animalDataGet = async () => {
    await fetch(Cattleregistration.AnimalGet, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ tagId: TagId ? TagId : idNo })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')

        let loaded = 0
        return new Response(
          new ReadableStream({
            start (controller) {
              const reader = response.body.getReader()
              read()
              function read () {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(res => res.json())
      .then(result => {
        // console.log("Result from server is " + result[13]["Fld"].toString());
        setParityDrop(result[13]['Fld'].toString())
        setParity(result[13]['Fld'])
        setIsLoading(false)
        setProgressData('0%')
      })
  }

  const getData = async () => {
    setParityStatus(false)
    animalDataGet()
    const res3 = await getSireData()
    const res4 = await getStaffData()
    const res1 = await getLifeTimeData({ detailsId: TagId ? TagId : idNo })

    if (res1) {
      setLifeDataList(res1)
    }
    const res = await getUserData({
      Parity: parseInt(parity) ? parseInt(parity) : 0,
      tagid: idNo ? idNo : TagId
    })
    // console.log("data2");
    const res5 = await dryReasonData()
    if (res3) {
      setSireList(res3)
    }
    if (res4) {
      setInseminatorList(res4)
    }
    if (res) {
      setUserDataList(res)
      console.log('res  : ' + JSON.stringify(res))
      const lastRecord = res[res.length - 1]
      setLastRecord1(lastRecord)
    }

    if (res5) {
      setDryReasonList(res5.master_dryOffReason)
    }
    console.log('res5')
  }

  const getRefreshAPi = async () => {
    setRefFnAct(true)
    setTableParity('')
    setParityStatus(false)
    const res3 = await getSireData()
    const res4 = await getStaffData()
    const res = await getUserData({
      parity: parseInt(parity),
      tagid: idNo ? idNo : TagId
    })
    const res1 = await getLifeTimeData({ detailsId: TagId ? TagId : idNo })
    const res5 = await dryReasonData()

    if (res3) {
      setSireList(res3)
    }
    if (res4) {
      setInseminatorList(res4)
    }
    if (res) {
      setUserDataList(res)
      const lastRecord = res[res.length - 1]
      setLastRecord1(lastRecord)
    }
    if (res1) {
      setLifeDataList(res1)
    }
    if (res5) {
      setDryReasonList(res5.master_dryOffReason)
    }
  }

  const lastRecord = userDataList[userDataList?.length - 1]
  const isLastRecordPregnant =
    lastRecord && lastRecord['PD2 Result'] === 'PREGNANT'

  const getDataById = async () => {
    const res = await getTreeData({
      Herdcode: '1',
      Herdtype: 'Animal',
      userCode: ''
    })
    if (res) {
      setDataList(res)
      // console.log(res + " res ");
    }
  }

  useEffect(() => {
    // console.log("lastRecord1lastRecord1  " + JSON.stringify(lastRecord1))
    getData()
    let divHeight = document.getElementById('heightOffsetStock')?.offsetHeight
    setHeight(divHeight)
    getDataById()
    setIdNo(TagId ? TagId : idNo)
  }, [parity])

  const dryOff = async () => {
    if (idNo === null) {
      toast.error('Please Select TagId')
    }
    if (dateOfDry === '') {
      toast.error('Please Select Dry Date')
    } else {
      const res2 = await getDryoffData({
        dateOfDry,
        dryReason,
        dryTreatment: dryTreatment,
        heatDate,
        tagId: TagId ? TagId : idNo
      })
      if (res2) {
        animalDataGet()
        custMe()
        refFn()
        subDetails(TagId ? TagId : idNo)
        getData()
        toast.success('Dryoff  Successful')
      }
    }
  }

  const Add = () => {
    setModalShow(true)
    setAiDate('')
    setService('')
    setInseminator('')
    setSire('')
    setDose('')
    setPdDone('')
    setPdResult('')
  }
  const pdUpdate = e => {
    setPdModalShow(true)
    setAiDate('')
    setService('')
    setInseminator('')
    setSire('')
    setDose('')
    setPdDone('')
    setPdResult('')
    setPdDone1(e['PD Done'])
    setPdDate1(dayjs(e['PD Date']))
    setPdResult1(e['PD1 Result'])

    setPdDoneUpdate(lastRecord['PD Done'] || '')
    setPdResultUpdate(lastRecord['PD2 Result'] || '')

    const selectedSireLabel1 = e['PD Done']
    const selectedSireValue1 = lastRecord.find(
      item => item.Name === selectedSireLabel1
    )?.id
    setPdDoneUpdateId(selectedSireValue1)

    const selectedSireLabel = e['PD2 Result']
    const selectedSireValue = lastRecord.find(
      item => item.Name === selectedSireLabel
    )?.id
    setPdResultUpdateId(selectedSireValue)
  }

  useEffect(() => {
    // console.log('pd done  ' + pdDoneUpdate)
  }, [pdDoneUpdate])

  useEffect(() => {
    // console.log('pd res  ' + pdResultUpdate)
  }, [pdResultUpdate])

  useEffect(() => {
    // console.log('pd done id ' + pdDoneUpdateId)
  }, [pdDoneUpdateId])

  useEffect(() => {
    // console.log('pd res id ' + pdResultUpdateId)
  }, [pdResultUpdateId])

  // useEffect(() => {
  //   pdUpdate()
  // }, [])

  const refFn = () => {
    setCalvingType('')
    setCalfSex('')
    setCalfId('')
  }

  const AddData = async () => {
    const adjustedDate = new Date(aiDate)
    adjustedDate.setHours(adjustedDate.getHours() - 6)
    adjustedDate.setDate(adjustedDate.getDate() + 1)
    const formattedDate = adjustedDate.toISOString()

    const res = await saveAiData({
      service: parseInt(service) ? parseInt(service) : 0,
      AIT: inseminator ? inseminator : 0,
      sire: sire ? sire : 0,
      HeatDate: formattedDate,
      createdByUser: '1',
      TagId: TagId ? TagId : idNo,
      TotalAIDose: Dose ? Dose : 0,
      InseminationTicketNumber: '0',
      Lat: '0',
      Long: '0',
      herd: parseInt(herdSelectId) ? parseInt(herdSelectId) : 0,
      lot: parseInt(lotSelectId) ? parseInt(lotSelectId) : 0,
      farmer: parseInt(farmerSelectId) ? parseInt(farmerSelectId) : 0,
      StrawImage: '0',
      SortedSemon: '0',
      BatchNo: '0',
      ENTRY: '0'
    })
    if (res) {
      refFn()
      getData()
      toast.success('Add A.I Successfully')
      setModalShow(false)
      subDetails(TagId ? TagId : idNo)
    } else {
      toast.error('Something went wrong !')
      setModalShow(false)
    }
  }

  const EditData = async () => {
    try {
      const pdDateFormate = dayjs(pdDate1).format('DD-MM-YYYY')

      // const isNumericPdResult = !isNaN(pdResult)
      // const pd2Value = isNumericPdResult ? pdResult : pdResultId

      // const isNumericPdResult1 = !isNaN(sire)
      // const sireValue = isNumericPdResult1 ? sire : sireId
      // console.log('sireValue' + sireValue)

      // let formattedDate = null

      // if (pdDate && !isNaN(new Date(pdDate))) {
      //   // console.log("pd date cmp");
      //   const adjustedDate = new Date(pdDate)
      //   adjustedDate.setHours(adjustedDate.getHours() - 6)
      //   adjustedDate.setDate(adjustedDate.getDate() + 1)
      //   formattedDate = adjustedDate.toISOString()
      // }
      // console.log('pdDateFormate ' + pdDateFormate)
      // console.log('pdDateFormate ' + pdDateFormate)
      // console.log('pdDone111111111 : ' + pdDone)
      const res = await EditAI({
        id: editId,
        HeatDate: aiDate,
        Sire: sireId ? sireId : null,
        AIDone: 2,
        Dose: Dose,
        PDDoneby: pdDoneID ? pdDoneID : pdDoneUpdateId ? pdDoneUpdateId : null,
        PDDate: pdDate1 ? pdDate1 : pdResultUpdateId ? pdResultUpdateId : null,
        PD2: pdResultId ? pdResultId : null
      })

      if (res) {
        subDetails(idNo)
        refFn()
        getData()
        toast.success('Edit Record Successfully')
        setEditModalShow(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updatePd = async () => {
    // console.log('Updating P.D with the following data:')
    // console.log('pdDoneUpdate: ', pdDoneUpdate)
    // console.log('pdResultUpdate: ', pdResultUpdate)

    const res1 = await getPdData({
      Parity: parseInt(parity) ? parseInt(parity) : 0,
      TagId: TagId ? TagId : idNo,
      AIT: parseInt(pdDoneUpdateId) ? parseInt(pdDoneUpdateId) : 0,
      PDDate: pdDate1 ? pdDate1 : 0,
      Result: parseInt(pdResultUpdateId) ? parseInt(pdResultUpdateId) : 0,
      ENTRY: 0,
      createdByUser: 0,
      lastUpdatedByUser: 0,
      Lat: 0,
      Long: 0,
      herd: HerdId ? HerdId : herdSelectId ? herdSelectId : null,
      lot: LotId ? LotId : lotSelectId ? lotSelectId : null,
      farmer: FarmerId ? FarmerId : farmerSelectId ? farmerSelectId : null,
      createdAt: selectedToDate,
      updatedAt: selectedToDate
    })

    if (res1) {
      refFn()
      getData()
      subDetails(idNo)
      toast.success('P.D Update Successful')
      setPdModalShow(false)
      subDetails(TagId ? TagId : idNo)
    } else {
      console.error('P.D Update Failed')
    }
  }
  // const updatePd = async () => {
  //   const res1 = await getPdData({
  //     Parity: parseInt(parity) ? parseInt(parity) : 0,
  //     TagId: TagId ? TagId : idNo,
  //     AIT: 0,
  //     PDDate: pdDate1 ? pdDate1 : 0,
  //     Result: parseInt(pdResultUpdate) ? parseInt(pdResultUpdate) : 0,
  //     ENTRY: parseInt(pdDoneUpdate) ? parseInt(pdDoneUpdate) : 0,
  //     createdByUser: 0,
  //     lastUpdatedByUser: 0,
  //     Lat: 0,
  //     Long: 0,
  //     herd: HerdId,
  //     lot: LotId,
  //     farmer: FarmerId,
  //     createdAt: selectedToDate,
  //     updatedAt: selectedToDate
  //   })
  //   if (res1) {
  //     refFn()
  //     getData()
  //     toast.success('P.D Update Successful')
  //     setPdModalShow(false)
  //     subDetails(TagId ? TagId : idNo)
  //   }
  // }
  const problem = e => {}

  const paritySelect = e => {
    if (e.target.value.length <= 2) {
      setParity(e.target.value)
    }
  }

  const saveBreeding = async () => {
    // console.log('calvingType : ' + herdCalvin)
    if (calvingDate !== '') {
      const res = await calvingDataBreed({
        Parity: tableParity ? tableParity : parity,
        TagId: TagId ? TagId : idNo,
        CalvingDate: calvingDate ? calvingDate : moment('YYYY-MM-DD'),
        CalvingType: calvingType ? calvingType : '1',
        CalfSex: calfSex ? calfSex : 0,
        ReproductionProblemNote: '',
        Comments: '',
        ENTRY: '1',
        Lat: 1,
        Long: 1,
        Sex: 1,
        calvingTypeOption: 1,
        staff: 1,
        createdByUser: 1,
        herd: HerdId
          ? HerdId
          : herdCalvin
          ? herdCalvin
          : herdSelectId
          ? herdSelectId
          : '' || null,
        lot: LotId
          ? LotId
          : lotCalvin
          ? lotCalvin
          : lotSelectId
          ? lotSelectId
          : '' || null,
        farmer: FarmerId
          ? FarmerId
          : farmerCalvin
          ? farmerCalvin
          : farmerSelectId
          ? farmerSelectId
          : '' || null,
        CalfID: '',
        Calf2Sex: 1,
        CalfID: calfId !== '' ? calfId : '0',
        Calf2ID: calfId1 !== '' ? calfId1 : '0',
        image1: '',
        image2: '',
        CalvingTicketNumber: 1,
        createdAt: selectedToDate
      })
      if (res) {
        subDetails(idNo)
        setHeat(true)
        setCalving(false)
        setDry(false)
        refFn()
        getData()
        toast.success('Calving Add Successfully')
      }
    }
  }

  const handleDateChange = e => {
    const selectedDate = e.$d
    setAiDate(e.$d)
    // console.log("ai date : " + aiDate);
  }

  const formatDate = date => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    return date.toLocaleDateString('en-IN', options).replace(/\//g, '-')
  }

  const handleDateChangePdDate = e => {
    const selectedDate = e.$id
    setPdDate(e.$id)
  }

  const SearchFn = (e, value) => {
    const data = e.target.value
    if (data !== '' && value === 'userData') {
      const results = lifeDataList.filter(user => {
        return user[0]?.parity?.toString().startsWith(data)
      })
      // setSearchFilter(results);
    } else if (data !== '' && value === 'lifeReproduction') {
      const results1 = userDataList.filter(user2 => {
        return user2.Inseminator?.toLowerCase().startsWith(data.toLowerCase())
      })
      setSearchFilter1(results1)

      // console.log(results1);
    } else if (data == '' && value === 'userData') {
      // setSearchFilter(lifeDataList);
      // console.log(searchFilter);
    } else {
      setSearchFilter1(userDataList)
    }
    setSearch(data)
    // console.log();
  }

  return (
    <>
      <Container fluid id='breed'>
        <Row>
          {headerShow && (
            <Col lg='2' className='hideNavbar'>
              <Sidenav />
            </Col>
          )}
          <Col lg={headerShow ? '10' : '12'} className='header'>
            <Container fluid>
              <Row className='pt-1 sub-header align-items-center justify-content-between'>
                <Col className='py-2 d-flex align-items-center'>
                  <button
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    onClick={handleShow}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <button
                    style={{ border: 'none' }}
                    className='hide_Navbar'
                    onClick={() => setShow(true)}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <h5 className='ms-4 mt-2'>
                    Cattle Details / Breeding Details
                  </h5>
                </Col>
                <Col
                  md='1'
                  sm='1'
                  xs='10'
                  className='py-2 d-flex align-items-center text-end ms-auto'
                >
                  <BackBtn
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    lable='Back'
                    onClick={() => {
                      navigate('/')
                    }}
                  />
                </Col>
                <Col md='1' sm='1' xs='2' className='text-end profile_dropdown'>
                  <img src={profile} width={40} />
                  <div className='dropdpwn_Open'>
                    <Row className='text-center align-items-center'>
                      <Col xs='12' className='menuHover mb-3'>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs='12' className='menuHover' onClick={logOut}>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row
                className='pt-1 sub-header1 align-items-center justify-content-between'
                style={{ background: 'white' }}
              >
                <Col xl='10' className='d-flex justify-content-start'>
                  <div className='ms-2 mt-2'>
                    <img src={breeding} width={40} />
                  </div>
                  <div className='ms-4'>
                    <h5 className='mb-0'>Breeding Details</h5>
                    <p className='mb-1 mt-1'>
                      Individual animal Al and PD entry: In case you want to
                      enter Al and subsequently PD of the individual animal can
                      be done by selecting animals it can be done by selecting
                      animals and file all information.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row
                className='align-items-center justify-content-start mb-2 mt-2'
                style={{ backgroundColor: '#ECECEC', padding: '5px' }}
              >
                <Col
                  xl='2'
                  md='3'
                  sm='4'
                  xs='12'
                  className='align-items-center'
                >
                  {console.log('tag id..' + idNo)}
                  <Select
                    placeholder={TagId ? TagId : idNo ? idNo : 'Tag Id'}
                    value={idNo}
                    onChange={selectedOption => {
                      selectIdNo(selectedOption)
                    }}
                    options={dataList?.map(item => ({
                      value: item.Animalid,
                      label: item.Animal
                    }))}
                  />
                </Col>

                <Col
                  xl='1'
                  md='2'
                  sm='4'
                  xs='12'
                  className='align-items-center'
                >
                  {/* <TextField label="Parity" placeholder="01" id="standard-basic" fullWidth variant="standard" value={parity} onChange={(e) => paritySelect(e)} /> */}
                  <div className=' d-flex align-items-center idNo'>
                    <h6 style={{ paddingRight: '5px' }} className='mb-0'>
                      Herd :{' '}
                    </h6>
                    <input
                      type='text'
                      style={{ width: '50%', backgroundColor: 'white' }}
                      value={herdName}
                      disabled
                      onChange={e => {
                        setHerdName(e.target.value)
                      }}
                    />
                  </div>
                </Col>
                <Col
                  xl='1'
                  md='2'
                  sm='4'
                  xs='12'
                  className='align-items-center'
                >
                  {/* <TextField label="Parity" placeholder="01" id="standard-basic" fullWidth variant="standard" value={parity} onChange={(e) => paritySelect(e)} /> */}
                  <div className=' d-flex align-items-center idNo'>
                    <h6 style={{ paddingRight: '5px' }} className='mb-0'>
                      Lot :{' '}
                    </h6>
                    <input
                      type='text'
                      style={{ width: '60%', backgroundColor: 'white' }}
                      value={lotName}
                      disabled
                      onChange={e => {
                        setLotName(e.target.value)
                      }}
                      className='ms-1'
                    />
                  </div>
                </Col>
                <Col
                  xl='2'
                  md='3'
                  sm='4'
                  xs='12'
                  className='align-items-center'
                >
                  {/* <TextField label="Parity" placeholder="01" id="standard-basic" fullWidth variant="standard" value={parity} onChange={(e) => paritySelect(e)} /> */}
                  <div className='d-flex align-items-center idNo'>
                    <h6 style={{ paddingRight: '5px' }} className='mb-0'>
                      Farmer :{' '}
                    </h6>
                    <input
                      type='text'
                      style={{ width: '70%', backgroundColor: 'white' }}
                      value={farmerName}
                      disabled
                      onChange={e => {
                        setFarmerName(e.target.value)
                      }}
                    />
                  </div>
                </Col>
                <Col
                  xl='1'
                  md='2'
                  sm='4'
                  xs='12'
                  className='align-items-center'
                >
                  {/* <TextField label="Parity" placeholder="01" id="standard-basic" fullWidth variant="standard" value={parity} onChange={(e) => paritySelect(e)} /> */}
                  <div className='d-flex align-items-center idNo '>
                    <h6 style={{ paddingRight: '5px' }} className='mb-0'>
                      Parity :{' '}
                    </h6>
                    <input
                      type='text'
                      style={{ width: '40%', backgroundColor: 'white' }}
                      value={
                        parityStatus === true
                          ? tableParity
                          : parity === null || parity === 'null'
                          ? '1'
                          : parity
                      }
                      disabled
                      onChange={e => {
                        setParity(e.target.value)
                      }}
                    />
                  </div>
                </Col>
                <Col
                  xl='2'
                  md='2'
                  sm='4'
                  xs='12'
                  className='align-items-center'
                >
                  <div className=' d-flex align-items-center idNo'>
                    <h6 style={{ paddingRight: '5px' }} className='mb-0'>
                      Status :{' '}
                    </h6>
                    <input
                      type='text'
                      style={{ width: '70%', backgroundColor: 'white' }}
                      value={statusName || ''}
                      disabled
                      onChange={e => {
                        setStatusName(e.target.value)
                      }}
                    />
                  </div>
                </Col>
                <Col
                  xl='3'
                  md='2'
                  sm='4'
                  xs='12'
                  className='align-items-center'
                >
                  <div className=' d-flex align-items-center idNo'>
                    <h6 style={{ paddingRight: '5px' }} className='mb-0'>
                      Breeding Status :{' '}
                    </h6>
                    <input
                      type='text'
                      style={{ width: '60%', backgroundColor: 'white' }}
                      value={breedingStatusName || ''}
                      disabled
                      onChange={e => {
                        setBreedingStatusName(e.target.value)
                      }}
                    />
                  </div>
                </Col>
                {/* <Col xl="2" md="3" sm="4" xs="12" className="ms-auto">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e, "userData")} />
                                </Col> */}
              </Row>
              <Row className='align-items-center justify-content-start mb-2 mt-2'>
                <Col
                  xl='2'
                  md='2'
                  sm='4'
                  xs='12'
                  className='align-items-center'
                ></Col>
                {/* <Col xl="2" md="2" sm="4" xs="12" className="align-items-center">
                                    <div className="d-flex ms-0 align-items-center idNo">
                                        <h6 className="mb-0">Status : </h6>
                                        <input type="text" value={statusName} disabled onChange={(e) => { setStatusName(e.target.value) }} />
                                    </div>
                                </Col> */}
                {/* <Col xl="3" md="2" sm="4" xs="12" className="align-items-center">
                                    <div className="d-flex ms-0 align-items-center idNo">
                                        <h6 className="mb-0">Breeding Status : </h6>
                                        <input type="text" value={breedingStatusName} disabled onChange={(e) => { setBreedingStatusName(e.target.value) }} />
                                    </div>
                                </Col> */}
              </Row>
              <Row className='align-items-center '>
                <Col xs='3' lg='2' xxl='1' className=''>
                  <button onClick={custMe} className='btn-blue'>
                    Heat & P.D Details
                  </button>
                </Col>
                <Col xs='3' lg='2' xxl='1'>
                  <button onClick={sum} className='btn-yellow'>
                    Calving Details
                  </button>
                </Col>
                <Col xs='3' lg='2' xxl='1'>
                  <button onClick={sum_} className='btn-perot'>
                    Dry Details
                  </button>
                </Col>
              </Row>
              {heat && (
                <>
                  <Row className='justify-content-between'>
                    <Col xl='8' lg='8' xxl='9' id='aiTable'>
                      {userDataList?.length > 0 ? (
                        <div className='blue_bg tableBorder1 mt-1'>
                          <Table
                            columns={column}
                            data={searchFilter1 ? searchFilter1 : userDataList}
                          />
                        </div>
                      ) : (
                        <Row className='align-items-center mt-1'>
                          <Col>
                            <div
                              className='tableBorder tableBorder1 ms-2'
                              style={{ overflow: 'hidden' }}
                            >
                              <div className='d-flex colmBreed'>
                                {column.map(i => {
                                  return (
                                    <Col
                                      className={`blankColumns ${i.name.replace(
                                        ' ',
                                        '_'
                                      )}`}
                                      style={{
                                        backgroundColor: 'rgb(169,169,169)'
                                      }}
                                    >
                                      {i.name}
                                    </Col>
                                  )
                                })}
                              </div>
                              <h6 className='text-center mt-4 noData'>
                                No Data
                              </h6>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col
                      xl='4'
                      lg='4'
                      xxl='3'
                      className='ms-auto'
                      id='aiTable1'
                    >
                      {columnData?.length ? (
                        <div className='blue_bg tableBorder1 mt-1'>
                          <Table columns={column2} data={columnData} />
                        </div>
                      ) : (
                        <Row className='align-items-center gx-2 mt-1'>
                          <div className='tableBorder'>
                            <div className='d-flex colmBreed'>
                              {column2.map(i => {
                                return (
                                  <Col
                                    className={`blankColumns ${i.name.replace(
                                      ' ',
                                      '_'
                                    )}`}
                                    style={{
                                      backgroundColor: 'rgb(169,169,169)',
                                      width: '10px'
                                    }}
                                  >
                                    {i.name}
                                  </Col>
                                )
                              })}
                            </div>
                            <h6 className='text-center mt-4 noData'>No Data</h6>
                          </div>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {heat && (
                <Row className='mt-2'>
                  {/* {
                                        aiSave == true ? <> <Col xs="2" xl="1" className="mt-1">
                                            <button onClick={Add} className="btn-blue-Animal">Add A.I</button>
                                        </Col>
                                            <Col xl="1" xs="2" className="mt-1">
                                                <button onClick={pdUpdate} className="btn-blue-Animal">Update P.D</button>
                                            </Col></> : <Col xl="1" xs="2" className="mt-1">
                                            <button onClick={pdUpdate} className="btn-blue-Animal">Update P.D</button>
                                        </Col>
                                    } */}
                  {isLastRecordPregnant ? (
                    <Col xl='2' xxl='1' xs='2' lg='2' className='mt-1'>
                      <button
                        onClick={e => pdUpdate(e)}
                        className='btn-blue-Animal'
                      >
                        Update P.D
                      </button>
                    </Col>
                  ) : (
                    <>
                      <Col xs='2' xl='1' className='mt-1'>
                        <button onClick={Add} className='btn-blue-Animal'>
                          Add A.I
                        </button>
                      </Col>
                      <Col xl='1' xs='2' className='mt-1'>
                        <button onClick={pdUpdate} className='btn-blue-Animal'>
                          Update P.D
                        </button>
                      </Col>
                    </>
                  )}
                  {/* <Col xs="2" xl="1" className="mt-1">
                                        <button onClick={Add} className="btn-blue-Animal">Add A.I</button>
                                    </Col>
                                    <Col xl="1" xs="2" className="mt-1">
                                        <button onClick={pdUpdate} className="btn-blue-Animal">Update P.D</button>
                                    </Col> */}
                </Row>
              )}

              {calving && (
                <Row>
                  <Col xl='3' lg='3' md='3' sm='6'>
                    <div className='blue_bg p-3 my-3'>
                      <sub className='mb-0 pb-0'>Calving Date</sub>
                      <input
                        type='date'
                        className='dateSelect'
                        value={calvingDate}
                        onChange={e => setCalvingDate(e.target.value)}
                      />
                      <p className='mt-4 mb-0'>Type of Calving :</p>

                      <RadioGroup
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                      >
                        <FormControlLabel
                          value='1'
                          checked={calvingType !== '2'}
                          onChange={e => setCalvingType(e.target.value)}
                          control={<Radio />}
                          label='Normal'
                        />
                        <FormControlLabel
                          value='2'
                          checked={calvingType === '2'}
                          onChange={e => setCalvingType(e.target.value)}
                          control={<Radio />}
                          label='Abnormal'
                        />
                      </RadioGroup>
                      <Row className='align-items-end'>
                        <Col>
                          <Select
                            placeholder='Calf Sex 1'
                            // className="mb-3"
                            onChange={selectedOption => {
                              setCalfSex(selectedOption.value)
                            }}
                            options={calfSexList.map(item => ({
                              value: item.value,
                              label: item.label
                            }))}
                          />
                        </Col>
                        <Col>
                          <TextField
                            className=''
                            label='Claf Id 1'
                            placeholder='Enter Calf Id'
                            id='standard-basic'
                            fullWidth
                            variant='standard'
                            value={calfId}
                            onChange={e => {
                              setCalfId(e.target.value)
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className='align-items-end'>
                        <Col>
                          <Select
                            placeholder='Calf Sex 2'
                            classNames='mt-2'
                            onChange={selectedOption => {
                              setCalfSex2(selectedOption.value)
                            }}
                            options={calfSexList.map(item => ({
                              value: item.value,
                              label: item.label
                            }))}
                          />
                        </Col>
                        <Col>
                          <TextField
                            className='mt-1'
                            label='Claf Id 2'
                            placeholder='Enter Calf Id 2'
                            id='standard-basic'
                            fullWidth
                            variant='standard'
                            value={calfId1}
                            onChange={e => {
                              setCalfId1(e.target.value)
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xl='4' lg='5' md='5' sm='6'>
                    <div className='blue_bg overSize p-3 my-3'>
                      <div className='mt-1'>
                        <h6 className='mb-0'>
                          <b>Reproductive Problems</b>
                        </h6>
                      </div>
                      <hr />
                      <div className='mt-1'>
                        <input
                          type='checkbox'
                          id='vehicle1'
                          value='infection'
                          className='me-2'
                          onChange={e => {
                            problem(e)
                          }}
                        />
                        infection
                      </div>
                      <div className='mt-1'>
                        <input
                          type='checkbox'
                          id='vehicle1'
                          value='pre proapse'
                          className='me-2'
                          onChange={e => {
                            problem(e)
                          }}
                        />
                        pre proapse
                      </div>
                      <div className='mt-1'>
                        <input
                          type='checkbox'
                          id='vehicle1'
                          value='abortion'
                          className='me-2'
                          onChange={e => {
                            problem(e.ta)
                          }}
                        />
                        abortion
                      </div>
                      <div className='mt-1'>
                        <input
                          type='checkbox'
                          id='vehicle1'
                          value='calving'
                          className='me-2'
                          onChange={e => {
                            problem(e.target.checked)
                          }}
                        />
                        calving
                      </div>
                    </div>
                  </Col>
                  <Col xl='5' lg='4' md='4'>
                    <div className='blue_bg p-3 my-3'>
                      <h6 className='mb-0'>
                        <b>Calving Note</b>
                      </h6>
                      <hr />
                      <textarea
                        placeholder='Calving Note'
                        rows='5'
                        className='mt-2 text_area p-2'
                      />
                      <Row className='justify-content-end'>
                        {/* <Col xxl="2" md="2" sm="3" xs="6">
                                                    <button type="button" className="mt-1 btn-blue" onClick={saveBreeding}>Calf Save</button>
                                                </Col> */}
                        <Col xxl='2' md='2' sm='3' xs='6'>
                          <button
                            type='button'
                            className='mt-1 btn-blue'
                            onClick={saveBreeding}
                          >
                            Save
                          </button>
                        </Col>
                        <Col xl='4' md='2' sm='3' xs='6'>
                          <button type='button' className='mt-1 btn-blue'>
                            Production Details
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}

              {dry && (
                <Row id='heightOffsetStock'>
                  <Col xxl='9' xl='10' lg='12'>
                    <div className='blue_bg p-3 my-3'>
                      <Row className='align-items-end justify-content-start'>
                        <Col xl='2' lg='2' md='3' sm='3'>
                          <sub className='mb-0 pb-0'>Drying Date</sub>
                          <input
                            type='date'
                            className='dateSelect'
                            value={dateOfDry}
                            onChange={e => {
                              setDateOfDry(e.target.value)
                            }}
                          />
                        </Col>
                        {/* <Col xl="2" lg="2" md="3" sm="3">
                                                    <sub className="mb-0 pb-0">Heat Date</sub>
                                                    <input type="date" className="dateSelect" value={heatDate} onChange={(e) => { setHeatDate(e.target.value) }} />
                                                </Col> */}
                        {/* <Col xl="3" lg="3" md="3" sm="4">
                                                    <TextField label="Dry off Reasons" placeholder="Sex " id="standard-basic" fullWidth variant="standard" value={dryReason} onChange={(e) => { setDryReason(e.target.value) }} />
                                                </Col> */}
                        <Col xl='2' lg='2' md='3' sm='3'>
                          <Select
                            placeholder='Dry off Reasons'
                            onChange={selectedOption => {
                              console.log('selectedOption ' + selectedOption)
                              console.log(
                                'dryReasonList ' + JSON.stringify(dryReasonList)
                              )
                              setDryReason(selectedOption.value)
                            }}
                            options={dryReasonList?.map(item => ({
                              value: item.ID,
                              label: item.Name
                            }))}
                          />
                        </Col>
                        <Col xl='3' lg='3' md='3' sm='4'>
                          <TextField
                            label='Dry Off treatment'
                            placeholder=''
                            id='standard-basic'
                            fullWidth
                            variant='standard'
                            value={dryTreatment}
                            onChange={e => {
                              setDryTreatment(e.target.value)
                            }}
                          />
                        </Col>
                        <Col xl='2' lg='2' md='3' sm='3' xs='6' xxl='1'>
                          <button
                            type='button'
                            className='mt-3 btn-blue'
                            onClick={dryOff}
                          >
                            Dry Off
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}

              <Row className={dry ? 'tableBreed1' : 'tableBreed'}>
                <Col id='breedFixTable'>
                  {lifeDataList?.length > 0 ? (
                    <div className='blue_bg tableBorder2 mt-1'>
                      <Table
                        columns={column3}
                        data={lifeDataList}
                        height={`calc(100vh - (${heightOffSet}px + 100px))`}
                      />
                    </div>
                  ) : (
                    <Row
                      className={
                        dry
                          ? 'tableBreed1 align-items-center'
                          : 'tableBreed align-items-center'
                      }
                    >
                      <Col>
                        <div className='tableBorder ms-2'>
                          <div className='d-flex tabledata colmBreed'>
                            {column3.map(i => {
                              return (
                                <Col
                                  className={`blankColumns ${i.name.replace(
                                    ' ',
                                    '_'
                                  )}`}
                                  style={{
                                    backgroundColor: 'rgb(169,169,169)'
                                  }}
                                >
                                  {i.name}
                                </Col>
                              )
                            })}
                          </div>
                          <h6 className='text-center align-items-center mt-5 noData'>
                            No Data
                          </h6>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {/* 
                                    <div className="blue_bg p-3 mt-1">
                                        <Table columns={column3} data={lifeDataList} height={`calc(100vh - (${heightOffSet}px + 100px))`} />
                                    </div> */}
                </Col>
              </Row>
              <Row className='justify-content-end'>
                <Col xxl='1' md='2' sm='3' xs='6'>
                  <button
                    type='button'
                    className='mt-3 btn-perot'
                    onClick={refFn1}
                  >
                    Refresh
                  </button>
                </Col>
                <Col xxl='1' md='2' sm='3' xs='6'>
                  <button type='button' className='mt-3 btn-yellow'>
                    Close
                  </button>
                </Col>
                <Col xxl='1' md='2' sm='3' xs='6'>
                  <button type='button' className='mt-3 btn-red'>
                    Delete
                  </button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        id='breed1'
      >
        <Modal.Header style={{ background: '#DFDFDF' }}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Add New A.I
          </Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='align-items-end'>
              <Col xl='6' className='mb-2'>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='A.I Date'
                      value={aiDate}
                      onChange={e => {
                        setAiDate(e)
                      }}
                      format='DD-MM-YYYY'
                    />
                  </LocalizationProvider>
                </div>
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={service !== '' ? service : 'Service'}
                  onChange={selectedOption => {
                    setService(selectedOption.value)
                  }}
                  options={manualOptions.map(item => ({
                    value: item.value,
                    label: item.label
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={inseminator !== '' ? inseminator : 'Inseminator'}
                  onChange={selectedOption => {
                    setInseminator(selectedOption.value)
                  }}
                  options={inseminatorList.map(item => ({
                    value: item.ID,
                    label: item.Name
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={sire !== '' ? sire : 'Sire'}
                  onChange={selectedOption => {
                    setSire(selectedOption.value)
                  }}
                  options={sireList.map(item => ({
                    value: item.id,
                    label: item.Name
                  }))}
                />
              </Col>
              <Col xl='6'>
                <Select
                  placeholder={Dose !== '' ? Dose : 'Dose'}
                  onChange={selectedOption => {
                    setDose(selectedOption.value)
                  }}
                  options={doseOption.map(item => ({
                    value: item.value,
                    label: item.label
                  }))}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className='justify-content-start'>
              <Col xl='2'>
                <button
                  className='btn-blue-Animal mt-1'
                  style={{ background: '#43668E' }}
                  onClick={AddData}
                >
                  A.I Save
                </button>
              </Col>
              <Col xl='2' className='ms-auto'>
                <button
                  className='btn-blue-Animal mt-1'
                  onClick={() => setModalShow(false)}
                  style={{ background: '#E16659' }}
                >
                  {' '}
                  Cancel{' '}
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        id='breed1'
      >
        <Modal.Header style={{ background: '#DFDFDF' }}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit A.I && PD
          </Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='align-items-end'>
              {/* <Col xl="6" className="mb-2">
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="A.I Date" value={aiDate} onChange={(e) => { setAiDate(e.target.value) }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </Col> */}
              <Col xl='6' className='mb-2'>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='A.I Date'
                      value={aiDate}
                      onChange={handleDateChange}
                      format='DD-MM-YYYY'
                    />
                  </LocalizationProvider>
                </div>
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={
                    service !== '' || service !== null ? service : 'Service'
                  }
                  onChange={selectedOption => {
                    setService(selectedOption.value)
                  }}
                  options={manualOptions.map(item => ({
                    value: item.value,
                    label: item.label
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={
                    inseminator !== '' || inseminator !== null
                      ? inseminator
                      : 'Inseminator'
                  }
                  onChange={selectedOption => {
                    setInseminator(selectedOption.value)
                  }}
                  options={inseminatorList.map(item => ({
                    value: item.ID,
                    label: item.Name
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={sire !== null ? sire : 'Sire'}
                  onChange={selectedOption => {
                    setSireId(selectedOption.label)
                    // console.log(" a  : " +selectedOption.label)
                  }}
                  options={sireList.map(item => ({
                    value: item.code,
                    label: item.Name
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={Dose !== '' || Dose !== null ? Dose : 'Dose'}
                  onChange={selectedOption => {
                    setDose(selectedOption.value)
                  }}
                  options={doseOption.map(item => ({
                    value: item.value,
                    label: item.label
                  }))}
                />
              </Col>
              {/* <Col xl="6" className="mb-2">
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="P.D Date" value={pdDate} onChange={(e) => { setPdDate(e) }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </Col> */}
              <Col xl='6' className='mb-2'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='P.D Date'
                    value={pdDate1}
                    onChange={e => {
                      setPdDate1(e)
                      // console.log('pdDate1 : ' + pdDate1)
                    }}
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={pdDone !== null ? pdDone : 'P.D Done'}
                  onChange={selectedOption => {
                    // console.log('Selected Option: ' + JSON.stringify(selectedOption))
                    // console.log('inseminatorList Option: ' +JSON.stringify(inseminatorList) )
                    setPdDoneID(selectedOption.value)
                  }}
                  options={inseminatorList.map(item => ({
                    value: item.ID,
                    label: item.Name
                  }))}
                />
              </Col>

              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={
                    pdResult !== '' || pdResult !== null
                      ? pdResult
                      : 'P.D Result'
                  }
                  onChange={selectedOption => {
                    setPdResultId(selectedOption.value)
                    // console.log(
                    //   'pdResultList Option: ' + JSON.stringify(pdResultList)
                    // )
                  }}
                  options={pdResultList?.map(item => ({
                    value: item.value,
                    label: item.label
                  }))}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className='justify-content-start'>
              <Col xl='2'>
                <button
                  className='btn-blue-Animal mt-1'
                  style={{ background: '#43668E' }}
                  onClick={EditData}
                >
                  Save
                </button>
              </Col>
              <Col xl='2' className='ms-auto'>
                <button
                  className='btn-blue-Animal mt-1'
                  onClick={() => setEditModalShow(false)}
                  style={{ background: '#E16659' }}
                >
                  {' '}
                  Cancel{' '}
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={editModalShow1}
        onHide={() => setEditModalShow1(false)}
        id='breed1'
      >
        <Modal.Header style={{ background: '#DFDFDF' }}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit Parity
          </Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='align-items-end'>
              {/* <Col xl="6" className="mb-2">
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="A.I Date" value={aiDate} onChange={(e) => { setAiDate(e.target.value) }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </Col> */}
              <Col xl='6' className='mb-2'>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='A.I Date'
                      value={aiDate}
                      onChange={handleDateChange}
                      format='DD-MM-YYYY'
                    />
                  </LocalizationProvider>
                </div>
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={
                    service !== '' || service !== null ? service : 'Service'
                  }
                  onChange={selectedOption => {
                    setService(selectedOption.value)
                  }}
                  options={manualOptions.map(item => ({
                    value: item.value,
                    label: item.label
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={
                    inseminator !== '' || inseminator !== null
                      ? inseminator
                      : 'Inseminator'
                  }
                  onChange={selectedOption => {
                    setInseminator(selectedOption.value)
                  }}
                  options={inseminatorList.map(item => ({
                    value: item.ID,
                    label: item.Name
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={sire !== '' || sire !== null ? sire : 'Sire'}
                  onChange={selectedOption => {
                    setSireId(selectedOption.value)
                  }}
                  options={sireList.map(item => ({
                    value: item.code,
                    label: item.Name
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={Dose !== '' || Dose !== null ? Dose : 'Dose'}
                  onChange={selectedOption => {
                    setDose(selectedOption.value)
                  }}
                  options={doseOption.map(item => ({
                    value: item.value,
                    label: item.label
                  }))}
                />
              </Col>
              {/* <Col xl="6" className="mb-2">
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="P.D Date" value={pdDate} onChange={(e) => { setPdDate(e) }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </Col> */}
              <Col xl='6' className='mb-2'>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='P.D Date'
                      value={pdDate}
                      onChange={handleDateChangePdDate}
                      format='DD-MM-YYYY'
                    />
                  </LocalizationProvider>
                </div>
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={pdDone !== null ? pdDone : 'P.D Done'}
                  onChange={selectedOption => {
                    setPdDone(selectedOption.value)
                  }}
                  options={inseminatorList.map(item => ({
                    value: item.ID,
                    label: item.Name
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={
                    pdResult !== '' || pdResult !== null
                      ? pdResult
                      : 'P.D Result'
                  }
                  onChange={selectedOption => {
                    setPdResultId(selectedOption.value)
                  }}
                  options={pdResultList.map(item => ({
                    value: item.value,
                    label: item.label
                  }))}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className='justify-content-start'>
              <Col xl='2'>
                <button
                  className='btn-blue-Animal mt-1'
                  style={{ background: '#43668E' }}
                  onClick={EditData}
                >
                  Save
                </button>
              </Col>
              <Col xl='2' className='ms-auto'>
                <button
                  className='btn-blue-Animal mt-1'
                  onClick={() => setEditModalShow(false)}
                  style={{ background: '#E16659' }}
                >
                  {' '}
                  Cancel{' '}
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={pdModalShow}
        onHide={() => setPdModalShow(false)}
      >
        <Modal.Header style={{ background: '#DFDFDF' }}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Update PD
          </Modal.Title>
          <img src={closeButton} onClick={() => setPdModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='align-items-end'>
              {/* <Col xl="6" className="mb-2">
                                <div>
                                    <sub className="mb-0 pb-0">P.D Date</sub>
                                    <input type="date" className="dateSelect_bg" value={pdDate1} onChange={(e) => { setPdDate1(e.target.value) }} />
                                </div>
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="PD Date"
                                            value={pdDate1}
                                            onChange={(e) => { setPdDate1(e.target.value) }}
                                            format="DD-MM-YYYY"
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={(pdDone1 !== "" || pdDone1 !== null) ? pdDone1 : "P.D Done"}
                                    onChange={(selectedOption) => {
                                        setPdDone1(selectedOption.value);
                                    }}
                                    options={inseminatorList.map((item) => ({
                                        value: item.ID,
                                        label: item.Name,
                                    }))}
                                />
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={(pdResult1 !== "" || pdResult1 !== null) ? pdResult1 : "P.D Result"}
                                    onChange={(selectedOption) => {
                                        setPdResult1(selectedOption.value);
                                    }}
                                    options={pdResultList.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                    }))}
                                />
                            </Col> */}
              <Col xl='6' className='mb-2'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='P.D Date'
                    value={currentDate1}
                    onChange={newValue => setCurrentDate1(newValue)}
                    maxDate={dayjs()}
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={pdDoneUpdate ? pdDoneUpdate : 'P.D Done'}
                  value={pdDoneUpdate}
                  onChange={selectedOption => {
                    setPdDoneUpdateId(selectedOption.value)
                    setPdDoneUpdate(selectedOption.label)
                    // console.log('Updated pdDoneUpdate: ' + selectedOption.label)
                  }}
                  options={inseminatorList.map(item => ({
                    value: item.ID,
                    label: item.Name
                  }))}
                />
              </Col>
              <Col xl='6' className='mb-2'>
                <Select
                  placeholder={pdResultUpdate ? pdResultUpdate : 'P.D Result'}
                  value={pdResultUpdate}
                  onChange={selectedOption => {
                    setPdResultUpdateId(selectedOption.value)
                    setPdResultUpdate(selectedOption.label)
                    // console.log(
                    //   'Updated pdResultUpdate: ' + selectedOption.label
                    // )
                  }}
                  options={pdResultList.map(item => ({
                    value: item.value,
                    label: item.label
                  }))}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className='justify-content-start'>
              <Col xl='2'>
                <button
                  className='btn-blue-Animal mt-1'
                  style={{ background: '#43668E' }}
                  onClick={updatePd}
                >
                  PD Save
                </button>
              </Col>
              <Col xl='2' className='ms-auto'>
                <button
                  className='btn-blue-Animal mt-1'
                  onClick={() => setPdModalShow(false)}
                  style={{ background: '#E16659' }}
                >
                  {' '}
                  Cancel{' '}
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal
        size=''
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={deleteShow}
        onHide={() => setDeleteShow(false)}
      >
        <Modal.Header style={{ background: '#DFDFDF' }}>
          <Modal.Title id='contained-modal-title-vcenter'>Delete</Modal.Title>
          <img src={closeButton} onClick={() => setDeleteShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='my-3'>
              <Col sm='12'>
                <h6>Are you sure want to delete this item ?</h6>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className='py-0'>
          <Container>
            <Row className='justify-content-end'>
              <Col xxl='2' md='3'>
                <button
                  className='btn-blue-Animal mt-2 mb-1'
                  onClick={deleteFn}
                >
                  Delete
                </button>
              </Col>
              <Col xxl='2' md='3'>
                <button
                  className='btn-blue-Animal mt-2 mb-1'
                  onClick={() => setDeleteShow(false)}
                  style={{ background: '#E16659' }}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal
        size=''
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={deleteShow1}
        onHide={() => setDeleteShow1(false)}
      >
        <Modal.Header style={{ background: '#DFDFDF' }}>
          <Modal.Title id='contained-modal-title-vcenter'>Delete</Modal.Title>
          <img src={closeButton} onClick={() => setDeleteShow1(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='my-3'>
              <Col sm='12'>
                <h6>Are you sure want to delete this Parity ?</h6>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className='py-0'>
          <Container>
            <Row className='justify-content-end'>
              <Col xxl='2' md='3'>
                <button
                  className='btn-blue-Animal mt-2 mb-1'
                  onClick={calvingdeleteFn}
                >
                  Delete
                </button>
              </Col>
              <Col xxl='2' md='3'>
                <button
                  className='btn-blue-Animal mt-2 mb-1'
                  onClick={() => setDeleteShow(false)}
                  style={{ background: '#E16659' }}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        style={{ fontSize: '14px' }}
      />

      {show && (
        <Offcanvas className='hide_Navbar' show={show} onHide={handleClose}>
          <Container>
            <Row>
              <Col>
                <Sidenav />
              </Col>
            </Row>
          </Container>
        </Offcanvas>
      )}
      {isLoading ? (
        <div className='circleText'>
          <div className='progressText'>{progressData}</div>
          <div className=''>
            <span className='loader-circle-13'></span>
          </div>
        </div>
      ) : (
        false
      )}
    </>
  )
}
