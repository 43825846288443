import { Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";


import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackBtn from '../../Componet/Button/BackBtn';
import { downloadExcel } from 'react-export-table-to-excel';
const { VopostApi, MasterMedicineApi } = API_URLS;


const label = { inputProps: { "aria-label": "Switch demo" } };

const VoPost = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const API_KEY = serviceUrl;

    async function getData(credentials) {
        return fetch(VopostApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function addData(credentials) {
        return fetch(MasterMedicineApi.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    async function updateData(credentials) {
        return fetch(VopostApi.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    const [code, setCode] = useState("")
    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [staffMobile, setStaffMobile] = useState("")
    const [search, setSearch] = useState("")
    const [editId, setEditId] = useState("")
    const [user, setUser] = useState("")
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");


    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [deleteShow, setDeleteShow] = useState(false)
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false)
    const [editToggle, setEditToggle] = useState(false);


    const [postList, setPostList] = useState([])

    const vopostGetData = async () => {
        setIsLoading(true)
        const res = await getData();
        if (res) {
            setPostList(res.data);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        vopostGetData()
    }, [])


    const AddData = async () => {
        if (editId) {
            // const res = await updateData({ id: editId, Name: name });
            // if (res) {
            //     setPostList(res.data);
            //     setModalShow(false)
            //     vopostGetData()
            // }

            fetch(`${API_KEY}/master/vo_post/` + editId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ id: editId, Name: name })
            })
                .then((result) => result.json())
                .then((result) => {
                    // setPostList(result.data);
                    setModalShow(false)
                    vopostGetData()
                });
        }
        else {
            const res = await addData({ Name: name, id: 0 });
            if (res) {
                setPostList(res.data);
                setModalShow(false)
                vopostGetData()
            }

        }
    }

    const Add = () => {
        setModalShow(true);
        setEditId("")
        setEditToggle("");
        setEditId("");
        setName("");
    }

    const updateFn = (e) => {
        setEditId(e.id)
        setName(e.Name);
        setModalShow(true);
    };

    const colum = [
        {
            name: "ID",
            selector: "id",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px", width: "100%" , display:"flex" , justifyContent:"flex-end"   }}>{c["id"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Name",
            selector: "Name",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "100%" , display:"flex" , justifyContent:"flex-start"  }}>{c["Name"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                </>
            ),
        },
    ]

    const handledExcel = () => {
        // Define the keys you want to export
        const keysToExport = ["id", "Name"];

        const filteredData = postList?.map(item => {
            const filteredItem = {};
            keysToExport.forEach(key => {
                // Convert boolean to string if the key is "IsSuspended"
                filteredItem[key] = key === "IsSuspended" ? String(item[key]) : item[key];
            });
            return filteredItem;
        });

        // Prepare the header row
        const header = keysToExport.map(key => {
            const column = colum.find(col => col.selector === key);
            return column ? column.name : key;
        });

        // Download Excel
        downloadExcel({
            fileName: "Vo Post",
            sheet: "data",
            tablePayload: {
                header,
                body: filteredData
            }
        });
    }

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = postList.filter((d) => {
                return d.Name.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(postList);
        }
        setSearch(data);
    }
    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="5" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}> <img src={menu} width={20} /> </button>
                                    <h5 className="ms-4 mt-2">Master / Vo  Post</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-between p-2 d-flex" id='heightOffsetStock'>
                                <Col sm="8" lg="4" xl="3" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1 me-2" onClick={() => Add()}>Add Vo Post</button>
                                    <button className="btn-blue-Animal mt-1 mb-1 ms-2" onClick={() => handledExcel()}>Export</button>

                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            {/* <Table columns={colum} data={} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination /> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {postList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : postList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update VO Post" : "Add New VO Post"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="12">
                                <TextField label="Name" value={name} onChange={(e) => { setName(e.target.value); }} id="standard-basic" data={postList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-between">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => AddData(e)}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}


        </>

    )
}

export default VoPost;