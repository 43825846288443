import React, { useState, useEffect, useMemo } from "react";
import './Customreport.css';
import { Col, Container, Row, Offcanvas, Accordion, Modal } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, CartesianGrid, BarChart, Bar } from "recharts";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { downloadExcel } from "react-export-table-to-excel";

import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';
import file from '../../Photo/file.svg';
import save from '../../Photo/milk-union/save.svg';
import preview from '../../Photo/milk-union/preview.svg';
import close from '../../Photo/milk-union/close.svg';
import FileCheker from '../../Photo/FileCheker.svg'
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import moment from "moment";
import Dropdown from "../../Componet/Dropdown/Dropdown";
import Table from "../../Componet/DataTable/Table";
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import Input from "../../Componet/InputFild/Input";
import { click } from "@testing-library/user-event/dist/click";
import { useNavigate } from "react-router-dom";
import Searcable from "../../Componet/Dropdown/Searcable";
import BackBtn from "../../Componet/Button/BackBtn";

const { REPORT_UNION } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function SelectTab(credentials) {
    return fetch(REPORT_UNION.Select_Tab, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}
async function SelectReport(credentials) {
    return fetch(REPORT_UNION.Select_Report, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

export default function CustomReport() {
    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const [heightOffSet, setHeight] = useState(0);

    const [progressData, setProgressData] = useState("0%");
    const [selectTab, selectTabFn] = useState("");
    const [reportName, setReportName] = useState("");
    const [name, setName] = useState("");
    const [name1, setName1] = useState("");
    const [search, setSearch] = useState("");
    const [colName, setColName] = useState([]);
    const [bene1, setBene1] = useState("");
    const [bene2, setBene2] = useState("");
    const [bene3, setBene3] = useState("");
    const [bene4, setBene4] = useState("");
    const [bene5, setBene5] = useState("");
    const [bene6, setBene6] = useState("");
    const [bene7, setBene7] = useState("");
    const [bene8, setBene8] = useState("");
    const [bene9, setBene9] = useState("");
    const [bene10, setBene10] = useState("");
    const [bene11, setBene11] = useState("");
    const [bene12, setBene12] = useState("");
    const [bene13, setBene13] = useState("");
    const [bene14, setBene14] = useState("");
    const [bene15, setBene15] = useState("");
    const [bene16, setBene16] = useState("");
    const [bene17, setBene17] = useState("");
    const [bene18, setBene18] = useState("");
    const [bene19, setBene19] = useState("");
    const [condition, setCondition] = useState("")
    const [bene20, setBene20] = useState("");
    const [daragValue, setDragValue] = useState("")
    const [value, setValue] = useState("")
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [reportList, setReportList] = useState([]);
    const [fatchTabList, setFatchTabList] = useState([]);
    const [tabList, setTabList] = useState([]);
    const [milkList, setmilkList] = useState([]);
    const [submilk1, setsubmilk1] = useState([]);
    const [submilk2, setSubMilk2] = useState([]);
    const [rowsList, setRowsList] = useState([]);
    const [rowsList1, setRowsList1] = useState([]);
    const [herdList, setHerdList] = useState([]);
    const [dragName, setDragName] = useState([])
    const [dateList, setDateList] = useState([]);
    const [childSubName, setChildSubName] = useState([])
    const [herdlistcolumn, setDynamicList] = useState([]);
    const [conditionList, setConditionList] = useState([])
    // const [subHerdList, setSubHerdList] = useState([]);
    const [dragItem, setDragItem] = useState([]);
    const [subFatchList, setSubFatchList] = useState([])
    const [showGraph, setShowGraph] = useState([]);

    const [selected, setSelected] = useState(false)
    const [sotable, setSotable] = useState(true)
    const [imageTrue, setImageTrue] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [centerShow, setCenterShow] = useState(false);
    const [herdModal, setHerdModal] = useState(false);
    const [headerShow, setHeaderShow] = useState(false);
    const [show, setShow] = useState(false);
    const [tst, setTst] = useState(true)
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const fatchSelectFn = async () => {
        const fatchReport = await SelectReport();
        if (fatchReport) { setReportList(fatchReport); }
        const fatchTab = await SelectTab();
        if (fatchTab) { setFatchTabList(fatchTab); }

        fetch(`${API_KEY}/reportUnion/getCustomReportField`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setmilkList(result); })
    }

    const data = () => {
        return (
            <div>
                $(document).ready(function() {'{'}
                $("#example").DataTable();
                {'}'});
            </div>
        );
    };

    const selectFn = (e) => {
        selectTabFn(e);
        if (e) {
            fetch(`${API_KEY}/reportUnion/fillTab`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ Lot: 0, Route: 0, Zone: 0, tab: e })
            })
                .then(res => res.json())
                .then((result) => { setTabList(result); })

            fetch(`${API_KEY}/reportUnion/fillTab`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ Lot: 0, Route: 0, Zone: 0, tab: "Supervisor" })
            })
                .then(res => res.json())
                .then((result) => { setSubFatchList(result); })
        }
    }


    const selectFn1 = (e) => {
        console.log(e)
        if (e) {
            fetch(`${API_KEY}/reportUnion/fillTab`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ Lot: 0, Route: 0, Zone: e, tab: "Paravet" })
            })
                .then(res => res.json())
                .then((result) => { setTabList(result); })
        }
    }

    const childrendate = (e) => {
        setName(e.name);
        setsubmilk1(e.children);
    }

    const childrendata1 = (e) => {
        // console.log(e);
        setName1(e.name);
        setSubMilk2(e.children);
    }

    const colums = [
        {
            name: "Center Name",
            selector: "Center Name",
            sortable: true
        },
        {
            name: "ID",
            selector: "ID",
            width: "30px",
            sortable: true
        },
    ]

    const paravetColums = [
        {
            name: "ID",
            selector: "ID",
            width: "70px",
            left: true
        },
        {
            name: "Code",
            selector: "code",
            width: "100px",
            left: true
        },
        {
            name: "Paravet Name",
            selector: "Paravet Name",
        },
    ]

    const districtColums = [
        {
            name: "Taluka Name",
            selector: "Taluka Name",
            sortable: true
        },
        {
            name: "ID",
            selector: "ID",
            width: "50px",
            sortable: true
        }
    ];

    const doctorColms = [
        {
            name: "Doctor Name",
            selector: "Doctor Name",
            sortable: true
        },
        {
            name: "ID",
            selector: "ID",
            width: "50px",
            sortable: true
        }
    ];

    const dscColms = [
        {
            name: "Lot Name",
            selector: "Lot Name",
            sortable: true
        },
        {
            name: "Lot No",
            selector: "Lot No",
            width: "50px",
            sortable: true
        },
        {
            name: "ID",
            selector: "ID",
            width: "50px",
            sortable: true
        }
    ];

    const ProjectColms = [
        {
            name: "Project Name",
            selector: "Project Name",
            sortable: true
        },
        {
            name: "ID",
            selector: "ID",
            width: "40px",
            sortable: true
        }
    ];

    const modalColms = [
        // {
        //     name: 'Total A.I',
        //     AI_Report: `${bene1}`,
        // },
        {
            name: 'Eligible A.I',
            AI_Report: `${bene2}`,
        },
        {
            name: 'Animal(s) registered',
            AI_Report: `${bene3}`,
        },
        {
            name: 'PD Recorded',
            AI_Report: `${bene4}`,
        },
        {
            name: 'Abortion Recorded',
            AI_Report: `${bene5}`,
        },
        {
            name: 'Calving Recorded',
            AI_Report: `${bene6}`,
        },
        {
            name: 'Calving Recorded(C.A.I)',
            AI_Report: `${bene7}`,
        },
        {
            name: 'Calving Recorded(S.A.I)',
            AI_Report: `${bene8}`,
        },
        {
            name: 'Female Calf Birth(C)',
            AI_Report: `${bene9}`,
        },
        {
            name: 'Female Calf Birth(S)',
            AI_Report: `${bene10}`,
        },
        {
            name: 'Registered Calf(C.A.I)',
            AI_Report: `${bene11}`,
        },
        {
            name: 'Registered Calf(S.A.I)',
            AI_Report: `${bene12}`,
        },
        {
            name: 'Dry Recorded',
            AI_Report: `${bene13}`,
        },
        {
            name: 'Milk Recorded',
            AI_Report: `${bene14}`,
        },
        {
            name: 'Vaccination Recorded',
            AI_Report: `${bene15}`,
        },
        {
            name: 'Deworming Recorded',
            AI_Report: `${bene16}`,
        },
        {
            name: 'Sold Recorded',
            AI_Report: `${bene17}`,
        },
        {
            name: 'Died Recorded',
            AI_Report: `${bene18}`,
        },
        {
            name: 'Culled Recorded',
            AI_Report: `${bene19}`,
        }
    ];


    const SelectedRowFn = (rows) => {
        setRowsList(rows.selectedRows);
    }

    const DateFn = () => {
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);

        const date1 = lastWeek;
        const date2 = new Date();

        if (date1.getTime() && date2.getTime()) {
            function dateRange(date1, date2, steps = 1) {
                const dateArray = [];
                let currentDate = date1;

                while (currentDate <= date2) {
                    dateArray.push(moment(currentDate).format("L"));
                    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
                }
                return dateArray
            }
            const dates = dateRange(date1, date2);
            setDateList(dates);
        }
    }

    const ApplyFn = async (e) => {
        let ids = (rowsList.map(i => i.ID)).join(',');
        const dataID = dragItem.join(',')
        if ((ids && dataID) || condition) {
            setIsLoading(true);
            await fetch(REPORT_UNION.MilkCustomReport, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({
                    Breed: "", CalculateType: "", OrderBy: "", Report_by: selectTab, SQL: "", Sire: "", Species: "", Status: "", TMWhereSQL: ids, TotCalculateType: "",
                    UserID: 201, WhereCondition: condition ? `and [${value}]${condition}$${daragValue}$` : "", fld: dataID
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then(value => {
                    setHerdList(value);
                    const column = Object.keys(value[0]);
                    setDynamicList(column);
                    setCenterShow(true);
                    var last = value[value.length - 1];
                    // setBene1(last['Total A.I']);
                    // setBene2(last['Eligible A.I']);
                    // setBene3(last['Animal(s) registered']);
                    // setBene4(last['PD Recorded']);
                    // setBene5(last['Abortion Recorded']);
                    // setBene6(last['Calving Recorded']);
                    // setBene7(last['Calving Recorded(C.A.I)']);
                    // setBene8(last['Calving Recorded(S.A.I)']);
                    // setBene9(last['Female Calf Birth(C)']);
                    // setBene10(last['Female Calf Birth(S)']);
                    // setBene11(last['Registered Calf(C.A.I)']);
                    // setBene12(last['Registered Calf(S.A.I)']);
                    // setBene13(last['Dry Recorded']);
                    // setBene14(last['Milk Recorded']);
                    // setBene15(last['Vaccination Recorded']);
                    // setBene16(last['Deworming Recorded']);
                    // setBene17(last['Sold Recorded']);
                    // setBene18(last['Died Recorded']);
                    // setBene19(last['Culled Recorded']);
                    // setBene20(last['Culled Recorded']);
                    setIsLoading(false);
                    setProgressData("0%");
                })
        }
        else {
            setIsLoading(false)
        }
    }

    const handleDragEnd = (e) => {
        setDragItem(prev => prev.includes(e.id) ? prev.filter(n => n !== e.id) : [...prev, e.id])
    }

    const ThData = () => {
        console.log("yes")
        return herdlistcolumn.map((data) => {
            return <th key={data} className={selected && value === data ? "bg-dark text-white colFix" : "bg-light text-dark colFix"} data-value={data} onClick={sotable ? (e) => { sortAscData(e) } : (e) => { sortDscData(e) }}> {data} < span className=" ms-1"> {sotable ? <AiFillCaretUp /> : <AiFillCaretDown />}</span ></th >
        })
    }

    // const tdData = () => {
    //     return herdList.map((data) => {
    //         return (
    //             <tr>
    //                 {
    //                     herdlistcolumn.map((v,columnIndex) => {
    //                         console.log(v)
    //                         return <td sty >{data[v]}</td>
    //                     })
    //                 }
    //             </tr>

    //         )
    //     })
    // }
    const tdData = () => {
        return herdList.map((data, index) => {
            return (
                <tr key={index}>
                    {herdlistcolumn.map((v, columnIndex) => {
                        console.log(v);
                        const content = data[v] || '';
                        let width = 'auto';
                        let textAlign = 'end';

                        if (v === 'Birth Date') {
                            textAlign = 'center';
                            width = columnIndex === 0 ? '10%' : `${content.length * 10}px`;
                        } else {
                            width = columnIndex === 0 ? '10%' : `${content.length * 10}px`;
                        }

                        return <td key={columnIndex} style={{ width, textAlign }}>{content}</td>;
                    })}
                </tr>
            );
        });
    };



    // const tdData = () => {
    //     return herdList.map((data) => {
    //         return (
    //             <tr>
    //                 {herdlistcolumn.map((v) => {
    //                     const cellData = data[v];
    //                     const cellWidth = `${cellData.length * 10}px`;
    //                     return <td style={{ width: cellWidth }}>{cellData}</td>;
    //                 })}
    //             </tr>
    //         );
    //     });
    // };

    // const tdData = () => {
    //     const columnWidths = {};

    //     herdList.forEach((data) => {
    //         herdlistcolumn.forEach((v) => {
    //             const cellData = data[v];
    //             if (cellData !== null && cellData !== undefined) {
    //                 const cellWidth = cellData.toString().length ;
    //                 console.log(`Column: ${v}, Data: ${cellData}, Width: ${cellWidth}`);
    //                 columnWidths[v] = Math.max(columnWidths[v] || 0, cellWidth);
    //             }
    //         });
    //     });

    //     return herdList.map((data, rowIndex) => (
    //         <tr key={rowIndex}>
    //             {herdlistcolumn.map((v, columnIndex) => (
    //                 <td key={columnIndex} style={{ width: `${columnWidths[v]}%` }}>
    //                     {data[v]}
    //                 </td>
    //             ))}
    //         </tr>
    //     ));
    // };

    const refFn = () => {
        setReportName("")
        selectTabFn("")
        setIsLoading(false)
        setCenterShow(false)
        setDragItem([])
    }

    const sortAscData = (e) => {
        console.log(e.target.dataset.value)
        setSelected((prev) => !prev)
        setValue(e.target.dataset.value)
        setSotable((prev) => !prev)
        let dte = [...herdList]
        if (dte.length > 0) {
            let res = dte.sort((a, b) => a.TagID.localeCompare(b.TagID))
            setHerdList(res)
        }
    }

    const sortDscData = (e) => {
        setValue(e.target.dataset.value)
        setSelected((prev) => !prev)
        setSotable((prev) => !prev)
        let dte = [...herdList]
        if (dte.length > 0) {
            let res = dte.sort((a, b) => b.TagID.localeCompare(a.TagID))
            setHerdList(res)
        }
    }


    useEffect(() => {
        fatchSelectFn();
        DateFn();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        console.log(dragItem)
        ApplyFn();
    }, [value, dragItem]);


    // const selectItem = (e1) => {
    //     // setDragItem((prev) => {
    //     //     return [...prev.filter(current => current !== e1.id), e1.id]
    //     // })
    //     // setDragItem((current) =>
    //     //     [...current.filter((e) => e !== e1.id), e1.id]
    //     // );
    //     // ApplyFn(e1);
    //     if (e1) {
    //         setTst((prev) => !prev)
    //         ApplyFn(e1)
    //     }
    // }


    // const selectItem = (e) => {
    //     if (e) {
    //         setDragItem(nums => nums.includes(e.id) ? nums.filter(n => n !== e.id) : [e.id, ...nums])
    //         ApplyFn(e)
    //     }
    //     else {
    //         setDragItem(nums => nums.includes(e.id) ? nums.filter(n => n !== e.id) : [e.id, ...nums])
    //         ApplyFn("")
    //     }
    // }

    // const editFn = (e, value) => {
    //     let id = e.ID;
    //     let ids = (rowsList.map(i => i.ID)).join(',');
    //     setEditName(value);
    //     if (e.ID || e.ID == 0) {
    //         const dateShare = dateList;
    //         fetch(`https://testing.herdman.in/reportUnion/MilkUnionReportDetails`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Breed: [], CalculateType: null, DT: dateShare, DateType: "Date", FieldValue: value, RegFlag: null, Report_by: selectTab, Reportcode: reportName,
    //                 Sire: null, Species: null, Status: "All", TMWhereSQL: id == 0 ? ids : id, TotCalculateType: null, UnknownSireFlag: null, UserID: 201, requestType: "HERDWISE", semenType: 2
    //             })
    //         })
    //             .then(res => res.json())
    //             .then((result) => {console.log(result); setSubHerdList(result); setHerdModal(true); })
    //     }
    // }


    const handleDownloadExcel = () => {
        // const dateShare = rowsDateList === "" ? selectedFromDate : rowsDateList;
        // const ReportName = `Report Name:- ${reportExcelName}`;
        // const ReportTab = `Report Tab:- ${reportExcelTab}`;
        // if (centerShow === true && herdList.length > 0) {
        //     const opt = `Report Detail ${dateShare}`;
        //     const header = Object.keys(subDateWise[0]);

        //     const csvContent = `${opt}\n${ReportName}\n${ReportTab}\n${header.join(",")}\n${subDateWise.map(row => Object.values(row).join(",")).join("\n")}`;

        //     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        //     const link = document.createElement("a");
        //     const url = URL.createObjectURL(blob);
        //     link.setAttribute("href", url);
        //     link.setAttribute("download", `${opt}.csv`);
        //     link.style.visibility = "hidden";
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // } else {
        //     alert("No Data Found");
        // }
    };

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (
                        <Col lg="2" className="hideNavbar">
                            <Sidenav />
                        </Col>
                    )}
                    <Col className="header">
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="9" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h6 className="ms-4 mt-2">Milk Union Reports / Custom Reports</h6>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end">
                                    <button className="btn-blue-Animal" onClick={refFn}>Refresh</button>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                {/* <Row className=" mb-2 justify-content-center">
                                    <Col xl="12">
                                        <FormControl variant="standard" fullWidth>
                                            <Searcable
                                                placeholder="Report Name"
                                                options={reportList}
                                                onChange={(selectedOption) => setReportName(selectedOption)}
                                                value={reportName}
                                                labelKey="name"
                                                valueKey="id"
                                            />
                                        </FormControl>
                                    </Col>
                                </Row> */}
                                <Col xl="2">
                                    <div className="blue_bg_Animal">
                                        <Row className="pt-3 justify-content-center">
                                            <Col xl="11">
                                                <FormControl variant="standard" fullWidth>
                                                    {/* <InputLabel id="demo-simple-select-standard-label">Tabs</InputLabel>
                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={selectTab} onChange={(e) => selectFn(e.target.value)}>
                                                        {fatchTabList.map((e) =>
                                                            <MenuItem className='drop_value' key={e.id} value={e.Name}>{e.Name}</MenuItem>
                                                        )}
                                                    </Select> */}
                                                    <Searcable
                                                        placeholder="Tabs"
                                                        options={fatchTabList}
                                                        onChange={(selectedOption) => selectFn(selectedOption.value)}
                                                        // value={selectTab}
                                                        labelKey="Name"
                                                        valueKey="Name"
                                                    />
                                                </FormControl>
                                            </Col>
                                        </Row>
                                        {
                                            selectTab === "Paravet" && <Row className="pt-3 justify-content-center">
                                                <Col xl="11">
                                                    <FormControl variant="standard" fullWidth>
                                                        <Searcable
                                                            placeholder="Supervisior Name"
                                                            options={subFatchList}
                                                            onChange={(selectedOption) => selectFn1(selectedOption.value)}
                                                            // value={selectTab}
                                                            labelKey="Paravet Name"
                                                            valueKey="ID"
                                                        />
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        }

                                        <Row className="pt-3 pb-3 justify-content-center">
                                            <Col>
                                                {selectTab === "Center" || selectTab === "Route" ?
                                                    <Table columns={colums} data={tabList} height={`calc(100vh - (${heightOffSet}px + 170px))`} selectableRows onSelectedRowsChange={SelectedRowFn} /> :
                                                    <>
                                                        {selectTab === "District" || selectTab === "Taluka" || selectTab === "Village" || selectTab === "Hamlet" ?
                                                            <Table columns={districtColums} data={tabList} selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                                                        {selectTab === "Doctor/VO" ? <Table columns={doctorColms} data={tabList} selectableRows onSelectedRowsChange={SelectedRowFn} height={`calc(100vh - (${heightOffSet}px + 170px))`} /> : ""}
                                                        {selectTab === "DCS/Lot" ? <Table columns={dscColms} data={tabList} selectableRows onSelectedRowsChange={SelectedRowFn} height={`calc(100vh - (${heightOffSet}px + 170px))`} /> : ""}
                                                        {selectTab === "Paravet" ? <Table columns={paravetColums} data={tabList} selectableRows onSelectedRowsChange={SelectedRowFn} height={`calc(100vh - (${heightOffSet}px + 170px))`} /> : ""}
                                                        {selectTab === "Project Wise" ? <Table columns={ProjectColms} data={tabList} selectableRows onSelectedRowsChange={SelectedRowFn} height={`calc(100vh - (${heightOffSet}px + 170px))`} /> : ""}
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xl="8" className="p-0">
                                    <Row>
                                        <Col xl="12">
                                            <div className="blue_bg_Animal p-2 align-items-end justify-content-center" style={{ height: `calc(100vh - (${heightOffSet}px + 64px))` }}>
                                                <Row>
                                                    <Col className="mx-2 mainTable" style={{ overflow: "auto", maxHeight: "80vh" }}>
                                                        <table className="overTable customeTable" border={1}>
                                                            {centerShow && (
                                                                <>
                                                                    <thead border={1}>
                                                                        <tr className="herdHeader1">{ThData()}</tr>
                                                                    </thead>
                                                                    <tbody border={1}>
                                                                        {tdData()}
                                                                    </tbody>
                                                                </>)}
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center justify-content-between" id='heightOffsetStock'>
                                        {/* <Col xl="2"><button className="btn-blue-Animal mt-1" onClick={ApplyFn}>Apply</button></Col> */}
                                        <Col></Col>
                                        <Col xl="2"><button className="btn-blue-Animal mt-2" onClick={handleDownloadExcel}>Export to Excel</button></Col>
                                        {/* <Col><button className="btn-blue-Animal mt-2"><img src={save} className="me-2 mb-0" width="15" />Graph</button></Col> */}
                                        <Col xl="2"><button className="btn-blue-Animal mt-2"><img src={preview} className="me-2 mb-0" width="15" />Preview</button></Col>
                                        <Col xl="2"><button className="btn-blue-Animal mt-2"><img src={close} className="me-2 mb-0" width="15" />Close</button></Col>
                                    </Row>
                                </Col>
                                <Col xl="2">
                                    {/* <div className="blue_bg_Animal p-1 align-items-end justify-content-center"> */}
                                    <Accordion defaultActiveKey={['0']} flush>
                                        <Accordion.Item eventKey="0" style={{ maxHeight: "82vh", overflow: "auto" }}>
                                            <Accordion.Header><h6 className="align-items-center mt-2 accordHeading">Current Parity</h6></Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                <Container>
                                                    {milkList.map((e) =>
                                                        <>
                                                            <Row className="text-start parity-list align-items-center" onClick={() => childrendate(e)}>
                                                                {e.children ? <Col xs="1">+</Col> : <Col xs="1">-</Col>}
                                                                <Col> <p>{e.name}</p></Col>
                                                            </Row>
                                                            {name == e.name ? submilk1.map((e1) =>
                                                                <>
                                                                    {e1.children ?
                                                                        <Row className="mt-1 text-start parity-list align-items-center" onClick={() => childrendata1(e1)} >
                                                                            <Col xs="1" className="ms-3 mainMenuName">+</Col>
                                                                            <Col><p>{e1.name}</p></Col>
                                                                        </Row>
                                                                        :
                                                                        <Row className=" ps-4 mt-1 text-start parity-list align-items-center" draggable onDragEnd={() => handleDragEnd(e1)}>
                                                                            {
                                                                                dragItem.filter(e => e == e1.id).length <= 0
                                                                                    ?
                                                                                    <Col xs="1" className={`${e1.name == "Milk Yield" || e1.name == "Calving" ? "ms-0" : "ms-0"}`}>
                                                                                        <img src={file} width={15} />
                                                                                    </Col> : null
                                                                            }
                                                                            {
                                                                                dragItem.filter(e => e == e1.id).length > 0
                                                                                    ? <Col xs="1" className="ms-3">
                                                                                        <img src={FileCheker} width={15} />
                                                                                    </Col> : null
                                                                            }
                                                                            <Col><p className="ms-1" style={{ fontSize: "12px" }}>{e1.name}</p></Col>
                                                                        </Row >
                                                                    }
                                                                    {name1 == e1.name ? submilk2.map((e2) =>
                                                                        <>
                                                                            {/* <Row className="mt-1 text-start parity-list align-items-center">
                                                                                <Col xs="1" className="ms-5">{e2.children ? "+" : <img src={file} width={15} />}</Col>
                                                                                <Col><p>{e2.name}</p></Col>
                                                                            </Row> */}

                                                                            {e2.children ?
                                                                                <Row className="mt-1 text-start parity-list align-items-center" onClick={() => childrendata1(e2)} >
                                                                                    <Col xs="1" className="ms-3">+</Col>
                                                                                    <Col><p >{e2.name}</p></Col>
                                                                                </Row>
                                                                                :
                                                                                <Row className="ps-4 mt-1 text-start parity-list align-items-center" draggable onDragEnd={() => handleDragEnd(e2)}>
                                                                                    {
                                                                                        dragItem.filter(e => e == e2.id).length <= 0
                                                                                            ?
                                                                                            <Col xs="1" className="ms-3">
                                                                                                <img src={file} width={15} />
                                                                                            </Col> : null
                                                                                    }
                                                                                    {
                                                                                        dragItem.filter(e => e == e2.id).length > 0
                                                                                            ? <Col xs="1" className="ms-3">
                                                                                                <img src={FileCheker} width={15} />
                                                                                            </Col> : null
                                                                                    }
                                                                                    <Col><p className="ms-1" style={{ fontSize: "12px" }}>{e2.name}</p></Col>
                                                                                </Row >
                                                                            }
                                                                        </>
                                                                    ) : ""}
                                                                </>
                                                            ) : ""}
                                                        </>
                                                    )}
                                                </Container>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1" style={{ maxHeight: "82vh", overflow: "auto" }}>
                                            <Accordion.Header><h6 className="align-items-center mt-2 accordHeading">Previous Parity</h6></Accordion.Header>
                                            <Accordion.Body>
                                                <Container>
                                                </Container>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2" style={{ maxHeight: "82vh", overflow: "auto" }}>
                                            <Accordion.Header><h6 className="align-items-center mt-2 accordHeading">Properties</h6></Accordion.Header>
                                            <Accordion.Body>
                                                <Container>
                                                    <Row>
                                                        <Col xl="12">
                                                            <FormControl variant="standard" fullWidth>
                                                                <InputLabel id="demo-simple-select-standard-label">Condition</InputLabel>
                                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={condition} onChange={(e) => setCondition(e.target.value)}>
                                                                    <MenuItem value=">">greater then</MenuItem>
                                                                    <MenuItem value="<">less then</MenuItem>
                                                                    <MenuItem value="=">Equal to</MenuItem>
                                                                    <MenuItem value="=>">equal to grater then </MenuItem>
                                                                    <MenuItem value="<=">less then equal to</MenuItem>
                                                                    <MenuItem value="<=">less then greter then</MenuItem>
                                                                    <MenuItem value="Between">Between</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col xl="12">
                                                            <Input label="Value" value={daragValue} onChange={e => setDragValue(e.target.value)} style={{ width: "150px" }} />
                                                            {/* <label for="vehicle1">Value:</label>
                                                            <input type="text" style={{ width: "150px" }} onChange={(e)=>e.target.value} /> */}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col xl="6"><button className="btn-blue-Animal mt-2" onClick={ApplyFn}>Apply</button></Col>
                                                    </Row>
                                                </Container>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    {/* </div> */}
                                </Col>
                            </Row>
                        </Container>
                    </Col >
                </Row >
            </Container >

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)
            }

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }

        </>
    )
}