import { FormControlLabel, Radio, RadioGroup, Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';
import Date from '../../Componet/Datepicker/Date';
import Searcable from '../../Componet/Dropdown/Searcable';
import { ToastContainer, toast } from 'react-toastify';
import BackBtn from '../../Componet/Button/BackBtn';
import { downloadExcel } from 'react-export-table-to-excel';
import { ConstructionOutlined } from '@mui/icons-material';
const { SireApi } = API_URLS;


const label = { inputProps: { "aria-label": "Switch demo" } };

const Sire = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const userName = localStorage.getItem("USERNAME");

    async function addData(credentials) {
        return fetch(SireApi.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    async function updateData(credentials) {
        return fetch(SireApi.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const [code, setCode] = useState("")
    const [name, setName] = useState("")
    const [search, setSearch] = useState("")
    const [date, setDate] = useState()
    const [weight, setWeight] = useState("")
    const [bType, setBtype] = useState("")
    const [breed, setBreed] = useState("")
    const [motherId, setMotherId] = useState("")
    const [straw, setStraw] = useState("")
    const [source, setSource] = useState("")
    const [allowAllUser, setAllowAllUser] = useState("")
    const [company, setCompany] = useState("")
    const [motherMilkYield, setMotherMilkYield] = useState("")
    const [naturalOrAIBirth, setNaturalOrAIBirth] = useState("0")
    const [sireId, setSireId] = useState("")
    const [spCode, setSpCode] = useState("")
    const [searchFilter, setSearchFilter] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false)
    const [editToggle, setEditToggle] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false)

    const [editId, setEditId] = useState("")
    const [deleteId, setDeleteId] = useState()
    const [user, setUser] = useState("")


    const [sireList, setSireList] = useState([])
    const [breedList, setBreedList] = useState([])
    const [sourceList, setSourceList] = useState([])

    const sireGetData = async () => {
        setIsLoading(true)
        // fetch(SireApi.GetDetails, {
        fetch(SireApi.GetAllSire, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setSireList(result); setProgressData("0%"); })
        // const res = await getData();
        // if (res) {
        //     setSireList(res.data);
        //     setIsLoading(false)
        // }
    }

    const formApi = () => {
        //source:
        fetch(`${SireApi.GetAll}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                setSourceList(result);
            });

        //breed:

        fetch(`${SireApi.GetBreed}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) =>
                res.json()
            )
            .then((result) => {
                setBreedList(result);
            });
    }
    const Add = () => {
        setModalShow(true);
        setEditId("")
        setEditToggle("");
        setEditId("");
        setCode("")
        setName("")
        setDate("")
        setWeight("")
        setSireId("")
        setMotherId("")
        setMotherMilkYield("")
        setStraw("")
        setBreed("")
        setSource("")
        setSpCode("")
    }

    const handleKeyPress = (event) => {
        const pattern = /[0-9]/; // Allow only digits

        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    };

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = sireList.filter((d) => {
                return d.code.toLowerCase().startsWith(data.toLowerCase()) || d.Name.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(sireList);
        }
        setSearch(data);
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        sireGetData();
        formApi();
    }, [breed])

    const AddData = async () => {
        if (editId) {
            const res = await updateData({
                id: editId, allowAllUser,
                birthWeight: weight,
                breed: parseInt(breed.value),
                code,
                company: "1",
                dob: date,
                isSuspended,
                mid: motherId,
                minStrawStock: straw,
                motherMilkYield,
                name,
                naturalOrAIBirth: naturalOrAIBirth,
                sid: sireId,
                source,
                user: "201",
                mcode: spCode
            });
            if (res) {
                toast.success("Data Update Success")
                setModalShow(false)
                sireGetData()
                formApi();
            }
            formApi();
        }
        else {
            const res = await addData({
                allowAllUser,
                birthWeight: weight,
                breed: breed.value,
                code,
                company: "1",
                dob: date,
                isSuspended,
                mid: motherId,
                minStrawStock: straw,
                motherMilkYield,
                name,
                naturalOrAIBirth,
                sid: sireId,
                source,
                sireIndex: "",
                user: "201",
                mcode: spCode
            });
            if (res) {
                setModalShow(false)
                sireGetData()
                formApi()
            }

        }
    }


    const updateFn = (e) => {
        console.log(e)
        setEditId(e.id)
        setEditToggle(true);
        setEditId(e.id);
        setCode(e.code);
        setName(e.Name);
        setDate(dayjs(e["DOB"]))
        setBreed(e.breed)
        setWeight(e.BirthWeight)
        setModalShow(true);
        setIsSuspended(e.IsSuspended)
        setMotherId(e.MID)
        setStraw(e.MinStrawStock)
        setMotherMilkYield(e.MotherMilkYield)
        setNaturalOrAIBirth(e.NaturalOrAIBirth)
        setSireId(e.SID)
        setSource(e.source.id)
        setSpCode(e.mcode)
    };

    const deleteData = (id) => {
        // setUser(e.Name)
        // setDeleteShow(true)
        // let id = e.id;
        fetch(`${API_KEY}/sire/delete?id=` + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                sireGetData();
                setDeleteShow(false)
                setModalShow(false);
            });

    }
    const colum = [
        {
            name: "Code",
            selector: "code",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{c["code"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Name",
            selector: "Name",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "100%", display: "flex", justifyContent: "flex-start" }}>{c["Name"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "DOB",
            selector: "DOB",
            cell: (e) => <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{e["DOB"] !== null ? moment(e["DOB"])?.format("DD-MM-YYYY") : null}</div>,
            sortable: true
        },
        {
            name: "Birth Weight",
            selector: "BirthWeight",
            cell: (e) => <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{e["BirthWeight"]}</div>,
            sortable: true
        },
        {
            name: "Birth Type",
            selector: "NaturalOrAIBirth",
            cell: (c) => (
                <>
                    <div style={{ paddingLeft: "5px", width: "100%", display: "flex", justifyContent: "flex-start" }}>{c["NaturalOrAIBirth"] == "0" ? 'Natural' : 'A.I'}</div>
                </>
            ),
            sortable: true
        },
        userName === "haismanager" ?
            {
                name: "SAP Code",
                selector: "mcode",
            } : null,
        {
            name: "Min Straw Stock",
            selector: "MinStrawStock",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{c["MinStrawStock"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Breed",
            selector: "breed",
            cell: (e) => (
                <div style={{ paddingLeft: "5px", width: "100%", display: "flex", justifyContent: "flex-start" }}>
                    {
                        breedList.map((i) => {
                            if (i.id == e.breed) {
                                return (
                                    <>{i.Name}</>
                                )
                            }
                        })
                    }
                </div>
            ),
            sortable: true
        },
        {
            name: "Source",
            selector: "source",
            cell: (e) => <div style={{ paddingLeft: "5px", width: "100%" }}>{e.source ? e.source.Name : ""}</div>,
            sortable: true
        },
        {
            name: "Is Suspended",
            selector: "IsSuspended",
            cell: (d) => <div style={{ paddingLeft: "5px", width: "100%" }}>
                <Switch {...label} defaultChecked={d.IsSuspended} onChange={(e) => { setIsSuspended(e, d) }} /></div>,
            sortable: true
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => { updateFn(e); updateFn(e) }}
                        style={{ width: "15px", margin: "10px" }}
                    />
                    <img
                        src={remove}
                        onClick={() => { setDeleteShow(true); setDeleteId(e.id); setUser(e.Name); }}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
    ].filter(Boolean);


    const handledExcel = () => {
        // Define the keys you want to export
        const keysToExport = ["code", "Name", "DOB", "BirthWeight", "MinStrawStock", "breed", "source", "IsSuspended"];

        // Filter the zoneList array to include only the required keys
        const filteredData = sireList.map(item => {
            const filteredItem = {};
            keysToExport.forEach(key => {
                // Convert boolean to string if the key is "IsSuspended"
                filteredItem[key] = key === "IsSuspended" ? String(item[key]) : item[key];
            });
            return filteredItem;
        });

        // Prepare the header row
        const header = keysToExport.map(key => {
            const column = colum.find(col => col.selector === key);
            return column ? column.name : key;
        });

        // Download Excel
        downloadExcel({
            fileName: "Zone Sub Center",
            sheet: "data",
            tablePayload: {
                header,
                body: filteredData
            }
        });
    }

    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}> <img src={menu} width={20} /> </button>
                                    <h5 className="ms-4 mt-2">Master / sire</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-between p-2 d-flex" id='heightOffsetStock'>
                                <Col sm="8" lg="4" xl="3" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1 me-2" onClick={Add}>Add Sire</button>
                                    <button className="btn-blue-Animal mt-1 mb-1 ms-2" onClick={() => handledExcel()}>Export</button>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={sireList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagin   ation />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {sireList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : sireList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        Delete {user} ?
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => deleteData(deleteId)}>Delete</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(deleteId)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update Sire" : "Add New Sire"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="6">
                                <TextField label="Code" id="standard-basic" value={code} onChange={(e) => { setCode(e.target.value); }} data={sireList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Name" value={name} onChange={(e) => { setName(e.target.value); }} id="standard-basic" data={sireList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-2 align-items-end">
                            <Col xl="6">
                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "14px" }}>
                                    Birth Name
                                </InputLabel>
                                <TextField type="date" value={date} onChange={(e) => { setDate(e.target.value); }} id="standard-basic" data={sireList} fullWidth variant="standard" /> */}

                                <Date label="Joining Date" value={date} onChange={(e) => { setDate(e); }} />
                            </Col>
                            <Col xl="6">
                                <TextField label="Birth Weight" value={weight} onChange={(e) => { setWeight(e.target.value); }} id="standard-basic" data={sireList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "14px" }}>
                                    Birth Type
                                </InputLabel>
                                <Row>
                                    <Col className='ms-3'>
                                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label justify-content-between" value={naturalOrAIBirth} defaultValue={"0"} onChange={e => setNaturalOrAIBirth(e.target.value)} name="row-radio-buttons-group">
                                            <FormControlLabel value="0" control={<Radio className="p-1" />} label="Natural" className="mt-1" />
                                            <FormControlLabel value="1" control={<Radio className="p-1" />} label="A.I" className="mt-1" />
                                        </RadioGroup>
                                    </Col>
                                </Row>

                                {/* <TextField type="radio" name={bType} onChange={(e) => { setBtype(e.target.value) }} id="standard-basic" data={sireList} fullWidth variant="standard" /> */}
                            </Col>
                            <Col xl="6">
                                <TextField label="Sire ID" value={sireId} onChange={(e) => { setSireId(e.target.value) }} id="standard-basic" fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Mother ID" value={motherId} onChange={(e) => { setMotherId(e.target.value); }} id="standard-basic" data={sireList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Mother Milk Yield" value={motherMilkYield} onChange={(e) => { setMotherMilkYield(e.target.value); }} id="standard-basic" data={sireList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4 align-items-end">
                            <Col xl="6">
                                <TextField label="Straw Stock" value={straw} onChange={(e) => { setStraw(e.target.value); }} id="standard-basic" data={sireList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <Searcable
                                        placeholder="Breed"
                                        options={breedList}
                                        onChange={(selectedOption) => setBreed(selectedOption)}
                                        value={breed}
                                        labelKey="Name"
                                        valueKey="id"
                                    />

                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>
                                        Source
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={source} onChange={(e) => { setSource(e.target.value); }}>
                                        {sourceList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Searcable
                                        placeholder="Source"
                                        options={sourceList}
                                        onChange={(selectedOption) => setSource(selectedOption.id)}
                                        value={source}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>
                            </Col>
                            <Col xl="6">
                                <Switch {...label} onChange={(e) => { setIsSuspended(e.target.checked) }} defaultChecked={isSuspended} />Is Suspended : {isSuspended ? "Yes" : "No"}
                            </Col>
                        </Row>
                        {
                            userName === "haismanager" &&
                            <Row className="mt-2">
                                <Col xl="6">
                                    <TextField
                                        label="Sap Code"
                                        value={spCode}
                                        onChange={(e) => {
                                            setSpCode(e.target.value);
                                        }}
                                        id="standard-basic"
                                        fullWidth
                                        variant="standard"
                                        onKeyPress={handleKeyPress} 
                                    />
                                </Col>
                            </Row>
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => AddData()}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>

    )
}

export default Sire;