import React, { useState, useEffect } from "react";
import { Col, Container, Row, Offcanvas, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";

import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import edit from "../../Photo/master/edit.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import Sidenav from "../../Componet/Sidenav/Sidenav";
import Input from "../../Componet/InputFild/Input";
import Table from "../../Componet/DataTable/Table";

import { STORAGE_KEY } from "../../constant/common";
import { useNavigate } from "react-router-dom";
import { serviceUrl } from "../../API/Api";
import BackBtn from "../../Componet/Button/BackBtn";

export default function FarmerComplaint() {

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const navigate = useNavigate()

    const [name, setName] = useState("");
    const [gujrati, setGujrati] = useState("");
    const [id, setID] = useState("");
    const [search, setSearch] = useState("");
    const [progressData, setProgressData] = useState("0%");

    const logOut = () => { navigate("/") }

    const [farmerComplaintList, setFarmerComplaintList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }


    const fatchData = () => {
        setIsLoading(true)
        fetch(`${API_KEY}/health/farmer_complaint?offset=0&limit=5000&column_name=id&sort_order=desc&search_term=&filter=undefined`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setFarmerComplaintList(result.data); setIsLoading(false); setProgressData("0%") })
    };

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        fatchData();
    }, []);

    const colums = [
        {
            name: "Id",
            selector: "id",
            cell: (e) => (<p style={{paddingRight: "5px", width: "100%", display: "flex", justifyContent: "flex-end"}} className='ms-auto'>{e["id"]}</p>),
            sortable: true
        },
        {
            name: "Name",
            selector: "Name",
            cell: (e) => (<p style={{ paddingLeft: "5px", width: "100%", display: "flex", justifyContent: "flex-start"}} className='ms-auto'>{e["Name"]}</p>),
            sortable: true
        },
        {
            name: "Gujrati",
            selector: "Gujrathi",
            cell: (e) => (<p style={{ paddingLeft: "5px", width: "100%", display: "flex", justifyContent: "flex-start"}} className='ms-auto'>{e["Gujrathi"]}</p>),
            sortable: true
        },
        {
            name: "Actions",
            cell: (e) => (<img src={edit} width={15} onClick={() => editFn(e)} />),
        },
    ];

    const refreshFn = () => {
        setModalShow(false);
        fatchData();
        setID("");
        setName("");
        setGujrati("");
    }

    const editFn = (e) => {
        setID(e.id);
        setName(e.Name);
        setGujrati(e.Gujrathi);
        setModalShow(true);
    }

    const SearchFn = (e) => {
        setSearch(e);
        fetch(`${API_KEY}/health/farmer_complaint?offset=0&limit=5000&column_name=id&sort_order=desc&search_term=${e}&filter=undefined`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => { setFarmerComplaintList(result.data); })

    }

    const SaveFn = async () => {
        if (id) {
            fetch(`${API_KEY}/health/farmer_complaint/` + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ Name: name, Gujrathi: gujrati, id }),
            })
                .then(res => res.json())
                .then((result) => { if (result) { refreshFn(); } })
        }
    }

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (
                        <Col lg="2" className="hideNavbar">
                            <Sidenav />
                        </Col>
                    )}
                    <Col lg={headerShow ? "10" : "12"} className="header">
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Health / Farmer Complaint</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-between" id='heightOffsetStock'>
                                <Col xxl="2" md="3" sm="3" xs="6" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal" onClick={() => setModalShow(true)}>Add Farmer Complaint</button>
                                </Col>
                                <Col xxl="2" md="3" sm="3" xs="6" className="mt-2">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e.target.value)} />
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col className="mt-2">
                                        <Table columns={colums} data={farmerComplaintList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination> ... </Table>
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {farmerComplaintList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colums} data={farmerComplaintList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colums.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{id ? "Update Farmer Complaint" : "Add New Farmer Complaint"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xxl="6" md="6">
                                <TextField label="Name" value={name} onChange={e => setName(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col xxl="6" md="6">
                                <TextField label="Gujrati" value={gujrati} onChange={e => setGujrati(e.target.value)} fullWidth variant="standard" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="py-0">
                    <Container>
                        <Row className="justify-content-between">
                            <Col xxl="2" md="3">
                                <button className="btn-blue-Animal mt-2 mb-1" style={{ background: "#43668E" }} onClick={() => SaveFn()}>{id ? "Update" : "Save"}</button>
                            </Col>
                            <Col xxl="2" md="3">
                                <button className="btn-blue-Animal mt-2 mb-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}>Cancel</button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}


            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }
        </>
    );
}
