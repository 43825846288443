import React, { useState, useEffect } from 'react';
import './Dscwise.css';
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import { TextField, Switch } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';

import Sidenav from "../../Componet/Sidenav/Sidenav";
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';

import moment from 'moment';
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS } from "../../API/Api";
import BackBtn from '../../Componet/Button/BackBtn';
const { MppAPI } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
const label = { inputProps: { "aria-label": "Switch demo" } };

async function getConsult(credentials) {
    return fetch(MppAPI.Select, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getYear(credentials) {
    return fetch(MppAPI.SelectYear, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getData(credentials) {
    return fetch(MppAPI.MppView, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function saveData(credentials) {
    return fetch(MppAPI.DcsSave, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

export default function DscWise() {

    const selectedToDate = new Date().toISOString().slice(0, 10);
    const navigate = useNavigate();
    const logOut = () => { navigate("/"); }

    const [consult, setConsult] = useState("");
    const [cmtRound, setCmtRound] = useState(1);
    const [followUpTest, setFollowUpTest] = useState(1);
    const [year, setYear] = useState("");
    const [date, setDate] = useState("");
    const [scc, setSCC] = useState("");
    const [MilkType, setMilkType] = useState("");
    const [positive, setPositive] = useState(false);
    const [progressData, setProgressData] = useState("0%");

    const [heightOffSet, setHeight] = useState(0);
    const [dscList, setDscList] = useState([]);
    const [centerList, setCenterList] = useState([]);
    const [routeVisitList, setRouteVisitList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const milkType = ["Cow", "Buffalo", "Mix"];

    const fatchAnimalData = async () => {
        const res = await getConsult({ id: 5 });
        const res1 = await getYear();

        if (res) { setCenterList(res); }
        if (res1) { setRouteVisitList(res1); }
    }

    useEffect(() => {
        fatchAnimalData();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, []);

    const sccFn = (e) => {
        setSCC(e);
    }

    // const textHandle = (e, index) => {
    //     console.log(index);
    //     const value = e.target.value;
    //     // console.log(value);
    //     if (value >= 500000) { setPositive(true); } else { setPositive(false); }
    //     setDscList(dscList.map((o) => {
    //         console.log(o);
    //         if (o === index) { 
    //             return { ...index, SCC: value } 
    //         }
    //         else { 
    //             return o 
    //         };
    //     }));
    // }

    const textHandle = (e, row) => {
        const value = parseFloat(e.target.value);

        const updatedDscList = dscList.map((o) => {
            if (o.lotid === row.lotid) {
                return { ...o, SCC: value };
            } else {
                return o;
            }
        });

        const isPositive = value >= 500000;

        setDscList(updatedDscList);
        setPositive(isPositive);
    };


    const colum = [
        {
            name: "Lot Id",
            selector: "lotid",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px" }}>{c.lotid}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "DCS SAP",
            selector: "DCS SAP",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px" }}>{c["DCS SAP"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Village",
            selector: "Village",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px" }}>{c.Village}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Milk Type",
            cell: (e1) =>
                <div className='p-3 tableDrop' style={{ width: "50%" }}>
                    <Select
                        placeholder={e1["Milk Type"] ? e1["Milk Type"] : "Consult"}
                        onChange={(selectedOption) => setConsult(selectedOption.value)}
                        options={milkType.map((item) => ({ value: item, label: item }))}
                    />
                </div>
        },
        {
            name: "SCC/CC",
            cell: (d) => (
                <div style={{ paddingRight: "5px" }}>
                    <input
                        type='number'
                        style={{ width: "90%" }}
                        onChange={(e) => textHandle(e, d)}
                        value={d.SCC ? d.SCC : ""}
                    />
                </div>
            ),
            sortable: true
        },
        // {
        //     name: "SCC/CC",
        //     cell: (d) => <input type='number' onChange={(e) => textHandle(e, d)} value={d.SCC ? d.SCC : ""} />,
        // },
        // {
        //     name: "IsPositive",
        //     cell: (e) => (<Switch {...label} checked={e.Result || positive} onChange={(e) => setPositive(e.target.value)} />),
        // },
        {
            name: "IsPositive",
            cell: (d) => (
                <Switch
                    {...label}
                    checked={d.SCC >= 500000}
                    onChange={(e) => textHandle(e, d)}
                />
            ),
        },
        {
            name: "Action",
            cell: (e) => (
                <button style={{ marginLeft: "5px", width: "50%" }} type="button" className='SaveBtn' onClick={() => SaveFn(e)}>Save</button>
            ),
        }
    ]

    const SaveFn = async (e) => {

        const date = moment(selectedToDate).format("MM/DD/YYYY");
        const milkType1 = (MilkType === "Cow" ? "1" : "2");
        const farmer = consult === "Cow" ? "1" : "2";
        const result = positive ? "2" : "1";

        // if (scc && result) {
        const res = await saveData({
            createdAt: date, farmer: farmer, followUpTest, id: "0", inputDate: date, lot: e.lotid, mppCmtRound: cmtRound, scc: e.SCC, test: scc, testedBy: farmer,
            updatedAt: null, yearMaster: 2023, milkType: result, result
        })

        if (res) {
            fatchAnimalData()
            toast.success(res.msg)
        }
        else {
            fatchAnimalData()
            toast.error(res.error)
        }
        // }
    }

    const ViewFn = async () => {
        const inputDate = date ? moment(date).format("YYYY-MM-DD") : moment(selectedToDate).format("YYYY-MM-DD");
        if (consult) {
            setIsLoading(true);
            const res = await getData({ cmtRound: parseInt(cmtRound), followUpTest: 1, inputDate, staff: consult, year: 2023 });
            if (res) {
                setDscList(res);
                setIsLoading(false);
            }
            console.log(res);

        }
    }

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Project / MPP / Dcs Wise Test</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end" id='heightOffsetStock'>
                                <Col md="3">
                                    <Select
                                        placeholder="Consult"
                                        onChange={(selectedOption) => setConsult(selectedOption.value)}
                                        options={centerList.map((item) => ({ value: item.ID, label: item.name }))}
                                    />
                                </Col>
                                <Col xl="1" md="3" sm="3" xs="6">
                                    <TextField label="cmtRound" fullWidth variant="standard" value={cmtRound} onChange={e => setCmtRound(e.target.value)} />
                                </Col>
                                {/* <Col xl="1" md="3" sm="3" xs="6">
                                    <TextField label="FollowUpTest" fullWidth variant="standard" value={followUpTest} onChange={e => setFollowUpTest(e.target.value)} />
                                </Col> */}
                                <Col md="1">
                                    <Select
                                        placeholder="Year"
                                        onChange={(selectedOption) => setYear(selectedOption.value)}
                                        options={routeVisitList.map((item) => ({ value: item.id, label: item.YearName }))}
                                    />
                                </Col>
                                <Col xl="1" md="3" sm="3" xs="6">
                                    <div className="mt-1">
                                        <sub className="mb-0 pb-0">Date</sub>
                                        <input type="date" className="dateSelect_bg" defaultValue={selectedToDate} onChange={e => setDate(e.target.value)} />
                                    </div>
                                </Col>
                                {/* <Col lg="1" md="2" sm="3" xs="6" className='ms-auto'>
                                    <button className="btn-blue-Animal">Import</button>
                                </Col> */}
                                <Col lg="1" md="2" sm="3" xs="6" className='ms-auto'>
                                    <button className="btn-blue-Animal" onClick={() => ViewFn()}>View</button>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs="12" sm="12" className={dscList.length > 0 ? 'MainTableBorder' : ""}>
                                    <Table columns={colum} data={dscList} height={`calc(100vh - (${heightOffSet}px + 135px))`} pagination />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div>
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

        </>
    )
};
