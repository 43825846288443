import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import {
    TextField, FormControl, InputLabel,
    //  Select, 
    MenuItem, Switch
} from "@mui/material";

import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';

import Sidenav from "../../Componet/Sidenav/Sidenav";
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS } from "../../API/Api";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import BackBtn from '../../Componet/Button/BackBtn';
const { MppAPI } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
const label = { inputProps: { "aria-label": "Switch demo" } };

async function getConsult(credentials) {
    return fetch(MppAPI.Select, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getYear(credentials) {
    return fetch(MppAPI.SelectYear, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getData(credentials) {
    return fetch(MppAPI.FarmerView, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function SaveData(credentials) {
    return fetch(MppAPI.FarmerSave, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

export default function FarmerWise() {

    const navigate = useNavigate();
    const logOut = () => { navigate("/") };

    const selectedToDate = new Date().toISOString().slice(0, 10);

    const [consult, setConsult] = useState("");
    const [consultName, setConsultName] = useState("");
    const [cmtRound, setCmtRound] = useState(1);
    const [followUpTest, setFollowUpTest] = useState(1);
    const [progressData, setProgressData] = useState("0%");
    const [year, setYear] = useState("");
    const [date, setDate] = useState("");
    const [SCC, setSCC] = useState("")
    const [MilkType, setMilkType] = useState("");
    const [textType, setTestType] = useState("")
    const [resultNum, setResultNum] = useState("")

    const [heightOffSet, setHeight] = useState(0);

    const [dscList, setDscList] = useState([]);
    const [centerList, setCenterList] = useState([]);
    const [routeVisitList, setRouteVisitList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const milkType = ["+", "++", "+++"];

    const fatchAnimalData = async () => {
        const res = await getConsult({ id: 5 });
        const res1 = await getYear();
        if (res) {
            setCenterList(res);
        }
        if (res1) {
            setRouteVisitList(res1);
        }
    }

    useEffect(() => {
        fatchAnimalData();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, [textType]);

    const colum = [
        {
            name: "Farmerid",
            selector: "Farmerid",
            cell: (c) => (
                <>
                    <div style={{ paddingRight: "5px" }}>{c.Farmerid}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Farmer Code",
            selector: "Famer Code",

            cell: (e) => (<p style={{ paddingRight: "5px" }} className="m-0">{e['Famer Code']}</p>),
            width: "120px",
            sortable: true
        },
        {
            name: "Farmer Name",
            selector: "Farmer Name",

            cell: (e) => (<p style={{ paddingRight: "5px" }} className="m-0">{e['Farmer Name']}</p>),
            sortable: true
        },
        {
            name: "Milk Type",
            selector: "Milk Type",

            cell: (e) => (<p style={{ paddingRight: "5px" }} className="m-0">{e['Milk Type']}</p>),
            width: "150px",
            sortable: true
        },
        {
            name: "Test Type",
            cell: (e1) => (
                <Select
                    className='m-2'
                    placeholder={e1["Test Type"] ? e1["Test Type"] : "Test Type"}
                    // value={milkType.includes(e1["Test Type"]) ? e1["Test Type"] : ""}
                    onChange={(selectedOption) => setTestType(selectedOption.value)}
                    options={milkType.map((item) => ({ value: item, label: item }))}
                />
            ),
            width: "200px"
        },
        {
            name: "IsPositive",
            cell: (e) => (<Switch {...label} defaultChecked={e.Result ? true : false} />),
        },
        {
            name: "Action",
            cell: (e) => (
                <div style={{margin:"6%"}}>
                    <button style={{ width: "100%", padding:"9px" }} type="button" className='SaveBtn' onClick={() => saveFn(e)}>Save</button >
                </div>
            ),
            width: "120px"
        },
    ]

    const SelectFn = (inputEv, index) => {
        const value = inputEv;
        console.log(value)
        setDscList(dscList.map((o) => {
            if (o === index) { return { ...index, Result: value } }
            else { return o };
        }));
    }

    const View = async () => {
        if (year == "") {
            toast.error("Please Select Year")
        }
        else {
            const inputDate = date ? moment(date).format("DD/MM/YYYY") : moment(selectedToDate).format("DD/MM/YYYY");
            if (consult && year) {
                try {
                    setIsLoading(true);
                    const res = await getData({ cmtRound, followUpTest, inputDate, staff: consult, year });
                    if (res) {
                        setDscList(res);
                        setIsLoading(false);
                    }
                } finally {
                    setIsLoading(false);
                }
            }
        }
    }
    // const result = (e.Result == "+" ? "1" : (e.Result == "++" ? "2" : "3"));

    const saveFn = async (e) => {
        setIsLoading(true)
        const date = moment(selectedToDate).format("MM/DD/YYYY");

        const resultNum = textType === "++" ? "2" : textType === "+" ? "1" : textType === null || textType === "" ? "" : "3";
        console.log(e);

        const res = await SaveData({
            createdAt: date,
            farmer: e.Farmerid.toString(),
            followUpTest,
            id: "0",
            inputDate: date,
            lot: null,
            mppCmtRound: cmtRound,
            result: resultNum.toString(),
            test: resultNum.toString(),
            testedBy: consult,
            updatedAt: null,
            yearMaster: year,
        });

        if (res) {
            toast.success("Data Update Successfully");
            setIsLoading(false);
            setProgressData("0%")
        }
    };

    const handleDownloadExcel = () => {
        const Date = `Date:- ${date}`;
        if (dscList.length > 0) {
            const opt = `Farmer Wise Test ${date}`;

            const header = [...Object.keys(dscList[0]), "isPositive"];

            const csvContent = `${opt}\n${Date}\n${header.join(",")}\n${dscList.map(row => [...Object.values(row), row.isPositive].join(",")).join("\n")}`;

            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `${opt}.csv`);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            alert("No Data Found");
        }
    };


    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Project / MPP / Farmer Wise Test</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end" id='heightOffsetStock'>
                                <Col xl="3" md="4" sm="5">
                                    <Select
                                        placeholder="Consult"
                                        onChange={(selectedOption) => { setConsult(selectedOption.value); setConsultName(selectedOption.label) }}
                                        options={centerList.map((item) => ({ value: item.ID, label: item.name }))}
                                    />
                                </Col>
                                <Col xl="1" md="2" sm="3" xs="6" className='justify-content-start'>
                                    <TextField label="cmtRound" fullWidth variant="standard" value={cmtRound} onChange={e => setCmtRound(e.target.value)} />
                                </Col>
                                <Col xl="1" sm="2" xs="6" className='justify-content-start'>
                                    <TextField label="FollowUpTest" fullWidth variant="standard" value={followUpTest} onChange={e => setFollowUpTest(e.target.value)} />
                                </Col>
                                <Col xl="1" md="2" sm="3" xs="6" className='justify-content-start'>
                                    <Select
                                        placeholder="Year"
                                        onChange={(selectedOption) => setYear(selectedOption.value)}
                                        options={routeVisitList.map((item) => ({ value: item.id, label: item.YearName }))}
                                    />
                                </Col>
                                <Col xl="1" md="2" sm="3" xs="6" className='justify-content-start'>
                                    <div className="mt-1">
                                        <sub className="mb-0 pb-0">Date</sub>
                                        <input type="date" className="dateSelect_bg" defaultValue={selectedToDate} onChange={e => setDate(e.target.value)} />
                                    </div>
                                </Col>
                                <Col lg="1" md="2" sm="3" xs="6" className="mt-2 ms-auto ">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel} >Export</button>
                                </Col>
                                <Col lg="1" md="2" sm="3" xs="6" className="mt-2 ms-end ">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={() => { navigate("/OwnerFarmer") }}>Add Farmer</button>
                                </Col>
                                <Col lg="1" md="2" sm="3" xs="6" className="mt-2 ms-end ">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={(e) => View(e)}>View</button>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={dscList} height={`calc(100vh - (${heightOffSet}px + 130px))`} pagination />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {isLoading && (
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div>
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
            )}

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />
        </>
    )
};
