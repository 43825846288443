import { React } from 'react';
import './datatable.css';
import Table, { createTheme, defaultThemes } from "react-data-table-component";

const customStyles = {
    header: {
        style: {
            minHeight: '76px',
            background: "red"
        },
    },
    headRow: {
        style: {
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: defaultThemes.default.divider.default,

        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,

            },

        },
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,

            },
        },
    },
};

createTheme('solarized', {
    text: {
        primary: '#000',
        secondary: '#000',
    },
    background: {
        default: '#FFFFFF',
    },
    context: {
        background: '#FFFFFF',
        text: '#000',
    },
    divider: {
        default: '#FFFFFF',
    },
    button: {
        default: '#FFFFFF',
        hover: '#FFFFFF',
        focus: '#FFFFFF',
        disabled: '#FFFFFF',
    },
    sortFocus: {
        default: '#FFFFFF',
    },
}, 'light');

const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
};

export default function DataTable({ columns, data, ...props }) {
    return (
        <>
            <Table
                className={props.className}
                title={props.title}
                columns={columns}
                data={data}
                noHeader
                defaultSortField={props.sortField}
                defaultSortAsc={true}
                pagination={props.pagination}
                noTableHead={props.noTableHead}
                // noDataComponent="No Record Found"
                noDataComponent=""
                dense
                // fixedHeaderScrollHeight="71vh"
                fixedHeaderScrollHeight={props.height}
                selectableRows={props.selectableRows}
                selectableRowSelected={props.selectableRowSelected}
                highlightOnHover
                // subHeader
                // subHeaderComponent={props.subHeaderComponent}
                fixedHeader
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                paginationComponentOptions={paginationComponentOptions}
                theme="solarized"
                paginationPerPage={5}
                customStyles={customStyles}
                expandableRows={props.expandableRows}
            />
        </>
    )
};
