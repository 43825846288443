import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Offcanvas, Modal } from "react-bootstrap";
import {
    TextField, FormControl, InputLabel,
    //  Select,
    MenuItem
} from "@mui/material";

import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import remove from "../../Photo/master/remove.svg";
import edit from "../../Photo/master/edit.svg"
import closeButton from "../../Photo/milk-union/close button.svg";

import Sidenav from "../../Componet/Sidenav/Sidenav";
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';

import moment from 'moment';
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";

const { AllocationApi, CampPlanning } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getYearData(credentials) {
    return fetch(AllocationApi.GetYear, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getProjectData(credentials) {
    return fetch(AllocationApi.GetProject, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getFipData(credentials) {
    return fetch(AllocationApi.GetFipList, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getDcsData(credentials) {
    return fetch(AllocationApi.GetDcs, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
// async function getdata(credentials) {

//     return fetch(AllocationApi.GetData, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             "Accept": "application/json",
//             "Authorization": `Bearer ${added_by}`,
//         },
//         body: JSON.stringify(credentials)
//     })
//         .then(res => res.json());
// }
async function saveAllocation(credentials) {
    return fetch(AllocationApi.SaveAllocation, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getAllocateData(credentials) {
    return fetch(CampPlanning.GetCampGrid, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

export default function Allocation() {

    const API_KEY = serviceUrl;

    const selectedToDate = new Date().toISOString().slice(0, 10);
    const currentYear = new Date().getFullYear();

    const [fip, setFip] = useState("103");
    const [project, setProject] = useState("1");
    const [search, setSearch] = useState("");
    const [year, setYear] = useState("");
    const [dcs, setDcs] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const [startYear, setStartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [cstartYear, setCStartYear] = useState("");
    const [cendYear, setCEndYear] = useState("");
    const [deleteId, setDeleteId] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [heightOffSet, setHeight] = useState(0);

    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [projectList, setProjectList] = useState([]);
    const [searchFilter, setSearchFilter] = useState("");
    const [yearList, setYearList] = useState([]);
    const [dcsList, setDcsList] = useState([]);
    const [fipList, setFipList] = useState([]);
    const [allocationList, setAllocationList] = useState([]);



    const fetchData = async () => {
        const res = await getYearData();
        const res1 = await getProjectData();
        const res2 = await getFipData({ id: 5 });
        const res3 = await getDcsData();
        // const res4 = await getdata({ Year: year ? year : 2022, Projectid: parseInt(project), staff: parseInt(fip) });
        // const res5 = await getAllocateData({ year: 2023, projectId: project, staff: null, inputDate: selectedToDate });

        if (res) { setYearList(res); }
        if (res1) { setProjectList(res1); }
        if (res2) { setFipList(res2); }
        if (res3) { setDcsList(res3); }
        // if (res5) { setAllocationList(res5); }
    }

    const allocationData = async () => {
        setIsLoading(true)
        await fetch(CampPlanning.GetCampGrid, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ year: 2023, projectId: project ? project : 1, staff: null, inputDate: selectedToDate })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setAllocationList(result); setIsLoading(false); setProgressData("0%"); })
    }

    useEffect(() => {
        fetchData();
        allocationData()
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, [project]);

    const Add = () => {
        setModalShow(true);
    }

    const selectFip = (e) => {
        setFip(e);
        filterData(e, project);
    }

    const refFn = () => {
        setModalShow(false);
    }

    const selectProject = (e1) => {
        console.log(e1);
        setProject(e1);
        filterData(fip, e1);
    }

    const filterData = async (e, e1) => {
        setIsLoading(true);

        await fetch(CampPlanning.GetCampGrid, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ year: 2023, projectId: project ? project : 1, staff: fip ? fip : null, inputDate: selectedToDate })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setAllocationList(result); setIsLoading(false); setProgressData("0%"); })
    }
    const getdata = async (credentials) => {
        setIsLoading(true);
        try {
            const response = await fetch(AllocationApi.GetData, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify(credentials)
            });

            const contentLength = response.headers.get('content-length');
            let loaded = 0;

            const responseStream = new ReadableStream({
                start(controller) {
                    const reader = response.body.getReader();
                    read();
                    function read() {
                        reader.read()
                            .then((progressEvent) => {
                                if (progressEvent.done) {
                                    controller.close();
                                    return;
                                }
                                loaded += progressEvent.value.byteLength;
                                const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                setProgressData(percentageComplete)
                                controller.enqueue(progressEvent.value);
                                read();
                            })
                    }
                }
            });

            const res = new Response(responseStream);
            const data = await res.json();

            setAllocationList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
            setProgressData("0%");
        }
    };

    const colum = [
        // {
        //     name: "id",
        //     selector: "id",
        //     width: "100px",
        //     sortable: true
        // },
        {
            name: "DCS SAP",
            cell: (e) =>(<div style={{paddingLeft: "5px"}}> <p>{e["DCS SAP"]}</p></div>),
            sortable: true
        },
        {
            name: "Village",
            selector: "Village",
            cell: (c) => (
                <>
                <div style={{paddingLeft: "5px" , width:"110px"}}>{c["Village"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "Totalmilk",
            cell: (e) => (<TextField style={{paddingLeft: "5px" , width:"110px"}} label="" fullWidth variant="standard" value={e.Totalmilk} onChange={(e1) => totalMilkFn(e1, e)} />),
            sortable: true
            
        },
        {
            name: "Breedable",
            cell: (e) => (<TextField style={{paddingLeft: "5px" , width:"110px"}} label="" fullWidth variant="standard" value={e.Breedable} onChange={(e1) => breedableFn(e1, e)} />),
            sortable: true
           
        },
        {
            name: "neco",
            cell: (e) => (<TextField style={{paddingLeft: "5px" , width:"110px"}} label="" fullWidth variant="standard" value={e.Neco} onChange={(e1) => necoFn(e1, e)} />),
            sortable: true
           
        },
        {
            name: "NPG",
            cell: (e) => (<TextField style={{paddingLeft: "5px" , width:"110px"}} label="" fullWidth variant="standard" value={e.NPG} onChange={(e1) => NGPFn(e1, e)} />),
            sortable: true
           
        },
        {
            name: "Total",
            cell: (e) => (<TextField style={{paddingLeft: "5px" , width:"110px"}} label="" fullWidth variant="standard" value={e.Total} onChange={(e1) => totalFn(e1, e)} />),
            sortable: true
           
        },
        {
            name: "Actions",
            cell: (e) => <><button className='SaveBtn' onClick={() => saveFn(e)}>Save</button><img src={remove} className="ms-2" width={18} onClick={() => DeleteFn(e)} />
                {/* <img src={edit} className="ms-2" width={18} /> */}
            </>,
            width: "200px",
            sortable: true
        },
    ]

    const totalMilkFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setAllocationList(allocationList.map((o) => {
            if (o === index) { return { ...index, Totalmilk: value } }
            else { return o };
        }));
    }
    const breedableFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setAllocationList(allocationList.map((o) => {
            if (o === index) { return { ...index, Breedable: value } }
            else { return o };
        }));
    }
    const necoFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setAllocationList(allocationList.map((o) => {
            if (o === index) { return { ...index, Neco: value } }
            else { return o };
        }));
    }
    const NGPFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setAllocationList(allocationList.map((o) => {
            if (o === index) { return { ...index, NPG: value } }
            else { return o };
        }));
    }
    const totalFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setAllocationList(allocationList.map((o) => {
            if (o === index) { return { ...index, Total: value } }
            else { return o };
        }));
    }

    const saveFn = async (e) => {
        console.log(e)
        const Date = moment(selectedToDate).format("YYYY");

        const res = await saveAllocation({
            breedable: e.Breedable ? e.Breedable : null,
            startYear: Date,
            endYear: Date,
            complitionEndYear: Date,
            complitionStartYear: Date,
            id: e.DCSID.toString(),
            lot: e.lotid ? e.lotid : null,
            neco: e.Neco ? e.Neco : null,
            npg: e.NPG ? e.NPG : null,
            total: e.Total ? e.Total : null,
            totalMilk: e.Totalmilk,
            projectId: project,
            staff: fip
        })
        if (res) {
            toast.success("Data Save Successfull")
            allocationData()
            fetchData()
            setIsLoading(false)
        }
    }

    const DeleteFn = async (e) => {
        setDeleteModal(true);
        setDeleteId(e.DCSID);
    }

    const modalSaveFn = async () => {
        if (dcs) {
            const res = await saveAllocation({
                breedable: 0, complitionEndYear: parseInt(cendYear), complitionStartYear: parseInt(cstartYear), endYear: parseInt(endYear), startYear: parseInt(startYear),
                id: null, lot: dcs, neco: 0, npg: 0, projectId: parseInt(project), staff: fip, total: 0, totalMilk: 0
            });
            if (res) { allocationData(); toast.success(res.msg); fetchData(); refFn(); }
        }
    }

    const modalDeleteFn = async () => {
        setSearch("");
        setIsLoading(true)
        if (deleteId) {
            await fetch(`${API_KEY}/project/deleteProjectAllocation/${deleteId}`, {
                method: 'DELETE',
                headers: {
                    "Content-Type": 'application/json', "Accept": "application/json", "Authorization": `Bearer ${added_by}`,
                },
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { toast.success("Allocation Deleted Successfully"); allocationData(); setIsLoading(false); setProgressData("0%"); setDeleteModal(false); })
        }
    }

    const selectStartYear = (e) => {
        setStartYear(e.value);
        const jash = e.value + 1;
        setEndYear(jash);
    }

    const selectCStartYear = (e) => {
        setCStartYear(e.value);
        const jash = e.value + 1;
        setCEndYear(jash);
    }


    const renderYearOptions = () => {
        const years = [];

        for (let year = currentYear; year >= 2000; year--) {
            // years.push(
            //     <MenuItem value={year} key={year}>{year}</MenuItem>
            // );
            years.push({ value: year, label: year });
        }
        return years;
    };

    const searchFn = (e) => {
        const data = e.target.value;

        if (data !== '') {
            const results = allocationList.filter((user) => {
                return user["Village"].toLowerCase().startsWith(data.toLowerCase())
            });

            setSearchFilter(results);
        } else {
            setSearchFilter(allocationList);
        }

        setSearch(data);
    };
    const defaultProjectId = 1;
    const defaultProjectName = projectList.find(item => item.id === defaultProjectId)?.Name;
    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Project / FIP / Camp Allocation</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end">
                                    <img src={profile} width={40} />
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-start" id='heightOffsetStock'>
                                <Col xl="1" md="3" xs="6" className="mt-4 justify-content-start">
                                    <button className="btn-blue-Animal" onClick={Add}>Add Allocation</button>
                                </Col>
                                <Col md="3" xl="1" xs="6">
                                    <Select
                                        placeholder="Year"
                                        onChange={(selectedOption) => setYear(selectedOption.value)}
                                        options={yearList.map((item) => ({ value: item.id, label: item.YearName }))}
                                    />
                                </Col>
                                <Col md="3" xl="2" xs="6">
                                    {/* <Select
                                        placeholder="Project"
                                        onChange={(selectedOption) => selectProject(selectedOption.value)}
                                        options={projectList.map((item) => ({ value: item.id, label: item.Name }))}
                                        
                                    /> */}
                                    <Select
                                        placeholder="Project"
                                        onChange={(selectedOption) => { selectProject(selectedOption.value); }}
                                        options={projectList.map((item) => ({ value: item.id, label: item.Name }))}
                                        value={{ value: project, label: projectList.find(item => item.id == project)?.Name }}
                                    />
                                </Col>
                                <Col md="3" xl="3" xs="6">
                                    <Select
                                        placeholder="Fip Consultant"
                                        onChange={(selectedOption) => selectFip(selectedOption.value)}
                                        options={fipList.map((item) => ({ value: item.ID, label: item.name }))}
                                    />
                                </Col>
                                <Col md="3" xl="2" xs="6" className='align-items-center ms-auto'>
                                    <Input label="Search" value={search} onChange={e => searchFn(e)} />
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : allocationList} height={`calc(100vh - (${heightOffSet}px + 120px))`} pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {allocationList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : allocationList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Add FIP Allocation</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='align-items-end'>
                            <Col xl="12" sm="12" xs="6" className='mb-2'>
                                <Select
                                    placeholder="Dcs"
                                    onChange={(selectedOption) => setDcs(selectedOption.value)}
                                    options={dcsList.map((item) => ({ value: item.ID, label: item.name }))}
                                />
                                {/*
                                <Select
                                    placeholder="Tags"
                                    components={animatedComponents}
                                    options={tagList.map((item) => ({ value: item.Tagid, label: item.Tagid }))}
                                    onChange={selectTags}
                                    isMulti
                                    isClearable
                                />
                                 */}
                            </Col>
                            <Col xl="6" xs="6" className='mb-2'>
                                {/* <Select
                                    placeholder="Project"
                                    defaultValue={projectList.find((item) => item.id === project)}
                                    onChange={(selectedOption) => setProject(selectedOption.value)}
                                    options={projectList.map((item) => ({ value: item.id, label: item.Name }))}
                                /> */}
                                <Select
                                    placeholder="Project"
                                    onChange={(selectedOption) => { setProject(selectedOption.value); }}
                                    options={projectList.map((item) => ({ value: item.id, label: item.Name }))}
                                    value={{ value: project, label: projectList.find(item => item.id == project)?.Name }}
                                />
                            </Col>
                            <Col xl="6" xs="6" className='mb-2'>
                                <Select
                                    placeholder="Fip Consultant"
                                    onChange={(selectedOption) => setFip(selectedOption.value)}
                                    options={fipList.map((item) => ({ value: item.ID, label: item.name }))}
                                />
                            </Col>
                            <Col xs="6" xl="6" sm="3" md="2">
                                <Select
                                    placeholder="Start Year"
                                    value={renderYearOptions().find((option) => option.value === startYear)}
                                    onChange={(selectedOption) => selectStartYear(selectedOption)}
                                    options={renderYearOptions()}
                                />
                            </Col>
                            <Col xs="6" xl="6" sm="3" md="2">
                                <TextField label="End Year" fullWidth variant="standard" value={endYear} onChange={(e) => setEndYear(e.target.value)} />
                            </Col>
                            <Col xs="6" xl="6" sm="3" md="2" className='mt-2'>
                                <Select
                                    placeholder="Complition Start Year"
                                    value={renderYearOptions().find((option) => option.value === cstartYear)}
                                    onChange={(selectedOption) => selectCStartYear(selectedOption)}
                                    options={renderYearOptions()}
                                />
                            </Col>
                            <Col xs="6" xl="6" sm="3" md="2" className='mt-2'>
                                <TextField label="Complition End Year" fullWidth variant="standard" value={cendYear} onChange={(e) => setCEndYear(e.target.value)} />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={modalSaveFn}>Save</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}>Cancel</button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={deleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col><h4>Confirm</h4></Col>
                        </Row>
                        <Row>
                            <Col>Delete: {deleteId}</Col>
                        </Row>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={modalDeleteFn}>Delete</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteModal(false)} style={{ background: "#E16659" }}>Cancel</button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div>
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />


        </>
    )
};
