import React from 'react';
import './dropdown.css';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function Dropdown(props) {
    return (
        <>
            <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">{props.label}</InputLabel>
                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" defaultValue={props.defaultValue} className={props.className} value={props.value} onChange={props.onChange} label={props.label}>
                    {props.menuList.map((user) =>
                        <MenuItem id='drop_value' key={user} value={user}>{user}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {props.error ? <p className="errorText">{props.error}</p> : false}
        </>
    )
}