import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import Select from 'react-select';
import { BiChevronsRight } from "react-icons/bi";
import * as XLSX from "xlsx/xlsx";
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import {
    FormControl, InputLabel, MenuItem,
    //  Select
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
const { TransferAnimal, SocietyApi, FarmerApi, Cattleregistration, MilkTestApi } = API_URLS;

const Production = () => {

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const TagId = localStorage.getItem("Fld");
    const Parity = localStorage.getItem(STORAGE_KEY.Parity);
    const navigate = useNavigate();

    async function getRouteData(credentials) {
        return fetch(TransferAnimal.GetVisitRoute, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getSocietyData(credentials) {
        return fetch(SocietyApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getFarmerData(credentials) {
        return fetch(FarmerApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getTreeData(credentials) {
        return fetch(Cattleregistration.TreeByArea, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getParitywiseData(credentials) {
        return fetch(MilkTestApi.getParityWiseData, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }

    const selectedToDate = new Date().toISOString().slice(0, 10);

    const [search, setSearch] = useState(TagId);
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const [route, setRoute] = useState("");
    const [society, setSociety] = useState("");
    const [farmer, setFarmer] = useState("");
    const [searchId, setSearchId] = useState("");
    const [Lactos, setLactos] = useState(Parity)
    const [tagId, setTagId] = useState("")
    const [parity, setParity] = useState(Parity)

    const [show, setShow] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const [importFile, setImportFile] = useState(false)
    const handleClose = () => { setShow(false); };
 
    const handleShow = () => { setHeaderShow(!headerShow); };

    const [productionList, setProductionList] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [societyList, setSocietyList] = useState([]);
    const [farmerList, setFarmerList] = useState([]);
    const [tagIdList, setTagIdList] = useState([])
    const [parityWiseData, setParityWiseData] = useState([]);
    const [getData, setGetData] = useState([]);
    const [excelData, setExcelData] = useState([]);

    const [fileName, setFileName] = useState('Import');

    const fetchData = async () => {
        const res1 = await getRouteData()
        const res2 = await getSocietyData()
        const res3 = await getFarmerData()

        if (res1) { setRouteList(res1) }
        if (res2) { setSocietyList(res2.data) }
        if (res3) { setFarmerList(res3.data) }
    }

    const animalDataGet = async () => {
        await fetch(Cattleregistration.AnimalGet, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ tagId: tagId })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => {
                setParity(result[14]["Fld"]); setIsLoading(false); setProgressData("0%");
            })

    }

    const view = async (e) => {
        setFileName("Import")
        setLactos(e.Lacno)
        setIsLoading(true);
        await fetch(`${API_KEY}/api/sp/Milk/Displaymilk`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ IDNO: tagId, Parity: (e.Lacno ? e.Lacno : Lactos) ? e.Lacno ? e.Lacno : Lactos : parity })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => { setProductionList(result); setIsLoading(false); setProgressData("0%"); })
            .catch((error) => { setIsLoading(false); toast.error("Please Enter Valid Parity") })

        await fetch(`${MilkTestApi.getParityWiseData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ detailsId: tagId })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => { setParityWiseData(result.data); setIsLoading(false); setProgressData("0%") })
            .catch((error) => setIsLoading(false))

    }
    const ProductionGetData = async (e1) => {
        setIsLoading(true);
        await fetch(`${API_KEY}/api/sp/Milk/Displaymilk`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ IDNO: TagId !== null ? TagId : tagId, Parity: parity !== "" ? parseInt(Parity) : e1.Lactose })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => { setProductionList(result); setIsLoading(false); setProgressData("0%") })
            .catch((error) => setIsLoading(false))
    }


    const getDataById = async () => {
        const res = await getTreeData({ Herdcode: "1", Herdtype: "Animal", userCode: "" })
        if (res) { setTagIdList(res) }
    }

    useEffect(() => {
        fetchData()
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight)
        ProductionGetData()

        let objEdit = JSON.parse(localStorage.getItem("editAnimal"));
        getData.push(objEdit)
        for (let index = 0; index < getData.length; index++) {
            const element = getData[index];
            if (element) {
                setRoute(element["herdId"])
                setSociety(element["lotId"])
                setFarmer(element["farmerId"])
            }
        }
        getDataById()
    }, []);

    const parityWiseDataChange = async (e) => {
        view(e)
        setIsLoading(true);
        await fetch(`${API_KEY}/api/sp/Milk/Displaymilk`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ IDNO: tagId, Parity: e.Lactose.toString() })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => { setProductionList(result); setIsLoading(false); setProgressData("0%") })
            .catch((error) => setIsLoading(false))
    }

    const colum = [
        {
            name: "Date",
            selector: "Date",
            width: "120px",
            sortable: true
        },
        {
            name: "Days in milk",
            selector: "Days in milk",
            width: "70px",
            sortable: true
        },
        {
            name: "Mor",
            selector: "Mor",
            cell: (e1) => <input type='text' className="inpField1 ms-auto text-end" value={e1.Mor} onChange={(e) => morFn(e, e1)} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Eve",
            selector: "Eve",
            cell: (e1) => <input type='text' className="inpField1 ms-auto text-end" value={e1.Eve} onChange={(e) => eveFn(e, e1)} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Night",
            selector: "Night",
            cell: (e1) => <input type='text' className="inpField1 ms-auto text-end" value={e1.Night} onChange={(e) => nightFn(e, e1)} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Mid Night",
            selector: "Mid Night",
            cell: (e1) => <input type='text' className="inpField1 ms-auto text-end" value={e1["Mid Night"]} onChange={(e) => midNightFn(e, e1)} />,
            width: "70px",
            sortable: true,
            sortable: true
        },
        {
            name: "Milk Total",
            selector: "Milk Total",
            width: "70px",
            sortable: true
        },
        {
            name: "FAT",
            selector: "FAT",
            cell: (e1) => <input type='text' className="inpField1 ms-auto text-end" value={e1.FAT} onChange={(e) => fatFn(e, e1)} />,
            width: "70px",
            sortable: true
        },
        {
            name: "SNF",
            selector: "SNF",
            cell: (e1) => <input type='text' className="inpField1 ms-auto text-end" value={e1["SNF"]} onChange={(e) => snfFn(e, e1)} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Protein",
            selector: "Protein",
            cell: (e1) => <input type='text' className="inpField1 ms-auto text-end" value={e1.Protein} onChange={(e) => protineFn(e, e1)} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Lactose",
            selector: "Lactose",
            // cell: (e1) => <input type='text' className="inpField1 ms-auto text-end" value={e1.Lactose} onChange={(e) => loctoseFn(e, e1)} />,
            cell: (e1) => (<div onClick={() => { parityWiseDataChange(e1) }}>{e1.Lactose}</div>),
            width: "70px",
            sortable: true
        },
        {
            name: "Days Total",
            selector: "Days Total",
            width: "70px",
            sortable: true
        },
        {
            name: "Days Total",
            selector: "Days Total",
            width: "70px",
            sortable: true
        },
        {
            name: "Actions",
            cell: (e) => (<><button className='saveMilk me-0' onClick={() => saveFn(e)}>Save</button></>),
            width: "220px"
        },
    ]

    const colum1 = [
        {
            name: "Lacno",
            selector: "Lacno",
            cell: (e) => <div className='align-items-center d-flex rightArrow' onClick={() => { parityWiseDataChange(e) }} style={{ cursor: 'pointer' }}><BiChevronsRight size="25" color="green" className="me-2" /><div>{e.Lacno}</div></div>,
            width: "80px",
            sortable: true
        },
        {
            name: "Calving Date",
            selector: "Calving Date",
            width: "120px",
            sortable: true
        },
        {
            name: "Dry Date",
            selector: "Dry Date",
            width: "120px",
            sortable: true
        },
        {
            name: "Milk Yield",
            selector: "Milk Yield",
            width: "80px",
            sortable: true
        },
        {
            name: "Avg Yield",
            selector: "Avg Yield",
            width: "70px",
            sortable: true
        },
        // {
        //     name: "Actions",
        //     cell: (e) => (<><button className='saveMilk me-0' onClick={() => saveFn(e)}>Save</button></>),
        //     width: "220px"
        // },
    ]

    const morFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setProductionList(productionList.map((o) => {
            if (o === index) { return { ...index, Mor: value } }
            else { return o };
        }));
    }
    const eveFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setProductionList(productionList.map((o) => {
            if (o === index) { return { ...index, Eve: value } }
            else { return o };
        }));
    }
    const nightFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setProductionList(productionList.map((o) => {
            if (o === index) { return { ...index, Night: value } }
            else { return o };
        }));
    }
    const midNightFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setProductionList(productionList.map((o) => {
            if (o === index) { return { ...index, "Mid Night": value } }
            else { return o };
        }));
    }
    const fatFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setProductionList(productionList.map((o) => {
            if (o === index) { return { ...index, FAT: value } }
            else { return o };
        }));
    }
    const snfFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setProductionList(productionList.map((o) => {
            if (o === index) { return { ...index, SNF: value } }
            else { return o };
        }));
    }
    const loctoseFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setProductionList(productionList.map((o) => {
            if (o === index) { return { ...index, Lactose: value } }
            else { return o };
        }));
    }
    const protineFn = (inputEv, index) => {
        const value = inputEv.target.value;
        setProductionList(productionList.map((o) => {
            if (o === index) { return { ...index, Protein: value } }
            else { return o };
        }));
    }

    const logOut = () => {
        navigate("/")
    }

    const saveFn = async (e) => {
        console.log(e);
        const date = moment(selectedToDate).format("MM/DD/YYYY");

        await fetch(`${API_KEY}/v1/api/web/milk/save-milk-entry`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                eveningYield: e.Eve,
                fat: parseInt(e.FAT),
                inputDate: e.Date,
                lactose: e.Lactose,
                midnightYield: e["Mid Night"],
                morningYield: e.Mor,
                nightYield: e.Night,
                officialMilk: 1,
                parity: Parity,
                protein: e.Protein,
                snf: e.SNF,
                tagId: TagId,
                createdAt: date
            })
        })
            .then(res => res.json())
            .then((result) => { console.log(result); })
    }

    const selectTagId = async (e) => {
        setTagId(e)
        setProductionList([]);
        setParityWiseData([]);
        await fetch(Cattleregistration.AnimalGet, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ tagId: e })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => {
                setParity(result[14]["Fld"]);
                setLactos(result[14]["Fld"]);
                setRoute(result[3]["Fld"]);
                setSociety(result[2]["Fld"]);
                setFarmer(result[4]["Fld"]);
                setIsLoading(false);
                setProgressData("0%");
            })
    }


    const handleFileChange = (e) => {
        console.log("aaa" + e.target.files[0]);
        const file = e.target.files[0];
        setImportFile(true);

        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            if (jsonData.length > 1) {
                const headers = jsonData[0].map(header => header.trim());
                const excelData = jsonData.slice(1).map(row => {
                    return headers.reduce((acc, curr, index) => {
                        acc[curr] = row[index];
                        return acc;
                    }, {});
                });
                setExcelData(excelData);
                console.log("Parsed Excel Data:", excelData)
                let filteredList = [...productionList];
                var Night = "";
                for (let i = 0; i < excelData.length; i++) {
                    var Date = excelData[i].Date;
                    var Mor = excelData[i].Mor;
                    var Eve = excelData[i].Eve;
                    try {
                        Night = excelData[i].Night;
                    } catch (ex) {

                    }

                    for (let j = 0; j < filteredList.length; j++) {
                        // console.log("EXE " + Date.toString());
                        // console.log("filteredList[j].Date  " + filteredList[j].Date.toString());
                        if (filteredList[j].Date === Date.toString()) {
                            filteredList[j].Mor = Mor;
                            filteredList[j].Eve = Eve;
                            if (Night !== undefined) {
                                filteredList[j].Night = Night;
                            }
                        }
                    }
                }
                setProductionList(filteredList);
                console.log("filter", filteredList);

            } else {
                toast.error('Invalid file format or empty file');
            }

        };
        reader.readAsArrayBuffer(file);
    };

    const SaveFn = () => {
        if (productionList.length > 0) {
            setIsLoading(true)
            productionList.forEach((e) => {
                console.log("save : ", e);
                console.log(e)
                if ((e.Mor !== 0) || (e.Eve !== 0) || (e.Night !== 0 && e.Night.toString() !== "0") || (e.Midnight !== 0 && e.Midnight.toString() !== "0") || (e.FAT !== 0 && e.FAT.toString() !== "0") ||
                    (e.SNF !== 0 && e.SNF.toString() !== "0") ||
                    (e.Lactose !== 0 && e.Lactose.toString() !== "0")
                ) {
                    fetch(`${API_KEY}/v1/api/web/milk/save-milk-entry`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            "Accept": "application/json",
                            "Authorization": `Bearer ${added_by}`
                        },
                        body: JSON.stringify({ 
                            eveningYield: e.Eve, 
                            fat: e.FAT, 
                            lactose: e.Lactose, 
                            midnightYield: e["Mid Night"], 
                            inputDate: e.Date, 
                            morningYield: e.Mor, 
                            nightYield: e.Night, 
                            officialMilk: 1, 
                            parity: e["lactation No"] ? e["lactation No"] : 0, 
                            protein: "", 
                            snf: e.SNF, 
                            tagId: tagId, 
                            createdAt: selectedToDate 
                        })
                    })
                        .then(response => {
                            const contentLength = response.headers.get('content-length');
                            let loaded = 0;
                            return new Response(
                                new ReadableStream({
                                    start(controller) {
                                        const reader = response.body.getReader();
                                        read();
                                        function read() {
                                            reader.read()
                                                .then((progressEvent) => {
                                                    if (progressEvent.done) {
                                                        controller.close();
                                                        return;
                                                    }
                                                    loaded += progressEvent.value.byteLength;
                                                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                                    setProgressData(percentageComplete)
                                                    controller.enqueue(progressEvent.value);
                                                    read();
                                                })
                                        }
                                    }
                                })
                            );
                        })
                        .then(res => res.json())
                        .then((result) => { toast.success("Save Data Successful"); setIsLoading(false) })
                }
                else {
                    setIsLoading(false)
                }
            })
        }
        else {
            setIsLoading(false)
        }
    }


    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Cattle details / Individual Animal Milk Entry </h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-start p-2" id='heightOffsetStock'>
                                <Col xl="2" className="">
                                    <Select
                                        placeholder={TagId !== null ? TagId : "Tag Id"}
                                        onChange={(selectedOption) => {
                                            selectTagId(selectedOption.label);
                                        }}
                                        options={tagIdList.map((item) => ({
                                            value: item.Animalid,
                                            label: item.Animal,
                                        }))}
                                    />
                                </Col>
                                <Col xxl="1" xs="6" sm="2" md="2" xl="2" className='justify-content-start'>
                                    <Select
                                        placeholder={route !== "" ? `${route}` : "Route"}
                                        onChange={(selectedOption) => {
                                            setRoute(selectedOption.value);
                                        }}
                                        options={routeList.map((item) => ({
                                            value: item.Id,
                                            label: item.name,
                                        }))}
                                    />
                                </Col>
                                <Col xxl="1" xs="6" sm="2" md="2" xl="2" className='justify-content-start'>
                                    <Select
                                        placeholder={society !== "" ? `${society}` : "Society"}
                                        onChange={(selectedOption) => {
                                            setSociety(selectedOption.value);
                                        }}
                                        options={societyList.map((item) => ({
                                            value: item.id,
                                            label: item.DCSName,
                                        }))}
                                    />
                                </Col>
                                <Col xxl="2" xs="6" sm="2" md="2" xl="2" className='justify-content-start'>
                                    <Select
                                        placeholder={farmer !== "" ? `${farmer}` : "Farmer"}
                                        onChange={(selectedOption) => {
                                            setFarmer(selectedOption.value);
                                        }}
                                        options={farmerList.map((item) => ({
                                            value: item.id,
                                            label: item.FarmerName,
                                        }))}
                                    />
                                </Col>
                                <Col xxl="1" md="3" sm="3" xs="6" xl="2" className=''>
                                    <div className="form-field">
                                        <div className="form-field__control">
                                            <input id="email" type="text" className="form-field__input" placeholder=" " value={Lactos} onChange={e => setLactos(e.target.value)} />
                                            <label for="email" className="form-field__label">Lactation</label>
                                            <div className="form-field__bar"></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl="2" md="3" sm="3" xs="6" xl="2" className='ms-auto'>
                                    <Input label="Search" value={searchId} onChange={e => setSearchId(e.target.value)} />
                                </Col>
                                <Col xxl="1" md="1">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={view}>View</button>
                                </Col>
                                <Col xxl="1" md="1">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={SaveFn}>Save All</button>
                                </Col>
                                <Col xxl="1" md="1">
                                    <button className='btn-blue-Animal mt-1 mb-1'>
                                        <label className="file-input-label mb-0" >
                                            {fileName}
                                            <input
                                                type="file"
                                                accept=".xlsx"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                        </label>
                                    </button>
                                </Col>


                            </Row>
                            <Row className="mt-4">
                                {/* <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : productionList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                </Col> */}
                                <Col xs="12" sm="3" className={parityWiseData.length > 0 ? 'MainTableBorder m-1 mobileTable' : ""} style={{ width: "31.9%" }}>
                                    <Table columns={colum1} data={searchFilter ? searchFilter : parityWiseData} height={`calc(100vh - (${heightOffSet}px + 0px))`} />
                                </Col>

                                <Col xs="12" sm="8" className={productionList.length > 0 ? 'MainTableBorder m-1 ms-auto' : ""}>
                                    <Table columns={colum} data={searchFilter ? searchFilter : productionList} height={`calc(100vh - (${heightOffSet}px + 165px))`} />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />
            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false}

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

        </>
    )
}
export default Production    
