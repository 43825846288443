import React from "react";
import './input.css';

export default function Input(props) {
    return (
        <form novalidate>
            <div className="form-field">
                <div className="form-field__control">
                    <input id="email" type={props.type} className="form-field__input" placeholder=" " onClick={props.onClick} onChange={props.onChange} onKeyDown={props.onKeyDown}/>
                    <label for="email" className="form-field__label">{props.label}</label>
                    <div className="form-field__bar"></div>
                </div>
            </div>
        </form>
    )
};
