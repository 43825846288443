import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row, Offcanvas, Modal, Toast } from "react-bootstrap";

import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';

import Sidenav from "../../Componet/Sidenav/Sidenav";
import Multiselect from 'multiselect-react-dropdown';
import { SlArrowDown } from "react-icons/sl";
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import Input from '../../Componet/InputFild/Input';
import { useReactToPrint } from "react-to-print";
import closeButton from "../../Photo/milk-union/close button.svg";
import {
    FormControl, InputLabel, MenuItem,
    Select,
    TextField, fabClasses
} from '@mui/material';
// import Select, { components } from 'react-select';
import './Labmangement.css'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment/moment';
import Searcable from '../../Componet/Dropdown/Searcable';
import Table from '../../Componet/DataTable/Table';
import { BorderBottom, FamilyRestroomTwoTone } from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
const { Hemato_cytology, Cattleregistration, ZoneApi, StaffApi } = API_URLS;

async function getAnimalData(credentials) {
    return fetch(Cattleregistration.AnimalGet, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}


async function getAnimalDataNew(credentials) {
    return fetch(Cattleregistration.GetanimaldataNew, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

export default function TestRequest() {

    async function getDataLab(credentials) {
        return fetch(Hemato_cytology.getRegisterData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getVillageData(credentials) {
        return fetch(ZoneApi.VillageData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getTestedData(credentials) {
        return fetch(StaffApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getDropData(credentials) {
        return fetch(Hemato_cytology.allDropData, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getRefByData(credentials) {
        return fetch(Hemato_cytology.refBydataApi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }

    const API_KEY = serviceUrl;
    // const API_KEY = "http://localhost:2000"
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    const selectedToDate = new Date().toISOString().slice(0, 10);

    // const [search, setSearch] = useState("")
    // const [id, setId] = useState("")
    const [sample, setSample] = useState([])
    // const [patId, setPatId] = useState("")
    const [labName, setLabName] = useState("")
    const [refBy, setRefBy] = useState("")
    const [refInstitute, setRefInstitute] = useState("")
    // const [date, setDate] = useState(selectedToDate)
    // const [date, setDate] = useState("");
    const [owner, setOwner] = useState("")
    const [village, setVillage] = useState("")
    const [animalSpeices, setAnimalSpeices] = useState("")
    const [animal, setAnimal] = useState("")
    const [heightOffSet, setHeight] = useState(0);
    const [taluka, setTaluka] = useState("")
    const [tagIdNo, setTagIdNo] = useState("")
    const [tagid, setTagid] = useState("")
    const [IdTypeNo, setIdTypeNo] = useState("")
    const [district, setDistrict] = useState("")
    const [test, setTest] = useState([])
    const [unfitSample, setUnfitSample] = useState("")
    const [refCellNo, setRefCellNo] = useState("")
    const [testedBy, setTestedBy] = useState("")
    // const [progressData, setProgressData] = useState("0%")
    // const [formattedDate, setFormattedDate] = useState('');
    const [newTagId, setNewTagId] = useState("")
    const [refList, setRefList] = useState([])
    const [labList, setLabList] = useState([])
    const [refInstituteList, setRefInstituteList] = useState([])
    const [villageList, setVillageList] = useState([])
    const [animalSpeicesList, setAnimalSpeicesList] = useState([])
    const [talukaList, setTalukaList] = useState([])
    const [districtList, setDistrictList] = useState([])
    const [sampleList, setSampleList] = useState([])
    const [testList, setTestList] = useState([])
    const [unfitSampleList, setUnfitSampleList] = useState([])
    const [testedByList, setTestedByList] = useState([])
    const [labDataList, setLabDataList] = useState([])
    const [filterList, setFilterList] = useState()

    const [search, setSearch] = useState("")
    const [searchid, setSearchId] = useState("")
    const [date, setDate] = useState(selectedToDate)
    const [index, setIndex] = useState(1)
    const [testing, setTesting] = useState("")
    const formattedDate = moment(date).format('DD/MM/YYYY');
    const localDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    const [tagId, setTagId] = useState('');
    const [center, setCenter] = useState('');
    const [dcs, setDcs] = useState('');
    const [route, setRoute] = useState('');
    const [farmer, setFarmer] = useState('');
    const [species, setSpecies] = useState('');

    const [Owner, setowner] = useState('');

    const [id, setId] = useState("")
    const [patId, setPatId] = useState("")
    const [hematoCytology, setHematoCytology] = useState("")
    const [searchList, setSearchList] = useState("");
    const [hcs, setHcs] = useState("")
    const [clrChange, setClrChange] = useState(false)
    const [clrChange2, setClrChange2] = useState(false)
    const [clrChange3, setClrChange3] = useState(false)
    const [clrChange4, setClrChange4] = useState(false)
    const [clrChange5, setClrChange5] = useState(false)
    const [clrChange6, setClrChange6] = useState(false)
    const [clrChange7, setClrChange7] = useState(false)
    const [clrChange8, setClrChange8] = useState(false)
    const [clrChange9, setClrChange9] = useState(false)
    const [clrChange10, setClrChange10] = useState(false)
    const [clrChange11, setClrChange11] = useState(false)
    const [clrChange12, setClrChange12] = useState(false)
    const [allCheck, setAllCheck] = useState(false)

    const heamtocytology = useRef()


    const allCheck2 = (e) => {
        if (e == true) {
            setCalchecked(true);
            setPhoschecked(true);
            setMagnesiumChecked(true);
            setMethaemoglobinChecked(true);
            setCRPChecked(true);
            setBunchecked(true);
            setGluchecked(true);
            setBioOthersChecked(true)
            setElectrolytesChecked(true)
            setLiverFunctionTestChecked(true)
            setKidenyFunctionTestChecked(true)
        }
        else {
            setCalchecked(false);
            setPhoschecked(false);
            setMagnesiumChecked(false);
            setMethaemoglobinChecked(false);
            setCRPChecked(false);
            setBunchecked(false);
            setGluchecked(false);
            setBioOthersChecked(false)
            setElectrolytesChecked(false)
            setLiverFunctionTestChecked(false)
            setKidenyFunctionTestChecked(false)
        }
    }

    const allCheck1 = (e) => {
        if (e == true) {
            setHbGmchecked(true);
            setPCVchecked(true);
            setPLTchecked(true);
            setRBCchecked(true);
            setWBCchecked(true);
            setMCHchecked(true);
            setHCTchecked(true);
            setNEUTROPHILSchecked(true);
            setLYMPHOCYTESchecked(true);
            setMIDchecked(true);
            setNEUTROPHILSBANDchecked(true);
            setHemotoOthersChecked(true);
            setDiffContChecked(true);
            setBloodProtozoanChecked(true);
        }
        else {
            setHbGmchecked(false);
            setPCVchecked(false);
            setPLTchecked(false);
            setRBCchecked(false);
            setWBCchecked(false);
            setMCHchecked(false);
            setHCTchecked(false);
            setMCVchecked(false);
            setMCVchecked(false);
            setMCHCchecked(false);
            setNEUTROPHILSchecked(false);
            setLYMPHOCYTESchecked(false);
            setMIDchecked(false);
            setNEUTROPHILSBANDchecked(false);
            setHemotoOthersChecked(false);
            setDiffContChecked(false);
            setBloodProtozoanChecked(false);
        }
    }

    const [pdfformat, setPdfFormat] = useState(false);
    const [pdfformatBio, setPdfFormatBio] = useState(false);
    const [pdfformatGi, setPdfFormatGi] = useState(false);
    const [pdfformaturin, setPdfFormatUrin] = useState(false);
    const [pdfformatimmunology, setpdfformatimmunology] = useState(false);
    const [pdfformatSCM, setpdfFormatSCM] = useState(false);
    const [pdfformatAntigram, setpdfFormatAntigram] = useState(false);

    const [hemptoList, setHemptoList] = useState();
    const [bioList, setBioList] = useState();
    const [giList, setGiList] = useState();
    const [urinList, setUrinList] = useState();
    const [immunoList, setImmunoList] = useState();
    const [SCMList, setSCMList] = useState();
    const [AntigramList, setAntigramList] = useState();

    const [HemotoOthers, setHemotoOthers] = useState("");
    const [HemotoOthersChecked, setHemotoOthersChecked] = useState(false);

    const [hbGm, setHbGm] = useState("")
    const [hbGmchecked, setHbGmchecked] = useState(false)

    const [PCV, setPCV] = useState("")
    const [PCVchecked, setPCVchecked] = useState(false)

    const [PLT, setPLT] = useState("")
    const [PLTchecked, setPLTchecked] = useState(false)

    const [RBC, setRBC] = useState("")
    const [RBCchecked, setRBCchecked] = useState(false)

    const [WBC, setWBC] = useState("")
    const [WBCchecked, setWBCchecked] = useState(false)

    const [MCH, setMCH] = useState("")
    const [MCHchecked, setMCHchecked] = useState(false)

    const [HCT, setHCT] = useState("")
    const [HCTchecked, setHCTchecked] = useState(false)

    const [MCV, setMCV] = useState("")
    const [MCVchecked, setMCVchecked] = useState(false)

    const [MCHC, setMCHC] = useState("")
    const [MCHCchecked, setMCHCchecked] = useState(false)

    const [NEUTROPHILS, setNEUTROPHILS] = useState("")
    const [NEUTROPHILSchecked, setNEUTROPHILSchecked] = useState(false)

    const [NEUTROPHILSBAND, setNEUTROPHILSBAND] = useState("")
    const [NEUTROPHILSBANDchecked, setNEUTROPHILSBANDchecked] = useState(false)

    const [MONOCYTE, setMONOCYTE] = useState("")
    const [MONOCYTEchecked, setMONOCYTEchecked] = useState(false)

    const [BASOPHIL, setBASOPHIL] = useState("")
    const [BASOPHILchecked, setBASOPHILchecked] = useState(false)

    const [LYMPHOCYTES, setLYMPHOCYTES] = useState("")
    const [LYMPHOCYTESchecked, setLYMPHOCYTESchecked] = useState(false)

    const [MID, setMID] = useState("")
    const [MIDchecked, setMIDchecked] = useState(false)

    const [diffContChecked, setDiffContChecked] = useState("")
    const [bloodProtozoanChecked, setBloodProtozoanChecked] = useState(false)

    const [Theilaria, setTheilaria] = useState("")
    const [TheilariaChecked, setTheilariaChecked] = useState(false)

    const [BabesiaBigemina, setBabesiaBigemina] = useState("")
    const [BabesiaBigeminaChecked, setBabesiaBigeminaChecked] = useState(false)

    const [BabesiaBovis, setBabesiaBovis] = useState("")
    const [BabesiaBovisChecked, setBabesiaBovisChecked] = useState(false)

    const [Anaplasma, setAnaplasma] = useState("")
    const [AnaplasmaChecked, setAnaplasmaChecked] = useState(false)

    const [TrypanosomaEvansi, setTrypanosomaEvansi] = useState("")
    const [TrypanosomaEvansiChecked, setTrypanosomaEvansiChecked] = useState(false)

    // ----------------biochemestry------------------------

    const [magnesium, setMagnesium] = useState("")
    const [magnesiumChecked, setMagnesiumChecked] = useState(false)

    const [Electrolytes, setElectrolytes] = useState("")
    const [ElectrolytesChecked, setElectrolytesChecked] = useState(false)

    const [liverFunctionTest, setLiverFunctionTest] = useState("")
    const [liverFunctionTestChecked, setLiverFunctionTestChecked] = useState(false)

    const [kidenyFunctionTest, setKidenyFunctionTest] = useState("")
    const [kidenyFunctionTestChecked, setKidenyFunctionTestChecked] = useState(false)

    const [CRP, setCRP] = useState("")
    const [CRPChecked, setCRPChecked] = useState(false)

    const [Methaemoglobin, setMethaemoglobin] = useState("")
    const [MethaemoglobinChecked, setMethaemoglobinChecked] = useState(false)

    const [SodiumMeq, setSodiumMeq] = useState("")
    const [SodiumMeqChecked, setSodiumMeqChecked] = useState(false)

    const [PotassiumMeq, setPotassiumMeq] = useState("")
    const [PotassiumMeqChecked, setPotassiumMeqChecked] = useState(false)

    const [ChlorideMeq, setChlorideMeq] = useState("")
    const [ChlorideMeqChecked, setChlorideMeqChecked] = useState(false)

    const [CarbonateMeq, setCarbonateMeq] = useState("")
    const [CarbonateMeqChecked, setCarbonateMeqChecked] = useState(false)

    const [liverFunctionTestMeq, setLiverFunctionTestMeq] = useState("")
    const [liverFunctionTestMeqChecked, setLiverFunctionTestMeqChecked] = useState(false)

    const [bilirubinTotal, setBilirubinTotal] = useState("")
    const [bilirubinDirect, setBilirubinDirect] = useState("")
    const [bilirubinIndirect, setBilirubinIndirect] = useState("")
    const [SGOT, setSGOT] = useState("")
    const [SGPT, setSGPT] = useState("")
    const [AlkalinePhosphatase, setAlkalinePhosphatase] = useState("");

    const [SerumCreatinine, setSerumCreatinine] = useState("");

    const [BioOthersChecked, setBioOthersChecked] = useState(false);
    const [BioOthers, setBioOthers] = useState("");

    const [Cal, setCal] = useState("")
    const [Calchecked, setCalchecked] = useState(false)

    const [TBTEST, setTBTEST] = useState("")
    const [TBTESTchecked, setTBTESTchecked] = useState(false)

    const [Phos, setPhos] = useState("")
    const [Phoschecked, setPhoschecked] = useState(false)

    const [bioRbpt, setBioRbpt] = useState("")
    const [bioRbptchecked, setBioRbptchecked] = useState(false)

    const [JONHSTESTPPD, setJONHSTESTPPD] = useState("")
    const [JONHSTESTPPDchecked, setJONHSTESTPPDchecked] = useState(false)

    const [bun, setBun] = useState("")
    const [bunchecked, setBunchecked] = useState(false)

    const [glu, setGlu] = useState("")
    const [gluchecked, setGluchecked] = useState(false)


    // -------------------GI -------------------------

    const [glParatism, setGlParatism] = useState("")
    const [glParatismchecked, setGlParatismchecked] = useState(false)

    const [fsPositiveFor, setFsPositiveFor] = useState("")
    const [fsPositiveForchecked, setFsPositiveForchecked] = useState(false)

    const [parasiteName, setParasiteName] = useState("")
    const [OccultBlood, setOccultBlood] = useState("")
    const [mucousCells, setMucousCells] = useState("")
    const [coccidia, setCoccidia] = useState("")
    const [BalantidiumColi, setBalantidiumColi] = useState("")
    const [PusCells1, setPusCells1] = useState("")
    const [facelSample, setFacelSample] = useState("")
    const [facelSamplechecked, setFacelSamplechecked] = useState(false)

    const [nematodes, setNematodes] = useState("")
    const [nematodeschecked, setNematodeschecked] = useState(false)

    const [tramatodes, setTramatodes] = useState("")
    const [tramatodeschecked, setTramatodeschecked] = useState(false)

    const [cystodes, setCystodes] = useState("")
    const [cystodeschecked, setCystodeschecked] = useState(false)

    const [epgGt, setEpgGt] = useState("")
    const [epgGtchecked, setEpgGtchecked] = useState(false)


    const allCheck3 = (e) => {
        if (e == true) {
            setGlParatismchecked(true);
            setFsPositiveForchecked(true);
            setFacelSamplechecked(true);
            setNematodeschecked(true);
            setTramatodeschecked(true);
            setCystodeschecked(true);
            setEpgGtchecked(true);
        }
        else {
            setGlParatismchecked(false);
            setFsPositiveForchecked(false);
            setFacelSamplechecked(false);
            setNematodeschecked(false);
            setTramatodeschecked(false);
            setCystodeschecked(false);
            setEpgGtchecked(false);
        }
    }

    // --------------------------URINE ANALYSIS--------------------------

    const [GeneralUrineAnalysis, setGeneralUrineAnalysis] = useState("");
    const [GeneralUrineAnalysisChecked, setGeneralUrineAnalysisChecked] = useState(false);

    const [UrineChemistry, setUrineChemistry] = useState("");
    const [UrineChemistryChecked, setUrineChemistryChecked] = useState(false);

    const [UrineMicroscopy, setUrineMicroscopy] = useState("");
    const [UrineMicroscopyChecked, setUrineMicroscopyChecked] = useState(false);

    const [RBPT, setRBPT] = useState("");
    const [RBPTChecked, setRBPTChecked] = useState(false);

    const [STA, setSTA] = useState("");
    const [STAChecked, setSTAChecked] = useState(false);

    const [ELISA, setELISA] = useState("");
    const [ELISAChecked, setELISAChecked] = useState(false);

    const [Tuberculosis1, setTuberculosis] = useState("");
    const [TuberculosisChecked, setTuberculosisChecked] = useState(false);

    const [TuberculosisELISA, setTuberculosisELISA] = useState("");
    const [TuberculosisELISAChecked, setTuberculosisELISAChecked] = useState(false);

    const [Paratuberculosis, setParatuberculosis] = useState("");
    const [ParatuberculosisChecked, setParatuberculosisChecked] = useState(false);

    const [IBR, setIBR] = useState("");
    const [IBRChecked, setIBRChecked] = useState(false);


    const [colorChecked, setColorChecked] = useState(false)

    const [spGravity, setSpGravity] = useState("")
    const [ph, setPh] = useState("")

    const [glucose, setGlucose] = useState("")
    const [ketan, setKetan] = useState("")
    const [bileSalts, setBileSalts] = useState("");
    const [blood, setBlood] = useState("")
    const [protien, setProtien] = useState("")

    const [PusCells, setPusCells] = useState("")
    const [Crystals, setCrystals] = useState("")
    const [Bacteria, setBacteria] = useState("")
    const [Comments, setComments] = useState("")


    const [urinalysisId, setUrinalysisId] = useState("")
    const [urinalysisPatId, setUrinalysisPatId] = useState("")
    const [urinalysis, setUrinalysis] = useState("")
    const [bilirubin, setBilirubin] = useState("")
    const [usExam, setUsExam] = useState("")
    const [urobillinogen, setUrobillinogen] = useState("")
    const [nitrite, setNitrite] = useState("")
    const [leukocytes, setLeukocytes] = useState("")

    const allCheck4 = (e) => {
        if (e == true) {
            setGeneralUrineAnalysisChecked(true)
            setUrineChemistryChecked(true)
            setUrineMicroscopyChecked(true)
            setColorChecked(true)
        }
        else {
            setGeneralUrineAnalysisChecked(false)
            setUrineChemistryChecked(false)
            setUrineMicroscopyChecked(false)
            setColorChecked(false)
        }
    }


    const allCheck5 = (e) => {
        if (e == true) {
            setRBPTChecked(true)
            setSTAChecked(true)
            setELISAChecked(true)
            setTuberculosisChecked(true)
            setTuberculosisELISAChecked(true)
            setParatuberculosisChecked(true)
            setIBRChecked(true)
        }
        else {
            setRBPTChecked(false)
            setSTAChecked(false)
            setELISAChecked(false)
            setTuberculosisChecked(false)
            setTuberculosisELISAChecked(false)
            setParatuberculosisChecked(false)
            setIBRChecked(false)
        }
    }

    const allCheck6 = (e) => {
        if (e == true) {
            setMilPhisicalChecked(true)
            setMilkSubclinicalmastitisChecked(true)
            setMilkCMTChecked(true)
            setMilkCultureSensitivityChecked(true)
            setMilkPCRChecked(true)
        }
        else {
            setMilPhisicalChecked(false)
            setMilkSubclinicalmastitisChecked(false)
            setMilkCMTChecked(false)
            setMilkCultureSensitivityChecked(false)
            setMilkPCRChecked(false)
        }
    }



    // ---------------------------------SCM -------------------------------------

    const [milkPhisicalChecked, setMilPhisicalChecked] = useState("");
    const [MilkSubclinicalmastitisChecked, setMilkSubclinicalmastitisChecked] = useState("");
    const [MilkCMT, setMilkCMT] = useState("");
    const [MilkCMTChecked, setMilkCMTChecked] = useState("");
    const [MilkCultureSensitivity, setMilkCultureSensitivity] = useState("");
    const [MilkCultureSensitivityChecked, setMilkCultureSensitivityChecked] = useState("");
    const [MilkPCR, setMilkPCR] = useState("");
    const [MilkPCRChecked, setMilkPCRChecked] = useState("");

    const [colorM, setColorM] = useState("");
    const [CMT, setCMT] = useState("");
    const [SCC, setSCC] = useState("");
    const [EC, setEC] = useState("");
    const [Matrip, setMatrip] = useState("");


    const [tecm, setTecm] = useState("")
    const [tlc, setTlc] = useState("")
    const [n, setN] = useState("")
    const [nUl, setNul] = useState("")
    const [l, setL] = useState("")
    const [lUl, setLul] = useState("")
    const [m, setM] = useState("")
    const [mUl, setMul] = useState("")
    const [e, setE] = useState("")
    const [eUl, setEul] = useState("")
    const [b, setB] = useState("")
    const [bc, setBc] = useState("")
    const [p1pro, setP1pro] = useState("")
    const [fibrinogen, setFibrinogen] = useState("")
    const [ppFib, setPpFib] = useState("")
    const [se, setSe] = useState("")
    const [seCell, setSeCell] = useState("")

    const [biochemistryid, setBiochemistryId] = useState("")
    const [bioPatId, setBioPatId] = useState("")
    const [biochemistry, setBiochemistry] = useState("")
    const [bioBcs, setBioBcs] = useState("")
    const [caMg, setCamg] = useState("")
    const [proG, setProG] = useState("")
    const [cholM, setCholM] = useState("")
    const [pMg, setPMg] = useState("")
    const [albG, setAlbG] = useState("")
    const [asl, setAsl] = useState("")
    const [mg, setMg] = useState("")
    const [flgG, setFlgG] = useState("")
    const [sdh, setSdh] = useState("")
    const [na, setNa] = useState("")
    const [albGlo, setAlbGlo] = useState("")
    const [ggt, setGgt] = useState("")
    const [k, setK] = useState("")
    const [bioPpFib, setBioPpFib] = useState("")
    const [akp, setAkp] = useState("")
    const [c1, setC1] = useState("")

    const [tBill, setTBill] = useState("")

    const [cr, setCr] = useState("")
    const [dBill, setDBill] = useState("")
    const [randum, setRandum] = useState("")
    const [seCellMorphology, setSECellMorphology] = useState("")

    const [glId, setGlId] = useState("")
    const [glPatId, setGlPatId] = useState("")



    const [immunologyId, setImmunologyId] = useState("")
    const [immunologyPatId, setImmunologyPatId] = useState("")
    const [immunolTest, setImmunolTest] = useState("")
    const [brMrt, setBrMrt] = useState("")
    const [brElisa, setBrElisa] = useState("")
    const [rbpt, setRbpt] = useState("")
    const [irbElisa, setIrbElisa] = useState("")
    const [stat, setStat] = useState("")
    const [jidElisa, setJidElisa] = useState("")
    const [idTuberuline, setIdTuberuline] = useState("")
    const [idJohnine, setIdJohnine] = useState("")
    const [ibrFat, setIbrFat] = useState("")

    const [scmId, setScmId] = useState("")
    const [scmPatIdNo, setScmPatIdNo] = useState("")
    const [scmSampleType, setScmSampleType] = useState("")
    const [mastrip, setMastrip] = useState("")
    const [cmt, setCmt] = useState("")
    const [ec, setEc] = useState("")
    const [sscTh, setSscTh] = useState("")

    const [antigramId, setAntigramId] = useState("")
    const [antigramPatId, setAntigramPatId] = useState("")
    const [absSample, setAbsSample] = useState("")
    const [absSampleQuality, setAbsSampleQuality] = useState("")
    const [pathogenId, setPathogenId] = useState("")
    const [antibiotics, setAntibiotics] = useState("")
    const [bkMethod, setBkMethod] = useState("")

    const [spId, setSpId] = useState("")
    const [spPatIdNo, setSpPatIdNo] = useState("")
    const [specialPath, setSpecialPath] = useState("")
    const [sps2, setSps2] = useState("")
    const [sps3, setSps3] = useState("")
    const [sps4, setSps4] = useState("")
    const [sps5, setSps5] = useState("")
    const [sps6, setSps6] = useState("")
    const [spResu, setSpResu] = useState("")
    const [spr2, setSpr2] = useState("")
    const [spr3, setSpr3] = useState("")
    const [spr4, setSpr4] = useState("")
    const [spr5, setSpr5] = useState("")
    const [spr6, setSpr6] = useState("")

    const [toxicologyId, setToxicologyId] = useState("")
    const [toxicologyPatientId, setToxicologyPatientId] = useState("")
    const [txSample1, setTxSample1] = useState("")
    const [txSample2, setTxSample2] = useState("")
    const [txTest1, setTxTest1] = useState("")
    const [txTest2, setTxTest2] = useState("")
    const [txResult1, setTxResult1] = useState("")
    const [txResult2, setTxResult2] = useState("")
    // const [searchid, setSearchId] = useState('');


    // -----------------------------Milk Chemistry----------------------------


    const [Lactose, setLactose] = useState("")
    const [LactoseChecked, setLactoseChecked] = useState("")

    const [fat, setFat] = useState("")
    const [fatChecked, setFatChecked] = useState("")

    const [milkProtein, setMilkProtein] = useState("")
    const [milkProteinChecked, setMilkProteinChecked] = useState("")

    const [snf, setSnf] = useState("")
    const [snfChecked, setSnfChecked] = useState("")

    const [totlSolid, setTotlSolid] = useState("")
    const [totlSolidChecked, setTotlSolidChecked] = useState("")

    const [Milkketone, setMilkketone] = useState("")
    const [MilkketoneChecked, setMilkketoneChecked] = useState("")

    const [MUN, setMUN] = useState("")
    const [MUNChecked, setMUNChecked] = useState("")

    const [MilkSpGravity, setMilkSpGravity] = useState("")
    const [MilkSpGravityChecked, setMilkSpGravitCheckedy] = useState("")

    const [eConductivity, seteConductivity] = useState("")
    const [eConductivityChecked, seteConductivityChecked] = useState("")

    const [mph, setMph] = useState("")
    const [mphChecked, setMphChecked] = useState("")

    const [temperatureC, setTemperatureC] = useState("")
    const [temperatureCChecked, setTemperatureCChecked] = useState("")

    const [mCitrate, setMCitrate] = useState("")
    const [mCitrateChecked, setMCitrateChecked] = useState("")


    const [milkId, setMilkId] = useState("")
    const [milkPatientId, setMilkPatientId] = useState("")
    const [milkSampleType, setMilkSampleType] = useState("")


    const [ts, setTs] = useState("")
    const [sampleCondition, setSampleCondition] = useState("")
    const [density, setDensity] = useState("")
    const [mSpGravity, setMSpGravity] = useState("")
    const [fpc, setFpc] = useState("")
    const [ecMsCm, setEcMsCm] = useState("")


    const [mun, setMun] = useState("")


    const [modTechId, setModTechId] = useState("")
    const [modPatientId, setModPatientId] = useState("")
    const [modTechniques, setModTechniques] = useState("")
    const [mtResult, setMtResult] = useState("")

    const [remarkId, setRemarkId] = useState("")
    const [remarkPatientId, setRemarkPatientId] = useState("")
    const [remarkModTechnniques, setRemarkTechniques] = useState("")
    const [progressData, setProgressData] = useState("0%")

    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }


    const [hcsList, setHcsList] = useState([])
    const [hematoCytologyList, setHematoCytologyList] = useState([])
    const [seList, setSeList] = useState([])
    const [seCellList, setSeCellList] = useState([])

    const [biochemistryList, setBiochemistryList] = useState([])
    const [bcsList, setBcsList] = useState([])
    const [randumList, setRandumList] = useState([])

    const [glParatismList, setGlParatismList] = useState([])
    const [fsPositiveForList, setFsPositiveForList] = useState([])
    const [facelSampleList, setFacelSampleList] = useState([])

    const [urinalysisList, setUrinalysisList] = useState([])
    const [glucoseList, setGlucoseList] = useState([])
    const [ketanList, setKetanList] = useState([])
    const [bloodList, setBloodList] = useState([])
    const [billirubinList, setBillirubinList] = useState([])
    const [protienList, setProtienList] = useState([])
    const [urobillinogenList, setUrobillinogenList] = useState([])
    const [nitriteList, setNitriteList] = useState([])
    const [leukocytesList, setLeukocytesList] = useState([])

    const [immunolTestList, setImmunolTestList] = useState([])
    const [brMrtList, setBrMrtList] = useState([])
    const [brElisaList, setBrElisaList] = useState([])
    const [rbptList, setRbptList] = useState([])
    const [irbElisaList, setIrbElisaList] = useState([])
    const [statList, setStatList] = useState([])
    const [jidElisaList, setJidElisaList] = useState([])
    const [idTuberulineList, setIdTuberulineList] = useState([])
    const [idJohninelist, setIdJohnineList] = useState([])
    const [ibrFatList, setIbrFatList] = useState([])

    const [scmList, setScmList] = useState([])
    const [mastripList, setMastripList] = useState([])
    const [cmtList, setCmtList] = useState([])
    const [ecList, setEcList] = useState([])
    const [sscThList, setSscThList] = useState([])

    const [absSampleList, setAbsSampleList] = useState([])
    const [absSampleQualityList, setAbsSampleQualityList] = useState([])
    const [pathogenList, setPathogenList] = useState([])
    const [antibioticsList, setAntibioticsList] = useState([])
    const [bkMethodList, setBkMethodList] = useState([])

    const [specialPathList, setSpecialPathList] = useState([])
    const [sps2List, setSps2List] = useState([])
    const [sps3list, setSps3List] = useState([])
    const [sps4List, setSps4List] = useState([])
    const [sps5List, setSps5List] = useState([])
    const [sps6List, setSps6List] = useState([])
    const [spResuList, setSpResuList] = useState([])
    const [spr2List, setSpr2List] = useState([])
    const [spr3List, setSpr3List] = useState([])
    const [spr4List, setSpr4List] = useState([])
    const [spr5List, setSpr5List] = useState([])
    const [spr6List, setSpr6List] = useState([])

    const [txSample1List, setTxSample1List] = useState([])
    const [txSample2List, setTxSample2List] = useState([])
    const [txTest1List, setTxTest1List] = useState([])
    const [txTest2List, setTxTest2List] = useState([])
    const [txTestResult1List, setTxTestResult1List] = useState([])
    const [txTestResult2list, setTxTestResult2list] = useState([])

    const [milktypeList, setMilktypeList] = useState([])
    const [milksSampleList, setMilksSampleList] = useState([])
    const [sampleConditionList, setSampleConditionList] = useState([])

    const [modTechniquesList, setModTechniquesList] = useState([])
    const [mtResultList, setMtResultList] = useState([])
    const [remarkModtechniquesList, setRemarkModtechniquesList] = useState([]);

    const [HcsQualityD, setHCSQualityD] = useState([]);
    const [SeD, setSeD] = useState([])
    const [seCellListD, setSeCellListD] = useState([]);
    const [fsPositiveForListD, setFsPositiveForListD] = useState([]);
    const [facelSampleListD, setFacelSampleListD] = useState([]);
    const [PosNegD, setPosNegD] = useState([]);
    const [hemotoList, setHemotoList] = useState([])
    const [bioChemistryList, setBioChemistryList] = useState([])
    const [glPList, setGlPList] = useState([])
    const [tagIdList, setTagIdList] = useState([])
    const [distintDate, setDistintDate] = useState([])
    const [ketoneList, setKetoneList] = useState([])

    const Add = () => {
        setId("")
        setPatId("")
        setHematoCytology("")
        setHcs("")
        setHbGm("")
        setPCV("")
        setTecm("")
        setTlc("")
        setN("")
        setNul("")
        setL("")
        setLul("")
        setM("")
        setMul("")
        setE("")
        setEul("")
        setB("")
        setBc("")
        setP1pro("")
        setFibrinogen("")
        setPpFib("")
        setSe("")
        setSeCell("")
        setPLT("")
        setWBC("")
        setHCT("")
        setMCV("")
        setMCH("")
        setMCHC("")
        setRBC("")
        setNEUTROPHILS("")
        setLYMPHOCYTES("")
        setMID("")
        setNEUTROPHILSBAND("")
        setMONOCYTE("")
        setBASOPHIL("")

        setBiochemistryId("")
        setBioPatId("")
        setBiochemistry("")
        setBioBcs("")
        setCamg("")
        setProG("")
        setCholM("")
        setPMg("")
        setAlbG("")
        setAsl("")
        setMg("")
        setFlgG("")
        setSdh("")
        setNa("")
        setAlbGlo("")
        setGgt("")
        setK("")
        setPpFib("")
        setAkp("")
        setC1("")
        setBun("")
        setTBill("")
        setGlu("")
        setCr("")
        setDBill("")
        setRandum("")

        setGlId("")
        setGlPatId("")
        setGlParatism("")
        setFsPositiveFor("")
        setFacelSample("")
        setNematodes("")
        setTramatodes("")
        setCystodes("")
        setEpgGt("")

        setUrinalysisId("")
        setUrinalysisPatId("")
        setUrinalysis("")
        setGlucose("")
        setKetan("")
        setSpGravity("")
        setBlood("")
        setPh("")
        setBilirubin("")
        setUsExam("")
        setProtien("")
        setUrobillinogen("")
        setNitrite("")
        setLeukocytes("")

        setImmunologyId("")
        setImmunologyPatId("")
        setImmunolTest("")
        setBrMrt("")
        setBrElisa("")
        setRbpt("")
        setIrbElisa("")
        setStat("")
        setJidElisa("")
        setIdTuberuline("")
        setIdJohnine("")
        setIbrFat("")

        setScmId("")
        setPatId("")
        setScmSampleType("")
        setMastrip("")
        setCmt("")
        setEc("")
        setSscTh("")

        setAntigramId("")
        setAntigramPatId("")
        setAbsSample("")
        setAbsSampleQuality("")
        setPathogenId("")
        setAntibiotics("")
        setBkMethod("")

        setSpId("")
        setSpPatIdNo("")
        setSpecialPath("")
        setSps2("")
        setSps3("")
        setSps4("")
        setSps5("")
        setSps6("")
        setSpResu("")
        setSpr2("")
        setSpr3("")
        setSpr4("")
        setSpr5("")
        setSpr6("")

        setToxicologyId("")
        setToxicologyPatientId("")
        setTxSample1("")
        setTxSample2("")
        setTxTest1("")
        setTxTest2("")
        setTxResult1("")
        setTxResult2("")

        setMilkId("")
        setMilkPatientId("")
        setMilkSampleType("")
        setFat("")
        setSnf("")
        setSampleCondition("")
        setDensity("")
        setMSpGravity("")
        setMilkProtein("")
        setEcMsCm("")
        setMph("")
        setTemperatureC("")
        setLactose("")
        setMun("")
        setMCitrate("")

        setModTechId("")
        setModPatientId("")
        setModTechniques("")
        setMtResult("")

        setRemarkId("")
        setRemarkPatientId("")
        setRemarkTechniques("")
    }

    const saveanimaltagid = async () => {
        await fetch(`${Cattleregistration.AnimalGet}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                "tagId": searchid ? searchid : "",
            })
        })
            .then(res => res.json())
            .then((res) => {
                setTagIdList(res);
                console.log(res)
            })

    }

    const SaveHeamtoCytology = async () => {
        console.log(searchid);
        console.log("hello");
        if (searchid !== "") {
            // console.log("searchid == " + JSON.stringify(searchid));
            await fetch(`${API_KEY}/lab/Save_Heamto_Cytology`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    "ID": id ? id : 0,
                    "PatIdNo": tagId ? tagId : "",
                    "HeamtoCytology": hematoCytology ? hematoCytology : "",
                    "HcsQuality": hcs ? hcs : "",
                    "Hbgm": parseFloat(hbGm) ? parseFloat(hbGm) : parseFloat(""),
                    "Pcv": parseFloat(PCV) ? parseFloat(PCV) : 0,
                    Tec: tecm ? tecm : "",
                    Tlc: tlc ? tlc : "",
                    N: n ? n : "",
                    Nu1: nUl ? nUl : "",
                    L: l ? l : "",
                    Lu1: lUl ? lUl : "",
                    M: m ? m : "",
                    Mu1: mUl ? mUl : "",
                    E: e ? e : "",
                    Eu1: eUl ? eUl : "",
                    B: b ? b : "",
                    Bc: bc ? bc : "",
                    P1pro: p1pro ? p1pro : "",
                    Fibrinogen: fibrinogen ? fibrinogen : "",
                    Ppfib: ppFib ? ppFib : "",
                    SePositiveForInfectionOf: se.value ? se.value : "",
                    SeCellMorphology: seCell.value ? seCell.value : "",
                    Plt: parseFloat(PLT) ? parseFloat(PLT) : 0,
                    Wbc: parseFloat(WBC) ? parseFloat(WBC) : 0,
                    Hct: parseFloat(HCT) ? parseFloat(HCT) : 0,
                    Mcv: MCV ? MCV : "",
                    Mch: MCH ? MCH : "",
                    Mchc: MCHC ? MCHC : "",
                    Rbc: parseFloat(RBC) ? parseFloat(RBC) : 0,
                    Neutrophils: parseFloat(NEUTROPHILS) ? parseFloat(NEUTROPHILS) : 0,
                    Lymphocytes: parseFloat(LYMPHOCYTES) ? parseFloat(LYMPHOCYTES) : 0,
                    Mid: MID ? MID : "",
                    NeutrophilsBand: parseFloat(NEUTROPHILSBAND) ? parseFloat(NEUTROPHILSBAND) : 0,
                    HemotoOthers: parseFloat(HemotoOthers) ? parseFloat(HemotoOthers) : 0,
                    Monocyte: parseFloat(MONOCYTE) ? parseFloat(MONOCYTE) : 0,
                    Basophil: parseFloat(BASOPHIL) ? parseFloat(BASOPHIL) : 0,
                    Theilaria: Theilaria ? Theilaria : "",
                    BabesiaBigemina: BabesiaBigemina ? BabesiaBigemina : "",
                    BabesiaBovis: BabesiaBovis ? BabesiaBovis : "",
                    Anaplasma: Anaplasma ? Anaplasma : "",
                    TrypanosomaEvansi: TrypanosomaEvansi ? TrypanosomaEvansi : "",
                    TestDate: dateFormate,

                })

            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {

                    console.log(res);
                    Add(); toast.success("Save Hemato cytology Data Successful"); setIsLoading(false); setProgressData("0%")
                    setClrChange(true);
                    setHemotoOthers('');
                    setTheilaria('');
                    setBabesiaBigemina('');
                    setBabesiaBovis('');
                    setAnaplasma('');
                    setTrypanosomaEvansi('');

                })


            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveBioChemistry = async () => {
        // if (biochemistryid == 0) {
        //     toast.error("Please Select Date ");
        //     return;
        // }
        if (searchid !== "") {

            await fetch(`${API_KEY}/lab/Save_Biochemistry`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },

                body: JSON.stringify({
                    "ID": biochemistryid ? biochemistryid : 0,
                    "AKPiul": akp ? akp : "",
                    "Albg": albG ? albG : "",
                    "Albglo": albGlo ? albGlo : "",
                    "AlkalinePhosphatase": parseFloat(AlkalinePhosphatase) ? parseFloat(AlkalinePhosphatase) : 0,
                    "AsliUl": asl ? asl : "",
                    "Bcsquality": bioBcs ? bioBcs : "",
                    "bilirubinDirect": parseFloat(bilirubinDirect) ? parseFloat(bilirubinDirect) : 0,
                    "bilirubinIndirect": parseFloat(bilirubinIndirect) ? parseFloat(bilirubinIndirect) : 0,
                    "bilirubinTotal": parseFloat(bilirubinTotal) ? parseFloat(bilirubinTotal) : 0,
                    "Biochemistry": BioOthers ? BioOthers : "",
                    "BioOthers": BioOthers ? BioOthers : 0,
                    "bioRbpt": bioRbpt ? bioRbpt : "",
                    "bun": parseFloat(bun) ? parseFloat(bun) : 0,
                    "BUNmg": bun ? bun : 0,
                    "C1mEql": c1 ? c1 : "",
                    "Cal": parseFloat(Cal) ? parseFloat(Cal) : 0,
                    "Camg": caMg ? caMg : "",
                    "CarbonateMeq": parseFloat(CarbonateMeq) ? parseFloat(CarbonateMeq) : 0,
                    "ChlorideMeq": parseFloat(ChlorideMeq) ? parseFloat(ChlorideMeq) : 0,
                    "Cholm": cholM ? cholM : "",
                    "Crmg": cr ? cr : "",
                    "CRP": parseFloat(CRP) ? parseFloat(CRP) : 0,
                    "DBilmg": dBill ? dBill : "",
                    "Figg": flgG ? flgG : "",
                    "GGTiul": ggt ? ggt : "",
                    "glu": parseFloat(glu) ? parseFloat(glu) : 0,
                    "Glumg": parseFloat(glu) ? parseFloat(glu) : 0,
                    "JonhsTestTppd": JONHSTESTPPD ? JONHSTESTPPD : "",
                    "KmEql": k ? k : "",
                    "liverFunctionTestMeq": parseFloat(liverFunctionTestMeq) ? parseFloat(liverFunctionTestMeq) : 0,
                    "magnesium": parseFloat(magnesium) ? parseFloat(magnesium) : 0,
                    "Methaemoglobin": parseFloat(Methaemoglobin) ? parseFloat(Methaemoglobin) : 0,
                    "Mgmg": mg ? mg : "",
                    "Phos": parseFloat(Phos) ? parseFloat(Phos) : 0,
                    "PatIdNo": tagId ? tagId : "",
                    "Nameql": "",

                    "Pmg": parseFloat(pMg) ? parseFloat(pMg) : 0,
                    "PotassiumMeq": parseFloat(PotassiumMeq) ? parseFloat(PotassiumMeq) : 0,
                    "Ppfib": bioPpFib ? bioPpFib : "",
                    "Prog": proG ? proG : "",
                    "RandumRPT": randum ? randum : "",
                    "SDHiul": sdh ? sdh : "",
                    "SECellMorphology": seCellMorphology ? seCellMorphology : "",
                    "SerumCreatinine": parseFloat(SerumCreatinine) ? parseFloat(SerumCreatinine) : 0,
                    "SGOT": parseFloat(SGOT) ? parseFloat(SGOT) : 0,
                    "SGPT": parseFloat(SGPT) ? parseFloat(SGPT) : 0,
                    "SodiumMeq": parseFloat(SodiumMeq) ? parseFloat(SodiumMeq) : 0,
                    "TBillmg": tBill ? tBill : "",
                    "TbTest": TBTEST ? TBTEST : "",
                    "TestDate": dateFormate

                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {
                    setClrChange2(true)
                    Add(); toast.success("Save Bio Chemestry Data Successful"); setIsLoading(false); setProgressData("0%")
                    if (clrChange == false) {
                        SaveHeamtoCytology();
                    }
                    setCal('');
                    setPhos('');
                    setMagnesium('');
                    setGlu('');
                    setElectrolytes('');
                    setSodiumMeq('');
                    setPotassiumMeq('');
                    setChlorideMeq('');
                    setCarbonateMeq('');
                    setLiverFunctionTestMeq('');
                    setBilirubinTotal('')
                    setBilirubinDirect('')
                    setBilirubinIndirect('')
                    setSGOT('')
                    setSGPT('')
                    setAlkalinePhosphatase('')
                    setSerumCreatinine('')
                    setMethaemoglobin('')
                    setCRP('')
                    setBioOthers('')
                })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }

    }

    const SaveGiparasitism = async () => {
        if (searchid !== "") {
            if (clrChange == false) {
                SaveHeamtoCytology();
            }
            if (clrChange2 == false) {
                SaveBioChemistry();
            }
            await fetch(`${API_KEY}/lab/Save_GiParasitism`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({

                    ID: 0,
                    PatIdNo: tagId ? tagId : "",
                    GIParasitism: glParatism ? glParatism : "",
                    FSPositiveFor: fsPositiveFor ? fsPositiveFor : "",
                    OccultBlood: OccultBlood ? OccultBlood : "",
                    PusCells: PusCells1 ? PusCells1 : "",
                    mucousCells: mucousCells ? mucousCells : "",
                    coccidia: coccidia ? coccidia : "",
                    BalantidiumColi: BalantidiumColi ? BalantidiumColi : "",
                    TestDate: dateFormate ? dateFormate : "",
                    FecalSampleExa: facelSample ? facelSample : "",
                    Nematodes: nematodes ? nematodes : "",
                    Trematodes: tramatodes ? tramatodes : "",
                    Cystodes: cystodes ? cystodes : "",
                    EPGGT: parseFloat(epgGt) ? parseFloat(epgGt) : 0,

                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {
                    Add(); setClrChange3(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%");
                    setOccultBlood('');
                    setPusCells1('')

                    setMucousCells('')
                    setCoccidia('')
                    setBalantidiumColi('')
                })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveUrinalysis = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Urinalysis`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({

                    // "ID": 123,
                    // "PatIdNo": "562",
                    // "Urinalysis": "fgd",
                    // "Glucose": "dfgdfg",
                    // "Ketan": "dfgdfg",
                    // "SpGravity": 56,
                    // "Blood": "fgdfg",
                    // "pH": 89,
                    // "Bilirubin": "dfgdg",
                    // "UsExam": "dfgdf",
                    // "Protein": "gfdg",
                    // "Urobilinogen": 56,
                    // "Nitrite": 56,
                    // "Leukocytes": 50,
                    // "bileSalts": "fdgdf",
                    // "PusCells": "gfdg",
                    // "Crystals": "fgfdg",
                    // "Bacteria": "dfg",
                    // "Comments": "dfgfdg",
                    // "TestDate": "02/18/2024"


                    ID: "0",
                    PatIdNo: tagId ? tagId : "",
                    Urinalysis: urinalysis ? urinalysisId : "",
                    Glucose: glucose ? glucose : "",
                    Ketan: ketan ? ketan : "",
                    SpGravity: parseFloat(spGravity) ? parseFloat(spGravity) : 0,
                    Blood: blood ? blood : "",
                    pH: parseFloat(ph) ? parseFloat(ph) : 0,
                    Bilirubin: bilirubin ? bilirubin : "",
                    UsExam: usExam ? usExam : "",
                    Protein: protien ? protien : "",
                    Urobilinogen: parseFloat(urobillinogen) ? parseFloat(urobillinogen) : 0,
                    Nitrite: parseFloat(nitrite) ? parseFloat(nitrite) : 0,
                    Leukocytes: parseFloat(leukocytes) ? parseFloat(leukocytes) : 0,
                    TestDate: dateFormate ? dateFormate : "",
                    bileSalts: bileSalts ? bileSalts : "",
                    PusCells: PusCells ? PusCells : "",
                    Crystals: Crystals ? Crystals : "",
                    Bacteria: Bacteria ? Bacteria : "",
                    Comments: Comments ? Comments : ""
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {
                    Add(); setClrChange4(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%")
                    setPusCells('')
                    setCrystals('')
                    setBacteria('')
                    setComments('')
                })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveImmunology = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Immunology`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({

                    ID: immunologyId ? immunologyId : 0,
                    PatIdNo: tagId ? tagId : "",

                    Immuno1TestForDisease: immunolTest ? immunolTest : "",
                    BrMRT: brMrt ? brMrt : "",
                    BrELISA: ELISA ? ELISA : 0,
                    RBPT: RBPT ? RBPT : "",
                    IRBELISA: irbElisa ? irbElisa : 0,
                    STAT: STA ? STA : "",
                    JIDELISA: jidElisa ? jidElisa : "",
                    IDTuberuline: idTuberuline ? idTuberuline : "",
                    IDJohnie: idJohnine ? idJohnine : "",
                    IBRFAt: ibrFat ? ibrFat : "",
                    ELISA: parseInt(brElisa) ? parseInt(brElisa) : 0,
                    Tuberculosis: Tuberculosis1 ? Tuberculosis1 : "",
                    TuberculosisELISA: TuberculosisELISA ? TuberculosisELISA : "",
                    Paratuberculosis: Paratuberculosis ? Paratuberculosis : "",
                    IBR: IBR ? IBR : "",
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {
                    Add(); setClrChange5(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%")
                    setImmunolTest('')
                    setBrMrt('')
                    setELISA('')
                    setRBPT('')
                    setIrbElisa('')
                    setSTA('')
                    setELISA('')
                    setTuberculosis('')
                    setTuberculosisELISA('')
                    setParatuberculosis('')
                    setIBR('')
                    setIBR('')
                })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveScm = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_SCM`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({

                    // "ID": 123,
                    // "PatIdNo": "562",
                    // "SCMSampleType": "fgd",
                    // "Mastrip": "fgd",
                    // "CMT": "fgd",
                    // "EC": 54,
                    // "SSCThml": "fgd",
                    // "colorM": "fgd",
                    // "SCC": "fgd",
                    // "TestDate": "02/18/2024"

                    ID: 0,
                    PatIdNo: tagId ? tagId : "",
                    SCMSampleType: scmSampleType ? scmSampleType : "",
                    Mastrip: mastrip ? mastrip : "",
                    CMT: scmSampleType ? scmSampleType : "",
                    SCC: SCC ? SCC : "",
                    EC: parseFloat(EC) ? parseFloat(EC) : 0,
                    SSCThml: sscTh ? sscTh : "",
                    TestDate: dateFormate,
                    colorM: colorM ? colorM : "",

                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {
                    Add(); setClrChange6(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%")
                    setColorM('')
                    setMilkCMT('')
                    setMilkCultureSensitivity('')
                    setMilkPCR('')
                    setScmSampleType('')
                    setSCC('')
                    setEC('')
                })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveAntigram = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Antigram`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: tagId ? tagId : "",
                    ABSSample: absSample,
                    ABSSampleQuality: absSampleQuality,
                    PathogenId: pathogenId,
                    Antibiotics: antibiotics,
                    BkDDMethod: bkMethod,
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange7(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveSP = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Sp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: tagId ? tagId : "",
                    SpecialPath: specialPath,
                    SPS2: sps2,
                    SPS3: sps3,
                    SPS4: sps4,
                    SPS5: sps5,
                    SPS6: sps6,
                    SPResu: spResu,
                    SPR2: spr2,
                    SPR3: spr3,
                    SPR4: spr4,
                    SPR5: spr5,
                    SPR6: spr6,
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange8(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveToxicology = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Toxicology`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: tagId ? tagId : "",
                    TxSample1: txSample1 ? txSample1 : "",
                    TxSample2: txSample2 ? txSample2 : "",
                    TxTest1: txTest1 ? txTest1 : "",
                    TxTest2: txTest2 ? txTest2 : "",
                    TxResult1: txResult1 ? txResult1 : "",
                    TxResult2: txResult2 ? txResult2 : "",
                    TestDate: dateFormate ? dateFormate : "",
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange9(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveMilkChemistry = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_MilkChemistry`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({

                    ID: milkId ? milkId : 0,
                    PatIdNo: tagId ? tagId : "",
                    Lactose: Lactose ? Lactose : " ",
                    Fat: fat ? fat : " ",
                    Protein: milkProtein ? milkProtein : " ",
                    SNF: snf ? snf : " ",
                    TS: totlSolid ? totlSolid : " ",
                    Milkketone: Milkketone ? Milkketone : "",
                    MilkSpGravity: parseInt(MilkSpGravity) ? parseInt(MilkSpGravity) : 0,
                    MSpGravity: "",
                    Density: density ? density : " ",
                    MPh: parseInt(mph) ? parseInt(mph) : 0,
                    eConductivity: parseInt(eConductivity) ? parseInt(eConductivity) : 0,
                    TemperatureC: temperatureC ? temperatureC : " ",
                    Munmg: mun ? mun : " ",
                    MCitratemg: mCitrate ? mCitrate : " ",
                    MilkSampleType: milkSampleType ? milkSampleType : "",
                    SampleCondition: sampleCondition ? sampleCondition : " ",
                    Ecmscm: ecMsCm ? ecMsCm : " ",
                    Fpc: "",
                    TestDate: dateFormate,
                    // "ID": 0,
                    // "PatIdNo": "4534",
                    // "MilkSampleType": "dfg",
                    // "Fat": "dfgfdgdf",
                    // "SNF": "dfgdfg",
                    // "TS": "ddfgdfg",
                    // "SampleCondition": "fgh",
                    // "Density": "fghfgh",
                    // "MSpGravity": "fghf",
                    // "Protein": "fghfh",
                    // "Fpc": "fgh",
                    // "Ecmscm": "fgh",
                    // "MPh": 65,
                    // "TemperatureC": "fgh",
                    // "Lactose": "fgh",
                    // "Munmg": "fgh",
                    // "MCitratemg": "fgh"
                })
            })
                // TestDate: dateFormate,
                // Milkketone: Milkketone ? Milkketone : "",
                // eConductivity: parseFloat(eConductivity) ? parseFloat(eConductivity) : 0
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {
                    Add(); setClrChange10(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%");
                    setMilkId(0)
                    setMilkPatientId(0)
                    setLactose('')
                    setFat('')
                    setMilkProtein('')
                    setSnf('')
                    setMilkSampleType('')
                    setTotlSolid('')
                    setMilkketone('')
                    setMSpGravity('')
                    setMilkSpGravity('')
                    setDensity('')
                    setMph('')
                    seteConductivity('')
                    setTemperatureC('')
                    setMun('')
                    setMCitrate('')
                    // setTs('')
                    setSampleCondition('')
                    setFpc('')
                    setEcMsCm('')
                })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveModTech = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_ModTech`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: tagId ? tagId : "",
                    ModTechniques: modTechniques ? modTechniques : "",
                    MtResult: mtResult ? mtResult : "",
                    TestDate: dateFormate ? dateFormate : "",
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange11(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveRemarks = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Remarks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: tagId ? tagId : "",
                    Remarks: remarkModTechnniques,
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange12(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: tagId,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    // const view = async () => {
    //     if (index === 1) {
    //         await fetch(`${Hemato_cytology.distinctDateApi}/${searchid.value}`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setDistintDate(res.data)
    //                 setHematoCytologyList(res.data);
    //                 setId(res.data[0].ID)
    //                 setPatId(res.data[0].PatIdNo)
    //                 setHematoCytology(res.data[0].HeamtoCytology)
    //                 setHcs(res.data[0].HcsQuality)
    //                 setHbGm(res.data[0].Hbgm)
    //                 setPCV(res.data[0].Pcv)
    //                 setTecm(res.data[0].Tec)
    //                 setTlc(res.data[0].Tlc)
    //                 setN(res.data[0].N)
    //                 setNul(res.data[0].Nu1)
    //                 setL(res.data[0].L)
    //                 setLul(res.data[0].Lu1)
    //                 setM(res.data[0].M)
    //                 setMul(res.data[0].Mu1)
    //                 setE(res.data[0].E)
    //                 setEul(res.data[0].Eu1)
    //                 setB(res.data[0].B)
    //                 setBc(res.data[0].Bc)
    //                 setP1pro(res.data[0].P1pro)
    //                 setFibrinogen(res.data[0].Fibrinogen)
    //                 setPpFib(res.data[0].P1pro)
    //                 setSe(res.data[0].SePositiveForInfectionOf)
    //                 setSeCell(res.data[0].SeCellMorphology)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 2) {
    //         await fetch(`${Hemato_cytology.showBiochemistryData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setBiochemistryId(res.data[0].ID)
    //                 setBioPatId(res.data[0].PatIdNo)
    //                 setBiochemistry(res.data[0].Biochemistry)
    //                 setBioBcs(res.data[0].Bcsquality)
    //                 setProG(res.data[0].Prog)
    //                 setCholM(res.data[0].Cholm)
    //                 setCamg(res.data[0].Camg)
    //                 setPMg(res.data[0].Pmg)
    //                 setAlbG(res.data[0].Albg)
    //                 setAsl(res.data[0].AsliUl)
    //                 setMg(res.data[0].Mgmg)
    //                 setFlgG(res.data[0].Figg)
    //                 setSdh(res.data[0].SDHiul)
    //                 setNa(res.data[0].Nameql)
    //                 setAlbGlo(res.data[0].Albglo)
    //                 setGgt(res.data[0].GGTiul)
    //                 setK(res.data[0].KmEql)
    //                 setBioPpFib(res.data[0].Ppfib)
    //                 setAkp(res.data[0].AKPiul)
    //                 setC1(res.data[0].C1mEql)
    //                 setBun(res.data[0].BUNmg)
    //                 setTBill(res.data[0].TBillmg)
    //                 setGlu(res.data[0].Glumg)
    //                 setCr(res.data[0].Crmg)
    //                 setDBill(res.data[0].DBilmg)
    //                 setRandum(res.data[0].RandumRPT)
    //                 setSECellMorphology(res.data[0].SECellMorphology)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 3) {
    //         await fetch(`${Hemato_cytology.showGIParasitismData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setHematoCytologyList(res.data);
    //                 setGlId(res.data[0].ID)
    //                 setGlPatId(res.data[0].PatIdNo)
    //                 setGlParatism(res.data[0].GIParasitism)
    //                 setFsPositiveFor(res.data[0].FSPositiveFor)
    //                 setFacelSample(res.data[0].FecalSampleExa)
    //                 setTramatodes(res.data[0].Trematodes)
    //                 setCystodes(res.data[0].Cestodes)
    //                 setNematodes(res.data[0].Nematodes)
    //                 setEpgGt(res.data[0].EPGGT)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 4) {
    //         await fetch(`${Hemato_cytology.showUrinalysisData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setUrinalysisId(res.data[0].ID)
    //                 setUrinalysisPatId(res.data[0].PatIdNo)
    //                 setUrinalysis(res.data[0].Urinalysis)
    //                 setGlucose(res.data[0].Glucose)
    //                 setKetan(res.data[0].Ketan)
    //                 setSpGravity(res.data[0].SpGravity)
    //                 setBlood(res.data[0].Blood)
    //                 setPh(res.data[0].pH)
    //                 setBilirubin(res.data[0].Bilirubin)
    //                 setUsExam(res.data[0].UsExam)
    //                 setProtien(res.data[0].Protein)
    //                 setUrobillinogen(res.data[0].Urobilinogen)
    //                 setNitrite(res.data[0].Nitrite)
    //                 setLeukocytes(res.data[0].Leukocytes)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 5) {
    //         await fetch(`${Hemato_cytology.showImmunologyData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setImmunologyId(res.data[0].ID)
    //                 setImmunologyPatId(res.data[0].PatIdNo)
    //                 setImmunolTest(res.data[0].Immuno1TestForDisease)
    //                 setBrMrt(res.data[0].BrMRT)
    //                 setBrElisa(res.data[0].BrELISA)
    //                 setRbpt(res.data[0].RBPT)
    //                 setStat(res.data[0].STAT)
    //                 setJidElisa(res.data[0].JIDELISA)
    //                 setIdTuberuline(res.data[0].IDTuberuline)
    //                 setIdJohnine(res.data[0].IDJohnie)
    //                 setIbrFat(res.data[0].IBRFAt)
    //                 setIrbElisa(res.data[0].IRBELISA)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 6) {
    //         await fetch(`${Hemato_cytology.showSCMData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setScmId(res.data[0].ID)
    //                 setScmPatIdNo(res.data[0].PatIdNo)
    //                 setScmSampleType(res.data[0].SCMSampleType)
    //                 setMastrip(res.data[0].Mastrip)
    //                 setCmt(res.data[0].CMT)
    //                 setEc(res.data[0].EC)
    //                 setSscTh(res.data[0].SSCThml)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 7) {
    //         await fetch(`${Hemato_cytology.showAntigramData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setAntigramId(res.data[0].ID)
    //                 setAntigramPatId(res.data[0].PatIdNo)
    //                 setAbsSample(res.data[0].ABSSample)
    //                 setAbsSampleQuality(res.data[0].ABSSampleQuality)
    //                 setPathogenId(res.data[0].PathogenId)
    //                 setAntibiotics(res.data[0].Antibiotics)
    //                 setBkMethod(res.data[0].BkDDMethod)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 8) {
    //         await fetch(`${Hemato_cytology.showExecuteSpData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setSpId(res.data[0].ID)
    //                 setSpPatIdNo(res.data[0].PatIdNo)
    //                 setSpecialPath(res.data[0].SpecialPath)
    //                 setSps2(res.data[0].SPS2)
    //                 setSps3(res.data[0].SPS3)
    //                 setSps4(res.data[0].SPS4)
    //                 setSps5(res.data[0].SPS4)
    //                 setSps5(res.data[0].SPS5)
    //                 setSps6(res.data[0].SPS6)
    //                 setSpResu(res.data[0].SPResu)
    //                 setSpr2(res.data[0].SPR2)
    //                 setSpr3(res.data[0].SPR3)
    //                 setSpr4(res.data[0].SPR4)
    //                 setSpr5(res.data[0].SPR5)
    //                 setSpr6(res.data[0].SPR6)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 9) {
    //         await fetch(`${Hemato_cytology.showToxicologyData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setToxicologyId(res.data[0].ID)
    //                 setToxicologyPatientId(res.data[0].PatIdNo)
    //                 setTxSample1(res.data[0].TxSample1)
    //                 setTxSample2(res.data[0].TxSample2)
    //                 setTxTest1(res.data[0].TxTest1)
    //                 setTxTest2(res.data[0].TxTest2)
    //                 setTxResult1(res.data[0].TxResult1)
    //                 setTxResult2(res.data[0].TxResult2)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 10) {
    //         await fetch(`${Hemato_cytology.showExecuteMilkChemistryData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setMilkId(res.data[0].ID)
    //                 setMilkPatientId(res.data[0].PatIdNo)
    //                 setMilkSampleType(res.data[0].MilkSampleType)
    //                 setFat(res.data[0].Fat)
    //                 setSnf(res.data[0].SNF)
    //                 setTs(res.data[0].TS)
    //                 setSampleCondition(res.data[0].SampleCondition)
    //                 setDensity(res.data[0].Density)
    //                 setMSpGravity(res.data[0].MSpGravity)
    //                 setMilkProtein(res.data[0].Protein)
    //                 setFpc(res.data[0].Fpc)
    //                 setEcMsCm(res.data[0].Ecmscm)
    //                 setMph(res.data[0].MPh)
    //                 setTemperatureC(res.data[0].TemperatureC)
    //                 setLactose(res.data[0].Lactose)
    //                 setMun(res.data[0].Munmg)
    //                 setMCitrate(res.data[0].MCitratemg)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 11) {
    //         await fetch(`${Hemato_cytology.showExecuteModTechData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setModTechId(res.data[0].ID)
    //                 setModPatientId(res.data[0].PatIdNo)
    //                 setModTechniques(res.data[0].ModTechniques)
    //                 setMtResult(res.data[0].MtResult)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 12) {
    //         await fetch(`${Hemato_cytology.showExecuteRemarksData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setRemarkId(res.data[0].ID)
    //                 setRemarkPatientId(res.data[0].PatIdNo)
    //                 setRemarkTechniques(res.data[0].Remarks)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    // }

    const handleInput = (e, f) => {
        const inputValue = e.target.value;

        if (f == "ID") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setId(Value);
        }
        else if (f == "patId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setPatId(Value);
        } else if (f == "PCV") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setPCV(Value);
        }
        else if (f == "tecm") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setTecm(Value);
        }
        else if (f == "tlc") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setTlc(Value);
        } else if (f == "M") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setM(Value);
        } else if (f == "N") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setN(Value);
        } else if (f == "E") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setE(Value);
        } else if (f == "L") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setL(Value);
        } else if (f == "B") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setB(Value);
        } else if (f == "Bc") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setBc(Value);
        }
        else if (f == "PLT") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setPLT(Value);
        }
        else if (f == "RBC") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setRBC(Value);
        }
        else if (f == "WBC") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setWBC(Value);
        }
        else if (f == "HCT") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setHCT(Value);
        }
        else if (f == "MCV") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setMCV(Value);
        }
        else if (f == "MCH") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setMCH(Value);
        }
        else if (f == "MCHC") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setMCHC(Value);
        }
        else if (f == "NEUTROPHILS") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setNEUTROPHILS(Value);
        }
        else if (f == "LYMPHOCYTES") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setLYMPHOCYTES(Value);
        }
        else if (f == "MID") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setMID(Value);
        }
        else if (f == "NEUTROPHILS BAND") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setNEUTROPHILSBAND(Value);
        }
        else if (f == "MONOCYTE") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setMONOCYTE(Value);
        }
        else if (f == "BASOPHIL") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setBASOPHIL(Value);
        }
        else if (f == "N") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setNEUTROPHILSBAND(Value);
        }
        else if (f == "HB gm%") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setHbGm(Value);
        }
        else if (f == "HemotoOthers") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setHemotoOthers(Value);
        }
    };

    const handleInputBioChemistry = (e, f) => {
        const inputValue = e.target.value;

        if (f == "biochemistryid") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setBiochemistryId(Value);
        } else if (f == "bioPatId") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setBioPatId(Value);
        }
        else if (f == "caMg") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setCamg(Value);
        }
        else if (f == "proG") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setProG(Value);
        } else if (f == "magnesium") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setMagnesium(Value);
        } else if (f == "Methaemoglobin") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setMethaemoglobin(Value);
        } else if (f == "CRP") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setCRP(Value);
        }
        else if (f == "tecm") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setTecm(Value);
        }
        else if (f == "pMg") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setPMg(Value);
        }
        else if (f == "albG") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setAlbG(Value);
        }
        else if (f == "asl") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setAsl(Value);
        }
        else if (f == "mg") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setMg(Value);
        }
        else if (f == "flgG") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setFlgG(Value);
        }
        else if (f == "sdh") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setSdh(Value);
        }
        else if (f == "na") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setNa(Value);
        }
        else if (f == "albGlo") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setAlbGlo(Value);
        }
        else if (f == "ggt") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setGgt(Value);
        }
        else if (f == "k") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setK(Value);
        }
        else if (f == "ppFib") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setPpFib(Value);
        }
        else if (f == "akp") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setAkp(Value);
        }
        else if (f == "c1") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setC1(Value);
        }
        else if (f == "bun") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setBun(Value);
        }
        else if (f == "tBill") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setTBill(Value);
        }
        else if (f == "cr") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setCr(Value);
        }
        else if (f == "dBill") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setDBill(Value);
        }
        else if (f == "glu") {
            const value = inputValue.replace(/[^0-9.]/g, '');
            setGlu(value);
        }
        else if (f == "Cal") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setCal(Value);
        }
        else if (f == "Phos") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setPhos(Value);
        }
        else if (f == "bioRbpt") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setBioRbpt(Value);
        }
        else if (f == "JONHSTESTPPD") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setJONHSTESTPPD(Value);
        }
        else if (f == "TBTEST") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setTBTEST(Value);
        }
        else if (f == "SodiumMeq") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setSodiumMeq(Value);
        } else if (f == "PotassiumMeq") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setPotassiumMeq(Value);
        } else if (f == "ChlorideMeq") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setChlorideMeq(Value);
        }
        else if (f == "CarbonateMeq") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setCarbonateMeq(Value);
        }
        else if (f == "liverFunctionTestMeq") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setLiverFunctionTestMeq(Value);
        }
        else if (f == "bilirubinTotal") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setBilirubinTotal(Value);
        }
        else if (f == "bilirubinDirect") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setBilirubinDirect(Value);
        }
        else if (f == "bilirubinIndirect") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setBilirubinIndirect(Value);
        }
        else if (f == "SGOT") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setSGOT(Value);
        }
        else if (f == "SGPT") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setSGPT(Value);
        }
        else if (f == "AlkalinePhosphatase") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setAlkalinePhosphatase(Value);
        }
        else if (f == "SerumCreatinine") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setSerumCreatinine(Value);
        }
    };

    const handleInputGI = (e, f) => {
        const inputValue = e.target.value;

        if (f == "glId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setGlId(Value);
        }
        else if (f == "glPatId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setGlPatId(Value);
        }
    };

    const handleInputurinalysis = (e, f) => {
        const inputValue = e.target.value;

        if (f == "urinalysisId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setUrinalysisId(Value);
        }
        else if (f == "urinalysisPatId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            urinalysisPatId(Value);
        }
    };

    const handleInputImmunology = (e, f) => {
        const inputValue = e.target.value;

        if (f == "immunologyId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setImmunologyId(Value);
        }
        else if (f == "immunologyPatId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setImmunologyPatId(Value);
        }
        else if (f == "STAT") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setSTA(Value);
        }
        else if (f == "ELISA") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setELISA(Value);
        }
        else if (f == "Tuberculosis") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setTuberculosis(Value);
        }
        else if (f == "TuberculosisELISA") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setTuberculosisELISA(Value);
        }
        else if (f == "Paratuberculosis") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setParatuberculosis(Value);
        }
        else if (f == "IBR") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setIBR(Value);
        }

    };

    const DropdownOptionsHcs = async () => {

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 1 })
        })
            .then(res => res.json())
            .then((res) => {
                setHCSQualityD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 2 })
        })
            .then(res => res.json())
            .then((res) => {
                setSeD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 3 })
        })
            .then(res => res.json())
            .then((res) => {
                setSeCellListD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 4 })
        })
            .then(res => res.json())
            .then((res) => {
                setFsPositiveForListD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 5 })
        })
            .then(res => res.json())
            .then((res) => {
                setFacelSampleListD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 12 })
        })
            .then(res => res.json())
            .then((res) => {
                // setPosNegD(res.data)
                const abc = [
                    { id: "1", sampletype: "+" },
                    { id: "2", sampletype: "++" },
                    { id: "3", sampletype: "+++" },
                    { id: "4", sampletype: "++++" },
                ]
                setPosNegD(abc)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 13 })
        })
            .then(res => res.json())
            .then((res) => {
                setHemotoList(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 14 })
        })
            .then(res => res.json())
            .then((res) => {
                setBioChemistryList(res.data)
            })
    };

    const [tagIdList1, setTagIdList1] = useState([])

    const getDataById = async () => {
        // setIsLoading(true)
        await fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Herdcode: "1", Herdtype: "Animal", userCode: ""
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                console.log(res)
                const firstTenItems = res.slice(0, 1000);
                setTagIdList(firstTenItems);
                setIsLoading(false);
                setProgressData("0%")
                setTagIdList1(res)
                console.log(firstTenItems);
            })
    }

    const view = async () => {
        console.log("tag id : " + tagId);
        await fetch(`${Hemato_cytology.distinctDateApi2}/${tagId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {

                setDistintDate(res.data)
                console.log(res.data.length);
                setIsLoading(false);
                if (res.data.length == 0) {
                    toast.error("Animal not found")
                }
                setProgressData("0%")
            })
    }

    const animalSpeicesData = [
        {
            ID: "1",
            Name: "Cow"
        },
        {
            ID: "1",
            Name: "Buffelo"
        },
    ]

    const [PositiveNegative, setPositiveNegative] = useState([]);

    useEffect(() => {
        DropdownOptionsHcs()
        getDataById()
        const pn = [
            { id: "Positive", sampletype: "Positive" },
            { id: "Negative", sampletype: "Negative" },
        ]
        setPositiveNegative(pn)
        const ketone1 = [
            { id: "+", sampletype: "+" },
            { id: "++", sampletype: "++" },
            { id: "+++", sampletype: "+++" },
            { id: "++++", sampletype: "++++" },
            { id: "Absent", sampletype: "Absent" },
        ]
        setKetoneList(ketone1);
        // saveanimaltagid()
    }, [])

    const dateFormate = moment(date).format("MM/DD/YYYY")

    const dateDataFn = (e) => {
        // console.log(e.RegistrationDate)
        // console.log("Selected date data:", e);
        const regdate = moment(e.RegistrationDate).format("DD/MM/YYYY")
        // console.log("reg",
        //     e.RegistrationDate
        // );
        fetch(`${Hemato_cytology.showHematoData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setHemptoList(res.data[0])
                console.log(res.data[0])
                if (res.data.length == 0) {
                    console.log("datapriyank");
                    // setHeamatoCytology(0);
                    setHcs('');
                    setHbGm('');
                    setPCV('');
                    // setTec('');
                    setTlc('');
                    setN('');
                    // setNUl('');
                    setL('');
                    // setLUl('');
                    setM('');
                    // setMUL('');
                    setE('');
                    // setEUL('');
                    setB('');
                    // setBC('');
                    setP1pro('');
                    setFibrinogen('');
                    setPpFib('');
                    // setSEValue('');
                    // setSECellValue('');
                    setPLT('');
                    setWBC('');
                    setHCT('');
                    setMCV('');
                    setMCH('');
                    setMCHC('');
                    setRBC('');
                    setNEUTROPHILS('');
                    setLYMPHOCYTES('');
                    setMID('');
                    setNEUTROPHILSBAND('');
                    setHemotoOthers('');
                    setMONOCYTE('');
                    setBASOPHIL('');
                    setTheilaria('');
                    setBabesiaBigemina('');
                    setBabesiaBovis('');
                    setAnaplasma('');
                    setTrypanosomaEvansi('');
                    // setDateFormate('');
                    setIsLoading(false);
                    return;
                }

                // const data = res.data[0];

                // console.log("DATE " +data.Hbgm);

                // Set Heam data
                // setHeamatoCytology(res.data);
                setHcs(res.data[0].HcsQuality);
                setHbGm(res.data[0].Hbgm);
                setPCV(res.data[0].Pcv);
                setPLT(res.data[0].Plt);
                setRBC(res.data[0].Rbc);
                setWBC(res.data[0].Wbc);
                setHCT(res.data[0].Hct);
                setNEUTROPHILS(res.data[0].Neutrophils.toString());
                setLYMPHOCYTES(res.data[0].Lymphocytes.toString());
                setNEUTROPHILSBAND(res.data[0].NeutrophilsBand.toString());
                setHemotoOthers(res.data[0].HemotoOthers.toString());
                setTheilaria(res.data[0].Theilaria);
                setBabesiaBigemina(res.data[0].BabesiaBigemina);
                setBabesiaBovis(res.data[0].BabesiaBovis);
                setAnaplasma(res.data[0].Anaplasma);
                setTrypanosomaEvansi(res.data[0].TrypanosomaEvansi);
                // setTec(res.data[0].Tec);
                setTlc(res.data[0].Tlc);
                setN(res.data[0].N);
                // setNUl(res.data[0].Nu1);
                setL(res.data[0].L);
                // setLUl(res.data[0].Lu1);
                setM(res.data[0].M);
                // setMUL(res.data[0].Mu1);
                setE(res.data[0].E);
                // setEUL(res.data[0].Eu1);
                setB(res.data[0].B);
                // setBC(res.data[0].Bc);
                setP1pro(res.data[0].P1pro);
                setFibrinogen(res.data[0].Fibrinogen);
                setPpFib(res.data[0].Ppfib);
                // setSEValue(res.data[0].SePositiveForInfectionOf);
                // setSECellValue(res.data[0].SeCellMorphology);
                setMCV(res.data[0].Mcv);
                setMCH(res.data[0].Mch);
                setMCHC(res.data[0].Mchc);

                setMID(res.data[0].Mid);




                // setDateFormate(res.data[0].TestDate);

                setIsLoading(false);
                setProgressData("0%");
            })


        fetch(`${Hemato_cytology.showBiochemistryData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setBioList(res?.data[0])
                if (res.data.length == 0) {
                    setBiochemistryId(0);
                    setBioPatId(0);
                    setBiochemistry("");
                    setMagnesium("");
                    setSodiumMeq("");
                    setPotassiumMeq("");
                    setChlorideMeq("");
                    setCarbonateMeq("");
                    setLiverFunctionTestMeq("");
                    setBilirubinTotal("");
                    setBilirubinDirect("");
                    setBilirubinIndirect("");
                    setSGOT("");
                    setSGPT("");
                    setAlkalinePhosphatase("");
                    setSerumCreatinine("");
                    setMethaemoglobin("");
                    setCRP("");
                    setBioOthers("");
                    setBioBcs("");
                    setProG("");
                    setCholM("");
                    setCamg("");
                    setPMg("");
                    setAlbG("");
                    setAsl("");
                    setMg("");
                    setFlgG("");
                    setSdh("");
                    setNa("");
                    setAlbGlo("");
                    setGgt("");
                    setK("");
                    setBioPpFib("");
                    setAkp("");
                    setC1("");
                    setBun("");
                    setTBill("");
                    setGlu("");
                    setCr("");
                    setDBill("");
                    setRandum("");
                    setSECellMorphology("");
                    setCal("");
                    setPhos("");
                    setBioRbpt("");
                    setTBTEST("");
                    setJONHSTESTPPD("");
                    setIsLoading(false);

                    return;
                }
                console.log("DATE " + res.data[0].ID);
                setBiochemistryId(res.data[0].ID)
                setBioPatId(res.data[0].PatIdNo)
                setBiochemistry(res.data[0].Biochemistry)
                setCal(res.data[0].Cal)
                setPhos(res.data[0].Phos)
                setMagnesium(res.data[0].magnesium)
                setSodiumMeq(res.data[0].SodiumMeq)
                setPotassiumMeq(res.data[0].PotassiumMeq)
                setChlorideMeq(res.data[0].ChlorideMeq)
                setCarbonateMeq(res.data[0].CarbonateMeq)
                setLiverFunctionTestMeq(res.data[0].liverFunctionTestMeq)
                setBilirubinTotal(res.data[0].bilirubinTotal)
                setBilirubinDirect(res.data[0].bilirubinDirect)
                setBilirubinIndirect(res.data[0].bilirubinIndirect)
                setSGOT(res.data[0].SGOT)
                setSGPT(res.data[0].SGPT)
                setAlkalinePhosphatase(res.data[0].AlkalinePhosphatase)
                setSerumCreatinine(res.data[0].SerumCreatinine)
                setGlu(res.data[0].Glumg)
                setMethaemoglobin(res.data[0].Methaemoglobin)
                setCRP(res.data[0].CRP)
                setBioOthers(res.data[0].BioOthers)

                setBioBcs(res.data[0].Bcsquality)
                setProG(res.data[0].Prog)
                setCholM(res.data[0].Cholm)
                setCamg(res.data[0].Camg)
                setPMg(res.data[0].Pmg)
                setAlbG(res.data[0].Albg)
                setAsl(res.data[0].AsliUl)
                setMg(res.data[0].Mgmg)
                setFlgG(res.data[0].Figg)
                setSdh(res.data[0].SDHiul)
                setNa(res.data[0].Nameql)
                setAlbGlo(res.data[0].Albglo)
                setGgt(res.data[0].GGTiul)
                setK(res.data[0].KmEql)
                setBioPpFib(res.data[0].Ppfib)
                setAkp(res.data[0].AKPiul)
                setC1(res.data[0].C1mEql)
                setBun(res.data[0].BUNmg)
                setTBill(res.data[0].TBillmg)

                setCr(res.data[0].Crmg)
                setDBill(res.data[0].DBilmg)
                setRandum(res.data[0].RandumRPT)
                setSECellMorphology(res.data[0].SECellMorphology)
                setCal(res.data[0].Cal)
                setPhos(res.data[0].Phos)
                setBioRbpt(res.data[0].bioRbpt)
                setTBTEST(res.data[0].TbTest)
                setJONHSTESTPPD(res.data[0].JonhsTestTppd)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showGIParasitismData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setGiList(res.data[0])
                if (res.data.length == 0) {
                    setGlId(0);
                    setGlPatId('');
                    setGlParatism('');
                    setFsPositiveFor('');
                    setFacelSample('');
                    setTramatodes('');
                    setCystodes('');
                    setNematodes('');

                    setOccultBlood("")
                    setPusCells1("")
                    setMucousCells("")
                    setCoccidia("")
                    setBalantidiumColi("")
                    setEpgGt('');
                    setIsLoading(false);
                    setProgressData("0%")
                    return;
                }
                setHematoCytologyList(res.data);
                setGlId(res.data[0].ID)
                setGlPatId(res.data[0].PatIdNo)
                setGlParatism(res.data[0].GIParasitism)
                setFsPositiveFor(res.data[0].FSPositiveFor)
                setFacelSample(res.data[0].FecalSampleExa)
                setTramatodes(res.data[0].Trematodes)
                setCystodes(res.data[0].Cestodes)
                setNematodes(res.data[0].Nematodes)


                setOccultBlood(res.data[0].OccultBlood)
                setPusCells1(res.data[0].PusCells)
                setMucousCells(res.data[0].mucousCells)
                setCoccidia(res.data[0].coccidia)
                setBalantidiumColi(res.data[0].BalantidiumColi)




                setEpgGt(res.data[0].EPGGT)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showUrinalysisData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {


                if (res?.data.length == 0) {
                    setUrinList(res?.data[0])
                    setUrinalysisId('');
                    setUrinalysisPatId('');
                    setUrinalysis('');
                    setGlucose('');
                    setKetan('');
                    setSpGravity('');
                    setBlood('');
                    setPh('');
                    setBilirubin('');
                    setUsExam('');
                    setProtien('');
                    setUrobillinogen('');
                    setNitrite('');
                    setLeukocytes('');
                    setPusCells('');
                    setCrystals('');
                    setBacteria('');
                    setComments('');
                    setIsLoading(false);
                    setProgressData("0%")
                    return;
                }
                setUrinalysisId(res.data[0].ID)
                setUrinalysisPatId(res.data[0].PatIdNo)
                setSpGravity(res.data[0].SpGravity)
                setPh(res.data[0].pH)
                setGlucose(res.data[0].Glucose)
                setProtien(res.data[0].Protein)
                setKetan(res.data[0].Ketan)
                setBlood(res.data[0].Blood)
                setPusCells(res.data[0].PusCells)
                setCrystals(res.data[0].Crystals)
                setBacteria(res.data[0].Bacteria)
                setComments(res.data[0].Comments)
                setUrinalysis(res.data[0].Urinalysis)
                setBilirubin(res.data[0].Bilirubin)
                setUsExam(res.data[0].UsExam)

                setUrobillinogen(res.data[0].Urobilinogen)
                setNitrite(res.data[0].Nitrite)
                setLeukocytes(res.data[0].Leukocytes)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showImmunologyData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setImmunoList(res.data[0])
                if (res.data.length == 0) {
                    setImmunologyId(0);
                    setImmunologyPatId('');
                    setRbpt('');
                    setStat('');
                    setBrElisa('');
                    setTuberculosis('');
                    setTuberculosisELISA('');
                    setParatuberculosis('');
                    setJidElisa('');
                    setImmunolTest('');
                    setBrMrt('');
                    setIBR('');

                    setIdTuberuline('');
                    setIdJohnine('');
                    setIbrFat('');
                    setIrbElisa('');

                    return;
                }
                setImmunologyId(res.data[0].ID)
                setImmunologyPatId(res.data[0].PatIdNo)
                setRBPT(res.data[0].RBPT)
                setSTA(res.data[0].STAT)
                setELISA(res.data[0].BrELISA)
                setTuberculosis(res.data[0].Tuberculosis)
                setTuberculosisELISA(res.data[0].TuberculosisELISA)
                setParatuberculosis(res.data[0].Paratuberculosis)
                setIBR(res.data[0].IBR)
                setJidElisa(res.data[0].JIDELISA)
                setImmunolTest(res.data[0].Immuno1TestForDisease)
                setBrMrt(res.data[0].BrMRT)

                setIdTuberuline(res.data[0].IDTuberuline)
                setIdJohnine(res.data[0].IDJohnie)
                setIbrFat(res.data[0].IBRFAt)
                setIrbElisa(res.data[0].IRBELISA)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showSCMData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                if (res.data.length == 0) {
                    setSCMList(res.data[0]);
                    setScmId('');
                    setScmPatIdNo('');
                    setColorM('');
                    setScmSampleType('');
                    setMastrip('');
                    setCmt('');
                    setSCC('');
                    setEC('');
                    setSscTh('');
                    return;
                }
                setScmId(res.data[0].ID)
                setScmPatIdNo(res.data[0].PatIdNo)
                setColorM(res.data[0].ColorM)
                setScmSampleType(res.data[0].SCMSampleType)
                setMastrip(res.data[0].Mastrip)
                setCmt(res.data[0].CMT)
                setSCC(res.data[0].SCC)
                setEC(res.data[0].EC)
                setSscTh(res.data[0].SSCThml)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showAntigramData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setAntigramList(res?.data[0])
                if (res.data.length == 0) {
                    setAntigramId(0);
                    setAntigramPatId('');
                    setAbsSample('');
                    setAbsSampleQuality('');
                    setPathogenId('');
                    setAntibiotics('');
                    setBkMethod(''); return;
                }
                setAntigramId(res.data[0].ID)
                setAntigramPatId(res.data[0].PatIdNo)
                setAbsSample(res.data[0].ABSSample)
                setAbsSampleQuality(res.data[0].ABSSampleQuality)
                setPathogenId(res.data[0].PathogenId)
                setAntibiotics(res.data[0].Antibiotics)
                setBkMethod(res.data[0].BkDDMethod)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showExecuteSpData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                if (res.data.length == 0) {
                    setSpId(0);
                    setSpPatIdNo('');
                    setSpecialPath('');
                    setSps2('');
                    setSps3('');
                    setSps4('');
                    setSps5('');
                    setSps6('');
                    setSpResu('');
                    setSpr2('');
                    setSpr3('');
                    setSpr4('');
                    setSpr5('');
                    setSpr6('');
                    return;
                }
                setSpId(res.data[0].ID)
                setSpPatIdNo(res.data[0].PatIdNo)
                setSpecialPath(res.data[0].SpecialPath)
                setSps2(res.data[0].SPS2)
                setSps3(res.data[0].SPS3)
                setSps4(res.data[0].SPS4)
                setSps5(res.data[0].SPS5)
                setSps6(res.data[0].SPS6)
                setSpResu(res.data[0].SPResu)
                setSpr2(res.data[0].SPR2)
                setSpr3(res.data[0].SPR3)
                setSpr4(res.data[0].SPR4)
                setSpr5(res.data[0].SPR5)
                setSpr6(res.data[0].SPR6)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showToxicologyData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                if (res.data.length == 0) {
                    setToxicologyId(0);
                    setToxicologyPatientId('');
                    setTxSample1('');
                    setTxSample2('');
                    setTxTest1('');
                    setTxTest2('');
                    setTxResult1('');
                    setTxResult2('');
                    return;
                }
                setToxicologyId(res.data[0].ID)
                setToxicologyPatientId(res.data[0].PatIdNo)
                setTxSample1(res.data[0].TxSample1)
                setTxSample2(res.data[0].TxSample2)
                setTxTest1(res.data[0].TxTest1)
                setTxTest2(res.data[0].TxTest2)
                setTxResult1(res.data[0].TxResult1)
                setTxResult2(res.data[0].TxResult2)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showExecuteMilkChemistryData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                if (res.data.length == 0) {
                    setMilkId(0)
                    setMilkPatientId(0)
                    setLactose('')
                    setMilkSpGravity('')
                    setFat('')
                    setMilkProtein('')
                    setSnf('')
                    setMilkSampleType('')
                    setTotlSolid('')
                    setMilkketone('')
                    setMSpGravity('')
                    setDensity('')
                    setMph('')
                    seteConductivity('')
                    setTemperatureC('')
                    setMun('')
                    setMCitrate('')
                    // setTs('')
                    setSampleCondition('')
                    setFpc('')
                    setEcMsCm('')
                }
                setMilkId(res?.data[0]?.ID)
                setMilkPatientId(res.data[0].PatIdNo)
                setLactose(res.data[0].Lactose)
                setFat(res.data[0].Fat)
                setMilkProtein(res.data[0].Protein)
                setSnf(res.data[0].SNF)
                setTotlSolid(res.data[0].TS)
                setMilkketone(res.data[0].Milkketone)
                setMilkSpGravity(res.data[0].MilkSpGravity)
                setMSpGravity(res.data[0].MSpGravity)
                setMph(res.data[0].MPh)
                seteConductivity(res.data[0].eConductivity)
                setTemperatureC(res.data[0].TemperatureC)
                setMun(res.data[0].Munmg)
                setMCitrate(res.data[0].MCitratemg)
                setMilkSampleType(res.data[0].MilkSampleType)
                setDensity(res.data[0].Density)
                // setTs(res.data[0].TS)
                setSampleCondition(res.data[0].SampleCondition)
                setFpc(res.data[0].Fpc)
                setEcMsCm(res.data[0].Ecmscm)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showExecuteModTechData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                if (res.data.length == 0) {
                    setModTechId(0);
                    setModPatientId('');
                    setModTechniques('');
                    setMtResult('')
                    return;
                }
                setModTechId(res.data[0].ID)
                setModPatientId(res.data[0].PatIdNo)
                setModTechniques(res.data[0].ModTechniques)
                setMtResult(res.data[0].MtResult)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showExecuteRemarksData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: tagId,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                if (res.data.length == 0) {
                    setRemarkId('');
                    setRemarkPatientId('');
                    setRemarkTechniques('');
                    return;
                }
                setRemarkId(res.data[0].ID)
                setRemarkPatientId(res.data[0].PatIdNo)
                setRemarkTechniques(res.data[0].Remarks)
                setIsLoading(false);
                setProgressData("0%")
            })
    }

    const [a, seta] = useState()
    const colum = [
        {
            name: "Date",
            cell: (e) => (<div onClick={() => { dateDataFn(e); allFieldsOpen(); seta(e.RegistrationDate) }}>{moment(e.RegistrationDate).format('DD-MM-YYYY')}</div>),
            sortable: true
        },
    ]


    const printReport = () => {

    }

    // const printHeamtoCytology = useReactToPrint({
    //     content: () => heamtocytology.current,
    // });

    const printHeamtoCytology = async (type) => {
        setIsLoading(true)
        console.log(a);
        const regdate = moment(a).format("DD/MM/YYYY")
        try {

            const apiUrl = `${Hemato_cytology.lab_Certificate1}`;

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                    'Authorization': `Bearer ${added_by}`,
                },
                body: JSON.stringify({
                    Id: tagId,
                    Testing: "Heamto",
                    Date: regdate,
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const pdfBlob = await response.blob();

            const pdfUrl = URL.createObjectURL(pdfBlob);

            window.open(pdfUrl, '_blank');

            URL.revokeObjectURL(pdfUrl);
        } catch (error) {
            console.error('API request failed:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const allFieldsOpen = () => {
        allCheck2(true);
        allCheck1(true);
        allCheck3(true);
        allCheck4(true);
        allCheck5(true);
        allCheck6(true);
    }

    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        if (/^[0-9]*$/.test(inputValue)) {
            setSpGravity(inputValue);
        }
    };

    const [absSearch, setAbsSearch] = useState(false);

    const SearchFn = (e) => {
        setSearchId(e);
        const abc = tagIdList1;
        const searchdata = abc.filter((i) => i.Animal && i.Animal.startsWith(e));
        setAbsSearch(true);
        setTagIdList(searchdata);
        console.log(searchid);
    }

    const handleInputChanges = (event) => {
        setSearchId(event.target.value);
    };

    const fatchData = async () => {
        setIsLoading(true)
        const res = await getDataLab()
        const res1 = await getVillageData()
        const res2 = await getTestedData()
        const res3 = await getDropData({ QTYPE: "SAMPLE" })
        const res4 = await getDropData({ QTYPE: "UNFIT_SAMPLE" })
        const res5 = await getDropData({ QTYPE: "DIST_DIST_TALUKA" })
        const res6 = await getDropData({ QTYPE: "INSTITUTION" })
        const res7 = await getDropData({ QTYPE: "DISTRICT_TALUKA" })
        const res8 = await getDropData({ QTYPE: "LAB_LIST" })
        const res9 = await getDropData({ QTYPE: "LAB_TESTED" })
        const res10 = await getRefByData({ Userid: "201", TBLSTR: "Master_staff:0" })

        if (res) { setLabDataList(res.data); setIsLoading(false); setProgressData("0%") }
        if (res1) { setVillageList(res1); setIsLoading(false); setProgressData("0%") }
        if (res2) { setTestedByList(res2.data); setIsLoading(false); setProgressData("0%") }
        if (res3) { setSampleList(res3.data); setIsLoading(false); setProgressData("0%") }
        if (res4) { setUnfitSampleList(res4.data); setIsLoading(false); setProgressData("0%") }
        if (res5) { setDistrictList(res5.data); setIsLoading(false); setProgressData("0%") }
        if (res6) { setRefInstituteList(res6.data); setIsLoading(false); setProgressData("0%") }
        if (res7) { setTalukaList(res7.data); setIsLoading(false); setProgressData("0%") }
        if (res8) { setLabList(res8.data); setIsLoading(false); setProgressData("0%") }
        if (res9) { setTestList(res9.data); setIsLoading(false); setProgressData("0%") }
        if (res10) { setRefList(res10[0]); setIsLoading(false); setProgressData("0%") }
    }

    const Save = async () => {
        console.log(sample);
        setIsLoading(true)

        try {
            await fetch(`${API_KEY}/lab/Register/Save_LabData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: patId ? patId : "",
                    LabName: labName ? labName : 0,
                    RefBy: refBy ? refBy : 0,
                    RefInstitute: refInstitute,
                    Date: date,
                    Owner: owner,
                    Village: village,
                    AnimalSpecies: animalSpeices,
                    Animal: animal,
                    Taluka: taluka,
                    TagIdNo: newTagId ? newTagId : "",
                    District: district,
                    Sample: sample.toString(),
                    Test: test.toString(),
                    UnfitSample: unfitSample,
                    RefCellNo: refCellNo,
                    TestedBy: testedBy
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {
                    fatchData(); setModalShow(false); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%")
                })

            await fetch(`${Cattleregistration.AnimalGet}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    "tagId": newTagId.toString() ? newTagId.toString() : "",
                })
            })
                .then(res => res.json())
                .then((res) => {
                    setTagId(res[0]?.Fld || '');
                    setCenter(res[1]?.Fld || '');
                    setDcs(res[2]?.Fld || '');
                    setRoute(res[3]?.Fld || '');
                    setFarmer(res[4]?.Fld || '');
                    setSpecies(res[5]?.Fld || '');
                });

        } catch (error) {
            console.error('Error during API calls', error);
        }
    }

    useEffect(() => {
        fatchData()
    }, []);

    const [showfields, setShowFields] = useState(false)
    const [modaladd, setmodaladd] = useState(false)

    const fillData = (e) => {
        // setmodaladd(tagIdNo)
        console.log(e);
        setNewTagId(e)
        if (e) {
            const findrecord = labDataList.find((i) => { return i.TagIdNo == e })
            if (findrecord) {
                setShowFields(true)
                setTagIdNo(findrecord?.TagIdNo)
                setPatId(findrecord?.PatIdNo)
                setLabName(findrecord?.lab_list_name)
                setRefBy(findrecord?.RefBy)
                setRefInstitute(findrecord?.RefInstitute)
                setDate(findrecord?.Date)
                setDistrict(findrecord?.District)
                setTaluka(findrecord?.TagIdNo)
                setVillage(findrecord?.Village)
                setOwner(findrecord?.Owner)
                setAnimalSpeices(findrecord?.AnimalSpecies)
                setAnimal(findrecord?.Animal)
                setSample(findrecord?.lab_sample_name)
                setTest(findrecord?.lab_Tested_name)
                setUnfitSample(findrecord?.lab_Unfit_Sample_name)
                setRefCellNo(findrecord?.RefCellNo)
                setTestedBy(findrecord?.TestedBy)

                console.log(findrecord);
            } else {
                setShowFields(false)
                toast.error("Register Id not found, Please register.")
                setTagIdNo("")
                setPatId("")
                setLabName("")
                setRefBy("")
                setRefInstitute("")
                setDate("")
                setDistrict("")
                setTaluka("")
                setVillage("")
                setOwner("")
                setAnimalSpeices("")
                setAnimal("")
                setSample("")
                setTest("")
                setUnfitSample("")
                setRefCellNo("")
                setTestedBy("")
            }
        }
    }
    const resetFn = () => {
        setTagIdNo("")
        // setNewTagId("")
        setPatId("")
        setLabName("")
        setRefBy("")
        setRefInstitute("")
        setDate("")
        setDistrict("")
        setTaluka("")
        setVillage("")
        setOwner("")
        setAnimalSpeices("")
        setAnimal("")
        setSample("")
        setTest("")
        setUnfitSample("")
        setRefCellNo("")
        setTestedBy("")
    }
    const handleTagIdNoChange = (e, f) => {
        // const findrecord = labDataList.find((i) => { return i.TagIdNo == e })
        const numericValue = e.target.value.replace(/[^0-9]/g, '');
        if (f == "newTagId") {
            setNewTagId(numericValue);
        }
        else if (f == "patId") {
            setPatId(numericValue);
        }
        else if (f == "refCellNo") {
            setRefCellNo(numericValue);
        }
        // ;


    };
    const handleAlphabetChange = (e, f) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Include \s for spaces

        if (f === 'village') {
            setVillage(updatedValue);
        } else if (f === 'owner') {
            setOwner(updatedValue);
        } else if (f === 'animal') {
            setAnimal(updatedValue);
        }
    };

    const handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            // if (searchid.trim() == "" || searchid == []) {
            //     return toast.error('Please enter valid ID!')

            // }

            try {
                const response = await fetch(`${Cattleregistration.GetanimaldataNew}`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${added_by}`,
                    },
                    body: JSON.stringify({
                        "TagIdNo": searchid ? searchid : "",
                    })
                }).then(res => res.json())
                    .then((res) => {
                        if (res.Type == 1 && res.recordset.length > 0) {
                            const Fld = res.recordset;
                            console.log(Fld[0].Fld)
                            setTagId(Fld[0].Fld);
                            setIdTypeNo(res.Type);
                            setCenter(Fld[1].Fld);
                            setDcs(Fld[2].Fld);
                            setRoute(Fld[3].Fld);
                            setFarmer(Fld[4].Fld);
                            setSpecies(Fld[5].Fld);
                        } else if (res.Type == 2 && res.recordset.length > 0) {
                            const Fld = res.recordset[0];
                            setIdTypeNo(res.Type);
                            setTagId(Fld.TagIdNo);
                            setowner(Fld.Owner);
                        } else {
                            setIdTypeNo("");
                            toast.error("Invalid TagId!");

                        }
                        console.log(res);
                    });
                setSearchId("");
                setDistintDate("");
                setHcs('');
                setHbGm('');
                setPCV('');
                // setTec('');
                setTlc('');
                setN('');
                // setNUl('');
                setL('');
                // setLUl('');
                setM('');
                // setMUL('');
                setE('');
                // setEUL('');
                setB('');
                // setBC('');
                setP1pro('');
                setFibrinogen('');
                setPpFib('');
                // setSEValue('');
                // setSECellValue('');
                setPLT('');
                setWBC('');
                setHCT('');
                setMCV('');
                setMCH('');
                setMCHC('');
                setRBC('');
                setNEUTROPHILS('');
                setLYMPHOCYTES('');
                setMID('');
                setNEUTROPHILSBAND('');
                setHemotoOthers('');
                setMONOCYTE('');
                setBASOPHIL('');
                setTheilaria('');
                setBabesiaBigemina('');
                setBabesiaBovis('');
                setAnaplasma('');
                setTrypanosomaEvansi('');
                setBiochemistryId(0);
                setBioPatId(0);
                setBiochemistry("");
                setMagnesium("");
                setSodiumMeq("");
                setPotassiumMeq("");
                setChlorideMeq("");
                setCarbonateMeq("");
                setLiverFunctionTestMeq("");
                setBilirubinTotal("");
                setBilirubinDirect("");
                setBilirubinIndirect("");
                setSGOT("");
                setSGPT("");
                setAlkalinePhosphatase("");
                setSerumCreatinine("");
                setMethaemoglobin("");
                setCRP("");
                setBioOthers("");
                setBioBcs("");
                setProG("");
                setCholM("");
                setCamg("");
                setPMg("");
                setAlbG("");
                setAsl("");
                setMg("");
                setFlgG("");
                setSdh("");
                setNa("");
                setAlbGlo("");
                setGgt("");
                setK("");
                setBioPpFib("");
                setAkp("");
                setC1("");
                setBun("");
                setTBill("");
                setGlu("");
                setCr("");
                setDBill("");
                setRandum("");
                setSECellMorphology("");
                setCal("");
                setPhos("");
                setBioRbpt("");
                setTBTEST("");
                setJONHSTESTPPD("");
                setGlId(0);
                setGlPatId('');
                setGlParatism('');
                setFsPositiveFor('');
                setFacelSample('');
                setTramatodes('');
                setCystodes('');
                setNematodes('');

                setOccultBlood("")
                setPusCells1("")
                setMucousCells("")
                setCoccidia("")
                setBalantidiumColi("")
                setEpgGt('');
                setUrinalysisId('');
                setUrinalysisPatId('');
                setUrinalysis('');
                setGlucose('');
                setKetan('');
                setSpGravity('');
                setBlood('');
                setPh('');
                setBilirubin('');
                setUsExam('');
                setProtien('');
                setUrobillinogen('');
                setNitrite('');
                setLeukocytes('');
                setPusCells('');
                setCrystals('');
                setBacteria('');
                setComments('');
                setImmunologyId(0);
                setImmunologyPatId('');
                setRbpt('');
                setStat('');
                setBrElisa('');
                setTuberculosis('');
                setTuberculosisELISA('');
                setParatuberculosis('');
                setJidElisa('');
                setImmunolTest('');
                setBrMrt('');
                setIBR('');

                setIdTuberuline('');
                setIdJohnine('');
                setIbrFat('');
                setIrbElisa('');
                setScmId('');
                setScmPatIdNo('');
                setColorM('');
                setScmSampleType('');
                setMastrip('');
                setCmt('');
                setSCC('');
                setEC('');
                setSscTh('');
                setAntigramId(0);
                setAntigramPatId('');
                setAbsSample('');
                setAbsSampleQuality('');
                setPathogenId('');
                setAntibiotics('');
                setBkMethod('');
                setSpId(0);
                setSpPatIdNo('');
                setSpecialPath('');
                setSps2('');
                setSps3('');
                setSps4('');
                setSps5('');
                setSps6('');
                setSpResu('');
                setSpr2('');
                setSpr3('');
                setSpr4('');
                setSpr5('');
                setSpr6('');
                setToxicologyId(0);
                setToxicologyPatientId('');
                setTxSample1('');
                setTxSample2('');
                setTxTest1('');
                setTxTest2('');
                setTxResult1('');
                setTxResult2('');
                setMilkId(0)
                setMilkPatientId(0)
                setLactose('')
                setMilkSpGravity('')
                setFat('')
                setMilkProtein('')
                setSnf('')
                setMilkSampleType('')
                setTotlSolid('')
                setMilkketone('')
                setMSpGravity('')
                setDensity('')
                setMph('')
                seteConductivity('')
                setTemperatureC('')
                setMun('')
                setMCitrate('')
                // setTs('')
                setSampleCondition('')
                setFpc('')
                setEcMsCm('')
                setModTechId(0);
                setModPatientId('');
                setModTechniques('');
                setMtResult('')
                setRemarkId('');
                setRemarkPatientId('');
                setRemarkTechniques('');
                SearchFn(searchid);
                console.log(searchid);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };


    // const handleKeyPress = async (e) => {
    //     if (e.key === "Enter") {

    //         await fetch(`${Cattleregistration.AnimalGet}`, {
    //             method: 'POST',
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`,
    //             },
    //             body: JSON.stringify({
    //                 "tagId": searchid ? searchid : "",
    //             })
    //         })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setTagId(res[0]?.Fld || ''); 
    //                 setCenter(res[1]?.Fld || '');
    //                 setDcs(res[2]?.Fld || '');
    //                 setRoute(res[3]?.Fld || '');
    //                 setFarmer(res[4]?.Fld || '');
    //                 setSpecies(res[5]?.Fld || '');
    //             });

    //         SearchFn();
    //         saveanimaltagid();
    //         console.log(searchid);
    //     }
    // };

    // const handleKeyPress = async (e) => {
    //     if (e.key === "Enter") {
    //         const res = await getAnimalDataNew(searchid);
    // await fetch(`${Cattleregistration.AnimalGet}`, {
    //     method: 'POST',
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Accept": "application/json",
    //         "Authorization": `Bearer ${added_by}`,
    //     },
    //     body: JSON.stringify({
    //         "tagId": searchid ? searchid : "",
    //     })
    // })
    // .then(res => res.json())
    // .then((res) => {
    // if (res) {
    //     setTagId(res[0]?.Fld || ''); 
    //     setCenter(res[1]?.Fld || '');
    //     setDcs(res[2]?.Fld || '');
    //     setRoute(res[3]?.Fld || '');
    //     setFarmer(res[4]?.Fld || '');
    //     setSpecies(res[5]?.Fld || '');
    //     console.log("responsive"+searchid);
    // }

    // });

    //         SearchFn();
    //         saveanimaltagid();
    //         console.log(searchid);
    //     }
    // };


    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Lab Management / Test Request Master</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-start mt-1">
                                <Col xs="4" sm="2">
                                    {/* <FormControl variant="standard" fullWidth>
                                        <Searcable
                                            placeholder="Animal Tag Id"
                                            options={tagIdList}
                                            onChange={(selectedOption) => { setSearchId(selectedOption); }}
                                            value={searchid}
                                            labelKey="Animal"
                                            valueKey="Animal"
                                            className="relativedrop"
                                        />
                                    </FormControl> */}

                                    {/* <Input label="Animal Tag Id" value={searchid} onChange={(e) => { setSearchId(e.target.value) }}
                                        onKeyPress={(e)=>{handleKeyPress(e.target.value)}} /> */}
                                    <Input
                                        label="Animal Tag Id"
                                        value={searchid}
                                        onChange={(e) => { setSearchId(e.target.value) }}
                                        onKeyDown={handleKeyPress}
                                    />

                                </Col>
                                <Col xs="4" sm="2">
                                    <sub className="mb-0 pb-0">Date</sub>
                                    <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                </Col>
                                <Col xs="4" sm="2" md="2" xl="1" lg="2" className="mt-2">
                                    <button className="btn-blue-Animal mt-1" onClick={view}>View</button>
                                </Col>
                                <Col xs="4" sm="2" md="2" xl="2" lg="2" className="mt-2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setmodaladd(true); resetFn(); }}>Add New Registration</button>
                                </Col>
                                <Col xs="4" sm="2" className='ms-auto' >
                                    <Input label="Search" value={search} />
                                </Col>
                            </Row>
                            <>
                                {IdTypeNo == '1' && (
                                    <Row className="align-items-end justify-content-start my-3">
                                        <>
                                            <Row className="align-items-end justify-content-start my-3">
                                                <Col xl="2" md="4" sm="4" xs="12" className="align-items-center">
                                                    <div className="d-flex ms-0 align-items-center idNo par">
                                                        <h6 className="mb-0">TagIdNo :</h6>
                                                        <input type="text" value={tagId} />
                                                    </div>
                                                </Col>
                                                <Col xl="2" md="4" sm="4" xs="12" className="align-items-center">
                                                    <div className="d-flex ms-0 align-items-center idNo par">
                                                        <h6 className="mb-0">Center :</h6>
                                                        <input type="text" value={center} />
                                                    </div>
                                                </Col>
                                                <Col xl="2" md="4" sm="4" xs="12" className="align-items-center">
                                                    <div className="d-flex ms-0 align-items-center idNo par">
                                                        <h6 className="mb-0">Dcs :</h6>
                                                        <input type="text" value={dcs} />
                                                    </div>
                                                </Col>
                                                <Col xl="2" md="4" sm="4" xs="12" className="align-items-center">
                                                    <div className="d-flex ms-0 align-items-center idNo par">
                                                        <h6 className="mb-0">Route :</h6>
                                                        <input type="text" value={route} />
                                                    </div>
                                                </Col>
                                                <Col xl="2" md="4" sm="4" xs="12" className="align-items-center">
                                                    <div className="d-flex ms-0 align-items-center idNo par">
                                                        <h6 className="mb-0">Farmer :</h6>
                                                        <input type="text" value={farmer} />
                                                    </div>
                                                </Col>
                                                <Col xl="2" md="4" sm="4" xs="12" className="align-items-center">
                                                    <div className="d-flex ms-0 align-items-center idNo par">
                                                        <h6 className="mb-0">Species :</h6>
                                                        <input type="text" value={species} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    </Row>
                                )}
                                {IdTypeNo == '2' && (
                                    <Row className="align-items-end justify-content-start my-3">
                                        <>
                                            <Row className="align-items-end justify-content-start my-3">
                                                <Col xl="2" md="4" sm="4" xs="12" className="align-items-center">
                                                    <div className="d-flex ms-0 align-items-center idNo par">
                                                        <h6 className="mb-0">TagIdNo :</h6>
                                                        <input type="text" value={tagId} />
                                                    </div>
                                                </Col>
                                                <Col xl="2" md="4" sm="4" xs="12" className="align-items-center">
                                                    <div className="d-flex ms-0 align-items-center idNo par">
                                                        <h6 className="mb-0">Owner :</h6>
                                                        <input type="text" value={Owner} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    </Row>
                                )}

                            </>



                            <Row className='mt-2'>
                                <Col xs="4" sm="4" md="3" lg="2" xl="2">
                                    <div className='leftPannel'>
                                        {/* <Row className='align-items-center justify-content-center'>
                                            <Col xs="10" lg="10" md="10" sm="10" xl="10">
                                                <TextField label="ID" id="standard-basic" value={searchid} onChange={(e) => { setSearchId(e.target.value); }} fullWidth variant="standard" />
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center justify-content-center'>
                                            <Col xs="10" sm="10" md="10" xl="10" >
                                                <TextField label="Testing" id="standard-basic" value={testing} onChange={(e) => { setTesting(e.target.value); }} fullWidth variant="standard" />
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center justify-content-center'>
                                            <Col xs="10" sm="10" md="10" xl="10">
                                                <sub className="mb-0 pb-0">Date</sub>
                                                <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                            </Col>
                                        </Row>
                                        <Row className='mt-3 align-items-center justify-content-center'>
                                            <Col xs="12" sm="10" lg="2" md="4" xl="4" className="mt-2">
                                                <button className="btn-blue-Animal mt-1" onClick={Add}>Add New</button>
                                            </Col>
                                            <Col xs="12" sm="10" lg="2" md="4" xl="4" className="mt-2">
                                                <button className="btn-blue-Animal mt-1" onClick={view}>View</button>
                                            </Col>
                                        </Row> */}

                                        <Row className='align-items-center justify-content-center text-center'>
                                            <Col className='mt-2'>All Dates Of Testing</Col>
                                        </Row>
                                        <hr></hr>
                                        <Col className=''>
                                            <Table columns={colum} data={distintDate} />
                                        </Col>
                                    </div>
                                </Col>
                                <Col xs="8" sm="8" md="9" lg="10" xl="10">
                                    <div className='leftPannel'>
                                        <Row className='mainTabPanel p-4 pt-2'>
                                            <Col xs="12 " sm="4" lg="2" xl="2">
                                                <button className={`${index == 1 ? `${clrChange == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(1)}>Heamto-Cytology</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 2 ? `${clrChange2 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange2 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(2)}>Biochemistry</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 3 ? `${clrChange3 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange3 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(3)}>GI Parasitism</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 4 ? `${clrChange4 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange4 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(4)}>Urinalysis</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 5 ? `${clrChange5 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange5 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(5)}>Immunology</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 6 ? `${clrChange6 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange6 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(6)}>SCM</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 7 ? `${clrChange7 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange7 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(7)}>Antigram</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 8 ? `${clrChange8 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange8 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(8)}>SP</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 9 ? `${clrChange9 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange9 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(9)}>Toxicology</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 10 ? `${clrChange10 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange10 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(10)}>Milk Chemistry</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 11 ? `${clrChange11 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange11 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(11)}>Mod Tech</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 12 ? `${clrChange12 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange12 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(12)}>Remarks</button>
                                            </Col>
                                        </Row>
                                        {
                                            index === 1 &&
                                            <Container>
                                                <Row className='mt-4 p-3 align-items-end'>

                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                                        <input type='checkbox' className='ms-2' value={allCheck} onClick={(e) => { allCheck1(e.target.checked); setAllCheck(e.target.checked) }} /> All
                                                        <Row className='ms-1'>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={hbGmchecked} onChange={(e) => { setHbGmchecked(e.target.checked) }} /> Haemoglobin
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={RBCchecked} onChange={(e) => { setRBCchecked(e.target.checked) }} /> Erythrocyte (RBC) Cell count
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={WBCchecked} onChange={(e) => { setWBCchecked(e.target.checked) }} /> Leucocyte (WBC) Call Count
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={PLTchecked} onChange={(e) => { setPLTchecked(e.target.checked) }} /> Platelet (Thrombocytes) Cell count
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={PCVchecked} onChange={(e) => { setPCVchecked(e.target.checked) }} /> Packed Cell Volume (haematocrit)
                                                            </Col>

                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2"  >
                                                                <input
                                                                    type='checkbox'
                                                                    checked={diffContChecked}
                                                                    onChange={(e) => {
                                                                        setDiffContChecked(e.target.checked);
                                                                        e.target.checked
                                                                            ? (() => {
                                                                                setNEUTROPHILSchecked(true);
                                                                                setLYMPHOCYTESchecked(true);
                                                                                setNEUTROPHILSBANDchecked(true);
                                                                                setMONOCYTEchecked(true);
                                                                                setBASOPHILchecked(true)
                                                                            })()
                                                                            : (() => {
                                                                                setNEUTROPHILSchecked(false);
                                                                                setLYMPHOCYTESchecked(false);
                                                                                setNEUTROPHILSBANDchecked(false);
                                                                                setMONOCYTEchecked(false);
                                                                                setBASOPHILchecked(false)
                                                                            })();
                                                                    }}
                                                                /> Differential Count Cell count
                                                            </Col>

                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input
                                                                    type='checkbox'
                                                                    checked={bloodProtozoanChecked}
                                                                    onChange={(e) => {
                                                                        setBloodProtozoanChecked(e.target.checked)
                                                                        e.target.checked
                                                                            ? (() => {
                                                                                setTheilariaChecked(true);
                                                                                setBabesiaBigeminaChecked(true);
                                                                                setBabesiaBovisChecked(true);
                                                                                setAnaplasmaChecked(true);
                                                                                setTrypanosomaEvansiChecked(true);
                                                                            })()
                                                                            : (() => {
                                                                                setTheilariaChecked(false);
                                                                                setBabesiaBigeminaChecked(false);
                                                                                setBabesiaBovisChecked(false);
                                                                                setAnaplasmaChecked(false);
                                                                                setTrypanosomaEvansiChecked(false);
                                                                            })();
                                                                    }}
                                                                /> Blood Protozoan PCR
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={HemotoOthersChecked} onChange={(e) => { setHemotoOthersChecked(e.target.checked) }} /> Others
                                                            </Col>

                                                            {/* <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={HCTchecked} onChange={(e) => { setHCTchecked(e.target.checked) }} /> Haemoglobin cyanmethemoglobin test g %
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MCHchecked} onChange={(e) => { setMCHchecked(e.target.checked) }} /> MCH
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MCHCchecked} onChange={(e) => { setMCHCchecked(e.target.checked) }} /> MCHC
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={NEUTROPHILSchecked} onChange={(e) => { setNEUTROPHILSchecked(e.target.checked) }} /> NEUTROPHILS
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={LYMPHOCYTESchecked} onChange={(e) => { setLYMPHOCYTESchecked(e.target.checked) }} /> LYMPHOCYTES
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MIDchecked} onChange={(e) => { setMIDchecked(e.target.checked) }} /> MID
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={NEUTROPHILSBANDchecked} onChange={(e) => { setNEUTROPHILSBANDchecked(e.target.checked) }} />NEUTROPHILS BAND
                                                            </Col> */}

                                                        </Row>
                                                    </Col>



                                                    {
                                                        hbGmchecked && (
                                                            <Col sm="4">
                                                                <TextField label="HB gm%" id="standard-basic" value={hbGm} onChange={(e) => { handleInput(e, "HB gm%"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        PCVchecked && (
                                                            <Col sm="4">
                                                                <TextField label="PCV" id="standard-basic" value={PCV} onChange={(e) => { handleInput(e, "PCV"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        PLTchecked && (
                                                            <Col sm="4">
                                                                <TextField label="PLT" id="standard-basic" value={PLT} onChange={(e) => { handleInput(e, "PLT"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        RBCchecked && (
                                                            <Col sm="4">
                                                                <TextField label="RBC" id="standard-basic" value={RBC} onChange={(e) => { handleInput(e, "RBC"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        WBCchecked && (
                                                            <Col sm="4">
                                                                <TextField label="WBC" id="standard-basic" value={WBC} onChange={(e) => { handleInput(e, "WBC"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        HCTchecked && (
                                                            <Col sm="4">
                                                                <TextField label="HCT" id="standard-basic" value={HCT} onChange={(e) => { handleInput(e, "HCT"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        NEUTROPHILSchecked && (
                                                            <Col sm="4">
                                                                <TextField label="NEUTROPHILS" id="standard-basic" value={NEUTROPHILS} onChange={(e) => { handleInput(e, "NEUTROPHILS"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        LYMPHOCYTESchecked && (
                                                            <Col sm="4">
                                                                <TextField label="LYMPHOCYTES" id="standard-basic" value={LYMPHOCYTES} onChange={(e) => { handleInput(e, "LYMPHOCYTES"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        NEUTROPHILSBANDchecked && (
                                                            <Col sm="4">
                                                                <TextField label="NEUTROPHILS BAND" id="standard-basic" value={NEUTROPHILSBAND} onChange={(e) => { handleInput(e, "NEUTROPHILS BAND"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        MONOCYTEchecked && (
                                                            <Col sm="4">
                                                                <TextField label="MONOCYTE" id="standard-basic" value={MONOCYTE} onChange={(e) => { handleInput(e, "MONOCYTE"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        BASOPHILchecked && (
                                                            <Col sm="4">
                                                                <TextField label="BASOPHIL" id="standard-basic" value={BASOPHIL} onChange={(e) => { handleInput(e, "BASOPHIL"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        HemotoOthersChecked && (
                                                            <Col sm="4">
                                                                <TextField label="Others" id="standard-basic" value={HemotoOthers} onChange={(e) => { handleInput(e, "HemotoOthers"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {

                                                        bloodProtozoanChecked && (
                                                            <>
                                                                {/* <Col sm="4">
                                                                        <FormControl variant="standard" fullWidth>
                                                                            <Searcable
                                                                                placeholder="Theilaria"
                                                                                options={PositiveNegative}
                                                                                onChange={(selectedOption) => setTheilaria(selectedOption.sampletype)}
                                                                                value={Theilaria}
                                                                                labelKey="sampletype"
                                                                                valueKey="id"
                                                                            />
                                                                        </FormControl>
                                                                        
                                                                    </Col> */}
                                                                <Col sm="4">
                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Theilaria</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={Theilaria} onChange={(e) => { setTheilaria(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Positive" >Positive</MenuItem>
                                                                            <MenuItem className="drop_value" value="Negative" >Negative</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                {/* <Col sm="4">
                                                                        <FormControl variant="standard" fullWidth>
                                                                            <Searcable
                                                                                placeholder="Babesia bigemina"
                                                                                options={PositiveNegative}
                                                                                onChange={(selectedOption) => setBabesiaBigemina(selectedOption.sampletype)}
                                                                                value={BabesiaBigemina}
                                                                                labelKey="sampletype"
                                                                                valueKey="id"
                                                                            />
                                                                        </FormControl>
                                                                    </Col> */}
                                                                <Col sm="4">
                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Babesia Bigemina</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={BabesiaBigemina} onChange={(e) => { setBabesiaBigemina(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Positive" >Positive</MenuItem>
                                                                            <MenuItem className="drop_value" value="Negative" >Negative</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                {/* <FormControl variant="standard" fullWidth>
                                                                            <Searcable
                                                                                placeholder="Babesia bigemina"
                                                                                options={PositiveNegative}
                                                                                onChange={(selectedOption) => setBabesiaBovis(selectedOption.sampletype)}
                                                                                value={BabesiaBovis}
                                                                                labelKey="sampletype"
                                                                                valueKey="id"
                                                                            />
                                                                        </FormControl> */}

                                                                <Col sm="4">
                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Babesia Bovis</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={BabesiaBovis} onChange={(e) => { setBabesiaBovis(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Positive" >Positive</MenuItem>
                                                                            <MenuItem className="drop_value" value="Negative" >Negative</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                <Col sm="4">
                                                                    {/* <FormControl variant="standard" fullWidth>
                                                                            <Searcable
                                                                                placeholder="Anaplasma"
                                                                                options={PositiveNegative}
                                                                                onChange={(selectedOption) => setAnaplasma(selectedOption.sampletype)}
                                                                                value={Anaplasma}
                                                                                labelKey="sampletype"
                                                                                valueKey="id"
                                                                            />
                                                                        </FormControl> */}

                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Anaplasma</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={Anaplasma} onChange={(e) => { setAnaplasma(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Positive" >Positive</MenuItem>
                                                                            <MenuItem className="drop_value" value="Negative" >Negative</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                <Col sm="4">
                                                                    {/* <TextField label="Trypanosoma evansi" id="standard-basic" value={TrypanosomaEvansi} onChange={(e) => { setTrypanosomaEvansi(e.target.value); }} fullWidth variant="standard" /> */}

                                                                    {/* <FormControl variant="standard" fullWidth>
                                                                            <Searcable
                                                                                placeholder="Trypanosoma evansi"
                                                                                options={PositiveNegative}
                                                                                onChange={(selectedOption) => setTrypanosomaEvansi(selectedOption.sampletype)}
                                                                                value={TrypanosomaEvansi}
                                                                                labelKey="sampletype"
                                                                                valueKey="id"
                                                                            />
                                                                        </FormControl> */}

                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Trypanosoma evansi</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={TrypanosomaEvansi} onChange={(e) => { setTrypanosomaEvansi(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Positive" >Positive</MenuItem>
                                                                            <MenuItem className="drop_value" value="Negative" >Negative</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                            </>
                                                        )
                                                    }


                                                </Row>

                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { SaveHeamtoCytology(); }}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { printHeamtoCytology(); }}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(2) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 2 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" >
                                                        <input type='checkbox' className='ms-2' onClick={(e) => { allCheck2(e.target.checked) }} /> All
                                                        <Row className='ms-1'>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={Calchecked} onChange={(e) => { setCalchecked(e.target.checked) }} /> Serum calcium
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={Phoschecked} onChange={(e) => { setPhoschecked(e.target.checked) }} /> Serum phosphorus
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={magnesiumChecked} onChange={(e) => { setMagnesiumChecked(e.target.checked) }} /> Serum magnesium
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={gluchecked} onChange={(e) => { setGluchecked(e.target.checked) }} /> Serum glucose
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input
                                                                    type='checkbox'
                                                                    checked={ElectrolytesChecked}
                                                                    onChange={(e) => {
                                                                        setElectrolytesChecked(e.target.checked);
                                                                    }}
                                                                /> Serum Electrolytes
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input
                                                                    type='checkbox'
                                                                    checked={liverFunctionTestChecked}
                                                                    onChange={(e) => {
                                                                        setLiverFunctionTestChecked(e.target.checked);
                                                                    }}
                                                                /> Liver function test
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={kidenyFunctionTestChecked} onChange={(e) => { setKidenyFunctionTestChecked(e.target.checked) }} /> Kidney function test
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={CRPChecked} onChange={(e) => { setCRPChecked(e.target.checked) }} /> CRP
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MethaemoglobinChecked} onChange={(e) => { setMethaemoglobinChecked(e.target.checked) }} /> Methaemoglobin
                                                            </Col>
                                                            {/* <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={bunchecked} onChange={(e) => { setBunchecked(e.target.checked) }} /> Blood Urea Nitrogen
                                                            </Col> */}
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={BioOthersChecked} onChange={(e) => { setBioOthersChecked(e.target.checked) }} /> Others
                                                            </Col>
                                                            {/* <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={bioRbptchecked} onChange={(e) => { setBioRbptchecked(e.target.checked) }} /> Brucella RBPT
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={TBTESTchecked} onChange={(e) => { setTBTESTchecked(e.target.checked) }} /> TBTEST
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={JONHSTESTPPDchecked} onChange={(e) => { setJONHSTESTPPDchecked(e.target.checked) }} /> JonhsTestTppd
                                                            </Col> */}
                                                        </Row>
                                                    </Col>



                                                    {
                                                        Calchecked && (
                                                            <Col sm="4">
                                                                <TextField label="Serum calcium	mg / dl  " id="standard-basic" value={Cal} onChange={(e) => { handleInputBioChemistry(e, "Cal"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        Phoschecked && (
                                                            <Col sm="4">
                                                                <TextField label="Serum phosphorus dl" id="standard-basic" value={Phos} onChange={(e) => { handleInputBioChemistry(e, "Phos"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        magnesiumChecked && (
                                                            <Col sm="4">
                                                                <TextField label="Serum magnesium mg / dl" id="standard-basic" value={magnesium} onChange={(e) => { handleInputBioChemistry(e, "magnesium"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        ElectrolytesChecked && (
                                                            <>
                                                                <Col sm="4">
                                                                    <TextField label="Serum Sodium Meq per L" id="standard-basic" value={SodiumMeq} onChange={(e) => { handleInputBioChemistry(e, "SodiumMeq"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="Serum Potassium Meq per L" id="standard-basic" value={PotassiumMeq} onChange={(e) => { handleInputBioChemistry(e, "PotassiumMeq"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="Serum Chloride Meq per L" id="standard-basic" value={ChlorideMeq} onChange={(e) => { handleInputBioChemistry(e, "ChlorideMeq"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="Serum Carbonate Meq per L" id="standard-basic" value={CarbonateMeq} onChange={(e) => { handleInputBioChemistry(e, "CarbonateMeq"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="Liver function test Meq per L" id="standard-basic" value={liverFunctionTestMeq} onChange={(e) => { handleInputBioChemistry(e, "liverFunctionTestMeq"); }} fullWidth variant="standard" />
                                                                </Col>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        liverFunctionTestChecked && (
                                                            <>
                                                                <Col sm="4">
                                                                    <TextField label="Serum bilirubin total mg / dl" id="standard-basic" value={bilirubinTotal} onChange={(e) => { handleInputBioChemistry(e, "bilirubinTotal"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="Serum bilirubin direct mg / dl" id="standard-basic" value={bilirubinDirect} onChange={(e) => { handleInputBioChemistry(e, "bilirubinDirect"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="Serum bilirubin indirect / dl" id="standard-basic" value={bilirubinIndirect} onChange={(e) => { handleInputBioChemistry(e, "bilirubinIndirect"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="SGOT IU / ml" id="standard-basic" value={SGOT} onChange={(e) => { handleInputBioChemistry(e, "SGOT"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="SGPT IU / ml" id="standard-basic" value={SGPT} onChange={(e) => { handleInputBioChemistry(e, "SGPT"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="Alkaline phosphatase IU / ml " id="standard-basic" value={AlkalinePhosphatase} onChange={(e) => { handleInputBioChemistry(e, "AlkalinePhosphatase"); }} fullWidth variant="standard" />
                                                                </Col>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        kidenyFunctionTestChecked && (
                                                            <>
                                                                <Col sm="4">
                                                                    <TextField label="Serum creatinine	mg / dl	" id="standard-basic" value={SerumCreatinine} onChange={(e) => { handleInputBioChemistry(e, "SerumCreatinine"); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="Blood Urea Nitrogen mg / dl" id="standard-basic" value={bun} onChange={(e) => { handleInputBioChemistry(e, "bun"); }} fullWidth variant="standard" />
                                                                </Col>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        gluchecked && (
                                                            <Col sm="4">
                                                                <TextField label="Serum glucose mg/ " id="standard-basic" value={glu} onChange={(e) => { handleInputBioChemistry(e, "glu"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        MethaemoglobinChecked && (
                                                            <Col sm="4">
                                                                <TextField label="Serum methaemoglobin" id="standard-basic" value={Methaemoglobin} onChange={(e) => { handleInputBioChemistry(e, "Methaemoglobin"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        CRPChecked && (
                                                            <Col sm="4">
                                                                <TextField label="Serum calcium reactive protein (CRP) " id="standard-basic" value={CRP} onChange={(e) => { handleInputBioChemistry(e, "CRP"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }

                                                    {
                                                        BioOthersChecked && (
                                                            <Col sm="4">
                                                                <TextField label="Others" id="standard-basic" value={BioOthers} onChange={(e) => { setBioOthers(e.target.value); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }


                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(1) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveBioChemistry}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { printHeamtoCytology(); }}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(3) }}>Next</button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 3 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={glId} onChange={(e) => { handleInputGI(e, "glId"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={glPatId} onChange={(e) => { handleInputGI(e, "glPatId"); }} fullWidth variant="standard" />
                                                    </Col> */}


                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" >
                                                        <input type='checkbox' className='ms-2' onClick={(e) => { allCheck3(e.target.checked) }} /> All
                                                        <Row className='ms-1'>
                                                            <Col xxl="4" xl="4" lg="4" md="4" sm="4" xs="4">
                                                                <input type='checkbox' checked={glParatismchecked} onChange={(e) => { setGlParatismchecked(e.target.checked) }} /> GI parasitism (Faecal Examination)
                                                            </Col>
                                                            <Col xxl="3" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={fsPositiveForchecked} onChange={(e) => { setFsPositiveForchecked(e.target.checked) }} /> fs Positive For
                                                            </Col>
                                                            {/* <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={facelSamplechecked} onChange={(e) => { setFacelSamplechecked(e.target.checked) }} /> facel Sample
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={nematodeschecked} onChange={(e) => { setNematodeschecked(e.target.checked) }} /> nema todes
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={tramatodeschecked} onChange={(e) => { setTramatodeschecked(e.target.checked) }} /> trama todes
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={cystodeschecked} onChange={(e) => { setCystodeschecked(e.target.checked) }} /> cystodes
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={epgGtchecked} onChange={(e) => { setEpgGtchecked(e.target.checked) }} /> epg-Gt
                                                            </Col> */}
                                                        </Row>
                                                    </Col>

                                                    {/* {
                                                        pdfformatGi == true ?

                                                            <div ref={heamtocytology}>
                                                                <div className='d-flex justify-content-center mt-5'>
                                                                    <h1 style={{ BorderBottom: "2px dashed black" }}>GI Parasitism</h1>
                                                                </div>
                                                                <div className='d-flex justify-content-around'>
                                                                    <div>
                                                                        <table>
                                                                            {Object?.keys(giList)?.map((key) => (
                                                                                <tr key={key}>
                                                                                    <td>{key}</td>
                                                                                    <td className='px-3'>:</td>
                                                                                    <td>{giList[key]}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <> */}
                                                    {
                                                        glParatismchecked && (
                                                            <>
                                                                <Col sm="4">
                                                                    {/* <FormControl variant="standard" fullWidth>
                                                                        <Searcable
                                                                            placeholder="GI Parasitism"
                                                                            options={glParatismList}
                                                                            onChange={(selectedOption) => setGlParatism(selectedOption.sampletype)}
                                                                            value={glParatism}
                                                                            labelKey="sampletype"
                                                                            valueKey="id"
                                                                        />
                                                                    </FormControl> */}
                                                                    <TextField label="Parasite Name" id="standard-basic" value={glParatism} onChange={(e) => { setGlParatism(e.target.value); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Occult Blood</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={OccultBlood} onChange={(e) => { setOccultBlood(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Present" >Present</MenuItem>
                                                                            <MenuItem className="drop_value" value="Absent" >Absent</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Pus cells</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={PusCells1} onChange={(e) => { setPusCells1(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Present" >Present</MenuItem>
                                                                            <MenuItem className="drop_value" value="Absent" >Absent</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Mucous Cells</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={mucousCells} onChange={(e) => { setMucousCells(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Present" >Present</MenuItem>
                                                                            <MenuItem className="drop_value" value="Absent" >Absent</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Coccidia</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={coccidia} onChange={(e) => { setCoccidia(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Present" >Present</MenuItem>
                                                                            <MenuItem className="drop_value" value="Absent" >Absent</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Balantidium coli </InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={BalantidiumColi} onChange={(e) => { setBalantidiumColi(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="Present" >Present</MenuItem>
                                                                            <MenuItem className="drop_value" value="Absent" >Absent</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        fsPositiveForchecked && (
                                                            <Col sm="4" className='pt-2'>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <Searcable
                                                                        placeholder="FS-Positive for"
                                                                        options={fsPositiveForListD}
                                                                        // onChange={(selectedOption) => setFsPositiveFor(selectedOption)}
                                                                        onChange={(selectedOption) => {
                                                                            // console.log("Selected Option:", selectedOption.value.toString());
                                                                            setFsPositiveFor(selectedOption ? selectedOption.value : null);
                                                                        }}
                                                                        // options={yearList.map((item) => ({ value: item.id, label: item.YearName }))}
                                                                        value={fsPositiveFor}
                                                                        labelKey="sampletype"
                                                                        valueKey="id"
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        )
                                                        // }</>
                                                    }

                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(2) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { SaveGiparasitism() }}>Save</button>
                                                    </Col>
                                                    {/* <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { printHeamtoCytology(); setPdfFormatGi(true) }}>Print</button>
                                                    </Col> */}
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}

                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(4) }}>Next</button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 4 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={urinalysisId} onChange={(e) => { handleInputurinalysis(e, "urinalysisId"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={urinalysisPatId} onChange={(e) => { handleInputurinalysis(e, "urinalysisPatId"); }} fullWidth variant="standard" />
                                                    </Col> */}

                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" >
                                                        <input type='checkbox' className='ms-2' onClick={(e) => { allCheck4(e.target.checked) }} /> All
                                                        <Row className='ms-1'>
                                                            <Col xxl="3" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={GeneralUrineAnalysisChecked} onChange={(e) => { setGeneralUrineAnalysisChecked(e.target.checked) }} /> General urine analysis
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={UrineChemistryChecked} onChange={(e) => { setUrineChemistryChecked(e.target.checked) }} /> Urine chemistry
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={UrineMicroscopyChecked} onChange={(e) => { setUrineMicroscopyChecked(e.target.checked) }} /> Urine microscopy
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <div ref={heamtocytology}>
                                                        <div className='d-flex justify-content-center mt-5'>
                                                            <h1 style={{ BorderBottom: "2px dashed black" }}>Urin Analysis</h1>
                                                        </div>
                                                        <div className='d-flex justify-content-around'>
                                                            <div>
                                                                <table>
                                                                    {Object?.keys(urinList)?.map((key) => (
                                                                        <tr key={key}>
                                                                            <td>{key}</td>
                                                                            <td className='px-3'>:</td>
                                                                            <td>{urinList[key]}</td>
                                                                        </tr>
                                                                    ))}
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {GeneralUrineAnalysisChecked && (
                                                        <>

                                                            <Col sm="4">
                                                                <TextField
                                                                    label="Specific gravity"
                                                                    id="standard-basic"
                                                                    value={spGravity}
                                                                    onChange={handleInputChange}
                                                                    fullWidth
                                                                    variant="standard"
                                                                />
                                                            </Col>
                                                            <Col sm="4">
                                                                <TextField label="pH" id="standard-basic" value={ph} onChange={(e) => { setPh(e.target.value); }} fullWidth variant="standard" />
                                                            </Col>
                                                        </>
                                                    )}

                                                    {UrineChemistryChecked && (
                                                        <>
                                                            <Col sm="4">
                                                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>   Glucose</InputLabel>
                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={glucose} onChange={(e) => { setGlucose(e.target.value); }}>
                                                                        <MenuItem className="drop_value" value="+" >+</MenuItem>
                                                                        <MenuItem className="drop_value" value="++" >++</MenuItem>
                                                                        <MenuItem className="drop_value" value="+++" >+++</MenuItem>
                                                                        <MenuItem className="drop_value" value="++++" >++++</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                            <Col sm="4">
                                                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Protein</InputLabel>
                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={protien} onChange={(e) => { setProtien(e.target.value); }}>
                                                                        <MenuItem className="drop_value" value="+" >+</MenuItem>
                                                                        <MenuItem className="drop_value" value="++" >++</MenuItem>
                                                                        <MenuItem className="drop_value" value="+++" >+++</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                            <Col sm="4">
                                                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>   Ketone </InputLabel>
                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={ketan} onChange={(e) => { setKetan(e.target.value); }}>
                                                                        <MenuItem className="drop_value" value="Present" >Present</MenuItem>
                                                                        <MenuItem className="drop_value" value="Absent" >Absent</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                            <Col sm="4">
                                                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>   Occult Blood </InputLabel>
                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={blood} onChange={(e) => { setBlood(e.target.value); }}>
                                                                        <MenuItem className="drop_value" value="Present" >Present</MenuItem>
                                                                        <MenuItem className="drop_value" value="Absent" >Absent</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                        </>
                                                    )}

                                                    {UrineMicroscopyChecked && (
                                                        <>
                                                            <Col sm="4">
                                                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>   Pus cells</InputLabel>
                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={PusCells} onChange={(e) => { setPusCells(e.target.value); }}>

                                                                        <MenuItem className="drop_value" value="+" >+</MenuItem>
                                                                        <MenuItem className="drop_value" value="++" >++</MenuItem>
                                                                        <MenuItem className="drop_value" value="+++" >+++</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                            <Col sm="4">
                                                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>   Crystals </InputLabel>
                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={Crystals} onChange={(e) => { setCrystals(e.target.value); }}>

                                                                        <MenuItem className="drop_value" value="+" >+</MenuItem>
                                                                        <MenuItem className="drop_value" value="++" >++</MenuItem>
                                                                        <MenuItem className="drop_value" value="+++" >+++</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                            <Col sm="4">
                                                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>   Bacteria </InputLabel>
                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={Bacteria} onChange={(e) => { setBacteria(e.target.value); }}>

                                                                        <MenuItem className="drop_value" value="+" >+</MenuItem>
                                                                        <MenuItem className="drop_value" value="++" >++</MenuItem>
                                                                        <MenuItem className="drop_value" value="+++" >+++</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                            <Col sm="4">
                                                                <TextField label="Comments" id="standard-basic" value={Comments} onChange={(e) => { setComments(e.target.value); }} fullWidth variant="standard" />
                                                            </Col>
                                                        </>
                                                    )}


                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(3) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveUrinalysis}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { printHeamtoCytology(); }}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(5) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 5 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" >
                                                        <input type='checkbox' className='ms-2' onClick={(e) => { allCheck5(e.target.checked) }} /> All
                                                        <Row className='ms-1'>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={RBPTChecked} onChange={(e) => { setRBPTChecked(e.target.checked) }} /> Brucella RBPT
                                                            </Col>

                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={STAChecked} onChange={(e) => { setSTAChecked(e.target.checked) }} /> Brucella STA
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={ELISAChecked} onChange={(e) => { setELISAChecked(e.target.checked) }} /> Brucella ELISA
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={TuberculosisChecked} onChange={(e) => { setTuberculosisChecked(e.target.checked) }} /> Tuberculosis (Tuberculin skin test)
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input
                                                                    type='checkbox'
                                                                    checked={TuberculosisELISAChecked}
                                                                    onChange={(e) => {
                                                                        setTuberculosisELISAChecked(e.target.checked);
                                                                    }}
                                                                />
                                                                &nbsp;Tuberculosis (ELISA)
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input
                                                                    type='checkbox'
                                                                    checked={ParatuberculosisChecked}
                                                                    onChange={(e) => {
                                                                        setParatuberculosisChecked(e.target.checked);
                                                                    }}
                                                                />
                                                                &nbsp;Paratuberculosis (Para-tuberculin skin test)
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={IBRChecked} onChange={(e) => { setIBRChecked(e.target.checked) }} /> Infectious Bovine rhinotracheitis (IBR) ELISA
                                                            </Col>
                                                        </Row>
                                                    </Col>




                                                    {
                                                        RBPTChecked && (
                                                            <Col sm="4">
                                                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}> Brucella RBPT</InputLabel>
                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={RBPT} onChange={(e) => { setRBPT(e.target.value); }} >
                                                                        <MenuItem className="drop_value" value="0" >Positive</MenuItem>
                                                                        <MenuItem className="drop_value" value="1" >Negative</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        STAChecked && (
                                                            <Col sm="4">
                                                                <TextField label="Brucella STAT" id="standard-basic" value={STA} onChange={(e) => { handleInputImmunology(e, "STAT"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        ELISAChecked && (
                                                            <Col sm="4">
                                                                <TextField label="Brucella ELISA" id="standard-basic" value={ELISA} onChange={(e) => { handleInputImmunology(e, "ELISA"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        TuberculosisChecked && (
                                                            <>
                                                                <Col sm="4">
                                                                    <TextField label="Tuberculosis (Tuberculin skin test)" id="standard-basic" value={Tuberculosis1} onChange={(e) => { handleInputImmunology(e, "Tuberculosis"); }} fullWidth variant="standard" />
                                                                </Col>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        TuberculosisELISAChecked && (
                                                            <>
                                                                <Col sm="4">
                                                                    <TextField label="Tuberculosis (ELISA)" id="standard-basic" value={TuberculosisELISA} onChange={(e) => { handleInputImmunology(e, "TuberculosisELISA"); }} fullWidth variant="standard" />
                                                                </Col>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        ParatuberculosisChecked && (
                                                            <>
                                                                <Col sm="4">
                                                                    <TextField label="Paratuberculosis (Para-tuberculin skin test)" id="standard-basic" value={Paratuberculosis} onChange={(e) => { handleInputImmunology(e, "Paratuberculosis"); }} fullWidth variant="standard" />
                                                                </Col>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        IBRChecked && (
                                                            <>
                                                                <Col sm="4">
                                                                    <TextField label="Infectious Bovine rhinotracheitis (IBR) ELISA " id="standard-basic" value={IBR} onChange={(e) => { handleInputImmunology(e, "IBR"); }} fullWidth variant="standard" />
                                                                </Col>
                                                            </>
                                                        )
                                                    }




                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(4) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveImmunology}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { printHeamtoCytology(); }}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(6) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 6 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>

                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" >
                                                        <input type='checkbox' className='ms-2' onClick={(e) => { allCheck6(e.target.checked) }} /> All
                                                        <Row className='ms-1'>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={milkPhisicalChecked} onChange={(e) => { setMilPhisicalChecked(e.target.checked) }} /> Milk physical
                                                            </Col>

                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MilkSubclinicalmastitisChecked} onChange={(e) => { setMilkSubclinicalmastitisChecked(e.target.checked) }} /> Milk Subclinical mastitis
                                                            </Col>

                                                            {/* <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MilkCMTChecked} onChange={(e) => { setMilkCMTChecked(e.target.checked) }} /> Milk CMT
                                                            </Col> */}
                                                            {/* <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MilkCultureSensitivityChecked} onChange={(e) => { setMilkCultureSensitivityChecked(e.target.checked) }} /> Milk Culture Sensitivity
                                                            </Col> */}
                                                            {/* <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MilkPCRChecked} onChange={(e) => { setMilkPCRChecked(e.target.checked) }} /> Milk PCR
                                                            </Col> */}
                                                        </Row>
                                                    </Col>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={scmId} onChange={(e) => { setScmId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={scmPatIdNo} onChange={(e) => { setScmPatIdNo(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}



                                                    {
                                                        milkPhisicalChecked && (
                                                            <Col sm="4">
                                                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Colour </InputLabel>
                                                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={colorM} onChange={(e) => { setColorM(e.target.value); }}>
                                                                        <MenuItem className="drop_value" value="yellow icteric" >yellow icteric</MenuItem>
                                                                        <MenuItem className="drop_value" value="blood tinged" >blood tinged</MenuItem>
                                                                        <MenuItem className="drop_value" value="pus-cloudy" >pus-cloudy</MenuItem>
                                                                        <MenuItem className="drop_value" value="other abnormal" >other abnormal</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        MilkSubclinicalmastitisChecked && (
                                                            <>
                                                                <Col sm="4">
                                                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>CMT</InputLabel>
                                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={scmSampleType} onChange={(e) => { setScmSampleType(e.target.value); }}>
                                                                            <MenuItem className="drop_value" value="+" >+</MenuItem>
                                                                            <MenuItem className="drop_value" value="++" >++</MenuItem>
                                                                            <MenuItem className="drop_value" value="+++" >+++</MenuItem>
                                                                            <MenuItem className="drop_value" value="++++" >++++</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="SCC" ids="standard-basic" value={SCC} onChange={(e) => {
                                                                        setSCC(e.target.value);
                                                                    }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="EC" ids="standard-basic" value={EC} onChange={(e) => { setEC(e.target.value); }} fullWidth variant="standard" />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <TextField label="Mastrip" ids="standard-basic" value={mastrip} onChange={(e) => { setMastrip(e.target.value); }} fullWidth variant="standard" />
                                                                </Col>
                                                            </>
                                                        )
                                                    }



                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(5) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveScm}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { printHeamtoCytology(); }}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(7) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 7 &&
                                            <Container>

                                                <Row className='mt-4 p-3'>


                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ABS-Sample</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={absSample} onChange={(e) => { setAbsSample(e.target.value); }}>
                                                                {/* {absSampleList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ABS smaple quality</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={absSampleQuality} onChange={(e) => { setAbsSampleQuality(e.target.value); }}>
                                                                {/* {absSampleList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Pathogen ID</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pathogenId} onChange={(e) => { setPathogenId(e.target.value); }}>
                                                                {/* {pathogenList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Antibiotics</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={antibiotics} onChange={(e) => { setAntibiotics(e.target.value); }}>
                                                                {/* {antibioticsList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>BK-DDMethod</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={bkMethod} onChange={(e) => { setBkMethod(e.target.value); }}>
                                                                {/* {bkMethodList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>

                                                </Row>

                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(6) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveAntigram}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { printHeamtoCytology(); }}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(8) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 8 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Special Path</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={specialPath} onChange={(e) => { setSpecialPath(e.target.value); }}>
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps2} onChange={(e) => { setSps2(e.target.value); }}>
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-3</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps3} onChange={(e) => { setSps3(e.target.value); }}>
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-4</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps4} onChange={(e) => { setSps4(e.target.value); }}>
                                                                {/* {sps4List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-5</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps5} onChange={(e) => { setSps5(e.target.value); }}>
                                                                {/* {sps5List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-6</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps6} onChange={(e) => { setSps6(e.target.value); }}>
                                                                {/* {sps6List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SP-Resu</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spResu} onChange={(e) => { setSpResu(e.target.value); }}>
                                                                {/* {spResuList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr2} onChange={(e) => { setSpr2(e.target.value); }}>
                                                                {/* {spr2List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-3</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr3} onChange={(e) => { setSpr3(e.target.value); }}>
                                                                {/* {spr3List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-4</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr4} onChange={(e) => { setSpr4(e.target.value); }}>
                                                                {/* {spr4List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-5</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr5} onChange={(e) => { setSpr5(e.target.value); }}>
                                                                {/* {spr5List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-6</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr6} onChange={(e) => { setSpr6(e.target.value); }}>
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(7) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveSP}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(9) }}>Next</button>
                                                    </Col>

                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 9 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={toxicologyId} onChange={(e) => { setToxicologyId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={toxicologyPatientId} onChange={(e) => { setToxicologyPatientId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Sample-1</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txSample1} onChange={(e) => { setTxSample1(e.target.value); }}>
                                                                {/* {txSample1List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Sample-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txSample2} onChange={(e) => { setTxSample2(e.target.value); }}>
                                                                {/* {txSample2List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Test-1</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txTest1} onChange={(e) => { setTxTest1(e.target.value); }}>
                                                                {/* {txTest1List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Test-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txTest2} onChange={(e) => { setTxTest2(e.target.value); }}>
                                                                {/* {txTest2List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Result-1</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txResult1} onChange={(e) => { setTxResult1(e.target.value); }}>
                                                                {/* {txTestResult1List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Result-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txResult2} onChange={(e) => { setTxResult2(e.target.value); }}>
                                                                {/* {txTestResult2list.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(8) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveToxicology}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(10) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 10 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>

                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={milkId} onChange={(e) => { setMilkId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={milkPatientId} onChange={(e) => { setMilkPatientId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <TextField label="Lactose%" id="standard-basic" value={Lactose} onChange={(e) => { setLactose(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    {/* <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Milk sample type</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={milkSampleType} onChange={(e) => { setMilkSampleType(e.target.value); }}>
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <TextField label="Fat%" id="standard-basic" value={fat} onChange={(e) => { setFat(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Protein%" id="standard-basic" value={milkProtein} onChange={(e) => { setMilkProtein(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="SNF%" id="standard-basic" value={snf} onChange={(e) => { setSnf(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Total Solids%" id="standard-basic" value={totlSolid} onChange={(e) => { setTotlSolid(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Ketone</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={Milkketone} onChange={(e) => { setMilkketone(e.target.value); }}>
                                                                <MenuItem className="drop_value" value="1" >Present</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >Absent</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    {/* <Col sm="4">
                                                        <TextField label="MUN" id="standard-basic" value={MUN} onChange={(e) => { setMUN(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <TextField label="Specific Gravity" id="standard-basic" value={MilkSpGravity} onChange={(e) => { setMilkSpGravity(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    {/* <Col sm="4">
                                                        <TextField label="TS%" id="standard-basic" value={ts} onChange={(e) => { setTs(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    {/* <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sample condition</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sampleCondition} onChange={(e) => { setSampleCondition(e.target.value); }}>
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col> */}
                                                    {/* <Col sm="4">
                                                        <TextField label="Density%" id="standard-basic" value={density} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <TextField label="M-Sp Gravity" id="standard-basic" value={density} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>

                                                    {/* <Col sm="4">
                                                        <TextField label="FP-C" id="standard-basic" value={fpc} onChange={(e) => { setFpc(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="EC ms/cm" id="standard-basic" value={ecMsCm} onChange={(e) => { setEcMsCm(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <TextField label="M-pH" id="standard-basic" value={mph} onChange={(e) => { setMph(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Electric Conducivity" id="standard-basic" value={eConductivity} onChange={(e) => { seteConductivity(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Temperature-C" id="standard-basic" value={temperatureC} onChange={(e) => { setTemperatureC(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>

                                                    <Col sm="4">
                                                        <TextField label="MUN mg%" id="standard-basic" value={mun} onChange={(e) => { setMun(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="M-Citrate mg%" id="standard-basic" value={mCitrate} onChange={(e) => { setMCitrate(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(9) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveMilkChemistry}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(11) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 11 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={modTechId} onChange={(e) => { setModTechId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={modPatientId} onChange={(e) => { setModPatientId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Mod Techniques</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={modTechniques} onChange={(e) => { setModTechniques(e.target.value); }}>
                                                                {/* {modTechniquesList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>MT-Result</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={mtResult} onChange={(e) => { setMtResult(e.target.value); }}>
                                                                {/* {mtResultList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(10) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveModTech}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(12) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 12 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={remarkId} onChange={(e) => { setRemarkId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={remarkPatientId} onChange={(e) => { setRemarkPatientId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Remarks</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={remarkModTechnniques} onChange={(e) => { setRemarkTechniques(e.target.value); }}>
                                                                {/* {remarkModtechniquesList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(11) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveRemarks}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>

                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modaladd} onHide={() => setmodaladd(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Add New Registration</Modal.Title>
                    <img src={closeButton} onClick={() => setmodaladd(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='align-items-end'>
                            {/* <Col sm="4">
                                <TextField label="Tag ID No" id="standard-basic" value={tagIdNo} onChange={(e) => { handleTagIdNoChange(e, "tagIdNo") }} fullWidth variant="standard" />
                            </Col> */}
                            <Col sm="4">
                                <TextField label="Tag ID No" id="standard-basic" value={newTagId} onChange={(e) => { handleTagIdNoChange(e, "newTagId") }} fullWidth variant="standard" />
                            </Col>

                            <Col sm="4">
                                <TextField label="Pat ID No" id="standard-basic" value={patId} onChange={(e) => { handleTagIdNoChange(e, "patId") }} fullWidth variant="standard" />
                            </Col>
                            <Col sm="4">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Lab Name</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={labName} onChange={(e) => { setLabName(e.target.value); }}>
                                        {
                                            labList.map((i) => {
                                                return (
                                                    <MenuItem className="drop_value" value={i.id} >{i.name}</MenuItem>
                                                )
                                            })

                                        }
                                    </Select>
                                </FormControl>
                                {/* <Searcable
                                    placeholder="Lab Name"
                                    options={labList}
                                    onChange={(selectedOption) => { setLabName(selectedOption); }}
                                    value={labName}
                                    labelKey="name"
                                    valueKey="id"
                                /> */}
                            </Col>
                            <Col sm="4">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Ref By</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={refBy} onChange={(e) => { setRefBy(e.target.value); }}>
                                        {
                                            refList.map((i) => {
                                                return (
                                                    <MenuItem className="drop_value" value={i.id} style={{ height: "50px" }} >{i.Name}</MenuItem>
                                                )
                                            })

                                        }
                                    </Select>
                                </FormControl>
                                {/* <Searcable
                                    placeholder="Ref By"
                                    options={refList}
                                    onChange={(selectedOption) => { setRefBy(selectedOption); }}
                                    value={refBy}
                                    labelKey="Name"
                                    valueKey="id"
                                /> */}
                            </Col>
                            <Col sm="4">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Ref Institute</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={refInstitute} onChange={(e) => { setRefInstitute(e.target.value); }}>
                                        {
                                            refInstituteList.map((i) => {
                                                return (
                                                    <MenuItem className="drop_value" value={i.id} >{i.name}</MenuItem>
                                                )
                                            })

                                        }
                                    </Select>
                                </FormControl>
                                {/* <Searcable
                                    placeholder="Ref Institute"
                                    options={refInstituteList}
                                    onChange={(selectedOption) => { setRefInstitute(selectedOption); }}
                                    value={refInstitute}
                                    labelKey="name"
                                    valueKey="id"
                                /> */}
                            </Col>
                            <Col sm="4">

                                <div>
                                    <sub className="mb-0 pb-0">Date</sub>
                                    <input type="date" className="dateSelect_bg" id="date-1" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                </div>

                            </Col>
                            <Col sm="4">

                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>District</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={district} onChange={(e) => { setDistrict(e.target.value); }}>
                                        {
                                            districtList.map((i) => {
                                                return (
                                                    <MenuItem className="drop_value" value={i.DIST} >{i.DIST}</MenuItem>
                                                )
                                            })

                                        }

                                    </Select>
                                </FormControl>
                                {/* <Searcable
                                    placeholder="District"
                                    options={districtList}
                                    onChange={(selectedOption) => { setDistrict(selectedOption); }}
                                    value={district}
                                    labelKey="DIST"
                                    valueKey="DIST"
                                /> */}
                            </Col>
                            <Col sm="4">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Taluka</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={taluka} onChange={(e) => { setTaluka(e.target.value); }}>
                                        {
                                            talukaList.map((i) => {
                                                return (
                                                    <MenuItem className="drop_value" value={i.id} >{i.taluka}</MenuItem>
                                                )
                                            })

                                        }
                                    </Select>
                                </FormControl>
                                {/* <Searcable
                                    placeholder="Taluka"
                                    options={talukaList}
                                    onChange={(selectedOption) => { setTaluka(selectedOption); }}
                                    value={taluka}
                                    labelKey="taluka"
                                    valueKey="id"
                                /> */}
                            </Col>
                            <Col sm="4">
                                <TextField label="Village" id="standard-basic" value={village} onChange={(e) => { handleAlphabetChange(e, "village") }} fullWidth variant="standard" />
                            </Col>
                            <Col sm="4">
                                <TextField label="Owner" id="standard-basic" value={owner} onChange={(e) => { handleAlphabetChange(e, "owner") }} fullWidth variant="standard" />
                            </Col>
                            <Col sm="4">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Animal Species</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={animalSpeices} onChange={(e) => { setAnimalSpeices(e.target.value); }}>
                                        {
                                            animalSpeicesData.map((i) => {
                                                return (
                                                    <MenuItem className="drop_value" value={i.Name} >{i.Name}</MenuItem>
                                                )
                                            })

                                        }
                                    </Select>
                                </FormControl>
                                {/* <Searcable
                                    placeholder="Animal Species"
                                    options={animalSpeicesData}
                                    onChange={(selectedOption) => { setAnimalSpeices(selectedOption); }}
                                    value={animalSpeices}
                                    labelKey="Name"
                                    valueKey="ID"
                                /> */}
                            </Col>

                            <Col sm="4">
                                <TextField label="Animal" id="standard-basic" value={animal} onChange={(e) => { handleAlphabetChange(e, "animal") }} fullWidth variant="standard" />
                            </Col>
                            <Col sm="4">
                                <div className='multiselect d-flex justify-content-between'>
                                    <div className="multi align-items-start">
                                        <Multiselect
                                            placeholder="Sample"
                                            isObject={false}
                                            onRemove={(e) => { setSample(e) }}
                                            onChange={(selectedOption) => { setSample(selectedOption.join(',')); }}
                                            options={sampleList.map(item => (item.name))}
                                            onSelect={(e) => { setSample(e) }}
                                            showCheckbox
                                        />
                                        <div className="i-down align-items-end"><SlArrowDown /></div>
                                    </div>
                                </div>

                            </Col>
                            <Col sm="4">
                                <div className='multiselect d-flex justify-content-between'>
                                    <div className="multi align-items-start">
                                        <Multiselect
                                            placeholder="Test"
                                            isObject={false}
                                            onRemove={(e) => { setTest(e) }}
                                            onSelect={(e) => { setTest(e) }}
                                            onChange={(selectedOption) => { setTest(selectedOption.join(',')); }}
                                            options={testList.map(item => item.name)}
                                            showCheckbox
                                        />
                                        <div className="i-down align-items-end"><SlArrowDown /></div>
                                    </div>

                                </div>

                            </Col>
                            <Col sm="4">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Unfit sample</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={unfitSample} onChange={(e) => { setUnfitSample(e.target.value); }}>
                                        {
                                            unfitSampleList.map((i) => {
                                                return (
                                                    <MenuItem className="drop_value" value={i.id} >{i.name}</MenuItem>
                                                )
                                            })

                                        }
                                    </Select>
                                </FormControl>
                                {/* <Searcable
                                    placeholder="Unfit sample"
                                    options={unfitSampleList}
                                    onChange={(selectedOption) => { setUnfitSample(selectedOption); }}
                                    value={unfitSample}
                                    labelKey="name"
                                    valueKey="id"
                                /> */}
                            </Col>
                            <Col sm="4">
                                <TextField label="Ref Cell No" id="standard-basic" value={refCellNo} onChange={(e) => { handleTagIdNoChange(e, "refCellNo") }} fullWidth variant="standard" />
                            </Col>
                            <Col sm="4">
                                {/* <Select
                                    placeholder="Tested By"
                                    onChange={(selectedOption) => { setTestedBy(selectedOption.value); }}
                                    options={testedByList.map((item) => ({ value: item.id, label: item.Name }))}
                                /> */}
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tested By</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={testedBy} onChange={(e) => { setTestedBy(e.target.value); }}>
                                        {
                                            testedByList.map((i) => {
                                                return (
                                                    <MenuItem className="drop_value" value={i.id} >{i.Name}</MenuItem>
                                                )
                                            })

                                        }
                                    </Select>
                                </FormControl>
                                {/* <Searcable
                                    placeholder="Tested By"
                                    options={testedByList}
                                    onChange={(selectedOption) => { setTestedBy(selectedOption); }}
                                    value={testedBy}
                                    labelKey="Name"
                                    valueKey="id"
                                /> */}
                            </Col>

                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { Save(); setmodaladd(false) }}>Save</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setmodaladd(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal >

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

        </>
    )
};