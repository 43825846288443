import { Autocomplete, Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
// import Select from 'react-select';
import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import { downloadExcel } from "react-export-table-to-excel";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Searcable from '../../Componet/Dropdown/Searcable';
import BackBtn from '../../Componet/Button/BackBtn';
const { SocietyApi, ZoneApi, Herd } = API_URLS;


const label = { inputProps: { "aria-label": "Switch demo" } };

const SocietyLot = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    async function getData(credentials) {
        return
    }
    async function addData(credentials) {
        return fetch(SocietyApi.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function updateData(credentials) {
        return fetch(SocietyApi.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function updateData(credentials) {
        return fetch(SocietyApi.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const [route, setRoute] = useState("")
    const [route1, setRoute1] = useState("")
    const [sapCode, setSapCode] = useState("")
    const [lotCode, setLotCode] = useState("")
    const [name, setName] = useState("")
    const [longitude, setLongitude] = useState("")
    const [fromTime, setFromTime] = useState("")
    const [toTime, setToTime] = useState("")
    const [note, setNote] = useState("")
    const [search, setSearch] = useState("")
    const [week, setWeek] = useState("")
    const [village, setVillage] = useState(null)
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const [deleteId, setDeleteId] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false)
    const [editToggle, setEditToggle] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false)
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [editId, setEditId] = useState("")
    const [user, setUser] = useState("")


    const [societyList, setSocietyList] = useState([])
    const [weekDay, setWeekDay] = useState([{ value: "Monday", label: "Monday" }, { value: "Tuesday", label: "Tuesday" }, { value: "Wednesday", label: "Wednesday" }, , { value: "Thursday", label: "Thursday" }, { value: "Friday", label: "Friday" }, { value: "Saturday", label: "Saturday" }, { value: "Sunday", label: "Sunday" }])
    const [villageList, setVillageList] = useState([])
    const [herdList, setHerdList] = useState([])
    const [societyListData, setSocietyListData] = useState([])

    const societyGetData = async () => {
        // setIsLoading(true)
        if (societyListData.length > 0) {
            const results = societyListData.filter((d) => {
                console.log(d)
                return d.DCSSAPCode?.toLowerCase()?.startsWith(search.toLowerCase());
            });
            console.log(results)
            setSearchFilter(results);
        } else {
            setSearchFilter(societyListData);
        }
        console.log(searchFilter)


        // await fetch(`${SocietyApi.GetDetails}?offset=0&limit=&column_name=&sort_order=asc&search_term=${search}&company_filter=1&user=201&herd_filter=0`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`,
        //     },
        // })
        //     .then(response => {
        //         const contentLength = response.headers.get('content-length');
        //         let loaded = 0;
        //         return new Response(
        //             new ReadableStream({
        //                 start(controller) {
        //                     const reader = response.body.getReader();
        //                     read();
        //                     function read() {
        //                         reader.read()
        //                             .then((progressEvent) => {
        //                                 if (progressEvent.done) {
        //                                     controller.close();
        //                                     return;
        //                                 }
        //                                 loaded += progressEvent.value.byteLength;
        //                                 const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
        //                                 setProgressData(percentageComplete)
        //                                 controller.enqueue(progressEvent.value);
        //                                 read();
        //                             })
        //                     }
        //                 }
        //             })
        //         );
        //     })
        //     .then((res) => res.json())
        //     .then((result) => { setIsLoading(false); setSocietyList(result.data); setProgressData("0%"); })
    }




    const lotData = async () => {
        await fetch(`${SocietyApi.GetDetails}?offset=0&limit=&column_name=&sort_order=asc&search_term=${search}&company_filter=1&user=201&herd_filter=0`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setSocietyList(result.data); setProgressData("0%"); })
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        // societyGetData()
        // lotData()
        formApi()
        getHerData()
        // getTabledata()
    }, [])

    const formApi = () => {
        //village:
        fetch(`${ZoneApi.VillageData}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setVillageList(result);
            });

        //week:
        fetch(`${Herd.HerdByName}?name=`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {

                // setWeekDay(result);
            });

    }

    const getHerData = async () => {
        await fetch(`${Herd.HerdByName}?name=`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then((result) => { setHerdList(result) })
    }
    const getTabledata = async (e) => {
        setRoute(e)
        await fetch(`${SocietyApi.GetDetails}?offset=0&limit=1000000&column_name=Name&sort_order=asc&search_term&company_filter=10&user=7&herd_filter=${e.value}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then((result) => { setSocietyList(result.data) })
    }


    const AddData = async () => {
        console.log({ id: editId, herd: route1, code: sapCode, name, isSuspended, Lotcode: lotCode, weekDay: weekDay, Village: village, lng: longitude, fromTime, toTime, note })
        if (editId) {
            const res = await updateData({ id: editId, herd: route1, code: sapCode, name, isSuspended, Lotcode: lotCode, weekDay: weekDay, Village: village.value, lng: longitude, fromTime, toTime, note });
            if (res) {
                setModalShow(false)
                toast.success("Lot Update Successfull")
                societyGetData()
            }
        }
        else {
            const res = await addData({ herd: route1, id: 0, code: sapCode, company: "1", name, isSuspended, Lotcode: lotCode, weekDay: weekDay.value, user: "201", Village: village.value, lat: "", lng: longitude, fromTime, toTime, note });
            if (res) {
                setModalShow(false)
                toast.success("Lot Save Successfull")
                societyGetData()
            }

        }
    }

    const Add = () => {
        setModalShow(true)
        setEditId("")
        setFromTime("")
        setToTime("")
        setRoute1("")
        setSapCode("")
        setRoute("")
        setIsSuspended(false)
        setLotCode("")
        setWeek("")
        setVillage("")
        setLongitude("")
        setName("")
        setNote("")
        setIsSuspended(false)
    }
    const updateFn = (e) => {
        setEditToggle(true);
        setEditId(e.id)
        setSapCode(e.DCSSAPCode)
        setName(e.Name);
        setRoute1(e.herd.id)
        setLongitude(e.Lng);
        setFromTime(e.FromTime)
        setVillage(e.Village)
        setToTime(e.ToTime)
        setLotCode(e.code)
        setWeekDay(e.WeekDay)
        setModalShow(true);
        setLongitude(e.Lng)
        setIsSuspended(e.IsSuspended)
        setNote(e.Note)
    };

    const deleteData = (id) => {
        fetch(`${SocietyApi.DelateData}?id=` + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                societyGetData();
                toast.success("Lot Deleted Successfull")
                setDeleteShow(false)
                setModalShow(false);
            });

    }


    const colum = [
        {
            name: "DCS Code No",
            selector: "DCSCodeNo",
            cell: (c) => (
                <>
                    <div style={{  paddingRight: "5px", width: "110px" , display:"flex" , justifyContent:"flex-end"  }}>{c["DCSCodeNo"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "DCS SAP Code",
            selector: "DCSSAPCode",
            cell: (c) => (
                <>
                    <div style={{  paddingRight: "5px", width: "110px" , display:"flex" , justifyContent:"flex-end" }}>{c["DCSSAPCode"]}</div>
                </>
            ),
            sortable: true
        },
        {
            name: "DCS Name",
            selector: "DCSName",
            cell: (c) => (
                <>
                  <div style={{  paddingLeft: "5px", width: "100%" , display:"flex" , justifyContent:"flex-start"  }}>{c["DCSName"]}</div>
                </>
              ),
            width: "170px",
            sortable: true
        },
        {
            name: "AH Center Code",
            selector: "AHCenterCode",
            cell: (c) => (
                <>
                  <div style={{  paddingRight: "5px", width: "110px" , display:"flex" , justifyContent:"flex-end" }}>{c["AHCenterCode"]}</div>
                </>
              ),
            sortable: true
        },
        {
            name: "Block (CS)",
            selector: "Block(CS)",
            cell: (c) => (
                <>
                  <div style={{  paddingRight: "5px", width: "110px" , display:"flex" , justifyContent:"flex-end" }}>{c["Block"]}</div>
                </>
              ),
            sortable: true
        },
        {
            name: "Taluka",
            selector: "Taluka",
            cell: (c) => (
                <>
                  <div style={{  paddingLeft: "5px", width: "100%" , display:"flex" , justifyContent:"flex-start"  }}>{c["Taluka"]}</div>
                </>
              ),
            sortable: true
        },
        {
            name: "District",
            selector: "District",
            cell: (c) => (
                <>
                  <div style={{   paddingLeft: "5px", width: "100%" , display:"flex" , justifyContent:"flex-start"  }}>{c["District"]}</div>
                </>
              ),
            sortable: true
        },
        {
            name: "Revenue Village Code",
            selector: "RevenueVillageCode",
            cell: (c) => (
                <>
                  <div style={{  paddingRight: "5px", width: "110px" , display:"flex" , justifyContent:"flex-end" }}>{c["RevenueVillageCode"]}</div>
                </>
              ),
            sortable: true
        },
        {
            name: "Revenue Village Name",
            selector: "RevenueVillageName",
            cell: (c) => (
                <>
                  <div style={{  paddingLeft: "5px", width: "100%" , display:"flex" , justifyContent:"flex-start" }}>{c["RevenueVillageName"]}</div>
                </>
              ),
            sortable: true
        },
        {
            name: "Milk Route Code",
            selector: "MilkRouteCode",
            cell: (c) => (
                <>
                  <div style={{  paddingRight: "5px", width: "110px" , display:"flex" , justifyContent:"flex-end" }}>{c["MilkRouteCode"]}</div>
                </>
              ),
            sortable: true
        },
        {
            name: "Milk Route Name",
            selector: "MilkRouteName",
            cell: (c) => (
                <>
                  <div style={{ paddingLeft: "5px", width: "100%" , display:"flex" , justifyContent:"flex-start"  }}>{c["MilkRouteName"]}</div>
                </>
              ),
            width: "150px",
            sortable: true
        },
        {
            name: "Contact person",
            selector: "",
            sortable: true
        },
        {
            name: "Is Suspended",
            selector: "IsSuspended",
            cell: (d) => <Switch {...label} defaultChecked={d.IsSuspended} onChange={(e) => { handleChange(e, d) }} />,
            sortable: true
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                    <img
                        src={remove}
                        onClick={() => { setDeleteShow(true); setDeleteId(e.id); setUser(e.Name) }}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
    ]


    const handleChange = async (e, d) => {
        setIsSuspended(e.target.checked)
        setIsLoading(true)
        await fetch(`${SocietyApi.SuspendApi}?id=${d.id}&deactivate=${e.target.checked}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { toast.success("Success suspended status changed. "); setIsLoading(false); setProgressData("0%"); })
    }
    const handleVillageChange = (selectedOption) => {
        setVillage(selectedOption ? selectedOption.Name : null);
    };
    const SearchFn = async (e) => {
        // const data = e.target.value;
        // if (search !== '') {
        //     const results = societyList.filter((d) => {
        //         return d.DCSSAPCode?.toLowerCase()?.startsWith(search.toLowerCase());
        //     });
        //     console.log(results)
        //     setSearchFilter(results);
        // } else {
        //     setSearchFilter(societyList);
        // }
        // setSearch(data);

        if (search !== "") {
            await fetch(`${SocietyApi.GetDetails}?offset=0&limit=100000&column_name=&sort_order=asc&search_term=${search}&company_filter=1&user=201&herd_filter=0`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then((res) => res.json())
                .then((result) => { setIsLoading(false); setSocietyList(result.data); setProgressData("0%"); })
        }
        else {
            await fetch(`${SocietyApi.GetDetails}?offset=0&limit=&column_name=&sort_order=asc&search_term=&company_filter=1&user=201&herd_filter=0`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then((res) => res.json())
                .then((result) => { setIsLoading(false); setSocietyList(result.data); setProgressData("0%"); })
        }
    }

    const handleDownloadExcel = () => {
        const header = []
        const opt = `Society Lot`;
        colum.map((e) => {
            header.push(e.name)
        })

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header,
                body: societyList
            }
        });
    }

    // const handleDownloadExcel = () => {
    //     const header = []
    //     const rowData = {};
    //     const opt = `Society Lot ${route}`;
    //     colum.forEach((e) => {
    //         header.push(e.name)
    //     })

    //     const body = societyList.map((item) => {
    //         header.forEach((key) => {
    //             rowData[key] = item[key] || "";
    //         });
    //         return rowData;
    //     });
    //     console.log(rowData)

    //     downloadExcel({
    //         fileName: opt,
    //         sheet: "data",
    //         tablePayload: {
    //             header: header,
    //             body: body
    //         }
    //     });
    //     console.log(header);
    // }


    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}> <img src={menu} width={20} /> </button>
                                    <h5 className="ms-4 mt-2">Master / lot</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-between p-2" id='heightOffsetStock'>
                                <Col sm="4" lg="2" xl="1" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add Lot/Society</button>
                                </Col>
                                <Col></Col>
                                <Col md="2" className='align-items-center jusify-content-end'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Herd/Route</InputLabel>
                                        <Select value={route} onChange={(e) => getTabledata(e.target.value)}>
                                            {herdList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Route"
                                            options={herdList}
                                            onChange={(selectedOption) => getTabledata(selectedOption)}
                                            value={route}
                                            labelKey="Name"
                                            valueKey="id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                </Col>
                                <Col sm="4" lg="2" xl="1" className="mt-2  align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={() => SearchFn()}>Search</button>
                                </Col>
                                <Col sm="4" lg="2" xl="1" className="mt-2  align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : societyList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {societyList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        {/* <Table columns={colum} data={searchFilter ? searchFilter : societyList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination /> */}
                                        <Table columns={colum} data={searchFilter ? searchFilter : societyList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        Delete {user} ?
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => deleteData(deleteId)}>Delete</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update lot" : "Add New lot"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='align-items-end'>
                            <Col xl="6">
                                {/* <FormControl variant="standard" fullWidth> */}
                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Herd/Route</InputLabel>
                                    <Select value={route} onChange={(e) => setRoute(e.target.value)}>
                                        {herdList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                {/* <Searcable
                                    placeholder="Herd/Route"
                                    options={herdList}
                                    onChange={(selectedOption) => setRoute1(selectedOption.value)}
                                    value={{ value: route1, label: herdList.find(item => item.id === route1)?.Name }}
                                    labelKey="Name"
                                    valueKey="id"
                                /> */}

                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <Searcable
                                        placeholder="Herd/Route"
                                        options={herdList}
                                        onChange={(selectedOption) => setRoute1(selectedOption.value)}
                                        value={route1.value}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>
                                {/* </FormControl> */}
                                {/* <TextField label="Herd/Route" id="standard-basic" value={route} onChange={(e) => { setRoute(e.target.value); }} data={societyList} fullWidth variant="standard" /> */}
                            </Col>
                            <Col xl="6">
                                <TextField label="SAP Code" value={sapCode} onChange={(e) => { setSapCode(e.target.value); }} id="standard-basic" data={societyList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="LotCode" id="standard-basic" value={lotCode} onChange={(e) => { setLotCode(e.target.value); }} data={societyList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Name" value={name} onChange={(e) => { setName(e.target.value) }} id="standard-basic" data={societyList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4 align-items-end" >
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }} > Week Day</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={week} onChange={(e) => { setWeek(e.target.value) }}>
                                        {weekDay.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Searcable
                                        placeholder="Week Day"
                                        options={weekDay}
                                        onChange={(selectedOption) => setWeek(selectedOption.value)}
                                        value={week}
                                        labelKey="label"
                                        valueKey="value"
                                    />
                                </FormControl>
                            </Col>
                            <Col xl="6" >
                                <FormControl variant="standard" fullWidth >

                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Village</InputLabel> */}
                                    {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={village} onChange={(e) => { setVillage(e.target.value) }}>
                                        {villageList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Searcable
                                        placeholder="Village"
                                        value={village}
                                        options={villageList}
                                        onChange={(e) => {
                                            setVillage(e);
                                        }}
                                        labelKey="Name"
                                        valueKey="ID"
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Longitude" value={longitude} onChange={(e) => { setLongitude(e.target.value) }} id="standard-basic" data={societyList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="From Time" value={fromTime} onChange={(e) => { setFromTime(e.target.value) }} id="standard-basic" data={societyList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="To Time" value={toTime} onChange={(e) => { setToTime(e.target.value) }} id="standard-basic" data={societyList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6" className='text-center'>
                                <Switch {...label} onChange={(e) => { setIsSuspended(e.target.checked) }} defaultChecked={isSuspended} />
                                Is Suspended : {isSuspended ? "Yes" : "No"}
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <TextField label="Note" value={note} onChange={(e) => { setNote(e.target.value) }} id="standard-basic" data={societyList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => AddData(e)}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>

    )
}

export default SocietyLot;