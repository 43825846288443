import React, { useState, useEffect } from "react";
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import { TextField } from '@mui/material';

import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';

import Table from "../../Componet/DataTable/Table";
import Dropdown from "../../Componet/Dropdown/Dropdown";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import Input from "../../Componet/InputFild/Input";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS } from "../../API/Api";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../Componet/Button/BackBtn";
const { DIAGNOSIS } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

export default function Treatment() {
    const navigate = useNavigate()

    const [id, setID] = useState("");
    const [search, setSearch] = useState("");

    const [show, setShow] = useState(false);
    const [treatShow, setTreatShow] = useState(false)
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); };
    const logOut = () => { navigate("/") }

    const [treatList, setTreatList] = useState([]);

    const addTreatFn = () => {
        setTreatShow(true);
    }

    const menuitm = ["1", "2", "3", "4", "5"];

    const columns = [
        {
            name: "Type",
            selector: row => row.Type,
            sortable: true
        },
        {
            name: "MedicineName",
            cell: (e) => (<>
                <Dropdown label="" menuList={menuitm} placeholder="Sire ID" />
            </>),
            sortable: true
        },
        {
            name: "BatchNo",
            cell: (e) => (<>
                <Dropdown label="" menuList={menuitm} placeholder="Sire ID" />
            </>),
            sortable: true
        },
        {
            name: "Route",
            selector: row => row.Route,
        },
        {
            name: "Dose",
            selector: row => row.Dose,
            sortable: true
        },
        {
            name: "FollowUp",
            cell: (e) => <>
                <Dropdown label="" menuList={menuitm} placeholder="Sire ID" />
            </>
        },
    ]

    const columnData = [
        {
            Type: "",
            Route: "",
            Dose: "",
        },
        {
            Type: "",
            Route: "",
            Dose: "",
        }
    ]

    const colom = [
        {
            name: "Tag Id",
            selector: "Tag Id",
            sortable: true
        },
        {
            name: "From Date",
            selector: "",
            sortable: true
        },
        {
            name: "To Date",
            selector: "",
            sortable: true
        },
        {
            name: "No. Treatment",
            selector: "",
            sortable: true
        },
        {
            name: "Complaint",
            selector: "",
            sortable: true
        },
        {
            name: "System Affected",
            selector: "",
            sortable: true
        },
        {
            name: "Diagnosls",
            selector: "",
            sortable: true
        },
        {
            name: "Doctor",
            selector: "Doctor",
            sortable: true
        },
    ]


    // https://dudhsagar.herdman.in/treatment/get-all

    return (
        <>

            <Container fluid>
                <Row>
                    {headerShow && (
                        <Col lg="2" className="hideNavbar">
                            <Sidenav />
                        </Col>
                    )}
                    <Col lg={headerShow ? "10" : "12"} className="header">
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Cattle Details / Treatment</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            {treatShow && (
                                <>
                                    <Row className="align-items-end justify-content-start">
                                        <Col xl="3" className="mt-3">
                                            <TextField label="ID No" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                        <Col xl="3" className="mt-3">
                                            <sub className="mb-0 pb-0">Date</sub>
                                            <input type="date" className="dateSelect" />
                                        </Col>
                                        <Col xl="3" className="mt-3">
                                            <TextField label="Complaint" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="3" className="mt-3">
                                            <Dropdown label="Doctor" menuList={menuitm} placeholder="Sire ID" />
                                        </Col>
                                        <Col xl="3" className="mt-3">
                                            <Dropdown label="System" menuList={menuitm} placeholder="Sire ID" />
                                        </Col>
                                        <Col xl="3" className="mt-3">
                                            <Dropdown label="Diagnosis" menuList={menuitm} placeholder="Sire ID" />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center justify-content-start">
                                        <Col xl="3" className="mt-3">
                                            <TextField label="Temp" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                        <Col xl="3" className="mt-3">
                                            <TextField label="Pulse" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                        <Col xl="3" className="mt-3">
                                            <TextField label="Respiration" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center justify-content-start">
                                        <Col xl="3" className="mt-3">
                                            <TextField label="Observation" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                        <Col xl="3" className="mt-3">
                                            <TextField label="Lab Test" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                        <Col xl="3" className="mt-3">
                                            <TextField label="Note" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center justify-content-start">
                                        <Col xl="3" className="mt-3">
                                            <TextField label="Treatment Cost" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                    </Row>
                                    <Row className="mt-4 align-items-center justify-content-start">
                                        <Col xl="8"><Table columns={columns} data={columnData} /></Col>
                                    </Row>
                                </>
                            )}

                            <Row className="align-items-center justify-content-between" id='heightOffsetStock'>
                                <Col xxl="1" md="3" xl="2" sm="3" xs="6" className="mt-2 d-flex">
                                    <button className="btn-blue-Animal" onClick={() => addTreatFn()}>Add Treatment</button>
                                </Col>
                                <Col xxl="2" md="3" sm="3" xs="6" className="mt-2">
                                    <Input label="Search" value={search} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col><Table columns={colom} data={treatList} /></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            {show && (
                <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>
            )}
        </>
    )
};
