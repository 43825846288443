import './App.css';
import './index.css';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './LoginFlow/Login';
import Overview from './Pages/Overview';

import CattleRegistration from './Pages/Cattle_Management/CattleRegistration';
import Deworming from "./Pages/Cattle_Management/Deworming";
import Production from "./Pages/Cattle_Management/Production";
import Reproduction from "./Pages/Cattle_Management/Reproduction";
import Vaccination from "./Pages/Cattle_Management/Vaccination";
import Weight from "./Pages/Cattle_Management/Weight";
import BreedingDetails from "./Pages/Cattle_Management/BreedingDetails";
import Treatment from './Pages/Cattle_Management/Treatment';

import Userdetails from "./Pages/User_Management/Userdetails";
import UserWiseLoginDetail from "./Pages/User_Management/UserWiseLoginDetail";
import UserProfile from './Pages/User_Management/UserProfile';


import Alarm from './Pages/Listing/Alarm';
import DailyActionPlan from './Pages/Listing/DailyActionPlan';

import AnimalWise from './Pages/MilkUnionReports/AnimalWise';
import HerdWise from './Pages/MilkUnionReports/HerdWise';
import Datewise from './Pages/MilkUnionReports/Datewise';
import CustomReport from './Pages/MilkUnionReports/CustomReport';

import SystemAffected from './Pages/Health/SystemAffected';
import Diagnosis from './Pages/Health/Diagnosis';
import FarmerComplaint from './Pages/Health/FarmerComplaint';

import RouteHerd from './Pages/Master/RouteHerd';
import CcCenter from './Pages/Master/CcCenter';
import ZoneSubCenter from './Pages/Master/ZoneSubCenter';
import SocietyLot from './Pages/Master/SocietyLot';
import OwnerFarmer from './Pages/Master/OwnerFarmer';
import Project from './Pages/Master/Project';
import Sire from './Pages/Master/Sire';
import Staff from './Pages/Master/Staff';
import Insurance from './Pages/Master/Insurance';
import Source from './Pages/Master/Source';
import ClafSchedule from './Pages/Master/ClafSchedule';
import SmsSetting from './Pages/Master/SmsSetting';
import MedicineRoute from './Pages/Master/MedicineRoute';
import DewormingType from './Pages/Master/DewormingType';
import VoPost from './Pages/Master/VoPost';
import VoCategory from './Pages/Master/VoCategory';
import Shift from './Pages/Master/Shift';
import ShiftAllocation from './Pages/Master/ShiftAllocation';

import Animal from './Pages/Transfer/Animal';
import Farmer from './Pages/Transfer/Farmer';
import Village from './Pages/Transfer/Village';
import TransferProject from './Pages/Transfer/TransferProject';

import OpeningBalance from './Pages/Straw_Managment/OpeningBalance';
import StrawDistribution from './Pages/Straw_Managment/StrawDistribution';
import StrawDamage from './Pages/Straw_Managment/StrawDamage';
import TransferStraw from './Pages/Straw_Managment/TransferStraw';
import ReturnStrawToSemen from './Pages/Straw_Managment/ReturnStrawToSemen';
import ReturnStrawToSupplier from './Pages/Straw_Managment/ReturnStrawToSupplier';
import MissingStraw from './Pages/Straw_Managment/MissingStraw';
import MannualEntry from './Pages/Straw_Managment/MannualEntry';
import CampsUsage from './Pages/Straw_Managment/CampsUsage';
import Recovery from './Pages/Straw_Managment/Recovery';
import SemenSupplier from './Pages/Straw_Managment/Semen Supplier/SemenSupplier';
import SupplierStock from './Pages/Straw_Managment/SupplierStock';
import SemenReqest from './Pages/Straw_Managment/Semen Supplier/SemenReqest';
import AITStock from './Pages/Straw_Managment/AITStock';

import Opening_Balance from './Pages/Medicine Managment/Opening_Balance';
import MedicineDistribution from './Pages/Medicine Managment/MedicineDistribution';

import MapAnalytics from './Pages/MapAnalytics';
import Doctortrack from './Pages/Doctortrack';
import Allmilkunion from './Pages/Allmilkunion';
import Inventory from './Pages/Inventory';
import FeedManagement from './Pages/Feedmanagement';
import Synclog from './Pages/Synclog';

import Allocation from './Pages/Project/Allocation';
import Vapawerness from './Pages/Project/Vapawarness';
import Campplanning from './Pages/Project/Campplanning';
import Implimentation from './Pages/Project/Implimentation';
import Campreport from './Pages/Project/Campreport';
import Transferanimal from './Pages/Project/Transferanimal';
import Addanimal from './Pages/Project/Addanimal';

import Bulkwisemilk from './Pages/Milk Entry/Bulkwisemilk';
import Milk from './Pages/Milk Entry/Milk';
import MilkPdTest from './Pages/Milk Entry/MilkPdTest';

import DscWise from './Pages/MPP/DscWise';
import FarmerWise from './Pages/MPP/FarmerWise';
import Animal_Wise from './Pages/MPP/AnimalWise';
import Campmaster from './Pages/MPP/Campmaster';

import FipTreatment from './Pages/FIP/FipTreatment';
import LabRegistration from './Pages/Lab Management/LabRegistration';
import TestRequest from './Pages/Lab Management/TestRequest';
import Animalreport from './Pages/MPP/Reports/Animalreport';
import Societyreport from './Pages/MPP/Reports/Societyreport';
import Visit_Registration from './Pages/Visit Management/Visit_Registration';
import AIT_Visit_Registration from './Pages/Visit Management/AIT_Visit_Registration';
import Visit_Reallocation from './Pages/Visit Management/Visit_Reallocation';
import Visit_Cancellation from './Pages/Visit Management/Visit_Cancellation';
import IndividualMilk from './Pages/Milk Entry/IndividualMilk';
import SccMppReport from './Pages/Milk Entry/SccMppReport';
import AnimalQr from './Pages/AnimalQr';
import AhcReport from './Pages/AhcReports/AhcReport';
import Visit_Reallocation_User from './Pages/Visit Management/Visit_Reallocation_User';
import AnimalHistory from './Pages/AnimalHistory'

function App() {
  const profileId = localStorage.getItem("id")

  if (window.location.pathname !== "resgistraion/add" || window.location.pathname !== "Production") {
    localStorage.removeItem("editAnimal")
  }
  if (window.location.pathname !== "Production") {
    localStorage.removeItem("Parity")
    localStorage.removeItem("Fld")
  }
  if (window.location.pathname !== "BreedingDetails") {
    localStorage.removeItem("FarmerId")
    localStorage.removeItem("HerdId")
    localStorage.removeItem("LotId")
  }
  return (
    <>
      <Routes>
        <Route path="/Overview" element={<Overview />}></Route>
        <Route path="/" element={<Login />}></Route>

        {/* Cattle - Management */}
        <Route path="/Cattle-Registration" element={<CattleRegistration />}></Route>
        <Route path="/BreedingDetails" element={<BreedingDetails />}></Route>
        <Route path="/Deworming" element={<Deworming />}></Route>
        <Route path="/Reproduction" element={<Reproduction />}></Route>
        <Route path='/Production' element={<Production />}></Route>
        <Route path='/Treatment' element={<Treatment />}></Route>
        <Route path='/Vaccination' element={<Vaccination />}></Route>
        <Route path='/Weight' element={<Weight />}></Route>
        <Route path='/UserWiseLoginDetail' element={<UserWiseLoginDetail />}></Route>

        {/* User Managment */}
        <Route path="/Userdetails" element={<Userdetails />}></Route>
        {/* <Route path="/Userdetails/Profile/:tableId" element={<UserProfile />}></Route> */}
        <Route path='/UserWiseLoginDetails' element={<UserWiseLoginDetail />}></Route>

        {/* Cattle - Management */}
        <Route path="/Alarm" element={<Alarm />}></Route>
        <Route path='/DailyActionPlan' element={<DailyActionPlan />}></Route>


        {/* Milk Union Reports */}
        <Route path="/AnimalWise" element={<AnimalWise />}></Route>
        <Route path="/HerdWise" element={<HerdWise />}></Route>
        <Route path='/Datewise' element={<Datewise />}></Route>
        <Route path='/CustomReport' element={<CustomReport />}></Route>

        {/* Health */}
        <Route path='/SystemAffected' element={<SystemAffected />}></Route>
        <Route path='/Diagnosis' element={<Diagnosis />}></Route>
        <Route path='/FarmerComplaint' element={<FarmerComplaint />}></Route>

        {/* Master */}
        <Route path='/RouteHerd' element={<RouteHerd />}></Route>
        <Route path='/CcCenter' element={<CcCenter />}></Route>
        <Route path='/ZoneSubCenter' element={<ZoneSubCenter />}></Route>
        <Route path='/SocietyLot' element={<SocietyLot />}></Route>
        <Route path='/OwnerFarmer' element={<OwnerFarmer />}></Route>
        <Route path='/Project' element={<Project />}></Route>
        <Route path='/Sire' element={<Sire />}></Route>
        <Route path='/Staff' element={<Staff />}></Route>
        <Route path='/Insurance' element={<Insurance />}></Route>
        <Route path='/Source' element={<Source />}></Route>
        <Route path='/ClafSchedule' element={<ClafSchedule />}></Route>
        <Route path='/SmsSetting' element={<SmsSetting />}></Route>
        <Route path='/MedicineRoute' element={<MedicineRoute />}></Route>
        <Route path='/DewormingType' element={<DewormingType />}></Route>
        <Route path='/VoPost' element={<VoPost />}></Route>
        <Route path='/VoCategory' element={<VoCategory />}></Route>
        <Route path='/Shift' element={<Shift />}></Route>
        <Route path='/ShiftAllocation' element={<ShiftAllocation />}></Route>

        {/* Transfer */}
        <Route path='/Animal-Transfer' element={<Animal />}></Route>
        <Route path='/Farmer-Transfer' element={<Farmer />}></Route>
        <Route path='/Village-Transfer' element={<Village />}></Route>
        <Route path='/Project-Transfer' element={<TransferProject />}></Route>


        {/* Straw Manaagment */}
        <Route path='/OpeningBalance' element={<OpeningBalance />}></Route>
        <Route path='/StrawDistribution' element={<StrawDistribution />}></Route>
        <Route path='/StrawDamage' element={<StrawDamage />}></Route>
        <Route path='/TransferStraw' element={<TransferStraw />}></Route>
        <Route path='/StrawToSemen' element={<ReturnStrawToSemen />}></Route>
        <Route path='/StrawToSupplier' element={<ReturnStrawToSupplier />}></Route>
        <Route path='/MissingStraw' element={<MissingStraw />}></Route>
        <Route path='/MannualEntry' element={<MannualEntry />}></Route>
        <Route path='/CampUsage' element={<CampsUsage />}></Route>
        <Route path='/Recovery' element={<Recovery />}></Route>
        <Route path='/SupplierStock' element={<SupplierStock />}></Route>
        <Route path='/AITStock' element={<AITStock />}></Route>
        <Route path='/SemenSupplier' element={<SemenSupplier />}></Route>
        <Route path='/SemenRequest' element={<SemenReqest />}></Route>


        {/* Medicine Managment */}
        <Route path='/Opening_Balance' element={<Opening_Balance />}></Route>
        <Route path='/MedicineDistribution' element={<MedicineDistribution />}></Route>

        {/* Map Analytics */}
        <Route path='/MapAnalytics' element={<MapAnalytics />}></Route>

        {/* DoctorTrack */}
        <Route path='/Doctortrack' element={<Doctortrack />}></Route>

        {/* AnimalHistory */}
        <Route path='/AnimalHistory' element={<AnimalHistory />}></Route>

        {/* MPP */}
        <Route path='/Dcswisetest' element={<DscWise />}></Route>
        <Route path='/FarmerWise' element={<FarmerWise />}></Route>
        <Route path='/Animal-Wise' element={<Animal_Wise />}></Route>
        <Route path='/Animalreport' element={<Animalreport />}></Route>
        <Route path='/Societyreport' element={<Societyreport />}></Route>

        {/* milk union */}
        <Route path='/AllMilkUnion' element={<Allmilkunion />}></Route>
        <Route path='/Inventory' element={<Inventory />}></Route>
        <Route path='/FeedManagement' element={<FeedManagement />}></Route>
        <Route path='/Synclog' element={<Synclog />}></Route>

        {/* Project menu */}
        <Route path='/TransferAnimal' element={<Transferanimal />}></Route>
        <Route path='/FipTretment' element={<FipTreatment />}></Route>
        <Route path='/Campmaster' element={<Campmaster />}></Route>
        <Route path='/Allocation' element={<Allocation />}></Route>
        <Route path='/Vapawerness' element={<Vapawerness />}></Route>
        <Route path='/Campplanning' element={<Campplanning />}></Route>
        <Route path='/Implimentation' element={<Implimentation />}></Route>
        <Route path='/Campreport' element={<Campreport />}></Route>
        <Route path='/resgistraion/add' element={<Addanimal />}></Route>

        {/* Milk Entry */}
        <Route path='/Bulk-Wise-MilkEntry' element={<Bulkwisemilk />}></Route>
        <Route path='/MilkTest' element={<Milk />}></Route>
        <Route path='/MilkPdTest' element={<MilkPdTest />}></Route>
        <Route path='/IndividualEntry' element={<IndividualMilk />}></Route>
        <Route path='/SccMppReport' element={<SccMppReport />}></Route>

        {/* Lab Module */}
        <Route path='/LabRegistration' element={<LabRegistration />}></Route>
        <Route path='/TestRequest' element={<TestRequest />}></Route>


        {/* Visit Management */}
        <Route path='/Visit_Registration' element={<Visit_Registration />}></Route>
        <Route path='/AIT_Visit_Registration' element={<AIT_Visit_Registration />}></Route>
        <Route path='/Visit_Reallocation' element={<Visit_Reallocation />}></Route>
        <Route path='/Visit_Cancellation' element={<Visit_Cancellation />}></Route>

        <Route path='/AnimalQr' element={<AnimalQr />}></Route>
        <Route path='/AhcReport' element={<AhcReport />}></Route>
        <Route path='/Visit_Reallocation_User' element={<Visit_Reallocation_User />}></Route>
      </Routes>
    </>
  );
}

export default App;
