import { React, useState } from 'react'
import './datatable.css'
import DataTable, {
  createTheme,
  defaultThemes
} from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'

const customStyles = {
  header: {
    style: {
      minHeight: '76px',
      background: 'red'
    }
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
      borderbottomColor: defaultThemes.default.divider.default
    }
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        borderbottomColor: defaultThemes.default.divider.default
      }
    }
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        borderbottomColor: defaultThemes.default.divider.default
      }
    }
  }
}

createTheme(
  'solarized',
  {
    text: {
      primary: '#000',
      secondary: '#000'
    },
    background: {
      default: '#FFFFFF'
    },
    context: {
      background: '#FFFFFF',
      text: '#000'
    },
    divider: {
      default: '#FFFFFF'
    },
    button: {
      default: '#000',
      hover: '#ECECEC',
      focus: '#ECECEC',
      disabled: '#000'
    },
    sortFocus: {
      default: '#ECECEC'
    }
  },
  'light'
)

const paginationComponentOptions = {
  selectAllRowsItem: true,
  selectAllRowsItemText: 'ALL'
}

export default function Table ({ columns, data, ...props }) {
  const [currentPage, setCurrentPage] = useState(1)
  const rowsPerPage = 50

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * rowsPerPage
  const endIndex = startIndex + rowsPerPage

  // Slice the data to get only the records for the current page
  const paginatedData = data.slice(startIndex, endIndex)

  return (
    <>
      {/* <DataTableExtensions {...tableData} print={false}
            // export={props.export}
            // filter={props.filter}
            > */}
      <DataTable
        className={props.className}
        title={props.title}
        columns={columns}
        data={data}
        noHeader
        defaultSortField={props.sortField}
        defaultSortAsc={true}
        pagination={props.pagination}
        noTableHead={props.noTableHead}
        // noDataComponent="No Record Found"
        noDataComponent=''
        dense
        fixedHeaderScrollHeight={props.height}
        selectableRows={props.selectableRows}
        selectableRowSelected={props.selectableRowSelected}
        onSelectedRowsChange={props.onSelectedRowsChange}
        highlightOnHover
        fixedHeader
        paginationRowsPerPageOptions={[50, 100, 200, 400]}
        // paginationRowsPerPageOptions={[1000, 2000, 3000, 4000]}
        paginationComponentOptions={paginationComponentOptions}
        theme='solarized'
        total={props.total}
        paginationPerPage={50}
        customStyles={customStyles}
        expandableRows={props.expandableRows}
      />
      {/* </DataTableExtensions> */}
    </>
  )
}
